/* eslint:disable */
import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  Date: any;
  JSON: any;
  Timestamp: string;
  URL: string;
  UUID: any;
};

export type AcceptBidInput = {
  bidId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type AcceptBidPayload = {
  __typename?: 'AcceptBidPayload';
  bidGroup: BidGroup;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AcceptCancellationRequestInput = {
  cancellationRequestId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type AcceptCancellationRequestPayload = {
  __typename?: 'AcceptCancellationRequestPayload';
  cancellationRequest: CancellationRequest;
  clientMutationId?: Maybe<Scalars['String']>;
  order: Order;
};

export type AccessTokens = {
  __typename?: 'AccessTokens';
  accessToken: ExpiringToken;
  refreshToken: ExpiringToken;
  userId: Scalars['ID'];
};

export type Account = {
  __typename?: 'Account';
  addresses: Array<UserAddress>;
  bankAccounts: Array<BankAccount>;
  buyerPickupEnabled: Scalars['Boolean'];
  categories: Array<ProductCategory>;
  credit: Credit;
  dateOfBirth?: Maybe<Scalars['Date']>;
  defaultAddress?: Maybe<UserAddress>;
  defaultBankAccount?: Maybe<BankAccount>;
  email: AccountEmail;
  firstName: Scalars['String'];
  hasSeenMigrationAnnouncement: Scalars['Boolean'];
  hasSeenTutorial: Scalars['Boolean'];
  holidayModeEnabled: Scalars['Boolean'];
  inviter?: Maybe<User>;
  kycStatus: KycStatus;
  lastName: Scalars['String'];
  partialAddress?: Maybe<PartialAddress>;
  phoneNumber: AccountPhoneNumber;
  /** @deprecated shoeSize will be removed soon. */
  shoeSize?: Maybe<Scalars['String']>;
  /** @deprecated size will be removed soon. */
  size?: Maybe<Scalars['String']>;
  sizes: Array<ProductSizeOption>;
  stripeAccount?: Maybe<StripeAccount>;
  user: User;
  username: Scalars['String'];
};

export type AccountAddressesArgs = {
  regionId?: InputMaybe<Scalars['ID']>;
};

export type AccountEmail = {
  __typename?: 'AccountEmail';
  emailAddress: Scalars['String'];
  isVerified: Scalars['Boolean'];
};

export type AccountPhoneNumber = {
  __typename?: 'AccountPhoneNumber';
  isVerified: Scalars['Boolean'];
  phoneNumber: Scalars['String'];
};

export type AddBankAccountInput = {
  accountHolderName?: InputMaybe<Scalars['String']>;
  accountNumber: Scalars['String'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  routingNumber: Scalars['String'];
};

export type AddBankAccountPayload = {
  __typename?: 'AddBankAccountPayload';
  account: Account;
  bankAccount: BankAccount;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AddPartialAddressInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  postalCode: Scalars['String'];
};

export type AddPartialAddressPayload = {
  __typename?: 'AddPartialAddressPayload';
  account?: Maybe<Account>;
  clientMutationId?: Maybe<Scalars['String']>;
  partialAddress?: Maybe<PartialAddress>;
};

export type AddProductCommentInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  comment: Scalars['String'];
  productId: Scalars['ID'];
};

export type AddProductCommentPayload = {
  __typename?: 'AddProductCommentPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  comment: ProductComment;
};

export type AddProductInput = {
  biddingEnabled: Scalars['Boolean'];
  brand: Scalars['String'];
  categoryId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  colorIds?: InputMaybe<Array<Scalars['ID']>>;
  condition: ProductCondition;
  description?: InputMaybe<Scalars['String']>;
  imageFileNames?: InputMaybe<Array<Scalars['String']>>;
  isUnisex?: InputMaybe<Scalars['Boolean']>;
  media?: InputMaybe<Array<ImageInput>>;
  originalPrice?: InputMaybe<Scalars['Int']>;
  parcelTypeId?: InputMaybe<Scalars['ID']>;
  price: Scalars['Int'];
  priceExcludingShipping?: InputMaybe<Scalars['Int']>;
  reCaptchaToken?: InputMaybe<Scalars['String']>;
  sizeIds?: InputMaybe<Array<Scalars['ID']>>;
  tags: Array<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type AddProductPayload = {
  __typename?: 'AddProductPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  isFirstUserProduct: Scalars['Boolean'];
  product?: Maybe<Product>;
};

export type AddSavedSearchInput = {
  categoryId?: InputMaybe<Scalars['ID']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  colorIds?: InputMaybe<Array<Scalars['ID']>>;
  conditions?: InputMaybe<Array<ProductCondition>>;
  countries?: InputMaybe<Array<Country>>;
  includeUnisex?: InputMaybe<Scalars['Boolean']>;
  label: Scalars['String'];
  maxPrice?: InputMaybe<Scalars['Int']>;
  minPrice?: InputMaybe<Scalars['Int']>;
  notificationLevel: NotificationLevel;
  onlyPickUpPoints?: InputMaybe<Scalars['Boolean']>;
  query?: InputMaybe<Scalars['String']>;
  sizeIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type AddSavedSearchPayload = {
  __typename?: 'AddSavedSearchPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  savedSearch?: Maybe<SavedSearch>;
};

export type AddToBoardInput = {
  boardId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  productId: Scalars['ID'];
};

export type AddToBoardPayload = {
  __typename?: 'AddToBoardPayload';
  board: Board;
  clientMutationId?: Maybe<Scalars['String']>;
  product: Product;
};

export type AddToCartInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  productId: Scalars['ID'];
  reCaptchaToken?: InputMaybe<Scalars['String']>;
};

export type AddToCartPayload = {
  __typename?: 'AddToCartPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  product: Product;
  shoppingCart: ShoppingCart;
};

export type AddToNextOutletInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  productIds: Array<Scalars['ID']>;
};

export type AddToNextOutletPayload = {
  __typename?: 'AddToNextOutletPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  outlet: Outlet;
  products: Array<Product>;
};

export type AddUserAddressInput = {
  city: Scalars['String'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  country: Country;
  generateLocation?: InputMaybe<Scalars['Boolean']>;
  isDefault: Scalars['Boolean'];
  line1: Scalars['String'];
  line2?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<LatLngInput>;
  name: Scalars['String'];
  postalCode: Scalars['String'];
  regionId: Scalars['ID'];
};

export type AddUserAddressPayload = {
  __typename?: 'AddUserAddressPayload';
  account?: Maybe<Account>;
  address?: Maybe<UserAddress>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AddressRegion = {
  __typename?: 'AddressRegion';
  code: Scalars['String'];
  country: Country;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Addressable = {
  address: Scalars['String'];
};

export type AddressableAddressArgs = {
  compact?: InputMaybe<Scalars['Boolean']>;
};

export type AdminReturnRequestEvent = ReturnRequestEvent & {
  __typename?: 'AdminReturnRequestEvent';
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  initiatedOn: Scalars['Timestamp'];
  state: ReturnRequestState;
  viewerInitiatedEvent: Scalars['Boolean'];
};

export type Allocation = {
  __typename?: 'Allocation';
  experimentId: Scalars['UUID'];
  groupId: Scalars['UUID'];
  id: Scalars['ID'];
  isParticipating: Scalars['Boolean'];
};

export type AlternatingContentItem = {
  __typename?: 'AlternatingContentItem';
  id: Scalars['ID'];
  item: FeedItem;
  position: Scalars['Int'];
};

export type ApproveReturnRequestInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  orderId: Scalars['ID'];
};

export type ApproveReturnRequestPayload = {
  __typename?: 'ApproveReturnRequestPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  order: Order;
};

export enum AuthRole {
  Guest = 'GUEST',
  User = 'USER',
}

export type Badge = {
  backgroundColor: Scalars['String'];
  description: Scalars['String'];
  foregroundColor: Scalars['String'];
  icon: Image;
  title: Scalars['String'];
};

export type BadgeIconArgs = {
  variant?: InputMaybe<BadgeImageVariant>;
};

export enum BadgeImageVariant {
  Closet = 'CLOSET',
  Product = 'PRODUCT',
}

export type BankAccount = {
  __typename?: 'BankAccount';
  accountHolderName: Scalars['String'];
  accountNumberRedacted: Scalars['String'];
  id: Scalars['ID'];
  isDefault: Scalars['Boolean'];
};

export type BasicCartPriceBreakdownItem = CartPriceBreakdownItem & {
  __typename?: 'BasicCartPriceBreakdownItem';
  price: Price;
  pricePlaceholder?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type BidGroup = {
  bids: Array<BidGroupBid>;
  buyer: User;
  id: Scalars['ID'];
  seller: User;
  state: BidGroupState;
  viewerIsSeller: Scalars['Boolean'];
};

export type BidGroupBidsArgs = {
  last?: InputMaybe<Scalars['Int']>;
};

export type BidGroupBid = {
  __typename?: 'BidGroupBid';
  acceptedOn?: Maybe<Scalars['Timestamp']>;
  disbandedOn?: Maybe<Scalars['Timestamp']>;
  expiresOn: Scalars['Timestamp'];
  id: Scalars['ID'];
  price: Price;
  user: User;
  withdrawnOn?: Maybe<Scalars['Timestamp']>;
};

export enum BidGroupState {
  Accepted = 'ACCEPTED',
  Active = 'ACTIVE',
  ActivePlacer = 'ACTIVE_PLACER',
  ActiveReceiver = 'ACTIVE_RECEIVER',
  Canceled = 'CANCELED',
  CounterbidActive = 'COUNTERBID_ACTIVE',
  CounterPlacer = 'COUNTER_PLACER',
  CounterReceiver = 'COUNTER_RECEIVER',
  Disbanded = 'DISBANDED',
  Expired = 'EXPIRED',
  Overbid = 'OVERBID',
  Paid = 'PAID',
  Withdrawn = 'WITHDRAWN',
}

export enum BidGroupType {
  Placed = 'PLACED',
  Received = 'RECEIVED',
}

export type BidGroupsConnection = {
  __typename?: 'BidGroupsConnection';
  nodes: Array<BidGroup>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type BlogPost = {
  __typename?: 'BlogPost';
  content: Scalars['String'];
  date: Scalars['Timestamp'];
  excerpt?: Maybe<Scalars['String']>;
  featureImage?: Maybe<Image>;
  id: Scalars['ID'];
  metaDescription?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  noIndex: Scalars['Boolean'];
  tags: Array<BlogTag>;
  title: Scalars['String'];
  urlKey: Scalars['String'];
};

export type BlogPostFeatureImageArgs = {
  variant?: InputMaybe<BlogPostImageVariant>;
};

export type BlogPostFilters = {
  featured?: InputMaybe<Scalars['Boolean']>;
};

export enum BlogPostImageVariant {
  Original = 'ORIGINAL',
}

export type BlogPostsConnection = {
  __typename?: 'BlogPostsConnection';
  nodes: Array<BlogPost>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type BlogTag = {
  __typename?: 'BlogTag';
  description?: Maybe<Scalars['String']>;
  featureImage?: Maybe<Image>;
  id: Scalars['ID'];
  metaDescription?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  postsConnection: BlogPostsConnection;
  title: Scalars['String'];
  urlKey: Scalars['String'];
};

export type BlogTagFeatureImageArgs = {
  variant?: InputMaybe<BlogTagImageVariant>;
};

export type BlogTagPostsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<BlogPostFilters>;
  first?: InputMaybe<Scalars['Int']>;
};

export enum BlogTagImageVariant {
  Original = 'ORIGINAL',
}

export type Board = {
  __typename?: 'Board';
  id: Scalars['ID'];
  isDefault: Scalars['Boolean'];
  productCount: Scalars['Int'];
  productsConnection: ProductsConnection;
  title: Scalars['String'];
  user: User;
};

export type BoardProductsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type BoardsConnection = {
  __typename?: 'BoardsConnection';
  nodes: Array<Board>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type BrandPage = {
  __typename?: 'BrandPage';
  brandName: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  footerDescription?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isPopular: Scalars['Boolean'];
  language: Scalars['String'];
  metaDescription?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  productCount: Scalars['Int'];
  productsConnection: ProductsConnection;
  relatedBrandPages: Array<BrandPage>;
  relatedKeywordPages: Array<KeywordPage>;
  title: Scalars['String'];
  urlKey: Scalars['String'];
};

export type BrandPageProductsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ProductsOrder>;
};

export type BundleBidGroup = BidGroup & {
  __typename?: 'BundleBidGroup';
  bids: Array<BidGroupBid>;
  buyer: User;
  id: Scalars['ID'];
  image?: Maybe<Image>;
  products: Array<Product>;
  productsTotal?: Maybe<Price>;
  seller: User;
  state: BidGroupState;
  viewerIsSeller: Scalars['Boolean'];
};

export type BundleBidGroupBidsArgs = {
  last?: InputMaybe<Scalars['Int']>;
};

export type BundleBidGroupImageArgs = {
  variant?: InputMaybe<BundleImageVariant>;
};

export enum BundleImageVariant {
  Legacy = 'LEGACY',
}

export type BuyerPickup = {
  __typename?: 'BuyerPickup';
  distance: Distance;
  location: LatLng;
  locationLabel: Scalars['String'];
  range: BuyerPickupRange;
};

export enum BuyerPickupRange {
  Far = 'FAR',
  Nearby = 'NEARBY',
  TooFar = 'TOO_FAR',
}

export type CancelReturnRequestInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  orderId: Scalars['ID'];
};

export type CancelReturnRequestPayload = {
  __typename?: 'CancelReturnRequestPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  order: Order;
};

export type CancellationRequest = {
  __typename?: 'CancellationRequest';
  history: Array<CancellationRequestEvent>;
  id: Scalars['ID'];
  latestEvent: CancellationRequestEvent;
  reason: CancellationRequestReason;
  viewerCanWithdraw: Scalars['Boolean'];
};

export type CancellationRequestEvent = {
  __typename?: 'CancellationRequestEvent';
  explanation?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  initiatedOn: Scalars['Timestamp'];
  state: CancellationRequestState;
  user?: Maybe<User>;
  viewerInitiatedEvent: Scalars['Boolean'];
};

export type CancellationRequestReason = {
  __typename?: 'CancellationRequestReason';
  id: Scalars['ID'];
  title: Scalars['String'];
};

export enum CancellationRequestState {
  AdminApproved = 'ADMIN_APPROVED',
  AdminPending = 'ADMIN_PENDING',
  AdminRejected = 'ADMIN_REJECTED',
  AutoApproved = 'AUTO_APPROVED',
  AutoRejected = 'AUTO_REJECTED',
  BuyerApproved = 'BUYER_APPROVED',
  BuyerDisputed = 'BUYER_DISPUTED',
  BuyerSubmitted = 'BUYER_SUBMITTED',
  BuyerWithdrawn = 'BUYER_WITHDRAWN',
  SellerApproved = 'SELLER_APPROVED',
  SellerDisputed = 'SELLER_DISPUTED',
  SellerSubmitted = 'SELLER_SUBMITTED',
  SellerWithdrawn = 'SELLER_WITHDRAWN',
}

export type CanonicalUrl = {
  __typename?: 'CanonicalUrl';
  url: Scalars['URL'];
};

export type CartGroup = {
  id: Scalars['ID'];
  items: Array<ShoppingCartItem>;
  parcel?: Maybe<Parcel>;
  seller: User;
};

export type CartPayment = {
  __typename?: 'CartPayment';
  id: Scalars['ID'];
};

export type CartPriceBreakdownItem = {
  price: Price;
  pricePlaceholder?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type CategoryAggregationItem = {
  __typename?: 'CategoryAggregationItem';
  categoryId: Scalars['ID'];
  resultCount: Scalars['Int'];
};

export type CategoryPage = {
  __typename?: 'CategoryPage';
  category?: Maybe<ProductCategory>;
  description?: Maybe<Scalars['String']>;
  footerDescription?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  language: Scalars['String'];
  metaDescription?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  productsConnection: ProductsConnection;
  title?: Maybe<Scalars['String']>;
};

export type CategoryPageProductsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<CategoryPageProductFilters>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ProductsOrder>;
};

export type CategoryPageProductFilters = {
  colorIds?: InputMaybe<Array<Scalars['ID']>>;
  conditions?: InputMaybe<Array<ProductCondition>>;
  countries?: InputMaybe<Array<Country>>;
  distance?: InputMaybe<Scalars['Int']>;
  includeUnisex?: InputMaybe<Scalars['Boolean']>;
  maxPrice?: InputMaybe<Scalars['Int']>;
  minPrice?: InputMaybe<Scalars['Int']>;
  onlyOutlet?: InputMaybe<Scalars['Boolean']>;
  onlyPickUpPoints?: InputMaybe<Scalars['Boolean']>;
  query?: InputMaybe<Scalars['String']>;
  sizeIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type Chat = {
  __typename?: 'Chat';
  id: Scalars['ID'];
  messagesConnection: ChatMessagesConnection;
  unreadCount: Scalars['Int'];
  user: User;
};

export type ChatMessagesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type ChatMessage = {
  __typename?: 'ChatMessage';
  body: Scalars['String'];
  id: Scalars['ID'];
  sender: User;
  sentOn: Scalars['Timestamp'];
  viewerIsSender: Scalars['Boolean'];
};

export type ChatMessagesConnection = {
  __typename?: 'ChatMessagesConnection';
  nodes: Array<ChatMessage>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ChatsConnection = {
  __typename?: 'ChatsConnection';
  nodes: Array<Chat>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export enum ClosetUrlAttribution {
  Closet = 'CLOSET',
  None = 'NONE',
}

export type ColorAggregationItem = {
  __typename?: 'ColorAggregationItem';
  colorId: Scalars['ID'];
  resultCount: Scalars['Int'];
};

export type CommunicationSubscription = {
  id: Scalars['ID'];
  title: Scalars['String'];
  viewerHasEnabled: Scalars['Boolean'];
};

export type ConditionAggregationItem = {
  __typename?: 'ConditionAggregationItem';
  condition: ProductCondition;
  resultCount: Scalars['Int'];
};

export type ConfirmEmailAddressInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  emailConfirmationToken: Scalars['String'];
  reCaptchaToken?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type ConfirmEmailAddressPayload = {
  __typename?: 'ConfirmEmailAddressPayload';
  account: Account;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ConfirmPaymentInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  paymentId: Scalars['ID'];
};

export type ConfirmPaymentPayload = {
  __typename?: 'ConfirmPaymentPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  status: PaymentStatus;
};

export type ConsentToMigrationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type ConsentToMigrationPayload = {
  __typename?: 'ConsentToMigrationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  user: User;
};

export enum Country {
  Ca = 'CA',
}

export type CreateBoardInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type CreateBoardPayload = {
  __typename?: 'CreateBoardPayload';
  board: Board;
  clientMutationId?: Maybe<Scalars['String']>;
};

export enum CreateCancellationRequestState {
  Available = 'AVAILABLE',
  ShipmentPending = 'SHIPMENT_PENDING',
  Unavailable = 'UNAVAILABLE',
}

export type CreateKycAddressVerificationSubmissionInput = {
  back?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  front: Scalars['String'];
};

export type CreateKycAddressVerificationSubmissionPayload = {
  __typename?: 'CreateKYCAddressVerificationSubmissionPayload';
  account?: Maybe<Account>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateKycBasicInfoSubmissionInput = {
  accountNumber?: InputMaybe<Scalars['String']>;
  birthDate: Scalars['Date'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  routingNumber?: InputMaybe<Scalars['String']>;
};

export type CreateKycBasicInfoSubmissionPayload = {
  __typename?: 'CreateKYCBasicInfoSubmissionPayload';
  account?: Maybe<Account>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateKycPhotoIdSubmissionInput = {
  back?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  front: Scalars['String'];
};

export type CreateKycPhotoIdSubmissionPayload = {
  __typename?: 'CreateKYCPhotoIdSubmissionPayload';
  account?: Maybe<Account>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateOrderReviewInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  orderId: Scalars['ID'];
  rating: Scalars['Int'];
  review?: InputMaybe<Scalars['String']>;
};

export type CreateOrderReviewPayload = {
  __typename?: 'CreateOrderReviewPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  order: Order;
};

export type CreatePaymentIntentInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  paymentMethodType: PaymentMethodType;
};

export type CreatePaymentIntentPayload = {
  __typename?: 'CreatePaymentIntentPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  clientSecret: Scalars['ID'];
  payment: CartPayment;
};

export type CreatePaymentUrlInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  paymentMethodType: PaymentMethodType;
};

export type CreatePaymentUrlPayload = {
  __typename?: 'CreatePaymentURLPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  payment?: Maybe<CartPayment>;
  url: Scalars['URL'];
};

export type CreatePaymentUrlWithClientKeyInput = {
  clientKey: Scalars['String'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  paymentMethodId: Scalars['String'];
};

export type CreateReturnRequestInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  explanation: Scalars['String'];
  media: Array<ImageInput>;
  orderId: Scalars['ID'];
  reason: ReturnRequestReason;
};

export type CreateReturnRequestPayload = {
  __typename?: 'CreateReturnRequestPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  order: Order;
};

export type CreateShippingLabelInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  orderId: Scalars['ID'];
};

export type CreateShippingLabelPayload = {
  __typename?: 'CreateShippingLabelPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  order: Order;
};

export type CreateStripeAccountLinkInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  returnUrl: Scalars['URL'];
};

export type CreateStripeAccountLinkPayload = {
  __typename?: 'CreateStripeAccountLinkPayload';
  accountLink: Scalars['URL'];
  clientMutationId?: Maybe<Scalars['String']>;
  expiresAt: Scalars['Timestamp'];
};

export type CreateStripeUploadUrlInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  type: StripeUploadType;
};

export type CreateStripeUploadUrlPayload = {
  __typename?: 'CreateStripeUploadUrlPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  publishableKey: Scalars['String'];
  purpose: Scalars['String'];
  url: Scalars['URL'];
};

export type Credit = {
  __typename?: 'Credit';
  expiresOn: Scalars['Timestamp'];
  total: Price;
};

export type Crop = {
  __typename?: 'Crop';
  height: Scalars['Int'];
  width: Scalars['Int'];
  x: Scalars['Int'];
  y: Scalars['Int'];
};

export type CropInput = {
  height: Scalars['Int'];
  width: Scalars['Int'];
  x: Scalars['Int'];
  y: Scalars['Int'];
};

export enum Currency {
  Cad = 'CAD',
}

export type Debug = {
  __typename?: 'Debug';
  localizedError: Scalars['String'];
  normalError: Scalars['String'];
};

export type DeleteAccountInput = {
  additionalExplanation?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  reason: DeleteAccountReason;
};

export type DeleteAccountPayload = {
  __typename?: 'DeleteAccountPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export enum DeleteAccountReason {
  BadBuyerExperience = 'BAD_BUYER_EXPERIENCE',
  BadSellerExperience = 'BAD_SELLER_EXPERIENCE',
  Other = 'OTHER',
  Privacy = 'PRIVACY',
  TooMuchSpam = 'TOO_MUCH_SPAM',
}

export type DeleteBankAccountInput = {
  bankAccountId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type DeleteBankAccountPayload = {
  __typename?: 'DeleteBankAccountPayload';
  account: Account;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteBoardInput = {
  boardId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type DeleteBoardPayload = {
  __typename?: 'DeleteBoardPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  deletedBoardId: Scalars['ID'];
};

export type DeletePhoneNumberInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type DeletePhoneNumberPayload = {
  __typename?: 'DeletePhoneNumberPayload';
  account: Account;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteProductInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  productId: Scalars['ID'];
};

export type DeleteProductPayload = {
  __typename?: 'DeleteProductPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  /** @deprecated use product.id instead. */
  deletedProductId: Scalars['ID'];
  product: Product;
};

export type DeleteSavedSearchInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  savedSearchId: Scalars['ID'];
};

export type DeleteSavedSearchPayload = {
  __typename?: 'DeleteSavedSearchPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  deletedSavedSearchId: Scalars['ID'];
};

export type DeleteUserAddressInput = {
  addressId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type DeleteUserAddressPayload = {
  __typename?: 'DeleteUserAddressPayload';
  account?: Maybe<Account>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export enum DeliveryType {
  BuyerPickup = 'BUYER_PICKUP',
  NotSet = 'NOT_SET',
  Shipment = 'SHIPMENT',
}

export type DescriptionCartPriceBreakdownItem = CartPriceBreakdownItem & {
  __typename?: 'DescriptionCartPriceBreakdownItem';
  description?: Maybe<Scalars['String']>;
  icon: Image;
  price: Price;
  pricePlaceholder?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type DescriptionCartPriceBreakdownItemIconArgs = {
  variant?: InputMaybe<PriceBreakdownImageVariant>;
};

export type DisableBuyerPickupInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type DisableBuyerPickupPayload = {
  __typename?: 'DisableBuyerPickupPayload';
  account?: Maybe<Account>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DisableShipperServicePointsInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  shipperId: Scalars['ID'];
};

export type DisableShipperServicePointsPayload = {
  __typename?: 'DisableShipperServicePointsPayload';
  account?: Maybe<Account>;
  clientMutationId?: Maybe<Scalars['String']>;
  shipper?: Maybe<Shipper>;
};

export type DisputeCancellationRequestInput = {
  cancellationRequestId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  explanation: Scalars['String'];
};

export type DisputeCancellationRequestPayload = {
  __typename?: 'DisputeCancellationRequestPayload';
  cancellationRequest: CancellationRequest;
  clientMutationId?: Maybe<Scalars['String']>;
  order: Order;
};

export type DisputeReturnRequestInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  explanation: Scalars['String'];
  media: Array<ImageInput>;
  orderId: Scalars['ID'];
};

export type DisputeReturnRequestPayload = {
  __typename?: 'DisputeReturnRequestPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  order: Order;
};

export type Distance = {
  __typename?: 'Distance';
  amount: Scalars['Float'];
  unit: DistanceUnit;
};

export enum DistanceUnit {
  Km = 'KM',
}

export type EmailSubscription = CommunicationSubscription & {
  __typename?: 'EmailSubscription';
  id: Scalars['ID'];
  title: Scalars['String'];
  viewerHasEnabled: Scalars['Boolean'];
};

export type EmailSubscriptionAuthorisationInput = {
  authCode: Scalars['String'];
  email: Scalars['String'];
  userId: Scalars['ID'];
};

export type EnableBuyerPickupInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type EnableBuyerPickupPayload = {
  __typename?: 'EnableBuyerPickupPayload';
  account?: Maybe<Account>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type EnableShipperServicePointsInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  shipperId: Scalars['ID'];
};

export type EnableShipperServicePointsPayload = {
  __typename?: 'EnableShipperServicePointsPayload';
  account?: Maybe<Account>;
  clientMutationId?: Maybe<Scalars['String']>;
  shipper?: Maybe<Shipper>;
};

export type ExpiringToken = {
  __typename?: 'ExpiringToken';
  expiresAt: Scalars['Timestamp'];
  token: Scalars['String'];
};

export type FavoriteProductInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  productId: Scalars['ID'];
};

export type FavoriteProductPayload = {
  __typename?: 'FavoriteProductPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
};

export type FeedItem = {
  id: Scalars['ID'];
  style: FeedItemStyle;
};

export enum FeedItemStyle {
  Small = 'SMALL',
  Wide = 'WIDE',
}

export type FollowUserInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type FollowUserPayload = {
  __typename?: 'FollowUserPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  user: User;
};

export type ForgotPasswordInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  emailAddress: Scalars['String'];
};

export type ForgotPasswordPayload = {
  __typename?: 'ForgotPasswordPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  emailAddress: Scalars['String'];
};

export type GenderAggregationItem = {
  __typename?: 'GenderAggregationItem';
  gender: ProductGender;
  resultCount: Scalars['Int'];
};

export type HtmlFeedItem = FeedItem & {
  __typename?: 'HTMLFeedItem';
  campaignId: Scalars['String'];
  html: Scalars['String'];
  id: Scalars['ID'];
  style: FeedItemStyle;
};

export type HeartBeat = {
  __typename?: 'HeartBeat';
  sessionId: Scalars['String'];
  unreadChatMessagesCount: Scalars['Int'];
  unreadNotificationsCount: Scalars['Int'];
};

export type HtmlDocument = {
  __typename?: 'HtmlDocument';
  html: Scalars['String'];
};

export enum HtmlDocumentName {
  CookieStatement = 'COOKIE_STATEMENT',
  PrivacyStatement = 'PRIVACY_STATEMENT',
  TermsAndConditions = 'TERMS_AND_CONDITIONS',
}

export type Image = {
  __typename?: 'Image';
  url: Scalars['URL'];
};

export type ImageInput = {
  crop?: InputMaybe<CropInput>;
  id: Scalars['ID'];
};

export type KycRequirement = {
  __typename?: 'KYCRequirement';
  accountLink: KycRequirementStatus;
  addressVerification: KycRequirementStatus;
  basicInfo: KycRequirementStatus;
  photoId: KycRequirementStatus;
};

export enum KycRequirementStatus {
  Approved = 'APPROVED',
  NotRequired = 'NOT_REQUIRED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Required = 'REQUIRED',
}

export enum KycStatus {
  AccountLinkRequired = 'ACCOUNT_LINK_REQUIRED',
  AddressVerificationRequired = 'ADDRESS_VERIFICATION_REQUIRED',
  Approved = 'APPROVED',
  BasicInfoRequired = 'BASIC_INFO_REQUIRED',
  Pending = 'PENDING',
  PhotoIdRequired = 'PHOTO_ID_REQUIRED',
}

export type KeepOrderInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  orderId: Scalars['ID'];
};

export type KeepOrderPayload = {
  __typename?: 'KeepOrderPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  order: Order;
};

export type KeywordPage = {
  __typename?: 'KeywordPage';
  description: Scalars['String'];
  filters: KeywordPageFilters;
  id: Scalars['ID'];
  language: Scalars['String'];
  metaDescription?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  productCount: Scalars['Int'];
  productsConnection: ProductsConnection;
  relatedKeywordPages: Array<KeywordPage>;
  title: Scalars['String'];
  urlKey: Scalars['String'];
};

export type KeywordPageProductsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type KeywordPageFilters = {
  __typename?: 'KeywordPageFilters';
  category?: Maybe<ProductCategory>;
  query?: Maybe<Scalars['String']>;
};

export type LabelOrderShipment = OrderShipment & {
  __typename?: 'LabelOrderShipment';
  costs: Price;
  destination: ShipmentDestination;
  label: OrderShipmentLabel;
  parcel: Parcel;
  receivedOn?: Maybe<Scalars['Timestamp']>;
  shippedOn?: Maybe<Scalars['Timestamp']>;
  tracking?: Maybe<ShipmentTracking>;
};

export enum Language {
  En = 'en',
  Fr = 'fr',
}

export type LatLng = {
  __typename?: 'LatLng';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type LatLngInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export enum LegacyExtension {
  Jpg = 'JPG',
  Mov = 'MOV',
  Mp4 = 'MP4',
  Png = 'PNG',
}

export type LegacyFinishUploadsInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  uploadedMedia: Array<LegacyUploadedMedia>;
};

export type LegacyFinishUploadsPayload = {
  __typename?: 'LegacyFinishUploadsPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type LegacyMediaRequest = {
  extension: LegacyExtension;
  mediaType: LegacyMediaType;
  objectType: LegacyObjectType;
};

export type LegacyMediaResponse = {
  __typename?: 'LegacyMediaResponse';
  mediaId?: Maybe<Scalars['String']>;
  uploadURL?: Maybe<Scalars['String']>;
};

export enum LegacyMediaType {
  Photo = 'PHOTO',
  Video = 'VIDEO',
}

export enum LegacyObjectType {
  ChannelImage = 'CHANNEL_IMAGE',
  ChannelPost = 'CHANNEL_POST',
  Chat = 'CHAT',
  Product = 'PRODUCT',
  UserCover = 'USER_COVER',
  UserProfile = 'USER_PROFILE',
}

export type LegacyProductCommentTranslation = {
  __typename?: 'LegacyProductCommentTranslation';
  body: Scalars['String'];
  originalComment: ProductComment;
};

export type LegacyRequestUploadsInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  mediaRequests: Array<LegacyMediaRequest>;
};

export type LegacyRequestUploadsPayload = {
  __typename?: 'LegacyRequestUploadsPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  mediaResponse: Array<LegacyMediaResponse>;
};

export type LegacySendEventBatchInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  events: Array<Scalars['JSON']>;
};

export type LegacySendEventBatchPayload = {
  __typename?: 'LegacySendEventBatchPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type LegacyUploadedMedia = {
  mediaId: Scalars['String'];
};

export type LoginInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  emailAddress: Scalars['String'];
  password: Scalars['String'];
};

export type LoginPayload = {
  __typename?: 'LoginPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  tokens: AccessTokens;
};

export type LogoutInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type LogoutPayload = {
  __typename?: 'LogoutPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type MarkBankAccountAsDefaultInput = {
  bankAccountId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type MarkBankAccountAsDefaultPayload = {
  __typename?: 'MarkBankAccountAsDefaultPayload';
  account: Account;
  bankAccount: BankAccount;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type MarkMigrationAnnouncementAsSeenInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type MarkMigrationAnnouncementAsSeenPayload = {
  __typename?: 'MarkMigrationAnnouncementAsSeenPayload';
  account: Account;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type MarkTutorialAsViewedInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type MarkTutorialAsViewedPayload = {
  __typename?: 'MarkTutorialAsViewedPayload';
  account: Account;
  clientMutationId?: Maybe<Scalars['String']>;
};

export enum MediaContentType {
  ImageJpeg = 'IMAGE_JPEG',
  ImagePng = 'IMAGE_PNG',
}

export enum MediaReferenceType {
  Chat = 'CHAT',
  Cover = 'COVER',
  Product = 'PRODUCT',
  Profile = 'PROFILE',
  ReturnRequest = 'RETURN_REQUEST',
}

export type MediaUploadRequest = {
  __typename?: 'MediaUploadRequest';
  id: Scalars['ID'];
  url: Scalars['URL'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptBid?: Maybe<AcceptBidPayload>;
  acceptCancellationRequest?: Maybe<AcceptCancellationRequestPayload>;
  addBankAccount?: Maybe<AddBankAccountPayload>;
  addPartialAddress?: Maybe<AddPartialAddressPayload>;
  addProduct?: Maybe<AddProductPayload>;
  addProductComment?: Maybe<AddProductCommentPayload>;
  addSavedSearch?: Maybe<AddSavedSearchPayload>;
  addToBoard?: Maybe<AddToBoardPayload>;
  addToCart?: Maybe<AddToCartPayload>;
  addToNextOutlet?: Maybe<AddToNextOutletPayload>;
  addUserAddress?: Maybe<AddUserAddressPayload>;
  approveReturnRequest?: Maybe<ApproveReturnRequestPayload>;
  cancelReturnRequest?: Maybe<CancelReturnRequestPayload>;
  confirmEmailAddress?: Maybe<ConfirmEmailAddressPayload>;
  confirmPayment?: Maybe<ConfirmPaymentPayload>;
  consentToMigration?: Maybe<ConsentToMigrationPayload>;
  createBoard?: Maybe<CreateBoardPayload>;
  createKYCAddressVerificationSubmission?: Maybe<CreateKycAddressVerificationSubmissionPayload>;
  createKYCBasicInfoSubmission?: Maybe<CreateKycBasicInfoSubmissionPayload>;
  createKYCPhotoIdSubmission?: Maybe<CreateKycPhotoIdSubmissionPayload>;
  createOrderReview?: Maybe<CreateOrderReviewPayload>;
  createPaymentIntent?: Maybe<CreatePaymentIntentPayload>;
  createPaymentURL?: Maybe<CreatePaymentUrlPayload>;
  createPaymentUrlWithClientKey?: Maybe<CreatePaymentUrlPayload>;
  createReturnRequest?: Maybe<CreateReturnRequestPayload>;
  createShippingLabel?: Maybe<CreateShippingLabelPayload>;
  createStripeAccountLink?: Maybe<CreateStripeAccountLinkPayload>;
  createStripeUploadUrl?: Maybe<CreateStripeUploadUrlPayload>;
  deleteAccount?: Maybe<DeleteAccountPayload>;
  deleteBankAccount?: Maybe<DeleteBankAccountPayload>;
  deleteBoard?: Maybe<DeleteBoardPayload>;
  deletePhoneNumber?: Maybe<DeletePhoneNumberPayload>;
  deleteProduct?: Maybe<DeleteProductPayload>;
  deleteSavedSearch?: Maybe<DeleteSavedSearchPayload>;
  deleteUserAddress?: Maybe<DeleteUserAddressPayload>;
  disableBuyerPickup?: Maybe<DisableBuyerPickupPayload>;
  disableShipperServicePoints?: Maybe<DisableShipperServicePointsPayload>;
  disputeCancellationRequest?: Maybe<DisputeCancellationRequestPayload>;
  disputeReturnRequest?: Maybe<DisputeReturnRequestPayload>;
  enableBuyerPickup?: Maybe<EnableBuyerPickupPayload>;
  enableShipperServicePoints?: Maybe<EnableShipperServicePointsPayload>;
  favoriteProduct?: Maybe<FavoriteProductPayload>;
  followUser?: Maybe<FollowUserPayload>;
  forgotPassword?: Maybe<ForgotPasswordPayload>;
  keepOrder?: Maybe<KeepOrderPayload>;
  legacyAcceptBid?: Maybe<Scalars['JSON']>;
  legacyAcceptInvite?: Maybe<Scalars['JSON']>;
  legacyAddComment?: Maybe<Scalars['JSON']>;
  legacyAddDevice?: Maybe<Scalars['JSON']>;
  legacyApproveReturnRequest?: Maybe<Scalars['JSON']>;
  legacyCancelReturnRequest?: Maybe<Scalars['JSON']>;
  legacyConfirmPhoneVerification?: Maybe<Scalars['JSON']>;
  /** @deprecated disbanded bid is no longer available, replaced with auto-cancellation. */
  legacyDisbandBid?: Maybe<Scalars['JSON']>;
  legacyDisputeReturnRequest?: Maybe<Scalars['JSON']>;
  legacyFingerprintUtmTags?: Maybe<Scalars['JSON']>;
  /** @deprecated no longer in use, making the mutation has no effect. To confirm a upload use dedicated mutations. */
  legacyFinishUploads?: Maybe<LegacyFinishUploadsPayload>;
  legacyFinishedTutorial?: Maybe<Scalars['JSON']>;
  legacyMarkAllNotificationsAsRead?: Maybe<Scalars['JSON']>;
  legacyMarkNotificationAsClicked?: Maybe<Scalars['JSON']>;
  legacyPlaceBid?: Maybe<Scalars['JSON']>;
  legacyPostConversationMessage?: Maybe<Scalars['JSON']>;
  legacyPostRating?: Maybe<Scalars['JSON']>;
  legacyProductSubscribe?: Maybe<Scalars['JSON']>;
  legacyProductUnsubscribe?: Maybe<Scalars['JSON']>;
  legacyReactivateProducts?: Maybe<Scalars['JSON']>;
  legacyReducePrices?: Maybe<Scalars['JSON']>;
  legacyReportProduct?: Maybe<Scalars['JSON']>;
  legacyRepostProduct?: Maybe<Scalars['JSON']>;
  legacyRequestReactivation?: Maybe<Scalars['JSON']>;
  /** @deprecated use requestMediaUploads mutation instead. */
  legacyRequestUploads?: Maybe<LegacyRequestUploadsPayload>;
  legacySendEventBatch?: Maybe<LegacySendEventBatchPayload>;
  legacySetTodoProgress?: Maybe<Scalars['JSON']>;
  legacySubmitReturnRequest?: Maybe<Scalars['JSON']>;
  legacyTrackEvent?: Maybe<Scalars['JSON']>;
  legacyTrackSearch?: Maybe<Scalars['JSON']>;
  legacyUpdateUser?: Maybe<Scalars['JSON']>;
  legacyVerifyPhone?: Maybe<Scalars['JSON']>;
  legacyWithdrawBid?: Maybe<Scalars['JSON']>;
  login?: Maybe<LoginPayload>;
  logout?: Maybe<LogoutPayload>;
  markBankAccountAsDefault?: Maybe<MarkBankAccountAsDefaultPayload>;
  markMigrationAnnouncementAsSeen?: Maybe<MarkMigrationAnnouncementAsSeenPayload>;
  markTutorialAsViewed?: Maybe<MarkTutorialAsViewedPayload>;
  participateInExperiment?: Maybe<ParticipateInExperimentPayload>;
  placeBundleBid?: Maybe<PlaceBundleBidPayload>;
  placeCounterBid?: Maybe<PlaceCounterBidPayload>;
  placeMassProductBid?: Maybe<PlaceMassProductBidPayload>;
  placeProActiveProductBid?: Maybe<PlaceProActiveProductBidPayload>;
  placeProductBid?: Maybe<PlaceProductBidPayload>;
  reactivateProducts?: Maybe<ReactivateProductsPayload>;
  receiveOrder?: Maybe<ReceiveOrderPayload>;
  receiveReturnedOrder?: Maybe<ReceiveReturnedOrderPayload>;
  refreshAccessToken?: Maybe<RefreshAccessTokenPayload>;
  registerAccount?: Maybe<RegisterAccountPayload>;
  removeFromCart?: Maybe<RemoveFromCartPayload>;
  reportProduct?: Maybe<ReportProductPayload>;
  repostOrder?: Maybe<RepostOrderPayload>;
  requestMediaUploads?: Maybe<RequestMediaUploadsPayload>;
  resetPassword?: Maybe<ResetPasswordPayload>;
  sendChatMessage?: Maybe<SendChatMessagePayload>;
  sendOrder?: Maybe<SendOrderPayload>;
  setAddressForCartGroup?: Maybe<SetAddressForCartGroupPayload>;
  setBuyerPickupForCartGroup?: Maybe<SetBuyerPickupForCartGroupPayload>;
  setOrderTrackingCode?: Maybe<SetOrderTrackingCodePayload>;
  setParcelForCartGroup?: Maybe<SetParcelForCartGroupPayload>;
  setServicePointForCartGroup?: Maybe<SetServicePointForCartGroupPayload>;
  submitCancellationRequest?: Maybe<SubmitCancellationRequestPayload>;
  thirdPartyAuth?: Maybe<ThirdPartyAuthPayload>;
  unfavoriteProduct?: Maybe<UnfavoriteProductPayload>;
  unfollowUser?: Maybe<UnfollowUserPayload>;
  unsubscribeFromAllEmails?: Maybe<UnsubscribeFromAllEmailsPayload>;
  updateAccountCategories?: Maybe<UpdateAccountCategoriesPayload>;
  updateAccountSizes?: Maybe<UpdateAccountSizesPayload>;
  updateBoard?: Maybe<UpdateBoardPayload>;
  updateCoverImage?: Maybe<UpdateCoverImagePayload>;
  updateDateOfBirth?: Maybe<UpdateDateOfBirthPayload>;
  updateEmailAddress?: Maybe<UpdateEmailAddressPayload>;
  updateEmailSubscription?: Maybe<UpdateEmailSubscriptionPayload>;
  updateHolidayMode?: Maybe<UpdateHolidayModePayload>;
  updateLocale?: Maybe<UpdateLocalePayload>;
  updateName?: Maybe<UpdateNamePayload>;
  updatePhoneNumber?: Maybe<UpdatePhoneNumberPayload>;
  updateProduct?: Maybe<UpdateProductPayload>;
  updateProfileImage?: Maybe<UpdateProfileImagePayload>;
  updatePushNotificationSubscription?: Maybe<UpdatePushNotificationSubscriptionPayload>;
  updateSavedSearch?: Maybe<UpdateSavedSearchPayload>;
  updateStripeAccount?: Maybe<UpdateStripeAccountPayload>;
  updateTradeMode?: Maybe<UpdateTradeModePayload>;
  updateUserAddress?: Maybe<UpdateUserAddressPayload>;
  verifyPhoneNumber?: Maybe<VerifyPhoneNumberPayload>;
  withdrawBid?: Maybe<WithdrawBidPayload>;
  withdrawCancellationRequest?: Maybe<WithdrawCancellationRequestPayload>;
};

export type MutationAcceptBidArgs = {
  input: AcceptBidInput;
};

export type MutationAcceptCancellationRequestArgs = {
  input: AcceptCancellationRequestInput;
};

export type MutationAddBankAccountArgs = {
  input: AddBankAccountInput;
};

export type MutationAddPartialAddressArgs = {
  input: AddPartialAddressInput;
};

export type MutationAddProductArgs = {
  input: AddProductInput;
};

export type MutationAddProductCommentArgs = {
  input: AddProductCommentInput;
};

export type MutationAddSavedSearchArgs = {
  input: AddSavedSearchInput;
};

export type MutationAddToBoardArgs = {
  input: AddToBoardInput;
};

export type MutationAddToCartArgs = {
  input: AddToCartInput;
};

export type MutationAddToNextOutletArgs = {
  input: AddToNextOutletInput;
};

export type MutationAddUserAddressArgs = {
  input: AddUserAddressInput;
};

export type MutationApproveReturnRequestArgs = {
  input: ApproveReturnRequestInput;
};

export type MutationCancelReturnRequestArgs = {
  input: CancelReturnRequestInput;
};

export type MutationConfirmEmailAddressArgs = {
  input: ConfirmEmailAddressInput;
};

export type MutationConfirmPaymentArgs = {
  input: ConfirmPaymentInput;
};

export type MutationConsentToMigrationArgs = {
  input: ConsentToMigrationInput;
};

export type MutationCreateBoardArgs = {
  input: CreateBoardInput;
};

export type MutationCreateKycAddressVerificationSubmissionArgs = {
  input: CreateKycAddressVerificationSubmissionInput;
};

export type MutationCreateKycBasicInfoSubmissionArgs = {
  input: CreateKycBasicInfoSubmissionInput;
};

export type MutationCreateKycPhotoIdSubmissionArgs = {
  input: CreateKycPhotoIdSubmissionInput;
};

export type MutationCreateOrderReviewArgs = {
  input: CreateOrderReviewInput;
};

export type MutationCreatePaymentIntentArgs = {
  input: CreatePaymentIntentInput;
};

export type MutationCreatePaymentUrlArgs = {
  input: CreatePaymentUrlInput;
};

export type MutationCreatePaymentUrlWithClientKeyArgs = {
  input?: InputMaybe<CreatePaymentUrlWithClientKeyInput>;
};

export type MutationCreateReturnRequestArgs = {
  input: CreateReturnRequestInput;
};

export type MutationCreateShippingLabelArgs = {
  input: CreateShippingLabelInput;
};

export type MutationCreateStripeAccountLinkArgs = {
  input: CreateStripeAccountLinkInput;
};

export type MutationCreateStripeUploadUrlArgs = {
  input: CreateStripeUploadUrlInput;
};

export type MutationDeleteAccountArgs = {
  input: DeleteAccountInput;
};

export type MutationDeleteBankAccountArgs = {
  input: DeleteBankAccountInput;
};

export type MutationDeleteBoardArgs = {
  input: DeleteBoardInput;
};

export type MutationDeletePhoneNumberArgs = {
  input: DeletePhoneNumberInput;
};

export type MutationDeleteProductArgs = {
  input: DeleteProductInput;
};

export type MutationDeleteSavedSearchArgs = {
  input: DeleteSavedSearchInput;
};

export type MutationDeleteUserAddressArgs = {
  input: DeleteUserAddressInput;
};

export type MutationDisableBuyerPickupArgs = {
  input: DisableBuyerPickupInput;
};

export type MutationDisableShipperServicePointsArgs = {
  input: DisableShipperServicePointsInput;
};

export type MutationDisputeCancellationRequestArgs = {
  input: DisputeCancellationRequestInput;
};

export type MutationDisputeReturnRequestArgs = {
  input: DisputeReturnRequestInput;
};

export type MutationEnableBuyerPickupArgs = {
  input: EnableBuyerPickupInput;
};

export type MutationEnableShipperServicePointsArgs = {
  input: EnableShipperServicePointsInput;
};

export type MutationFavoriteProductArgs = {
  input: FavoriteProductInput;
};

export type MutationFollowUserArgs = {
  input: FollowUserInput;
};

export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};

export type MutationKeepOrderArgs = {
  input: KeepOrderInput;
};

export type MutationLegacyAcceptBidArgs = {
  bid_id: Scalars['Int'];
};

export type MutationLegacyAcceptInviteArgs = {
  inviter_id: Scalars['Int'];
};

export type MutationLegacyAddCommentArgs = {
  comment: Scalars['String'];
  product_id: Scalars['Int'];
};

export type MutationLegacyAddDeviceArgs = {
  debug: Scalars['Boolean'];
  description: Scalars['String'];
  device_token: Scalars['String'];
};

export type MutationLegacyApproveReturnRequestArgs = {
  order_id: Scalars['Int'];
};

export type MutationLegacyCancelReturnRequestArgs = {
  order_id: Scalars['Int'];
};

export type MutationLegacyConfirmPhoneVerificationArgs = {
  sms_token: Scalars['String'];
};

export type MutationLegacyDisbandBidArgs = {
  bid_id: Scalars['Int'];
};

export type MutationLegacyDisputeReturnRequestArgs = {
  comments: Scalars['String'];
  images_list?: InputMaybe<Scalars['String']>;
  order_id: Scalars['Int'];
};

export type MutationLegacyFingerprintUtmTagsArgs = {
  device_pixel_ratio: Scalars['String'];
  ios_version: Scalars['String'];
  resolution: Scalars['String'];
};

export type MutationLegacyFinishUploadsArgs = {
  input?: InputMaybe<LegacyFinishUploadsInput>;
};

export type MutationLegacyMarkNotificationAsClickedArgs = {
  notification_id: Scalars['Int'];
};

export type MutationLegacyPlaceBidArgs = {
  bid_amount: Scalars['Int'];
  bid_id?: InputMaybe<Scalars['Int']>;
  bundle_id?: InputMaybe<Scalars['Int']>;
  product_id?: InputMaybe<Scalars['Int']>;
  product_ids?: InputMaybe<Scalars['String']>;
};

export type MutationLegacyPostConversationMessageArgs = {
  media_id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  other_user: Scalars['Int'];
};

export type MutationLegacyPostRatingArgs = {
  order_id: Scalars['Int'];
  rating: Scalars['Int'];
  text?: InputMaybe<Scalars['String']>;
};

export type MutationLegacyProductSubscribeArgs = {
  product_id: Scalars['Int'];
};

export type MutationLegacyProductUnsubscribeArgs = {
  product_id: Scalars['Int'];
};

export type MutationLegacyReactivateProductsArgs = {
  product_ids: Scalars['String'];
};

export type MutationLegacyReducePricesArgs = {
  product_ids: Scalars['String'];
  reduction: Scalars['Float'];
};

export type MutationLegacyReportProductArgs = {
  product_id: Scalars['Int'];
  reason: Scalars['Int'];
};

export type MutationLegacyRepostProductArgs = {
  order_id: Scalars['Int'];
};

export type MutationLegacyRequestReactivationArgs = {
  product_id: Scalars['Int'];
};

export type MutationLegacyRequestUploadsArgs = {
  input?: InputMaybe<LegacyRequestUploadsInput>;
};

export type MutationLegacySendEventBatchArgs = {
  input: LegacySendEventBatchInput;
};

export type MutationLegacySetTodoProgressArgs = {
  field: Scalars['String'];
  todo_finished: Scalars['Boolean'];
  value: Scalars['String'];
};

export type MutationLegacySubmitReturnRequestArgs = {
  comments: Scalars['String'];
  images_list?: InputMaybe<Scalars['String']>;
  order_id: Scalars['Int'];
};

export type MutationLegacyTrackEventArgs = {
  data?: InputMaybe<Scalars['JSON']>;
  session_id: Scalars['String'];
  value: Scalars['String'];
};

export type MutationLegacyTrackSearchArgs = {
  query?: InputMaybe<Scalars['String']>;
  user_id: Scalars['Int'];
};

export type MutationLegacyUpdateUserArgs = {
  data: Scalars['JSON'];
};

export type MutationLegacyVerifyPhoneArgs = {
  phone: Scalars['BigInt'];
};

export type MutationLegacyWithdrawBidArgs = {
  bid_id: Scalars['Int'];
};

export type MutationLoginArgs = {
  input: LoginInput;
};

export type MutationLogoutArgs = {
  input: LogoutInput;
};

export type MutationMarkBankAccountAsDefaultArgs = {
  input: MarkBankAccountAsDefaultInput;
};

export type MutationMarkMigrationAnnouncementAsSeenArgs = {
  input: MarkMigrationAnnouncementAsSeenInput;
};

export type MutationMarkTutorialAsViewedArgs = {
  input: MarkTutorialAsViewedInput;
};

export type MutationParticipateInExperimentArgs = {
  input: ParticipateInExperimentInput;
};

export type MutationPlaceBundleBidArgs = {
  input: PlaceBundleBidInput;
};

export type MutationPlaceCounterBidArgs = {
  input: PlaceCounterBidInput;
};

export type MutationPlaceMassProductBidArgs = {
  input: PlaceMassProductBidInput;
};

export type MutationPlaceProActiveProductBidArgs = {
  input: PlaceProActiveProductBidInput;
};

export type MutationPlaceProductBidArgs = {
  input: PlaceProductBidInput;
};

export type MutationReactivateProductsArgs = {
  input: ReactivateProductsInput;
};

export type MutationReceiveOrderArgs = {
  input: ReceiveOrderInput;
};

export type MutationReceiveReturnedOrderArgs = {
  input: ReceiveReturnedOrderInput;
};

export type MutationRefreshAccessTokenArgs = {
  input: RefreshAccessTokenInput;
};

export type MutationRegisterAccountArgs = {
  input: RegisterAccountInput;
};

export type MutationRemoveFromCartArgs = {
  input: RemoveFromCartInput;
};

export type MutationReportProductArgs = {
  input: ReportProductInput;
};

export type MutationRepostOrderArgs = {
  input: RepostOrderInput;
};

export type MutationRequestMediaUploadsArgs = {
  input?: InputMaybe<RequestMediaUploadsInput>;
};

export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};

export type MutationSendChatMessageArgs = {
  input: SendChatMessageInput;
};

export type MutationSendOrderArgs = {
  input: SendOrderInput;
};

export type MutationSetAddressForCartGroupArgs = {
  input: SetAddressForCartGroupInput;
};

export type MutationSetBuyerPickupForCartGroupArgs = {
  input: SetBuyerPickupForCartGroupInput;
};

export type MutationSetOrderTrackingCodeArgs = {
  input: SetOrderTrackingCodeInput;
};

export type MutationSetParcelForCartGroupArgs = {
  input: SetParcelForCartGroupInput;
};

export type MutationSetServicePointForCartGroupArgs = {
  input: SetServicePointForCartGroupInput;
};

export type MutationSubmitCancellationRequestArgs = {
  input: SubmitCancellationRequestInput;
};

export type MutationThirdPartyAuthArgs = {
  input: ThirdPartyAuthInput;
};

export type MutationUnfavoriteProductArgs = {
  input: UnfavoriteProductInput;
};

export type MutationUnfollowUserArgs = {
  input: UnfollowUserInput;
};

export type MutationUnsubscribeFromAllEmailsArgs = {
  input: UnsubscribeFromAllEmailsInput;
};

export type MutationUpdateAccountCategoriesArgs = {
  input: UpdateAccountCategoriesInput;
};

export type MutationUpdateAccountSizesArgs = {
  input: UpdateAccountSizesInput;
};

export type MutationUpdateBoardArgs = {
  input: UpdateBoardInput;
};

export type MutationUpdateCoverImageArgs = {
  input: UpdateCoverImageInput;
};

export type MutationUpdateDateOfBirthArgs = {
  input: UpdateDateOfBirthInput;
};

export type MutationUpdateEmailAddressArgs = {
  input: UpdateEmailAddressInput;
};

export type MutationUpdateEmailSubscriptionArgs = {
  input: UpdateEmailSubscriptionInput;
};

export type MutationUpdateHolidayModeArgs = {
  input: UpdateHolidayModeInput;
};

export type MutationUpdateLocaleArgs = {
  input: UpdateLocaleInput;
};

export type MutationUpdateNameArgs = {
  input: UpdateNameInput;
};

export type MutationUpdatePhoneNumberArgs = {
  input: UpdatePhoneNumberInput;
};

export type MutationUpdateProductArgs = {
  input: UpdateProductInput;
};

export type MutationUpdateProfileImageArgs = {
  input: UpdateProfileImageInput;
};

export type MutationUpdatePushNotificationSubscriptionArgs = {
  input: UpdatePushNotificationSubscriptionInput;
};

export type MutationUpdateSavedSearchArgs = {
  input: UpdateSavedSearchInput;
};

export type MutationUpdateStripeAccountArgs = {
  input: UpdateStripeAccountInput;
};

export type MutationUpdateTradeModeArgs = {
  input: UpdateTradeModeInput;
};

export type MutationUpdateUserAddressArgs = {
  input: UpdateUserAddressInput;
};

export type MutationVerifyPhoneNumberArgs = {
  input: VerifyPhoneNumberInput;
};

export type MutationWithdrawBidArgs = {
  input: WithdrawBidInput;
};

export type MutationWithdrawCancellationRequestArgs = {
  input: WithdrawCancellationRequestInput;
};

export type Notification = {
  __typename?: 'Notification';
  id: Scalars['ID'];
  image: Image;
  message: Scalars['String'];
  sentOn: Scalars['Timestamp'];
  title?: Maybe<Scalars['String']>;
  url: Scalars['URL'];
  viewerHasRead: Scalars['Boolean'];
};

export type NotificationImageArgs = {
  variant?: InputMaybe<NotificationImageVariant>;
};

export enum NotificationImageVariant {
  Legacy = 'LEGACY',
}

export enum NotificationLevel {
  Digest = 'DIGEST',
  Never = 'NEVER',
  Realtime = 'REALTIME',
}

export type NotificationsConnection = {
  __typename?: 'NotificationsConnection';
  nodes: Array<Notification>;
  pageInfo: PageInfo;
};

export type Order = {
  __typename?: 'Order';
  buyer: User;
  cancellationRequest?: Maybe<CancellationRequest>;
  cancellationRequestReasons?: Maybe<Array<CancellationRequestReason>>;
  cancelledOn?: Maybe<Scalars['Timestamp']>;
  cartGroup: OrderCartGroup;
  createCancellationRequestState: CreateCancellationRequestState;
  createdOn: Scalars['Timestamp'];
  decidedToKeepOn?: Maybe<Scalars['Timestamp']>;
  deliveryType: DeliveryType;
  id: Scalars['ID'];
  orderRating?: Maybe<OrderRating>;
  orderReference: Scalars['String'];
  payment: OrderPayment;
  returnRequest?: Maybe<ReturnRequest>;
  seller: User;
  shipment?: Maybe<OrderShipment>;
  state: OrderState;
  viewerIsSeller: Scalars['Boolean'];
};

export type OrderCartGroup = CartGroup & {
  __typename?: 'OrderCartGroup';
  id: Scalars['ID'];
  items: Array<ShoppingCartItem>;
  parcel?: Maybe<Parcel>;
  seller: User;
};

export type OrderPayment = {
  __typename?: 'OrderPayment';
  value: Price;
};

export type OrderRating = {
  __typename?: 'OrderRating';
  ratedBy: User;
  ratedOn: Scalars['Timestamp'];
  rating: Scalars['Int'];
  review?: Maybe<Scalars['String']>;
};

export type OrderRatingsConnection = {
  __typename?: 'OrderRatingsConnection';
  nodes: Array<OrderRating>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type OrderShipment = {
  costs: Price;
  destination: ShipmentDestination;
  parcel: Parcel;
  receivedOn?: Maybe<Scalars['Timestamp']>;
  shippedOn?: Maybe<Scalars['Timestamp']>;
  tracking?: Maybe<ShipmentTracking>;
};

export type OrderShipmentLabel = {
  __typename?: 'OrderShipmentLabel';
  shippingLabel?: Maybe<ShippingLabel>;
  status: OrderShipmentLabelStatus;
};

export enum OrderShipmentLabelStatus {
  BeingCreated = 'BEING_CREATED',
  Created = 'CREATED',
  Failed = 'FAILED',
  NotCreated = 'NOT_CREATED',
}

export enum OrderState {
  CancellationRequested = 'CANCELLATION_REQUESTED',
  Cancelled = 'CANCELLED',
  Kept = 'KEPT',
  NotPickedUp = 'NOT_PICKED_UP',
  NotShipped = 'NOT_SHIPPED',
  PaymentInfoMissing = 'PAYMENT_INFO_MISSING',
  PickedUp = 'PICKED_UP',
  Received = 'RECEIVED',
  ReturnReceived = 'RETURN_RECEIVED',
  ReturnRequested = 'RETURN_REQUESTED',
  ReturnShipped = 'RETURN_SHIPPED',
  Shipped = 'SHIPPED',
}

export enum OrderType {
  Bought = 'BOUGHT',
  Sold = 'SOLD',
}

export type OrdersConnection = {
  __typename?: 'OrdersConnection';
  nodes: Array<Order>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type Outlet = {
  __typename?: 'Outlet';
  endsOn: Scalars['Timestamp'];
  id: Scalars['ID'];
  startsOn: Scalars['Timestamp'];
  state: OutletState;
};

export type OutletDiscount = {
  __typename?: 'OutletDiscount';
  discountPercentage: Scalars['Int'];
  preOutletPrice: Price;
};

export type OutletInfo = {
  __typename?: 'OutletInfo';
  current: Array<Outlet>;
  next?: Maybe<Outlet>;
};

export type OutletProduct = {
  __typename?: 'OutletProduct';
  discountPercentage: Scalars['Int'];
  nextOutletPrice: Price;
  product: Product;
};

export type OutletProductsConnection = {
  __typename?: 'OutletProductsConnection';
  nodes: Array<OutletProduct>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export enum OutletState {
  Active = 'ACTIVE',
  Ended = 'ENDED',
  Pending = 'PENDING',
}

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
};

export type Parcel = {
  __typename?: 'Parcel';
  id: Scalars['ID'];
  parcelType: ParcelType;
  price: ParcelPrice;
  shipper: Shipper;
  shippingInstructions: Scalars['String'];
  shippingMethod: ShippingMethod;
};

export type ParcelPrice = Price | PriceRange;

export type ParcelType = {
  __typename?: 'ParcelType';
  compactDescription: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type PartialAddress = {
  __typename?: 'PartialAddress';
  city: Scalars['String'];
  country: Country;
  id: Scalars['ID'];
  name: Scalars['String'];
  postalCode: Scalars['String'];
  region: AddressRegion;
};

export type ParticipateInExperimentInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  experimentId: Scalars['UUID'];
};

export type ParticipateInExperimentPayload = {
  __typename?: 'ParticipateInExperimentPayload';
  allocation: Allocation;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type Payment = {
  __typename?: 'Payment';
  createdOn: Scalars['Timestamp'];
  value: Price;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  icon: Image;
  id: Scalars['ID'];
  label: Scalars['String'];
  type: PaymentMethodType;
};

export type PaymentMethodIconArgs = {
  variant?: InputMaybe<PaymentMethodImageVariant>;
};

export enum PaymentMethodImageVariant {
  Default = 'DEFAULT',
}

export enum PaymentMethodType {
  ApplePay = 'APPLE_PAY',
  Credit = 'CREDIT',
  CreditCard = 'CREDIT_CARD',
  GooglePay = 'GOOGLE_PAY',
}

export enum PaymentStatus {
  Failed = 'FAILED',
  Paid = 'PAID',
  Pending = 'PENDING',
}

export type PlaceBundleBidInput = {
  amount: Scalars['Int'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  productIds: Array<Scalars['ID']>;
};

export type PlaceBundleBidPayload = {
  __typename?: 'PlaceBundleBidPayload';
  bidGroup: BidGroup;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type PlaceCounterBidInput = {
  amount: Scalars['Int'];
  bidGroupId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type PlaceCounterBidPayload = {
  __typename?: 'PlaceCounterBidPayload';
  bidGroup: BidGroup;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type PlaceMassProductBidInput = {
  amount: Scalars['Int'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  productId: Scalars['ID'];
};

export type PlaceMassProductBidPayload = {
  __typename?: 'PlaceMassProductBidPayload';
  bidGroups: Array<Maybe<BidGroup>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type PlaceProActiveProductBidInput = {
  amount: Scalars['Int'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  productId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type PlaceProActiveProductBidPayload = {
  __typename?: 'PlaceProActiveProductBidPayload';
  bidGroup: BidGroup;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type PlaceProductBidInput = {
  amount: Scalars['Int'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  productId: Scalars['ID'];
};

export type PlaceProductBidPayload = {
  __typename?: 'PlaceProductBidPayload';
  bidGroup: BidGroup;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type PopularSearchTerm = {
  __typename?: 'PopularSearchTerm';
  hitCount: Scalars['Int'];
  query: Scalars['String'];
};

export type PreviousFavorite = {
  __typename?: 'PreviousFavorite';
  bidGroup?: Maybe<BidGroup>;
  user: User;
};

export type PreviousFavoritesConnection = {
  __typename?: 'PreviousFavoritesConnection';
  nodes: Array<PreviousFavorite>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type Price = {
  __typename?: 'Price';
  amount: Scalars['Int'];
  currency: Currency;
};

export enum PriceBreakdownImageVariant {
  Default = 'DEFAULT',
}

export type PriceRange = {
  __typename?: 'PriceRange';
  maxPrice: Price;
  minPrice: Price;
};

export type Product = {
  __typename?: 'Product';
  availableParcels: Array<Parcel>;
  badge?: Maybe<ProductBadge>;
  /** @deprecated use hasBiddingEnabled, hasBid, highestBidPrice, minimumBidPrice instead. */
  bidding?: Maybe<ProductBidding>;
  /** @deprecated block reasons should never be shown in the app. */
  blockReason?: Maybe<ProductBlockReason>;
  brand: ProductBrand;
  buyerPickup?: Maybe<BuyerPickup>;
  category: ProductCategory;
  colors: Array<ProductColor>;
  commentsConnection: ProductCommentsConnection;
  condition: ProductCondition;
  description?: Maybe<Scalars['String']>;
  favoriteCount: Scalars['Int'];
  favoritedByConnection: UsersConnection;
  hasBid: Scalars['Boolean'];
  hasBiddingEnabled: Scalars['Boolean'];
  highestBidPrice?: Maybe<Price>;
  id: Scalars['ID'];
  image?: Maybe<ProductImage>;
  images: Array<ProductImage>;
  isEligibleForOutlet: Scalars['Boolean'];
  isUnisex: Scalars['Boolean'];
  minimumBidPrice: Price;
  originalPrice?: Maybe<Price>;
  outlet?: Maybe<Outlet>;
  outletDiscount?: Maybe<OutletDiscount>;
  parcelType: ParcelType;
  previousFavoritesConnection: PreviousFavoritesConnection;
  price: Price;
  recentFavorites: Array<User>;
  relatedProductsConnection: ProductsConnection;
  seller: User;
  size?: Maybe<ProductSizeInterface>;
  sourceVariantId?: Maybe<Scalars['UUID']>;
  state: ProductState;
  tags: Array<ProductTag>;
  title: Scalars['String'];
  viewCount: Scalars['Int'];
  viewerHasFavorited: Scalars['Boolean'];
  viewerHasInCart: Scalars['Boolean'];
  viewerIsSeller: Scalars['Boolean'];
};

export type ProductCommentsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type ProductFavoritedByConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type ProductImageArgs = {
  variant?: InputMaybe<ProductImageVariant>;
};

export type ProductImagesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  variant?: InputMaybe<ProductImageVariant>;
};

export type ProductPreviousFavoritesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type ProductRelatedProductsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type ProductBadge = Badge & {
  __typename?: 'ProductBadge';
  backgroundColor: Scalars['String'];
  description: Scalars['String'];
  foregroundColor: Scalars['String'];
  icon: Image;
  title: Scalars['String'];
};

export type ProductBadgeIconArgs = {
  variant?: InputMaybe<BadgeImageVariant>;
};

export type ProductBidGroup = BidGroup & {
  __typename?: 'ProductBidGroup';
  bids: Array<BidGroupBid>;
  buyer: User;
  id: Scalars['ID'];
  product: Product;
  seller: User;
  state: BidGroupState;
  viewerIsSeller: Scalars['Boolean'];
};

export type ProductBidGroupBidsArgs = {
  last?: InputMaybe<Scalars['Int']>;
};

export type ProductBidding = {
  __typename?: 'ProductBidding';
  hasBid: Scalars['Boolean'];
  highestBid?: Maybe<Price>;
  minimumBid: Price;
};

export type ProductBlockReason = {
  __typename?: 'ProductBlockReason';
  description: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type ProductBrand = {
  __typename?: 'ProductBrand';
  name: Scalars['String'];
};

export type ProductCategory = {
  __typename?: 'ProductCategory';
  categoryPage: CategoryPage;
  categoryPageUrlKey: Scalars['String'];
  children?: Maybe<Array<ProductCategory>>;
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Image>;
  id: Scalars['ID'];
  isUnisex: Scalars['Boolean'];
  longDescription?: Maybe<Scalars['String']>;
  longTitle?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  parent?: Maybe<ProductCategory>;
  sizeTypeIds: Array<Scalars['ID']>;
  title: Scalars['String'];
};

export type ProductCategoryCategoryPageArgs = {
  language: Scalars['String'];
};

export type ProductCategoryCategoryPageUrlKeyArgs = {
  language: Scalars['String'];
};

export type ProductCategoryIconArgs = {
  variant?: InputMaybe<ProductCategoryIconVariant>;
};

export enum ProductCategoryIconVariant {
  Small = 'SMALL',
}

export type ProductColor = {
  __typename?: 'ProductColor';
  colors: Array<Scalars['String']>;
  id: Scalars['ID'];
  isLight: Scalars['Boolean'];
  title: Scalars['String'];
};

export type ProductComment = {
  __typename?: 'ProductComment';
  body: Scalars['String'];
  id: Scalars['ID'];
  isUnderReview: Scalars['Boolean'];
  sellerIsSender: Scalars['Boolean'];
  sender: User;
  sentOn: Scalars['Timestamp'];
  taggedUsers: Array<User>;
  viewerIsSender: Scalars['Boolean'];
};

export type ProductCommentsConnection = {
  __typename?: 'ProductCommentsConnection';
  nodes: Array<ProductComment>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export enum ProductCondition {
  Good = 'GOOD',
  NewWithoutTags = 'NEW_WITHOUT_TAGS',
  NewWithTags = 'NEW_WITH_TAGS',
  Satisfactory = 'SATISFACTORY',
  VeryGood = 'VERY_GOOD',
}

export type ProductFilters = {
  categoryId?: InputMaybe<Scalars['ID']>;
  colorIds?: InputMaybe<Array<Scalars['ID']>>;
  conditions?: InputMaybe<Array<ProductCondition>>;
  countries?: InputMaybe<Array<Country>>;
  distance?: InputMaybe<Scalars['Int']>;
  includeUnisex?: InputMaybe<Scalars['Boolean']>;
  maxPrice?: InputMaybe<Scalars['Int']>;
  minPrice?: InputMaybe<Scalars['Int']>;
  onlyOutlet?: InputMaybe<Scalars['Boolean']>;
  onlyPickUpPoints?: InputMaybe<Scalars['Boolean']>;
  query?: InputMaybe<Scalars['String']>;
  sizeIds?: InputMaybe<Array<Scalars['ID']>>;
};

export enum ProductGender {
  Female = 'FEMALE',
  Male = 'MALE',
  Unisex = 'UNISEX',
}

export type ProductImage = {
  __typename?: 'ProductImage';
  crop?: Maybe<Crop>;
  /** @deprecated use mediaId instead. */
  fileName: Scalars['String'];
  mediaId: Scalars['ID'];
  url: Scalars['URL'];
};

export enum ProductImageVariant {
  LegacyLarge = 'LEGACY_LARGE',
  LegacyLarger = 'LEGACY_LARGER',
  LegacySmall = 'LEGACY_SMALL',
  LegacyXlarge = 'LEGACY_XLARGE',
}

export type ProductPriceRecommendation = {
  __typename?: 'ProductPriceRecommendation';
  fairPrice: Price;
  highPrice: Price;
  lowPrice: Price;
};

export enum ProductReportReason {
  AgainstFur = 'AGAINST_FUR',
  BadContent = 'BAD_CONTENT',
  Brand = 'BRAND',
  Forbidden = 'FORBIDDEN',
  ManyItems = 'MANY_ITEMS',
  PhotoQuality = 'PHOTO_QUALITY',
  PoorRepresentation = 'POOR_REPRESENTATION',
  Repeating = 'REPEATING',
  Replica = 'REPLICA',
  Searching = 'SEARCHING',
  UsedCosmetics = 'USED_COSMETICS',
  WebPhoto = 'WEB_PHOTO',
  WrongCategory = 'WRONG_CATEGORY',
  WrongDescription = 'WRONG_DESCRIPTION',
}

export type ProductSize = ProductSizeInterface & {
  __typename?: 'ProductSize';
  label: Scalars['String'];
  sizeOptions: Array<ProductSizeOption>;
};

export type ProductSizeLabelArgs = {
  compact?: InputMaybe<Scalars['Boolean']>;
};

export type ProductSizeInterface = {
  label: Scalars['String'];
  sizeOptions: Array<ProductSizeOption>;
};

export type ProductSizeInterfaceLabelArgs = {
  compact?: InputMaybe<Scalars['Boolean']>;
};

export type ProductSizeOption = {
  __typename?: 'ProductSizeOption';
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type ProductSizeType = {
  __typename?: 'ProductSizeType';
  id: Scalars['ID'];
  options: Array<ProductSizeOption>;
  title: Scalars['String'];
};

export enum ProductState {
  Active = 'ACTIVE',
  BeingPaid = 'BEING_PAID',
  Blocked = 'BLOCKED',
  Deleted = 'DELETED',
  Expired = 'EXPIRED',
  Pending = 'PENDING',
  Sold = 'SOLD',
  UnderReview = 'UNDER_REVIEW',
  UserMigrated = 'USER_MIGRATED',
  UserOnHoliday = 'USER_ON_HOLIDAY',
  UserPendingKyc = 'USER_PENDING_KYC',
}

export type ProductTag = {
  __typename?: 'ProductTag';
  name: Scalars['String'];
};

export type ProductsAggregations = {
  __typename?: 'ProductsAggregations';
  averagePrice: Price;
  categories: Array<CategoryAggregationItem>;
  colors: Array<ColorAggregationItem>;
  conditions: Array<ConditionAggregationItem>;
  genders: Array<GenderAggregationItem>;
  maxPrice: Price;
  minPrice: Price;
};

export type ProductsConnection = {
  __typename?: 'ProductsConnection';
  aggregations: ProductsAggregations;
  nodes: Array<Product>;
  pageInfo: PageInfo;
  relatedKeywordPages: Array<KeywordPage>;
  totalCount: Scalars['Int'];
};

export enum ProductsOrder {
  Cheapest = 'CHEAPEST',
  MostExpensive = 'MOST_EXPENSIVE',
  MostViewed = 'MOST_VIEWED',
  Newest = 'NEWEST',
  Recommended = 'RECOMMENDED',
}

export type PushNotificationSubscription = CommunicationSubscription & {
  __typename?: 'PushNotificationSubscription';
  id: Scalars['ID'];
  title: Scalars['String'];
  viewerHasEnabled: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  addressRegions: Array<AddressRegion>;
  alternatingContent: Array<AlternatingContentItem>;
  blogPost?: Maybe<BlogPost>;
  blogPostsConnection: BlogPostsConnection;
  blogTag?: Maybe<BlogTag>;
  blogTags: Array<BlogTag>;
  board?: Maybe<Board>;
  brandPage?: Maybe<BrandPage>;
  brandPages: Array<BrandPage>;
  canonicalUrl?: Maybe<CanonicalUrl>;
  categoryPage?: Maybe<CategoryPage>;
  categorySuggestions: Array<ProductCategory>;
  colors: Array<ProductColor>;
  debug?: Maybe<Debug>;
  heartbeat: HeartBeat;
  htmlDocument?: Maybe<HtmlDocument>;
  keywordPage?: Maybe<KeywordPage>;
  legacyBid?: Maybe<Scalars['JSON']>;
  legacyChatTags?: Maybe<Scalars['JSON']>;
  legacyComments?: Maybe<Scalars['JSON']>;
  legacyConversation?: Maybe<Scalars['JSON']>;
  legacyConversations?: Maybe<Scalars['JSON']>;
  legacyCreditEvents?: Maybe<Scalars['JSON']>;
  legacyExpiredProducts?: Maybe<Scalars['JSON']>;
  legacyHeartbeat?: Maybe<Scalars['JSON']>;
  legacyNotifications?: Maybe<Scalars['JSON']>;
  legacyPlacedBids?: Maybe<Scalars['JSON']>;
  legacyProductCommentsTranslations: Array<LegacyProductCommentTranslation>;
  legacyProductFavorites?: Maybe<Scalars['JSON']>;
  legacyProducts?: Maybe<Scalars['JSON']>;
  legacyRatings?: Maybe<Scalars['JSON']>;
  legacyReceivedBids?: Maybe<Scalars['JSON']>;
  legacyReturnRequest?: Maybe<Scalars['JSON']>;
  legacyTodoProgress?: Maybe<Scalars['JSON']>;
  legacyTodoRecommendedFollowers?: Maybe<Scalars['JSON']>;
  legacyTranslations?: Maybe<Scalars['JSON']>;
  outletInfo: OutletInfo;
  popularBrandPages: Array<BrandPage>;
  popularSearchTerms: Array<PopularSearchTerm>;
  product?: Maybe<Product>;
  productCategories: Array<ProductCategory>;
  productCategory?: Maybe<ProductCategory>;
  productCount: Scalars['Int'];
  productParcelRecommendation?: Maybe<ParcelType>;
  productPriceRecommendation?: Maybe<ProductPriceRecommendation>;
  productSizeTypes: Array<ProductSizeType>;
  productsConnection: ProductsConnection;
  searchServicePoints?: Maybe<Array<ServicePointSearchResult>>;
  searchSuggestions: Array<SearchSuggestion>;
  shippers: Array<Shipper>;
  similarSoldProducts: Array<Product>;
  user?: Maybe<User>;
  usersConnection: UsersConnection;
  viewer?: Maybe<Viewer>;
};

export type QueryAlternatingContentArgs = {
  feedFilters?: InputMaybe<ProductFilters>;
  feedOrderBy?: InputMaybe<ProductsOrder>;
};

export type QueryBlogPostArgs = {
  urlKey: Scalars['String'];
};

export type QueryBlogPostsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<BlogPostFilters>;
  first?: InputMaybe<Scalars['Int']>;
};

export type QueryBlogTagArgs = {
  urlKey?: InputMaybe<Scalars['String']>;
};

export type QueryBoardArgs = {
  id: Scalars['ID'];
};

export type QueryBrandPageArgs = {
  urlKey: Scalars['String'];
};

export type QueryCanonicalUrlArgs = {
  url: Scalars['String'];
};

export type QueryCategoryPageArgs = {
  categoryUrlKey?: InputMaybe<Scalars['String']>;
  subcategoryUrlKey?: InputMaybe<Scalars['String']>;
};

export type QueryCategorySuggestionsArgs = {
  title: Scalars['String'];
};

export type QueryHeartbeatArgs = {
  campaign?: InputMaybe<Scalars['String']>;
  medium?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
};

export type QueryHtmlDocumentArgs = {
  name: HtmlDocumentName;
};

export type QueryKeywordPageArgs = {
  urlKey: Scalars['String'];
};

export type QueryLegacyBidArgs = {
  bid_group_id: Scalars['Int'];
};

export type QueryLegacyChatTagsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  product_id: Scalars['Int'];
  query: Scalars['String'];
};

export type QueryLegacyCommentsArgs = {
  comment_id_offset?: InputMaybe<Scalars['Int']>;
  product_id: Scalars['Int'];
  tags_supported?: InputMaybe<Scalars['Boolean']>;
};

export type QueryLegacyConversationArgs = {
  other_user: Scalars['Int'];
  timestamp?: InputMaybe<Scalars['String']>;
};

export type QueryLegacyCreditEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryLegacyExpiredProductsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  user_id: Scalars['Int'];
};

export type QueryLegacyHeartbeatArgs = {
  session_id?: InputMaybe<Scalars['String']>;
  utm_campaign?: InputMaybe<Scalars['String']>;
  utm_medium?: InputMaybe<Scalars['String']>;
  utm_source?: InputMaybe<Scalars['String']>;
};

export type QueryLegacyNotificationsArgs = {
  dont_mark_as_read: Scalars['Boolean'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryLegacyPlacedBidsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryLegacyProductCommentsTranslationsArgs = {
  commentIds: Array<Scalars['ID']>;
};

export type QueryLegacyProductFavoritesArgs = {
  offset: Scalars['Int'];
  product_id: Scalars['Int'];
};

export type QueryLegacyProductsArgs = {
  filters: Scalars['JSON'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryLegacyRatingsArgs = {
  user_id: Scalars['Int'];
};

export type QueryLegacyReceivedBidsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryLegacyReturnRequestArgs = {
  order_id: Scalars['String'];
};

export type QueryLegacyTodoProgressArgs = {
  check_chat: Scalars['Boolean'];
};

export type QueryLegacyTranslationsArgs = {
  latest_update?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
};

export type QueryPopularSearchTermsArgs = {
  first?: InputMaybe<Scalars['Int']>;
};

export type QueryProductArgs = {
  id: Scalars['ID'];
};

export type QueryProductCategoryArgs = {
  id: Scalars['ID'];
};

export type QueryProductCountArgs = {
  filterBy: ProductFilters;
};

export type QueryProductParcelRecommendationArgs = {
  biddingEnabled?: InputMaybe<Scalars['Boolean']>;
  brand?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['ID']>;
  colorIds?: InputMaybe<Array<Scalars['ID']>>;
  condition?: InputMaybe<ProductCondition>;
  description?: InputMaybe<Scalars['String']>;
  isUnisex?: InputMaybe<Scalars['Boolean']>;
  originalPrice?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Int']>;
  sizeIds?: InputMaybe<Array<Scalars['ID']>>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  title?: InputMaybe<Scalars['String']>;
};

export type QueryProductPriceRecommendationArgs = {
  biddingEnabled?: InputMaybe<Scalars['Boolean']>;
  brand?: InputMaybe<Scalars['String']>;
  brandName?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['ID']>;
  colorIds?: InputMaybe<Array<Scalars['ID']>>;
  condition?: InputMaybe<ProductCondition>;
  description?: InputMaybe<Scalars['String']>;
  isUnisex?: InputMaybe<Scalars['Boolean']>;
  originalPrice?: InputMaybe<Scalars['Int']>;
  parcelTypeId?: InputMaybe<Scalars['ID']>;
  price?: InputMaybe<Scalars['Int']>;
  sizeIds?: InputMaybe<Array<Scalars['ID']>>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  title?: InputMaybe<Scalars['String']>;
};

export type QueryProductsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<ProductFilters>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ProductsOrder>;
};

export type QuerySearchServicePointsArgs = {
  postalCode: Scalars['String'];
  regionId: Scalars['ID'];
  shipperId: Scalars['ID'];
};

export type QuerySearchSuggestionsArgs = {
  first: Scalars['Int'];
  query?: InputMaybe<Scalars['String']>;
};

export type QuerySimilarSoldProductsArgs = {
  brand: Scalars['String'];
  categoryId: Scalars['ID'];
  condition: ProductCondition;
  sizeIds: Array<Scalars['ID']>;
};

export type QueryUserArgs = {
  id: Scalars['ID'];
};

export type QueryUsersConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<UsersFilters>;
  first?: InputMaybe<Scalars['Int']>;
};

export type ReactivateProductsInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  priceReduction: ReactivateProductsPriceReduction;
  productIds: Array<Scalars['ID']>;
  reactivateAll: Scalars['Boolean'];
};

export type ReactivateProductsPayload = {
  __typename?: 'ReactivateProductsPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  products: Array<Product>;
};

export enum ReactivateProductsPriceReduction {
  NoReduction = 'NO_REDUCTION',
  TenPercent = 'TEN_PERCENT',
  TwentyFivePercent = 'TWENTY_FIVE_PERCENT',
}

export type ReceiveOrderInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  orderId: Scalars['ID'];
};

export type ReceiveOrderPayload = {
  __typename?: 'ReceiveOrderPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  order: Order;
};

export type ReceiveReturnedOrderInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  orderId: Scalars['ID'];
};

export type ReceiveReturnedOrderPayload = {
  __typename?: 'ReceiveReturnedOrderPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  order: Order;
};

export type RecommendedSellersFeedItem = FeedItem & {
  __typename?: 'RecommendedSellersFeedItem';
  id: Scalars['ID'];
  recommendedSellers: Array<User>;
  style: FeedItemStyle;
};

export type RefreshAccessTokenInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  refreshTokenId: Scalars['String'];
};

export type RefreshAccessTokenPayload = {
  __typename?: 'RefreshAccessTokenPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  tokens: AccessTokens;
};

export type RegisterAccountInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  reCaptchaToken?: InputMaybe<Scalars['String']>;
};

export type RegisterAccountPayload = {
  __typename?: 'RegisterAccountPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  tokens: AccessTokens;
};

export type RemoveFromCartInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  productId: Scalars['ID'];
};

export type RemoveFromCartPayload = {
  __typename?: 'RemoveFromCartPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  product: Product;
  shoppingCart: ShoppingCart;
};

export type ReportProductInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  productId: Scalars['ID'];
  reason: ProductReportReason;
};

export type ReportProductPayload = {
  __typename?: 'ReportProductPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  product: Product;
};

export type RepostOrderInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  orderId: Scalars['ID'];
};

export type RepostOrderPayload = {
  __typename?: 'RepostOrderPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  order: Order;
};

export type RequestMediaUploadsInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  contentTypes: Array<MediaContentType>;
  referenceType: MediaReferenceType;
};

export type RequestMediaUploadsPayload = {
  __typename?: 'RequestMediaUploadsPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  mediaUploadRequests: Array<MediaUploadRequest>;
};

export type ResetPasswordInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  newPassword: Scalars['String'];
  resetPasswordToken: Scalars['String'];
};

export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ReturnRequest = {
  __typename?: 'ReturnRequest';
  history: Array<ReturnRequestEvent>;
  id: Scalars['ID'];
  reason: ReturnRequestReason;
  returnAddress?: Maybe<UserAddress>;
  state: ReturnRequestState;
  viewerCanCancel: Scalars['Boolean'];
};

export type ReturnRequestEvent = {
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  initiatedOn: Scalars['Timestamp'];
  state: ReturnRequestState;
  viewerInitiatedEvent: Scalars['Boolean'];
};

export enum ReturnRequestEventAttachmentVariant {
  Default = 'DEFAULT',
}

export enum ReturnRequestReason {
  Counterfeit = 'COUNTERFEIT',
  Damaged = 'DAMAGED',
  DamagedInTransit = 'DAMAGED_IN_TRANSIT',
  NotAsDescribed = 'NOT_AS_DESCRIBED',
  Other = 'OTHER',
  Unknown = 'UNKNOWN',
  WrongOrIncomplete = 'WRONG_OR_INCOMPLETE',
}

export enum ReturnRequestState {
  AdminApproved = 'ADMIN_APPROVED',
  AutoApproved = 'AUTO_APPROVED',
  Cancelled = 'CANCELLED',
  Disputed = 'DISPUTED',
  Rejected = 'REJECTED',
  SellerApproved = 'SELLER_APPROVED',
  Submitted = 'SUBMITTED',
}

export type SavedSearch = {
  __typename?: 'SavedSearch';
  addedOn: Scalars['Timestamp'];
  category?: Maybe<ProductCategory>;
  colors?: Maybe<Array<ProductColor>>;
  conditions?: Maybe<Array<ProductCondition>>;
  countries?: Maybe<Array<Country>>;
  id: Scalars['ID'];
  includeUnisex: Scalars['Boolean'];
  label: Scalars['String'];
  maxPrice?: Maybe<Price>;
  minPrice?: Maybe<Price>;
  notificationLevel: NotificationLevel;
  onlyPickUpPoints: Scalars['Boolean'];
  query?: Maybe<Scalars['String']>;
  sizes?: Maybe<Array<ProductSizeOption>>;
};

export type SavedSearchesConnection = {
  __typename?: 'SavedSearchesConnection';
  nodes: Array<SavedSearch>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type SearchSuggestion = {
  __typename?: 'SearchSuggestion';
  label: Scalars['String'];
  query: Scalars['String'];
  resultCount: Scalars['Int'];
  type: SearchSuggestionType;
};

export enum SearchSuggestionType {
  Recent = 'RECENT',
  Suggestion = 'SUGGESTION',
}

export type SendChatMessageInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
  userId: Scalars['ID'];
};

export type SendChatMessagePayload = {
  __typename?: 'SendChatMessagePayload';
  chatMessage: ChatMessage;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SendOrderInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  orderId: Scalars['ID'];
};

export type SendOrderPayload = {
  __typename?: 'SendOrderPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  order: Order;
};

export type ServicePoint = Addressable & {
  __typename?: 'ServicePoint';
  address: Scalars['String'];
  city: Scalars['String'];
  country: Country;
  externalId: Scalars['ID'];
  line1: Scalars['String'];
  name: Scalars['String'];
  postalCode: Scalars['String'];
  regionCode: Scalars['String'];
};

export type ServicePointAddressArgs = {
  compact?: InputMaybe<Scalars['Boolean']>;
};

export type ServicePointSearchResult = {
  __typename?: 'ServicePointSearchResult';
  distance: Distance;
  servicePoint: ServicePoint;
};

export type SetAddressForCartGroupInput = {
  addressId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  groupId: Scalars['ID'];
};

export type SetAddressForCartGroupPayload = {
  __typename?: 'SetAddressForCartGroupPayload';
  cartGroup: ShoppingCartGroup;
  clientMutationId?: Maybe<Scalars['String']>;
  shoppingCart: ShoppingCart;
};

export type SetBuyerPickupForCartGroupInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  groupId: Scalars['ID'];
};

export type SetBuyerPickupForCartGroupPayload = {
  __typename?: 'SetBuyerPickupForCartGroupPayload';
  cartGroup: ShoppingCartGroup;
  clientMutationId?: Maybe<Scalars['String']>;
  shoppingCart: ShoppingCart;
};

export type SetOrderTrackingCodeInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  orderId: Scalars['ID'];
  trackingCode: Scalars['String'];
};

export type SetOrderTrackingCodePayload = {
  __typename?: 'SetOrderTrackingCodePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  order: Order;
};

export type SetParcelForCartGroupInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  groupId: Scalars['ID'];
  parcelId: Scalars['ID'];
};

export type SetParcelForCartGroupPayload = {
  __typename?: 'SetParcelForCartGroupPayload';
  cartGroup: ShoppingCartGroup;
  clientMutationId?: Maybe<Scalars['String']>;
  shoppingCart: ShoppingCart;
};

export type SetServicePointForCartGroupInput = {
  city: Scalars['String'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  country: Country;
  externalId: Scalars['ID'];
  groupId: Scalars['ID'];
  line1: Scalars['String'];
  name: Scalars['String'];
  postalCode: Scalars['String'];
  regionCode: Scalars['String'];
};

export type SetServicePointForCartGroupPayload = {
  __typename?: 'SetServicePointForCartGroupPayload';
  cartGroup: ShoppingCartGroup;
  clientMutationId?: Maybe<Scalars['String']>;
  shoppingCart: ShoppingCart;
};

export type ShipmentDestination = ShipmentServicePoint | UserAddress;

export type ShipmentServicePoint = {
  __typename?: 'ShipmentServicePoint';
  servicePoint: ServicePoint;
  userFullName: Scalars['String'];
};

export type ShipmentTracking = {
  __typename?: 'ShipmentTracking';
  trackingCode?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['URL']>;
};

export type Shipper = {
  __typename?: 'Shipper';
  buyerDescription: Scalars['String'];
  id: Scalars['ID'];
  isDefault: Scalars['Boolean'];
  logo: Image;
  sellerDescription: Scalars['String'];
  title: Scalars['String'];
  viewerHasEnabled: Scalars['Boolean'];
};

export type ShipperLogoArgs = {
  variant?: InputMaybe<ShipperLogoVariant>;
};

export enum ShipperLogoVariant {
  Default = 'DEFAULT',
}

export type ShippingLabel = {
  __typename?: 'ShippingLabel';
  id: Scalars['ID'];
  url: Scalars['URL'];
};

export enum ShippingMethod {
  HomeDelivery = 'HOME_DELIVERY',
  PickupPoint = 'PICKUP_POINT',
}

export type ShoppingCart = {
  __typename?: 'ShoppingCart';
  creditSummary: ShoppingCartCredit;
  groups: Array<ShoppingCartGroup>;
  id: Scalars['ID'];
  itemCount: Scalars['Int'];
  paymentMethods: Array<PaymentMethod>;
  prices: ShoppingCartPrices;
};

export type ShoppingCartCredit = {
  __typename?: 'ShoppingCartCredit';
  available: Scalars['Boolean'];
  credit: Price;
  unavailableReasons?: Maybe<Array<Scalars['String']>>;
};

export type ShoppingCartGroup = CartGroup & {
  __typename?: 'ShoppingCartGroup';
  availableParcels: Array<Parcel>;
  buyerPickup?: Maybe<BuyerPickup>;
  deliveryType: DeliveryType;
  id: Scalars['ID'];
  items: Array<ShoppingCartItem>;
  parcel?: Maybe<Parcel>;
  seller: User;
  shipmentDestination?: Maybe<ShipmentDestination>;
};

export type ShoppingCartItem = BundleBidGroup | Product | ProductBidGroup;

export type ShoppingCartPrices = {
  __typename?: 'ShoppingCartPrices';
  priceBreakdown: Array<CartPriceBreakdownItem>;
  subtotal: Price;
  total: Price;
};

export type StripeAccount = {
  __typename?: 'StripeAccount';
  addressLineOne: Scalars['String'];
  addressLineTwo?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country: Country;
  dateOfBirth?: Maybe<Scalars['Date']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  postalCode: Scalars['String'];
};

export enum StripeUploadType {
  AddressVerification = 'ADDRESS_VERIFICATION',
  PhotoId = 'PHOTO_ID',
}

export type SubmitCancellationRequestInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  explanation: Scalars['String'];
  orderId: Scalars['ID'];
  reasonId: Scalars['ID'];
};

export type SubmitCancellationRequestPayload = {
  __typename?: 'SubmitCancellationRequestPayload';
  cancellationRequest: CancellationRequest;
  clientMutationId?: Maybe<Scalars['String']>;
  order: Order;
};

export enum ThirdParty {
  Apple = 'APPLE',
  Facebook = 'FACEBOOK',
  Google = 'GOOGLE',
}

export type ThirdPartyAuthInput = {
  authCode: Scalars['String'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  thirdParty: ThirdParty;
};

export type ThirdPartyAuthPayload = {
  __typename?: 'ThirdPartyAuthPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  isExistingAccount: Scalars['Boolean'];
  tokens: AccessTokens;
};

export type TrackingCodeOrderShipment = OrderShipment & {
  __typename?: 'TrackingCodeOrderShipment';
  costs: Price;
  destination: ShipmentDestination;
  parcel: Parcel;
  receivedOn?: Maybe<Scalars['Timestamp']>;
  shippedOn?: Maybe<Scalars['Timestamp']>;
  tracking?: Maybe<ShipmentTracking>;
};

export type UnfavoriteProductInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  productId: Scalars['ID'];
};

export type UnfavoriteProductPayload = {
  __typename?: 'UnfavoriteProductPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
};

export type UnfollowUserInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type UnfollowUserPayload = {
  __typename?: 'UnfollowUserPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  user: User;
};

export type UnsubscribeFromAllEmailsInput = {
  authorisation?: InputMaybe<EmailSubscriptionAuthorisationInput>;
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type UnsubscribeFromAllEmailsPayload = {
  __typename?: 'UnsubscribeFromAllEmailsPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  subscriptions: Array<EmailSubscription>;
};

export type UpdateAccountCategoriesInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  ids: Array<Scalars['ID']>;
};

export type UpdateAccountCategoriesPayload = {
  __typename?: 'UpdateAccountCategoriesPayload';
  account: Account;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateAccountSizesInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  ids: Array<Scalars['ID']>;
};

export type UpdateAccountSizesPayload = {
  __typename?: 'UpdateAccountSizesPayload';
  account: Account;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateBoardInput = {
  boardId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type UpdateBoardPayload = {
  __typename?: 'UpdateBoardPayload';
  board: Board;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateCoverImageInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  media: ImageInput;
};

export type UpdateCoverImagePayload = {
  __typename?: 'UpdateCoverImagePayload';
  account: Account;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateDateOfBirthInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  dateOfBirth: Scalars['Date'];
};

export type UpdateDateOfBirthPayload = {
  __typename?: 'UpdateDateOfBirthPayload';
  account: Account;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateEmailAddressInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  emailAddress: Scalars['String'];
};

export type UpdateEmailAddressPayload = {
  __typename?: 'UpdateEmailAddressPayload';
  account: Account;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateEmailSubscriptionInput = {
  authorisation?: InputMaybe<EmailSubscriptionAuthorisationInput>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  subscribed: Scalars['Boolean'];
};

export type UpdateEmailSubscriptionPayload = {
  __typename?: 'UpdateEmailSubscriptionPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  subscriptions: Array<EmailSubscription>;
};

export type UpdateHolidayModeInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
};

export type UpdateHolidayModePayload = {
  __typename?: 'UpdateHolidayModePayload';
  account: Account;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateLocaleInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Country>;
  language?: InputMaybe<Language>;
};

export type UpdateLocalePayload = {
  __typename?: 'UpdateLocalePayload';
  account: Account;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateNameInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type UpdateNamePayload = {
  __typename?: 'UpdateNamePayload';
  account: Account;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePhoneNumberInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  skipVerification?: InputMaybe<Scalars['Boolean']>;
};

export type UpdatePhoneNumberPayload = {
  __typename?: 'UpdatePhoneNumberPayload';
  account: Account;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateProductInput = {
  biddingEnabled?: InputMaybe<Scalars['Boolean']>;
  brand?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['ID']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  colorIds?: InputMaybe<Array<Scalars['ID']>>;
  condition?: InputMaybe<ProductCondition>;
  description?: InputMaybe<Scalars['String']>;
  imageFileNames?: InputMaybe<Array<Scalars['String']>>;
  isUnisex?: InputMaybe<Scalars['Boolean']>;
  media?: InputMaybe<Array<ImageInput>>;
  originalPrice?: InputMaybe<Scalars['Int']>;
  parcelTypeId?: InputMaybe<Scalars['ID']>;
  price?: InputMaybe<Scalars['Int']>;
  priceExcludingShipping?: InputMaybe<Scalars['Int']>;
  productId: Scalars['ID'];
  sizeIds?: InputMaybe<Array<Scalars['ID']>>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateProductPayload = {
  __typename?: 'UpdateProductPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
};

export type UpdateProfileImageInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  media: ImageInput;
};

export type UpdateProfileImagePayload = {
  __typename?: 'UpdateProfileImagePayload';
  account: Account;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePushNotificationSubscriptionInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  subscribed: Scalars['Boolean'];
};

export type UpdatePushNotificationSubscriptionPayload = {
  __typename?: 'UpdatePushNotificationSubscriptionPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  subscriptions: Array<PushNotificationSubscription>;
};

export type UpdateSavedSearchInput = {
  categoryId?: InputMaybe<Scalars['ID']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  colorIds?: InputMaybe<Array<Scalars['ID']>>;
  conditions?: InputMaybe<Array<ProductCondition>>;
  countries?: InputMaybe<Array<Country>>;
  includeUnisex?: InputMaybe<Scalars['Boolean']>;
  label: Scalars['String'];
  maxPrice?: InputMaybe<Scalars['Int']>;
  minPrice?: InputMaybe<Scalars['Int']>;
  notificationLevel: NotificationLevel;
  onlyPickUpPoints?: InputMaybe<Scalars['Boolean']>;
  query?: InputMaybe<Scalars['String']>;
  savedSearchId: Scalars['ID'];
  sizeIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type UpdateSavedSearchPayload = {
  __typename?: 'UpdateSavedSearchPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  savedSearch: SavedSearch;
};

export type UpdateStripeAccountInput = {
  addressLineOne: Scalars['String'];
  addressLineTwo?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  country: Country;
  dateOfBirth: Scalars['Date'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  postalCode: Scalars['String'];
};

export type UpdateStripeAccountPayload = {
  __typename?: 'UpdateStripeAccountPayload';
  account?: Maybe<Account>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateTradeModeInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
};

export type UpdateTradeModePayload = {
  __typename?: 'UpdateTradeModePayload';
  account: Account;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateUserAddressInput = {
  addressId: Scalars['ID'];
  city?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Country>;
  generateLocation?: InputMaybe<Scalars['Boolean']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  line1?: InputMaybe<Scalars['String']>;
  line2?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<LatLngInput>;
  name?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  regionId?: InputMaybe<Scalars['ID']>;
};

export type UpdateUserAddressPayload = {
  __typename?: 'UpdateUserAddressPayload';
  account?: Maybe<Account>;
  address?: Maybe<UserAddress>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  badge?: Maybe<UserBadge>;
  boardsConnection: BoardsConnection;
  bundleProductsConnection: ProductsConnection;
  country: Country;
  coverImage: UserImage;
  displayName: Scalars['String'];
  favoriteCount: Scalars['Int'];
  favoritesConnection: ProductsConnection;
  firstName: Scalars['String'];
  followerCount: Scalars['Int'];
  followersConnection: UsersConnection;
  followingConnection: UsersConnection;
  followingCount: Scalars['Int'];
  gender: UserGender;
  hasConsentedToMigration: Scalars['Boolean'];
  holidayModeEnabled: Scalars['Boolean'];
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  isVerifiedUser: Scalars['Boolean'];
  isViewer: Scalars['Boolean'];
  language: Language;
  latestActivity: Scalars['Timestamp'];
  migratedPlatformUrl?: Maybe<Scalars['URL']>;
  orderRatingsConnection: OrderRatingsConnection;
  productCount: Scalars['Int'];
  productsConnection: ProductsConnection;
  profileImage: UserImage;
  rating?: Maybe<UserRating>;
  soldProductCount: Scalars['Int'];
  tradeEnabled: Scalars['Boolean'];
  url: Scalars['URL'];
  viewerIsFollowing: Scalars['Boolean'];
};

export type UserBoardsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type UserBundleProductsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type UserCoverImageArgs = {
  variant?: InputMaybe<UserCoverImageVariant>;
};

export type UserFavoritesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type UserFollowersConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type UserFollowingConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type UserOrderRatingsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type UserProductsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type UserProfileImageArgs = {
  variant?: InputMaybe<UserProfileImageVariant>;
};

export type UserUrlArgs = {
  attribution?: InputMaybe<ClosetUrlAttribution>;
};

export type UserAddress = Addressable & {
  __typename?: 'UserAddress';
  address: Scalars['String'];
  city: Scalars['String'];
  country: Country;
  id: Scalars['ID'];
  isDefault: Scalars['Boolean'];
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  location?: Maybe<LatLng>;
  name: Scalars['String'];
  postalCode: Scalars['String'];
  region: AddressRegion;
};

export type UserAddressAddressArgs = {
  compact?: InputMaybe<Scalars['Boolean']>;
};

export type UserBadge = Badge & {
  __typename?: 'UserBadge';
  backgroundColor: Scalars['String'];
  description: Scalars['String'];
  foregroundColor: Scalars['String'];
  icon: Image;
  title: Scalars['String'];
};

export type UserBadgeIconArgs = {
  variant?: InputMaybe<BadgeImageVariant>;
};

export enum UserCoverImageVariant {
  Legacy = 'LEGACY',
}

export enum UserGender {
  Female = 'FEMALE',
  Male = 'MALE',
}

export type UserImage = {
  __typename?: 'UserImage';
  isDefault: Scalars['Boolean'];
  url: Scalars['URL'];
};

export enum UserProfileImageVariant {
  Legacy = 'LEGACY',
}

export type UserRating = {
  __typename?: 'UserRating';
  rating: Scalars['Float'];
  ratingCount: Scalars['Int'];
};

export type UserReturnRequestEvent = ReturnRequestEvent & {
  __typename?: 'UserReturnRequestEvent';
  attachments: Array<Image>;
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  initiatedOn: Scalars['Timestamp'];
  state: ReturnRequestState;
  user: User;
  viewerInitiatedEvent: Scalars['Boolean'];
};

export type UserReturnRequestEventAttachmentsArgs = {
  variant?: InputMaybe<ReturnRequestEventAttachmentVariant>;
};

export type UserSizesItem = FeedItem & {
  __typename?: 'UserSizesItem';
  id: Scalars['ID'];
  style: FeedItemStyle;
};

export type UsersConnection = {
  __typename?: 'UsersConnection';
  nodes: Array<User>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type UsersFilters = {
  query?: InputMaybe<Scalars['String']>;
};

export type VerifyPhoneNumberInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  verificationToken: Scalars['String'];
};

export type VerifyPhoneNumberPayload = {
  __typename?: 'VerifyPhoneNumberPayload';
  account: Account;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type Viewer = {
  __typename?: 'Viewer';
  account: Account;
  address?: Maybe<UserAddress>;
  allocations: Array<Allocation>;
  availableOutletProductsConnection?: Maybe<OutletProductsConnection>;
  bidGroup?: Maybe<BidGroup>;
  bidGroupsConnection: BidGroupsConnection;
  chat?: Maybe<Chat>;
  chatsConnection: ChatsConnection;
  emailSubscriptions: Array<EmailSubscription>;
  expiredProductsConnection: ProductsConnection;
  me: User;
  notificationsConnection: NotificationsConnection;
  order?: Maybe<Order>;
  ordersConnection: OrdersConnection;
  parcelTypes: Array<ParcelType>;
  parcelsForParcelTypes: Array<Parcel>;
  pushNotificationSubscriptions: Array<PushNotificationSubscription>;
  savedSearchesConnection: SavedSearchesConnection;
  shoppingCart: ShoppingCart;
};

export type ViewerAddressArgs = {
  id: Scalars['ID'];
};

export type ViewerAvailableOutletProductsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type ViewerBidGroupArgs = {
  id: Scalars['ID'];
};

export type ViewerBidGroupsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  type: BidGroupType;
};

export type ViewerChatArgs = {
  userId: Scalars['ID'];
};

export type ViewerChatsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type ViewerEmailSubscriptionsArgs = {
  authorisation?: InputMaybe<EmailSubscriptionAuthorisationInput>;
};

export type ViewerExpiredProductsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type ViewerNotificationsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type ViewerOrderArgs = {
  id: Scalars['ID'];
};

export type ViewerOrdersConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  type: OrderType;
};

export type ViewerParcelsForParcelTypesArgs = {
  parcelTypeIds: Array<Scalars['ID']>;
  sellerId: Scalars['ID'];
};

export type ViewerSavedSearchesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type ViewerShoppingCartArgs = {
  excludeUnavailableItems?: InputMaybe<Scalars['Boolean']>;
};

export type WithdrawBidInput = {
  bidId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type WithdrawBidPayload = {
  __typename?: 'WithdrawBidPayload';
  bidGroup: BidGroup;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type WithdrawCancellationRequestInput = {
  cancellationRequestId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type WithdrawCancellationRequestPayload = {
  __typename?: 'WithdrawCancellationRequestPayload';
  cancellationRequest: CancellationRequest;
  clientMutationId?: Maybe<Scalars['String']>;
  order: Order;
};

export type AccountEmailQueryVariables = Exact<{ [key: string]: never }>;

export type AccountEmailQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Viewer';
    account: {
      __typename?: 'Account';
      email: {
        __typename?: 'AccountEmail';
        isVerified: boolean;
        emailAddress: string;
      };
    };
  } | null;
};

export type ResendActivationEmailMutationVariables = Exact<{
  input: UpdateEmailAddressInput;
}>;

export type ResendActivationEmailMutation = {
  __typename?: 'Mutation';
  updateEmailAddress?: {
    __typename?: 'UpdateEmailAddressPayload';
    account: {
      __typename?: 'Account';
      email: {
        __typename?: 'AccountEmail';
        isVerified: boolean;
        emailAddress: string;
      };
    };
  } | null;
};

export type AccountEmailFragment = {
  __typename?: 'AccountEmail';
  isVerified: boolean;
  emailAddress: string;
};

export type AddressesQueryVariables = Exact<{
  includeAddresses: Scalars['Boolean'];
}>;

export type AddressesQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Viewer';
    account: {
      __typename?: 'Account';
      firstName: string;
      lastName: string;
      addresses?: Array<{
        __typename?: 'UserAddress';
        id: string;
        name: string;
        line1: string;
        line2?: string | null;
        postalCode: string;
        city: string;
        country: Country;
        isDefault: boolean;
        address: string;
        region: { __typename?: 'AddressRegion'; id: string };
      }>;
      partialAddress?: {
        __typename?: 'PartialAddress';
        id: string;
        name: string;
        postalCode: string;
        city: string;
        country: Country;
        region: { __typename?: 'AddressRegion'; id: string };
      } | null;
    };
  } | null;
  addressRegions: Array<{
    __typename?: 'AddressRegion';
    id: string;
    country: Country;
    code: string;
    name: string;
  }>;
};

export type AddUserAddressMutationVariables = Exact<{
  input: AddUserAddressInput;
}>;

export type AddUserAddressMutation = {
  __typename?: 'Mutation';
  addUserAddress?: {
    __typename?: 'AddUserAddressPayload';
    account?: {
      __typename?: 'Account';
      addresses: Array<{
        __typename?: 'UserAddress';
        id: string;
        name: string;
        line1: string;
        line2?: string | null;
        postalCode: string;
        city: string;
        country: Country;
        isDefault: boolean;
        address: string;
        region: { __typename?: 'AddressRegion'; id: string };
      }>;
      defaultAddress?: {
        __typename?: 'UserAddress';
        id: string;
        name: string;
        line1: string;
        line2?: string | null;
        postalCode: string;
        city: string;
        country: Country;
        isDefault: boolean;
        address: string;
        region: { __typename?: 'AddressRegion'; id: string };
      } | null;
    } | null;
    address?: {
      __typename?: 'UserAddress';
      id: string;
      name: string;
      line1: string;
      line2?: string | null;
      postalCode: string;
      city: string;
      country: Country;
      isDefault: boolean;
      address: string;
      region: { __typename?: 'AddressRegion'; id: string };
    } | null;
  } | null;
};

export type UpdateUserAddressMutationVariables = Exact<{
  input: UpdateUserAddressInput;
}>;

export type UpdateUserAddressMutation = {
  __typename?: 'Mutation';
  updateUserAddress?: {
    __typename?: 'UpdateUserAddressPayload';
    account?: {
      __typename?: 'Account';
      addresses: Array<{
        __typename?: 'UserAddress';
        id: string;
        name: string;
        line1: string;
        line2?: string | null;
        postalCode: string;
        city: string;
        country: Country;
        isDefault: boolean;
        address: string;
        region: { __typename?: 'AddressRegion'; id: string };
      }>;
      defaultAddress?: {
        __typename?: 'UserAddress';
        id: string;
        name: string;
        line1: string;
        line2?: string | null;
        postalCode: string;
        city: string;
        country: Country;
        isDefault: boolean;
        address: string;
        region: { __typename?: 'AddressRegion'; id: string };
      } | null;
    } | null;
    address?: {
      __typename?: 'UserAddress';
      id: string;
      name: string;
      line1: string;
      line2?: string | null;
      postalCode: string;
      city: string;
      country: Country;
      isDefault: boolean;
      address: string;
      region: { __typename?: 'AddressRegion'; id: string };
    } | null;
  } | null;
};

export type DeleteUserAddressMutationVariables = Exact<{
  input: DeleteUserAddressInput;
}>;

export type DeleteUserAddressMutation = {
  __typename?: 'Mutation';
  deleteUserAddress?: {
    __typename?: 'DeleteUserAddressPayload';
    account?: {
      __typename?: 'Account';
      addresses: Array<{
        __typename?: 'UserAddress';
        id: string;
        name: string;
        line1: string;
        line2?: string | null;
        postalCode: string;
        city: string;
        country: Country;
        isDefault: boolean;
        address: string;
        region: { __typename?: 'AddressRegion'; id: string };
      }>;
      defaultAddress?: {
        __typename?: 'UserAddress';
        id: string;
        name: string;
        line1: string;
        line2?: string | null;
        postalCode: string;
        city: string;
        country: Country;
        isDefault: boolean;
        address: string;
        region: { __typename?: 'AddressRegion'; id: string };
      } | null;
    } | null;
  } | null;
};

export type FullUserAddressFragment = {
  __typename?: 'UserAddress';
  id: string;
  name: string;
  line1: string;
  line2?: string | null;
  postalCode: string;
  city: string;
  country: Country;
  isDefault: boolean;
  address: string;
  region: { __typename?: 'AddressRegion'; id: string };
};

export type PartialAddressFragment = {
  __typename?: 'PartialAddress';
  id: string;
  name: string;
  postalCode: string;
  city: string;
  country: Country;
  region: { __typename?: 'AddressRegion'; id: string };
};

export type ShippersQueryVariables = Exact<{ [key: string]: never }>;

export type ShippersQuery = {
  __typename?: 'Query';
  shippers: Array<{
    __typename?: 'Shipper';
    id: string;
    title: string;
    sellerDescription: string;
    viewerHasEnabled: boolean;
    isDefault: boolean;
    logo: { __typename?: 'Image'; url: string };
  }>;
};

export type DisableShipperServicePointsMutationVariables = Exact<{
  input: DisableShipperServicePointsInput;
}>;

export type DisableShipperServicePointsMutation = {
  __typename?: 'Mutation';
  disableShipperServicePoints?: {
    __typename?: 'DisableShipperServicePointsPayload';
    shipper?: {
      __typename?: 'Shipper';
      id: string;
      title: string;
      sellerDescription: string;
      viewerHasEnabled: boolean;
      isDefault: boolean;
      logo: { __typename?: 'Image'; url: string };
    } | null;
  } | null;
};

export type EnableShipperServicePointsMutationVariables = Exact<{
  input: EnableShipperServicePointsInput;
}>;

export type EnableShipperServicePointsMutation = {
  __typename?: 'Mutation';
  enableShipperServicePoints?: {
    __typename?: 'EnableShipperServicePointsPayload';
    shipper?: {
      __typename?: 'Shipper';
      id: string;
      title: string;
      sellerDescription: string;
      viewerHasEnabled: boolean;
      isDefault: boolean;
      logo: { __typename?: 'Image'; url: string };
    } | null;
  } | null;
};

export type DeliveryOptionShipperFragment = {
  __typename?: 'Shipper';
  id: string;
  title: string;
  sellerDescription: string;
  viewerHasEnabled: boolean;
  isDefault: boolean;
  logo: { __typename?: 'Image'; url: string };
};

export type SellerFavoritesQueryVariables = Exact<{
  productId: Scalars['ID'];
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
}>;

export type SellerFavoritesQuery = {
  __typename?: 'Query';
  product?: {
    __typename?: 'Product';
    id: string;
    recentFavorites: Array<{
      __typename?: 'User';
      id: string;
      displayName: string;
      profileImage: { __typename?: 'UserImage'; url: string };
    }>;
    price: { __typename?: 'Price'; amount: number };
    minimumBidPrice: { __typename?: 'Price'; amount: number };
    previousFavoritesConnection: {
      __typename?: 'PreviousFavoritesConnection';
      nodes: Array<{
        __typename?: 'PreviousFavorite';
        user: {
          __typename?: 'User';
          id: string;
          displayName: string;
          profileImage: { __typename?: 'UserImage'; url: string };
        };
        bidGroup?:
          | {
              __typename?: 'BundleBidGroup';
              id: string;
              state: BidGroupState;
              bids: Array<{
                __typename?: 'BidGroupBid';
                id: string;
                price: { __typename?: 'Price'; amount: number };
              }>;
            }
          | {
              __typename?: 'ProductBidGroup';
              id: string;
              state: BidGroupState;
              bids: Array<{
                __typename?: 'BidGroupBid';
                id: string;
                price: { __typename?: 'Price'; amount: number };
              }>;
            }
          | null;
      }>;
      pageInfo: {
        __typename?: 'PageInfo';
        hasNextPage?: boolean | null;
        endCursor?: string | null;
      };
    };
  } | null;
};

export type BuyerFavoritesQueryVariables = Exact<{
  productId: Scalars['ID'];
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
}>;

export type BuyerFavoritesQuery = {
  __typename?: 'Query';
  product?: {
    __typename?: 'Product';
    id: string;
    favoritedByConnection: {
      __typename?: 'UsersConnection';
      nodes: Array<{
        __typename?: 'User';
        id: string;
        displayName: string;
        profileImage: { __typename?: 'UserImage'; url: string };
      }>;
      pageInfo: {
        __typename?: 'PageInfo';
        hasNextPage?: boolean | null;
        endCursor?: string | null;
      };
    };
  } | null;
};

export type FavoritedByUserFragment = {
  __typename?: 'User';
  id: string;
  displayName: string;
  profileImage: { __typename?: 'UserImage'; url: string };
};

export type RatingsQueryVariables = Exact<{
  userId: Scalars['ID'];
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
}>;

export type RatingsQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    displayName: string;
    country: Country;
    profileImage: { __typename?: 'UserImage'; url: string };
    rating?: {
      __typename?: 'UserRating';
      ratingCount: number;
      rating: number;
    } | null;
    orderRatingsConnection: {
      __typename?: 'OrderRatingsConnection';
      nodes: Array<{
        __typename?: 'OrderRating';
        rating: number;
        review?: string | null;
        ratedOn: string;
        ratedBy: {
          __typename?: 'User';
          id: string;
          displayName: string;
          followerCount: number;
          profileImage: { __typename?: 'UserImage'; url: string };
        };
      }>;
      pageInfo: {
        __typename?: 'PageInfo';
        hasNextPage?: boolean | null;
        endCursor?: string | null;
      };
    };
  } | null;
};

export type RatingFragment = {
  __typename?: 'OrderRating';
  rating: number;
  review?: string | null;
  ratedOn: string;
  ratedBy: {
    __typename?: 'User';
    id: string;
    displayName: string;
    followerCount: number;
    profileImage: { __typename?: 'UserImage'; url: string };
  };
};

export type InitialDataQueryVariables = Exact<{ [key: string]: never }>;

export type InitialDataQuery = {
  __typename?: 'Query';
  legacyTranslations?: any | null;
};

export type CategoryFieldsFragment = {
  __typename?: 'ProductCategory';
  id: string;
  title: string;
  isUnisex: boolean;
  sizeTypeIds: Array<string>;
  enUrlKey: string;
  frUrlKey: string;
  parent?: { __typename?: 'ProductCategory'; id: string } | null;
  icon?: { __typename?: 'Image'; url: string } | null;
};

export type SizeTypeFragment = {
  __typename?: 'ProductSizeType';
  id: string;
  title: string;
  options: Array<{
    __typename?: 'ProductSizeOption';
    id: string;
    title: string;
  }>;
};

export type ColorFragment = {
  __typename?: 'ProductColor';
  id: string;
  title: string;
  colors: Array<string>;
  isLight: boolean;
};

export type LegacyTranslationsQueryVariables = Exact<{ [key: string]: never }>;

export type LegacyTranslationsQuery = {
  __typename?: 'Query';
  legacyTranslations?: any | null;
};

export type ServerInitQueryVariables = Exact<{ [key: string]: never }>;

export type ServerInitQuery = {
  __typename?: 'Query';
  productCategories: Array<{
    __typename?: 'ProductCategory';
    id: string;
    frUrlKey: string;
    enUrlKey: string;
    children?: Array<{
      __typename?: 'ProductCategory';
      id: string;
      frUrlKey: string;
      enUrlKey: string;
      children?: Array<{
        __typename?: 'ProductCategory';
        id: string;
        frUrlKey: string;
        enUrlKey: string;
        children?: Array<{
          __typename?: 'ProductCategory';
          id: string;
          frUrlKey: string;
          enUrlKey: string;
          children?: Array<{
            __typename?: 'ProductCategory';
            id: string;
            frUrlKey: string;
            enUrlKey: string;
            children?: Array<{
              __typename?: 'ProductCategory';
              id: string;
              frUrlKey: string;
              enUrlKey: string;
            }> | null;
          }> | null;
        }> | null;
      }> | null;
    }> | null;
  }>;
};

export type ServerCategoryFragment = {
  __typename?: 'ProductCategory';
  id: string;
  frUrlKey: string;
  enUrlKey: string;
};

export type AddProductParcelTypeFragment = {
  __typename?: 'ParcelType';
  id: string;
  title: string;
  description: string;
  compactDescription: string;
};

export type AddProductMutationVariables = Exact<{
  input: AddProductInput;
}>;

export type AddProductMutation = {
  __typename?: 'Mutation';
  addProduct?: {
    __typename?: 'AddProductPayload';
    product?: {
      __typename?: 'Product';
      title: string;
      description?: string | null;
      condition: ProductCondition;
      viewerIsSeller: boolean;
      viewerHasInCart: boolean;
      hasBid: boolean;
      hasBiddingEnabled: boolean;
      viewCount: number;
      favoriteCount: number;
      id: string;
      state: ProductState;
      viewerHasFavorited: boolean;
      category: {
        __typename?: 'ProductCategory';
        id: string;
        title: string;
        parent?: {
          __typename?: 'ProductCategory';
          id: string;
          title: string;
          parent?: {
            __typename?: 'ProductCategory';
            id: string;
            title: string;
            parent?: {
              __typename?: 'ProductCategory';
              id: string;
              title: string;
              parent?: {
                __typename?: 'ProductCategory';
                id: string;
                title: string;
                parent?: {
                  __typename?: 'ProductCategory';
                  id: string;
                  title: string;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      };
      thumbnails: Array<{ __typename?: 'ProductImage'; url: string }>;
      image?: { __typename?: 'ProductImage'; url: string } | null;
      images: Array<{ __typename?: 'ProductImage'; url: string }>;
      fullImages: Array<{ __typename?: 'ProductImage'; url: string }>;
      bidding?: {
        __typename?: 'ProductBidding';
        hasBid: boolean;
        highestBid?: { __typename?: 'Price'; amount: number } | null;
        minimumBid: { __typename?: 'Price'; amount: number };
      } | null;
      highestBidPrice?: { __typename?: 'Price'; amount: number } | null;
      minimumBidPrice: { __typename?: 'Price'; amount: number };
      availableParcels: Array<{
        __typename?: 'Parcel';
        id: string;
        price:
          | { __typename?: 'Price'; amount: number }
          | {
              __typename?: 'PriceRange';
              minPrice: { __typename?: 'Price'; amount: number };
              maxPrice: { __typename?: 'Price'; amount: number };
            };
        shipper: {
          __typename?: 'Shipper';
          id: string;
          title: string;
          buyerDescription: string;
          logo: { __typename?: 'Image'; url: string };
        };
      }>;
      seller: {
        __typename?: 'User';
        id: string;
        displayName: string;
        followerCount: number;
        viewerIsFollowing: boolean;
        rating?: {
          __typename?: 'UserRating';
          rating: number;
          ratingCount: number;
        } | null;
        profileImage: { __typename?: 'UserImage'; url: string };
        productsConnection: {
          __typename?: 'ProductsConnection';
          nodes: Array<{
            __typename?: 'Product';
            id: string;
            state: ProductState;
            title: string;
            viewerIsSeller: boolean;
            condition: ProductCondition;
            viewerHasFavorited: boolean;
            brand: { __typename?: 'ProductBrand'; name: string };
            category: {
              __typename?: 'ProductCategory';
              id: string;
              title: string;
              parent?: {
                __typename?: 'ProductCategory';
                id: string;
                title: string;
                parent?: {
                  __typename?: 'ProductCategory';
                  id: string;
                  title: string;
                  parent?: {
                    __typename?: 'ProductCategory';
                    id: string;
                    title: string;
                    parent?: {
                      __typename?: 'ProductCategory';
                      id: string;
                      title: string;
                      parent?: {
                        __typename?: 'ProductCategory';
                        id: string;
                        title: string;
                      } | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
            };
            price: { __typename?: 'Price'; amount: number };
            tags: Array<{ __typename?: 'ProductTag'; name: string }>;
            colors: Array<{ __typename?: 'ProductColor'; title: string }>;
            image?: { __typename?: 'ProductImage'; url: string } | null;
            size?: { __typename?: 'ProductSize'; label: string } | null;
            outletDiscount?: {
              __typename?: 'OutletDiscount';
              discountPercentage: number;
              preOutletPrice: { __typename?: 'Price'; amount: number };
            } | null;
          }>;
        };
      };
      relatedProductsConnection: {
        __typename?: 'ProductsConnection';
        nodes: Array<{
          __typename?: 'Product';
          id: string;
          state: ProductState;
          title: string;
          viewerIsSeller: boolean;
          condition: ProductCondition;
          viewerHasFavorited: boolean;
          brand: { __typename?: 'ProductBrand'; name: string };
          category: {
            __typename?: 'ProductCategory';
            id: string;
            title: string;
            parent?: {
              __typename?: 'ProductCategory';
              id: string;
              title: string;
              parent?: {
                __typename?: 'ProductCategory';
                id: string;
                title: string;
                parent?: {
                  __typename?: 'ProductCategory';
                  id: string;
                  title: string;
                  parent?: {
                    __typename?: 'ProductCategory';
                    id: string;
                    title: string;
                    parent?: {
                      __typename?: 'ProductCategory';
                      id: string;
                      title: string;
                    } | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          };
          price: { __typename?: 'Price'; amount: number };
          tags: Array<{ __typename?: 'ProductTag'; name: string }>;
          colors: Array<{ __typename?: 'ProductColor'; title: string }>;
          image?: { __typename?: 'ProductImage'; url: string } | null;
          size?: { __typename?: 'ProductSize'; label: string } | null;
          outletDiscount?: {
            __typename?: 'OutletDiscount';
            discountPercentage: number;
            preOutletPrice: { __typename?: 'Price'; amount: number };
          } | null;
        }>;
      };
      commentsConnection: {
        __typename?: 'ProductCommentsConnection';
        nodes: Array<{
          __typename?: 'ProductComment';
          id: string;
          sentOn: string;
          body: string;
          sellerIsSender: boolean;
          viewerIsSender: boolean;
          isUnderReview: boolean;
          sender: {
            __typename?: 'User';
            id: string;
            displayName: string;
            profileImage: { __typename?: 'UserImage'; url: string };
          };
          taggedUsers: Array<{
            __typename?: 'User';
            id: string;
            displayName: string;
          }>;
        }>;
        pageInfo: {
          __typename?: 'PageInfo';
          endCursor?: string | null;
          hasNextPage?: boolean | null;
        };
      };
      size?: {
        __typename?: 'ProductSize';
        label: string;
        sizeOptions: Array<{
          __typename?: 'ProductSizeOption';
          id: string;
          title: string;
        }>;
      } | null;
      brand: { __typename?: 'ProductBrand'; name: string };
      price: { __typename?: 'Price'; amount: number };
      tags: Array<{ __typename?: 'ProductTag'; name: string }>;
      colors: Array<{ __typename?: 'ProductColor'; title: string }>;
      outletDiscount?: {
        __typename?: 'OutletDiscount';
        discountPercentage: number;
        preOutletPrice: { __typename?: 'Price'; amount: number };
      } | null;
    } | null;
  } | null;
};

export type UpdateProductMutationVariables = Exact<{
  input: UpdateProductInput;
}>;

export type UpdateProductMutation = {
  __typename?: 'Mutation';
  updateProduct?: {
    __typename?: 'UpdateProductPayload';
    product?: {
      __typename?: 'Product';
      title: string;
      description?: string | null;
      condition: ProductCondition;
      viewerIsSeller: boolean;
      viewerHasInCart: boolean;
      hasBid: boolean;
      hasBiddingEnabled: boolean;
      viewCount: number;
      favoriteCount: number;
      id: string;
      state: ProductState;
      viewerHasFavorited: boolean;
      category: {
        __typename?: 'ProductCategory';
        id: string;
        title: string;
        parent?: {
          __typename?: 'ProductCategory';
          id: string;
          title: string;
          parent?: {
            __typename?: 'ProductCategory';
            id: string;
            title: string;
            parent?: {
              __typename?: 'ProductCategory';
              id: string;
              title: string;
              parent?: {
                __typename?: 'ProductCategory';
                id: string;
                title: string;
                parent?: {
                  __typename?: 'ProductCategory';
                  id: string;
                  title: string;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      };
      thumbnails: Array<{ __typename?: 'ProductImage'; url: string }>;
      image?: { __typename?: 'ProductImage'; url: string } | null;
      images: Array<{ __typename?: 'ProductImage'; url: string }>;
      fullImages: Array<{ __typename?: 'ProductImage'; url: string }>;
      bidding?: {
        __typename?: 'ProductBidding';
        hasBid: boolean;
        highestBid?: { __typename?: 'Price'; amount: number } | null;
        minimumBid: { __typename?: 'Price'; amount: number };
      } | null;
      highestBidPrice?: { __typename?: 'Price'; amount: number } | null;
      minimumBidPrice: { __typename?: 'Price'; amount: number };
      availableParcels: Array<{
        __typename?: 'Parcel';
        id: string;
        price:
          | { __typename?: 'Price'; amount: number }
          | {
              __typename?: 'PriceRange';
              minPrice: { __typename?: 'Price'; amount: number };
              maxPrice: { __typename?: 'Price'; amount: number };
            };
        shipper: {
          __typename?: 'Shipper';
          id: string;
          title: string;
          buyerDescription: string;
          logo: { __typename?: 'Image'; url: string };
        };
      }>;
      seller: {
        __typename?: 'User';
        id: string;
        displayName: string;
        followerCount: number;
        viewerIsFollowing: boolean;
        rating?: {
          __typename?: 'UserRating';
          rating: number;
          ratingCount: number;
        } | null;
        profileImage: { __typename?: 'UserImage'; url: string };
        productsConnection: {
          __typename?: 'ProductsConnection';
          nodes: Array<{
            __typename?: 'Product';
            id: string;
            state: ProductState;
            title: string;
            viewerIsSeller: boolean;
            condition: ProductCondition;
            viewerHasFavorited: boolean;
            brand: { __typename?: 'ProductBrand'; name: string };
            category: {
              __typename?: 'ProductCategory';
              id: string;
              title: string;
              parent?: {
                __typename?: 'ProductCategory';
                id: string;
                title: string;
                parent?: {
                  __typename?: 'ProductCategory';
                  id: string;
                  title: string;
                  parent?: {
                    __typename?: 'ProductCategory';
                    id: string;
                    title: string;
                    parent?: {
                      __typename?: 'ProductCategory';
                      id: string;
                      title: string;
                      parent?: {
                        __typename?: 'ProductCategory';
                        id: string;
                        title: string;
                      } | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
            };
            price: { __typename?: 'Price'; amount: number };
            tags: Array<{ __typename?: 'ProductTag'; name: string }>;
            colors: Array<{ __typename?: 'ProductColor'; title: string }>;
            image?: { __typename?: 'ProductImage'; url: string } | null;
            size?: { __typename?: 'ProductSize'; label: string } | null;
            outletDiscount?: {
              __typename?: 'OutletDiscount';
              discountPercentage: number;
              preOutletPrice: { __typename?: 'Price'; amount: number };
            } | null;
          }>;
        };
      };
      relatedProductsConnection: {
        __typename?: 'ProductsConnection';
        nodes: Array<{
          __typename?: 'Product';
          id: string;
          state: ProductState;
          title: string;
          viewerIsSeller: boolean;
          condition: ProductCondition;
          viewerHasFavorited: boolean;
          brand: { __typename?: 'ProductBrand'; name: string };
          category: {
            __typename?: 'ProductCategory';
            id: string;
            title: string;
            parent?: {
              __typename?: 'ProductCategory';
              id: string;
              title: string;
              parent?: {
                __typename?: 'ProductCategory';
                id: string;
                title: string;
                parent?: {
                  __typename?: 'ProductCategory';
                  id: string;
                  title: string;
                  parent?: {
                    __typename?: 'ProductCategory';
                    id: string;
                    title: string;
                    parent?: {
                      __typename?: 'ProductCategory';
                      id: string;
                      title: string;
                    } | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          };
          price: { __typename?: 'Price'; amount: number };
          tags: Array<{ __typename?: 'ProductTag'; name: string }>;
          colors: Array<{ __typename?: 'ProductColor'; title: string }>;
          image?: { __typename?: 'ProductImage'; url: string } | null;
          size?: { __typename?: 'ProductSize'; label: string } | null;
          outletDiscount?: {
            __typename?: 'OutletDiscount';
            discountPercentage: number;
            preOutletPrice: { __typename?: 'Price'; amount: number };
          } | null;
        }>;
      };
      commentsConnection: {
        __typename?: 'ProductCommentsConnection';
        nodes: Array<{
          __typename?: 'ProductComment';
          id: string;
          sentOn: string;
          body: string;
          sellerIsSender: boolean;
          viewerIsSender: boolean;
          isUnderReview: boolean;
          sender: {
            __typename?: 'User';
            id: string;
            displayName: string;
            profileImage: { __typename?: 'UserImage'; url: string };
          };
          taggedUsers: Array<{
            __typename?: 'User';
            id: string;
            displayName: string;
          }>;
        }>;
        pageInfo: {
          __typename?: 'PageInfo';
          endCursor?: string | null;
          hasNextPage?: boolean | null;
        };
      };
      size?: {
        __typename?: 'ProductSize';
        label: string;
        sizeOptions: Array<{
          __typename?: 'ProductSizeOption';
          id: string;
          title: string;
        }>;
      } | null;
      brand: { __typename?: 'ProductBrand'; name: string };
      price: { __typename?: 'Price'; amount: number };
      tags: Array<{ __typename?: 'ProductTag'; name: string }>;
      colors: Array<{ __typename?: 'ProductColor'; title: string }>;
      outletDiscount?: {
        __typename?: 'OutletDiscount';
        discountPercentage: number;
        preOutletPrice: { __typename?: 'Price'; amount: number };
      } | null;
    } | null;
  } | null;
};

export type AddPartialAddressMutationVariables = Exact<{
  input: AddPartialAddressInput;
}>;

export type AddPartialAddressMutation = {
  __typename?: 'Mutation';
  addPartialAddress?: {
    __typename?: 'AddPartialAddressPayload';
    account?: {
      __typename?: 'Account';
      partialAddress?: {
        __typename?: 'PartialAddress';
        id: string;
        name: string;
        postalCode: string;
        city: string;
        country: Country;
        region: { __typename?: 'AddressRegion'; id: string };
      } | null;
    } | null;
  } | null;
};

export type AddProductRequirementsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AddProductRequirementsQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Viewer';
    account: {
      __typename?: 'Account';
      kycStatus: KycStatus;
      partialAddress?: { __typename?: 'PartialAddress'; id: string } | null;
      defaultAddress?: { __typename?: 'UserAddress'; id: string } | null;
    };
    parcelTypes: Array<{
      __typename?: 'ParcelType';
      id: string;
      title: string;
      description: string;
      compactDescription: string;
    }>;
  } | null;
  shippers: Array<{
    __typename?: 'Shipper';
    id: string;
    title: string;
    sellerDescription: string;
    viewerHasEnabled: boolean;
    isDefault: boolean;
    logo: { __typename?: 'Image'; url: string };
  }>;
};

export type EditProductRequirementsQueryVariables = Exact<{
  productID: Scalars['ID'];
}>;

export type EditProductRequirementsQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Viewer';
    parcelTypes: Array<{
      __typename?: 'ParcelType';
      id: string;
      title: string;
      description: string;
      compactDescription: string;
    }>;
  } | null;
  product?: {
    __typename?: 'Product';
    id: string;
    title: string;
    hasBiddingEnabled: boolean;
    condition: ProductCondition;
    description?: string | null;
    isUnisex: boolean;
    brand: { __typename?: 'ProductBrand'; name: string };
    category: { __typename?: 'ProductCategory'; id: string };
    colors: Array<{ __typename?: 'ProductColor'; id: string }>;
    size?: {
      __typename?: 'ProductSize';
      sizeOptions: Array<{ __typename?: 'ProductSizeOption'; id: string }>;
    } | null;
    tags: Array<{ __typename?: 'ProductTag'; name: string }>;
    price: { __typename?: 'Price'; amount: number };
    originalPrice?: { __typename?: 'Price'; amount: number } | null;
    images: Array<{
      __typename?: 'ProductImage';
      mediaId: string;
      url: string;
      crop?: {
        __typename?: 'Crop';
        x: number;
        y: number;
        width: number;
        height: number;
      } | null;
    }>;
    originalImages: Array<{ __typename?: 'ProductImage'; url: string }>;
    parcelType: { __typename?: 'ParcelType'; id: string };
  } | null;
};

export type CategorySuggestionsQueryVariables = Exact<{
  title: Scalars['String'];
}>;

export type CategorySuggestionsQuery = {
  __typename?: 'Query';
  categorySuggestions: Array<{
    __typename?: 'ProductCategory';
    id: string;
    title: string;
  }>;
};

export type SimilarSoldProductsQueryVariables = Exact<{
  brand: Scalars['String'];
  categoryId: Scalars['ID'];
  sizeIds: Array<Scalars['ID']> | Scalars['ID'];
  condition: ProductCondition;
}>;

export type SimilarSoldProductsQuery = {
  __typename?: 'Query';
  similarSoldProducts: Array<{
    __typename?: 'Product';
    id: string;
    title: string;
    viewerIsSeller: boolean;
    condition: ProductCondition;
    brand: { __typename?: 'ProductBrand'; name: string };
    category: {
      __typename?: 'ProductCategory';
      id: string;
      title: string;
      parent?: {
        __typename?: 'ProductCategory';
        id: string;
        title: string;
        parent?: {
          __typename?: 'ProductCategory';
          id: string;
          title: string;
          parent?: {
            __typename?: 'ProductCategory';
            id: string;
            title: string;
            parent?: {
              __typename?: 'ProductCategory';
              id: string;
              title: string;
              parent?: {
                __typename?: 'ProductCategory';
                id: string;
                title: string;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    };
    price: { __typename?: 'Price'; amount: number };
    tags: Array<{ __typename?: 'ProductTag'; name: string }>;
    colors: Array<{ __typename?: 'ProductColor'; title: string }>;
    image?: { __typename?: 'ProductImage'; url: string } | null;
    size?: { __typename?: 'ProductSize'; label: string } | null;
  }>;
};

export type MeFragment = {
  __typename?: 'User';
  id: string;
  displayName: string;
  language: Language;
  profileImage: { __typename?: 'UserImage'; url: string };
  coverImage: { __typename?: 'UserImage'; url: string };
};

export type ConfirmEmailAddressMutationVariables = Exact<{
  input: ConfirmEmailAddressInput;
}>;

export type ConfirmEmailAddressMutation = {
  __typename?: 'Mutation';
  confirmEmailAddress?: {
    __typename?: 'ConfirmEmailAddressPayload';
    clientMutationId?: string | null;
  } | null;
};

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login?: {
    __typename?: 'LoginPayload';
    clientMutationId?: string | null;
  } | null;
};

export type RegisterAccountMutationVariables = Exact<{
  input: RegisterAccountInput;
}>;

export type RegisterAccountMutation = {
  __typename?: 'Mutation';
  registerAccount?: {
    __typename?: 'RegisterAccountPayload';
    clientMutationId?: string | null;
  } | null;
};

export type ThirdPartyAuthMutationVariables = Exact<{
  input: ThirdPartyAuthInput;
}>;

export type ThirdPartyAuthMutation = {
  __typename?: 'Mutation';
  thirdPartyAuth?: {
    __typename?: 'ThirdPartyAuthPayload';
    clientMutationId?: string | null;
    isExistingAccount: boolean;
  } | null;
};

export type LogoutMutationVariables = Exact<{
  input: LogoutInput;
}>;

export type LogoutMutation = {
  __typename?: 'Mutation';
  logout?: {
    __typename?: 'LogoutPayload';
    clientMutationId?: string | null;
  } | null;
};

export type ForgotPasswordMutationVariables = Exact<{
  input: ForgotPasswordInput;
}>;

export type ForgotPasswordMutation = {
  __typename?: 'Mutation';
  forgotPassword?: {
    __typename?: 'ForgotPasswordPayload';
    clientMutationId?: string | null;
  } | null;
};

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;

export type ResetPasswordMutation = {
  __typename?: 'Mutation';
  resetPassword?: {
    __typename?: 'ResetPasswordPayload';
    clientMutationId?: string | null;
  } | null;
};

export type MarkTutorialAsViewedMutationVariables = Exact<{
  [key: string]: never;
}>;

export type MarkTutorialAsViewedMutation = {
  __typename?: 'Mutation';
  markTutorialAsViewed?: {
    __typename?: 'MarkTutorialAsViewedPayload';
    clientMutationId?: string | null;
  } | null;
};

export type ViewerQueryVariables = Exact<{ [key: string]: never }>;

export type ViewerQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Viewer';
    account: { __typename?: 'Account'; hasSeenTutorial: boolean };
    me: {
      __typename?: 'User';
      id: string;
      displayName: string;
      language: Language;
      profileImage: { __typename?: 'UserImage'; url: string };
      coverImage: { __typename?: 'UserImage'; url: string };
    };
  } | null;
};

type FullBidGroup_BundleBidGroup_Fragment = {
  __typename?: 'BundleBidGroup';
  id: string;
  state: BidGroupState;
  viewerIsSeller: boolean;
  products: Array<{
    __typename?: 'Product';
    id: string;
    brand: { __typename?: 'ProductBrand'; name: string };
    image?: { __typename?: 'ProductImage'; url: string } | null;
    size?: { __typename?: 'ProductSize'; label: string } | null;
    price: { __typename?: 'Price'; amount: number };
  }>;
  seller: {
    __typename?: 'User';
    id: string;
    displayName: string;
    country: Country;
    rating?: {
      __typename?: 'UserRating';
      rating: number;
      ratingCount: number;
    } | null;
    profileImage: { __typename?: 'UserImage'; url: string };
  };
  buyer: {
    __typename?: 'User';
    id: string;
    displayName: string;
    country: Country;
    rating?: {
      __typename?: 'UserRating';
      rating: number;
      ratingCount: number;
    } | null;
    profileImage: { __typename?: 'UserImage'; url: string };
  };
  bids: Array<{
    __typename?: 'BidGroupBid';
    id: string;
    expiresOn: string;
    disbandedOn?: string | null;
    acceptedOn?: string | null;
    withdrawnOn?: string | null;
    price: { __typename?: 'Price'; amount: number };
    user: { __typename?: 'User'; id: string };
  }>;
};

type FullBidGroup_ProductBidGroup_Fragment = {
  __typename?: 'ProductBidGroup';
  id: string;
  state: BidGroupState;
  viewerIsSeller: boolean;
  product: {
    __typename?: 'Product';
    id: string;
    hasBid: boolean;
    hasBiddingEnabled: boolean;
    brand: { __typename?: 'ProductBrand'; name: string };
    image?: { __typename?: 'ProductImage'; url: string } | null;
    price: { __typename?: 'Price'; amount: number };
    highestBidPrice?: { __typename?: 'Price'; amount: number } | null;
    minimumBidPrice: { __typename?: 'Price'; amount: number };
  };
  seller: {
    __typename?: 'User';
    id: string;
    displayName: string;
    country: Country;
    rating?: {
      __typename?: 'UserRating';
      rating: number;
      ratingCount: number;
    } | null;
    profileImage: { __typename?: 'UserImage'; url: string };
  };
  buyer: {
    __typename?: 'User';
    id: string;
    displayName: string;
    country: Country;
    rating?: {
      __typename?: 'UserRating';
      rating: number;
      ratingCount: number;
    } | null;
    profileImage: { __typename?: 'UserImage'; url: string };
  };
  bids: Array<{
    __typename?: 'BidGroupBid';
    id: string;
    expiresOn: string;
    disbandedOn?: string | null;
    acceptedOn?: string | null;
    withdrawnOn?: string | null;
    price: { __typename?: 'Price'; amount: number };
    user: { __typename?: 'User'; id: string };
  }>;
};

export type FullBidGroupFragment =
  | FullBidGroup_BundleBidGroup_Fragment
  | FullBidGroup_ProductBidGroup_Fragment;

export type BidProductFragment = {
  __typename?: 'Product';
  id: string;
  hasBid: boolean;
  hasBiddingEnabled: boolean;
  brand: { __typename?: 'ProductBrand'; name: string };
  image?: { __typename?: 'ProductImage'; url: string } | null;
  price: { __typename?: 'Price'; amount: number };
  highestBidPrice?: { __typename?: 'Price'; amount: number } | null;
  minimumBidPrice: { __typename?: 'Price'; amount: number };
};

export type PlaceBidMutationVariables = Exact<{
  input: PlaceProductBidInput;
}>;

export type PlaceBidMutation = {
  __typename?: 'Mutation';
  placeProductBid?: {
    __typename?: 'PlaceProductBidPayload';
    bidGroup:
      | {
          __typename?: 'BundleBidGroup';
          id: string;
          state: BidGroupState;
          viewerIsSeller: boolean;
          products: Array<{
            __typename?: 'Product';
            id: string;
            brand: { __typename?: 'ProductBrand'; name: string };
            image?: { __typename?: 'ProductImage'; url: string } | null;
            size?: { __typename?: 'ProductSize'; label: string } | null;
            price: { __typename?: 'Price'; amount: number };
          }>;
          seller: {
            __typename?: 'User';
            id: string;
            displayName: string;
            country: Country;
            rating?: {
              __typename?: 'UserRating';
              rating: number;
              ratingCount: number;
            } | null;
            profileImage: { __typename?: 'UserImage'; url: string };
          };
          buyer: {
            __typename?: 'User';
            id: string;
            displayName: string;
            country: Country;
            rating?: {
              __typename?: 'UserRating';
              rating: number;
              ratingCount: number;
            } | null;
            profileImage: { __typename?: 'UserImage'; url: string };
          };
          bids: Array<{
            __typename?: 'BidGroupBid';
            id: string;
            expiresOn: string;
            disbandedOn?: string | null;
            acceptedOn?: string | null;
            withdrawnOn?: string | null;
            price: { __typename?: 'Price'; amount: number };
            user: { __typename?: 'User'; id: string };
          }>;
        }
      | {
          __typename?: 'ProductBidGroup';
          id: string;
          state: BidGroupState;
          viewerIsSeller: boolean;
          product: {
            __typename?: 'Product';
            id: string;
            hasBid: boolean;
            hasBiddingEnabled: boolean;
            brand: { __typename?: 'ProductBrand'; name: string };
            image?: { __typename?: 'ProductImage'; url: string } | null;
            price: { __typename?: 'Price'; amount: number };
            highestBidPrice?: { __typename?: 'Price'; amount: number } | null;
            minimumBidPrice: { __typename?: 'Price'; amount: number };
          };
          seller: {
            __typename?: 'User';
            id: string;
            displayName: string;
            country: Country;
            rating?: {
              __typename?: 'UserRating';
              rating: number;
              ratingCount: number;
            } | null;
            profileImage: { __typename?: 'UserImage'; url: string };
          };
          buyer: {
            __typename?: 'User';
            id: string;
            displayName: string;
            country: Country;
            rating?: {
              __typename?: 'UserRating';
              rating: number;
              ratingCount: number;
            } | null;
            profileImage: { __typename?: 'UserImage'; url: string };
          };
          bids: Array<{
            __typename?: 'BidGroupBid';
            id: string;
            expiresOn: string;
            disbandedOn?: string | null;
            acceptedOn?: string | null;
            withdrawnOn?: string | null;
            price: { __typename?: 'Price'; amount: number };
            user: { __typename?: 'User'; id: string };
          }>;
        };
  } | null;
};

export type WithdrawBidMutationVariables = Exact<{
  input: WithdrawBidInput;
}>;

export type WithdrawBidMutation = {
  __typename?: 'Mutation';
  withdrawBid?: {
    __typename?: 'WithdrawBidPayload';
    bidGroup:
      | {
          __typename?: 'BundleBidGroup';
          id: string;
          state: BidGroupState;
          viewerIsSeller: boolean;
          products: Array<{
            __typename?: 'Product';
            id: string;
            brand: { __typename?: 'ProductBrand'; name: string };
            image?: { __typename?: 'ProductImage'; url: string } | null;
            size?: { __typename?: 'ProductSize'; label: string } | null;
            price: { __typename?: 'Price'; amount: number };
          }>;
          seller: {
            __typename?: 'User';
            id: string;
            displayName: string;
            country: Country;
            rating?: {
              __typename?: 'UserRating';
              rating: number;
              ratingCount: number;
            } | null;
            profileImage: { __typename?: 'UserImage'; url: string };
          };
          buyer: {
            __typename?: 'User';
            id: string;
            displayName: string;
            country: Country;
            rating?: {
              __typename?: 'UserRating';
              rating: number;
              ratingCount: number;
            } | null;
            profileImage: { __typename?: 'UserImage'; url: string };
          };
          bids: Array<{
            __typename?: 'BidGroupBid';
            id: string;
            expiresOn: string;
            disbandedOn?: string | null;
            acceptedOn?: string | null;
            withdrawnOn?: string | null;
            price: { __typename?: 'Price'; amount: number };
            user: { __typename?: 'User'; id: string };
          }>;
        }
      | {
          __typename?: 'ProductBidGroup';
          id: string;
          state: BidGroupState;
          viewerIsSeller: boolean;
          product: {
            __typename?: 'Product';
            id: string;
            hasBid: boolean;
            hasBiddingEnabled: boolean;
            brand: { __typename?: 'ProductBrand'; name: string };
            image?: { __typename?: 'ProductImage'; url: string } | null;
            price: { __typename?: 'Price'; amount: number };
            highestBidPrice?: { __typename?: 'Price'; amount: number } | null;
            minimumBidPrice: { __typename?: 'Price'; amount: number };
          };
          seller: {
            __typename?: 'User';
            id: string;
            displayName: string;
            country: Country;
            rating?: {
              __typename?: 'UserRating';
              rating: number;
              ratingCount: number;
            } | null;
            profileImage: { __typename?: 'UserImage'; url: string };
          };
          buyer: {
            __typename?: 'User';
            id: string;
            displayName: string;
            country: Country;
            rating?: {
              __typename?: 'UserRating';
              rating: number;
              ratingCount: number;
            } | null;
            profileImage: { __typename?: 'UserImage'; url: string };
          };
          bids: Array<{
            __typename?: 'BidGroupBid';
            id: string;
            expiresOn: string;
            disbandedOn?: string | null;
            acceptedOn?: string | null;
            withdrawnOn?: string | null;
            price: { __typename?: 'Price'; amount: number };
            user: { __typename?: 'User'; id: string };
          }>;
        };
  } | null;
};

export type AcceptBidMutationVariables = Exact<{
  input: AcceptBidInput;
}>;

export type AcceptBidMutation = {
  __typename?: 'Mutation';
  acceptBid?: {
    __typename?: 'AcceptBidPayload';
    bidGroup:
      | {
          __typename?: 'BundleBidGroup';
          id: string;
          state: BidGroupState;
          viewerIsSeller: boolean;
          products: Array<{
            __typename?: 'Product';
            id: string;
            brand: { __typename?: 'ProductBrand'; name: string };
            image?: { __typename?: 'ProductImage'; url: string } | null;
            size?: { __typename?: 'ProductSize'; label: string } | null;
            price: { __typename?: 'Price'; amount: number };
          }>;
          seller: {
            __typename?: 'User';
            id: string;
            displayName: string;
            country: Country;
            rating?: {
              __typename?: 'UserRating';
              rating: number;
              ratingCount: number;
            } | null;
            profileImage: { __typename?: 'UserImage'; url: string };
          };
          buyer: {
            __typename?: 'User';
            id: string;
            displayName: string;
            country: Country;
            rating?: {
              __typename?: 'UserRating';
              rating: number;
              ratingCount: number;
            } | null;
            profileImage: { __typename?: 'UserImage'; url: string };
          };
          bids: Array<{
            __typename?: 'BidGroupBid';
            id: string;
            expiresOn: string;
            disbandedOn?: string | null;
            acceptedOn?: string | null;
            withdrawnOn?: string | null;
            price: { __typename?: 'Price'; amount: number };
            user: { __typename?: 'User'; id: string };
          }>;
        }
      | {
          __typename?: 'ProductBidGroup';
          id: string;
          state: BidGroupState;
          viewerIsSeller: boolean;
          product: {
            __typename?: 'Product';
            id: string;
            hasBid: boolean;
            hasBiddingEnabled: boolean;
            brand: { __typename?: 'ProductBrand'; name: string };
            image?: { __typename?: 'ProductImage'; url: string } | null;
            price: { __typename?: 'Price'; amount: number };
            highestBidPrice?: { __typename?: 'Price'; amount: number } | null;
            minimumBidPrice: { __typename?: 'Price'; amount: number };
          };
          seller: {
            __typename?: 'User';
            id: string;
            displayName: string;
            country: Country;
            rating?: {
              __typename?: 'UserRating';
              rating: number;
              ratingCount: number;
            } | null;
            profileImage: { __typename?: 'UserImage'; url: string };
          };
          buyer: {
            __typename?: 'User';
            id: string;
            displayName: string;
            country: Country;
            rating?: {
              __typename?: 'UserRating';
              rating: number;
              ratingCount: number;
            } | null;
            profileImage: { __typename?: 'UserImage'; url: string };
          };
          bids: Array<{
            __typename?: 'BidGroupBid';
            id: string;
            expiresOn: string;
            disbandedOn?: string | null;
            acceptedOn?: string | null;
            withdrawnOn?: string | null;
            price: { __typename?: 'Price'; amount: number };
            user: { __typename?: 'User'; id: string };
          }>;
        };
  } | null;
};

export type PlaceCounterBidMutationVariables = Exact<{
  input: PlaceCounterBidInput;
}>;

export type PlaceCounterBidMutation = {
  __typename?: 'Mutation';
  placeCounterBid?: {
    __typename?: 'PlaceCounterBidPayload';
    bidGroup:
      | {
          __typename?: 'BundleBidGroup';
          id: string;
          state: BidGroupState;
          viewerIsSeller: boolean;
          products: Array<{
            __typename?: 'Product';
            id: string;
            brand: { __typename?: 'ProductBrand'; name: string };
            image?: { __typename?: 'ProductImage'; url: string } | null;
            size?: { __typename?: 'ProductSize'; label: string } | null;
            price: { __typename?: 'Price'; amount: number };
          }>;
          seller: {
            __typename?: 'User';
            id: string;
            displayName: string;
            country: Country;
            rating?: {
              __typename?: 'UserRating';
              rating: number;
              ratingCount: number;
            } | null;
            profileImage: { __typename?: 'UserImage'; url: string };
          };
          buyer: {
            __typename?: 'User';
            id: string;
            displayName: string;
            country: Country;
            rating?: {
              __typename?: 'UserRating';
              rating: number;
              ratingCount: number;
            } | null;
            profileImage: { __typename?: 'UserImage'; url: string };
          };
          bids: Array<{
            __typename?: 'BidGroupBid';
            id: string;
            expiresOn: string;
            disbandedOn?: string | null;
            acceptedOn?: string | null;
            withdrawnOn?: string | null;
            price: { __typename?: 'Price'; amount: number };
            user: { __typename?: 'User'; id: string };
          }>;
        }
      | {
          __typename?: 'ProductBidGroup';
          id: string;
          state: BidGroupState;
          viewerIsSeller: boolean;
          product: {
            __typename?: 'Product';
            id: string;
            hasBid: boolean;
            hasBiddingEnabled: boolean;
            brand: { __typename?: 'ProductBrand'; name: string };
            image?: { __typename?: 'ProductImage'; url: string } | null;
            price: { __typename?: 'Price'; amount: number };
            highestBidPrice?: { __typename?: 'Price'; amount: number } | null;
            minimumBidPrice: { __typename?: 'Price'; amount: number };
          };
          seller: {
            __typename?: 'User';
            id: string;
            displayName: string;
            country: Country;
            rating?: {
              __typename?: 'UserRating';
              rating: number;
              ratingCount: number;
            } | null;
            profileImage: { __typename?: 'UserImage'; url: string };
          };
          buyer: {
            __typename?: 'User';
            id: string;
            displayName: string;
            country: Country;
            rating?: {
              __typename?: 'UserRating';
              rating: number;
              ratingCount: number;
            } | null;
            profileImage: { __typename?: 'UserImage'; url: string };
          };
          bids: Array<{
            __typename?: 'BidGroupBid';
            id: string;
            expiresOn: string;
            disbandedOn?: string | null;
            acceptedOn?: string | null;
            withdrawnOn?: string | null;
            price: { __typename?: 'Price'; amount: number };
            user: { __typename?: 'User'; id: string };
          }>;
        };
  } | null;
};

export type PlaceMassProductBidMutationVariables = Exact<{
  input: PlaceMassProductBidInput;
}>;

export type PlaceMassProductBidMutation = {
  __typename?: 'Mutation';
  placeMassProductBid?: {
    __typename?: 'PlaceMassProductBidPayload';
    bidGroups: Array<
      | {
          __typename?: 'BundleBidGroup';
          id: string;
          state: BidGroupState;
          viewerIsSeller: boolean;
          products: Array<{
            __typename?: 'Product';
            id: string;
            brand: { __typename?: 'ProductBrand'; name: string };
            image?: { __typename?: 'ProductImage'; url: string } | null;
            size?: { __typename?: 'ProductSize'; label: string } | null;
            price: { __typename?: 'Price'; amount: number };
          }>;
          seller: {
            __typename?: 'User';
            id: string;
            displayName: string;
            country: Country;
            rating?: {
              __typename?: 'UserRating';
              rating: number;
              ratingCount: number;
            } | null;
            profileImage: { __typename?: 'UserImage'; url: string };
          };
          buyer: {
            __typename?: 'User';
            id: string;
            displayName: string;
            country: Country;
            rating?: {
              __typename?: 'UserRating';
              rating: number;
              ratingCount: number;
            } | null;
            profileImage: { __typename?: 'UserImage'; url: string };
          };
          bids: Array<{
            __typename?: 'BidGroupBid';
            id: string;
            expiresOn: string;
            disbandedOn?: string | null;
            acceptedOn?: string | null;
            withdrawnOn?: string | null;
            price: { __typename?: 'Price'; amount: number };
            user: { __typename?: 'User'; id: string };
          }>;
        }
      | {
          __typename?: 'ProductBidGroup';
          id: string;
          state: BidGroupState;
          viewerIsSeller: boolean;
          product: {
            __typename?: 'Product';
            id: string;
            hasBid: boolean;
            hasBiddingEnabled: boolean;
            brand: { __typename?: 'ProductBrand'; name: string };
            image?: { __typename?: 'ProductImage'; url: string } | null;
            price: { __typename?: 'Price'; amount: number };
            highestBidPrice?: { __typename?: 'Price'; amount: number } | null;
            minimumBidPrice: { __typename?: 'Price'; amount: number };
          };
          seller: {
            __typename?: 'User';
            id: string;
            displayName: string;
            country: Country;
            rating?: {
              __typename?: 'UserRating';
              rating: number;
              ratingCount: number;
            } | null;
            profileImage: { __typename?: 'UserImage'; url: string };
          };
          buyer: {
            __typename?: 'User';
            id: string;
            displayName: string;
            country: Country;
            rating?: {
              __typename?: 'UserRating';
              rating: number;
              ratingCount: number;
            } | null;
            profileImage: { __typename?: 'UserImage'; url: string };
          };
          bids: Array<{
            __typename?: 'BidGroupBid';
            id: string;
            expiresOn: string;
            disbandedOn?: string | null;
            acceptedOn?: string | null;
            withdrawnOn?: string | null;
            price: { __typename?: 'Price'; amount: number };
            user: { __typename?: 'User'; id: string };
          }>;
        }
      | null
    >;
  } | null;
};

export type PlaceProActiveProductBidMutationVariables = Exact<{
  input: PlaceProActiveProductBidInput;
}>;

export type PlaceProActiveProductBidMutation = {
  __typename?: 'Mutation';
  placeProActiveProductBid?: {
    __typename?: 'PlaceProActiveProductBidPayload';
    bidGroup:
      | {
          __typename?: 'BundleBidGroup';
          id: string;
          state: BidGroupState;
          viewerIsSeller: boolean;
          products: Array<{
            __typename?: 'Product';
            id: string;
            brand: { __typename?: 'ProductBrand'; name: string };
            image?: { __typename?: 'ProductImage'; url: string } | null;
            size?: { __typename?: 'ProductSize'; label: string } | null;
            price: { __typename?: 'Price'; amount: number };
          }>;
          seller: {
            __typename?: 'User';
            id: string;
            displayName: string;
            country: Country;
            rating?: {
              __typename?: 'UserRating';
              rating: number;
              ratingCount: number;
            } | null;
            profileImage: { __typename?: 'UserImage'; url: string };
          };
          buyer: {
            __typename?: 'User';
            id: string;
            displayName: string;
            country: Country;
            rating?: {
              __typename?: 'UserRating';
              rating: number;
              ratingCount: number;
            } | null;
            profileImage: { __typename?: 'UserImage'; url: string };
          };
          bids: Array<{
            __typename?: 'BidGroupBid';
            id: string;
            expiresOn: string;
            disbandedOn?: string | null;
            acceptedOn?: string | null;
            withdrawnOn?: string | null;
            price: { __typename?: 'Price'; amount: number };
            user: { __typename?: 'User'; id: string };
          }>;
        }
      | {
          __typename?: 'ProductBidGroup';
          id: string;
          state: BidGroupState;
          viewerIsSeller: boolean;
          product: {
            __typename?: 'Product';
            id: string;
            hasBid: boolean;
            hasBiddingEnabled: boolean;
            brand: { __typename?: 'ProductBrand'; name: string };
            image?: { __typename?: 'ProductImage'; url: string } | null;
            price: { __typename?: 'Price'; amount: number };
            highestBidPrice?: { __typename?: 'Price'; amount: number } | null;
            minimumBidPrice: { __typename?: 'Price'; amount: number };
          };
          seller: {
            __typename?: 'User';
            id: string;
            displayName: string;
            country: Country;
            rating?: {
              __typename?: 'UserRating';
              rating: number;
              ratingCount: number;
            } | null;
            profileImage: { __typename?: 'UserImage'; url: string };
          };
          buyer: {
            __typename?: 'User';
            id: string;
            displayName: string;
            country: Country;
            rating?: {
              __typename?: 'UserRating';
              rating: number;
              ratingCount: number;
            } | null;
            profileImage: { __typename?: 'UserImage'; url: string };
          };
          bids: Array<{
            __typename?: 'BidGroupBid';
            id: string;
            expiresOn: string;
            disbandedOn?: string | null;
            acceptedOn?: string | null;
            withdrawnOn?: string | null;
            price: { __typename?: 'Price'; amount: number };
            user: { __typename?: 'User'; id: string };
          }>;
        };
  } | null;
};

export type PlaceBundleBidMutationVariables = Exact<{
  input: PlaceBundleBidInput;
}>;

export type PlaceBundleBidMutation = {
  __typename?: 'Mutation';
  placeBundleBid?: {
    __typename?: 'PlaceBundleBidPayload';
    bidGroup:
      | {
          __typename?: 'BundleBidGroup';
          id: string;
          state: BidGroupState;
          viewerIsSeller: boolean;
          products: Array<{
            __typename?: 'Product';
            id: string;
            brand: { __typename?: 'ProductBrand'; name: string };
            image?: { __typename?: 'ProductImage'; url: string } | null;
            size?: { __typename?: 'ProductSize'; label: string } | null;
            price: { __typename?: 'Price'; amount: number };
          }>;
          seller: {
            __typename?: 'User';
            id: string;
            displayName: string;
            country: Country;
            rating?: {
              __typename?: 'UserRating';
              rating: number;
              ratingCount: number;
            } | null;
            profileImage: { __typename?: 'UserImage'; url: string };
          };
          buyer: {
            __typename?: 'User';
            id: string;
            displayName: string;
            country: Country;
            rating?: {
              __typename?: 'UserRating';
              rating: number;
              ratingCount: number;
            } | null;
            profileImage: { __typename?: 'UserImage'; url: string };
          };
          bids: Array<{
            __typename?: 'BidGroupBid';
            id: string;
            expiresOn: string;
            disbandedOn?: string | null;
            acceptedOn?: string | null;
            withdrawnOn?: string | null;
            price: { __typename?: 'Price'; amount: number };
            user: { __typename?: 'User'; id: string };
          }>;
        }
      | {
          __typename?: 'ProductBidGroup';
          id: string;
          state: BidGroupState;
          viewerIsSeller: boolean;
          product: {
            __typename?: 'Product';
            id: string;
            hasBid: boolean;
            hasBiddingEnabled: boolean;
            brand: { __typename?: 'ProductBrand'; name: string };
            image?: { __typename?: 'ProductImage'; url: string } | null;
            price: { __typename?: 'Price'; amount: number };
            highestBidPrice?: { __typename?: 'Price'; amount: number } | null;
            minimumBidPrice: { __typename?: 'Price'; amount: number };
          };
          seller: {
            __typename?: 'User';
            id: string;
            displayName: string;
            country: Country;
            rating?: {
              __typename?: 'UserRating';
              rating: number;
              ratingCount: number;
            } | null;
            profileImage: { __typename?: 'UserImage'; url: string };
          };
          buyer: {
            __typename?: 'User';
            id: string;
            displayName: string;
            country: Country;
            rating?: {
              __typename?: 'UserRating';
              rating: number;
              ratingCount: number;
            } | null;
            profileImage: { __typename?: 'UserImage'; url: string };
          };
          bids: Array<{
            __typename?: 'BidGroupBid';
            id: string;
            expiresOn: string;
            disbandedOn?: string | null;
            acceptedOn?: string | null;
            withdrawnOn?: string | null;
            price: { __typename?: 'Price'; amount: number };
            user: { __typename?: 'User'; id: string };
          }>;
        };
  } | null;
};

export type BundleBidProductsQueryVariables = Exact<{
  userId: Scalars['ID'];
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
}>;

export type BundleBidProductsQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    bundleProductsConnection: {
      __typename?: 'ProductsConnection';
      nodes: Array<{
        __typename?: 'Product';
        id: string;
        state: ProductState;
        title: string;
        viewerIsSeller: boolean;
        condition: ProductCondition;
        viewerHasFavorited: boolean;
        brand: { __typename?: 'ProductBrand'; name: string };
        category: {
          __typename?: 'ProductCategory';
          id: string;
          title: string;
          parent?: {
            __typename?: 'ProductCategory';
            id: string;
            title: string;
            parent?: {
              __typename?: 'ProductCategory';
              id: string;
              title: string;
              parent?: {
                __typename?: 'ProductCategory';
                id: string;
                title: string;
                parent?: {
                  __typename?: 'ProductCategory';
                  id: string;
                  title: string;
                  parent?: {
                    __typename?: 'ProductCategory';
                    id: string;
                    title: string;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
        };
        price: { __typename?: 'Price'; amount: number };
        tags: Array<{ __typename?: 'ProductTag'; name: string }>;
        colors: Array<{ __typename?: 'ProductColor'; title: string }>;
        image?: { __typename?: 'ProductImage'; url: string } | null;
        size?: { __typename?: 'ProductSize'; label: string } | null;
        outletDiscount?: {
          __typename?: 'OutletDiscount';
          discountPercentage: number;
          preOutletPrice: { __typename?: 'Price'; amount: number };
        } | null;
      }>;
      pageInfo: {
        __typename?: 'PageInfo';
        endCursor?: string | null;
        hasNextPage?: boolean | null;
      };
    };
  } | null;
};

export type BidsQueryVariables = Exact<{
  type: BidGroupType;
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
}>;

export type BidsQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Viewer';
    bidGroupsConnection: {
      __typename?: 'BidGroupsConnection';
      totalCount: number;
      nodes: Array<
        | {
            __typename?: 'BundleBidGroup';
            id: string;
            state: BidGroupState;
            products: Array<{
              __typename?: 'Product';
              id: string;
              brand: { __typename?: 'ProductBrand'; name: string };
              image?: { __typename?: 'ProductImage'; url: string } | null;
              size?: { __typename?: 'ProductSize'; label: string } | null;
              price: { __typename?: 'Price'; amount: number };
            }>;
            bids: Array<{
              __typename?: 'BidGroupBid';
              expiresOn: string;
              price: { __typename?: 'Price'; amount: number };
            }>;
          }
        | {
            __typename?: 'ProductBidGroup';
            id: string;
            state: BidGroupState;
            product: {
              __typename?: 'Product';
              id: string;
              brand: { __typename?: 'ProductBrand'; name: string };
              image?: { __typename?: 'ProductImage'; url: string } | null;
            };
            bids: Array<{
              __typename?: 'BidGroupBid';
              expiresOn: string;
              price: { __typename?: 'Price'; amount: number };
            }>;
          }
      >;
    };
  } | null;
};

export type BidQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type BidQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Viewer';
    bidGroup?:
      | {
          __typename?: 'BundleBidGroup';
          id: string;
          state: BidGroupState;
          viewerIsSeller: boolean;
          products: Array<{
            __typename?: 'Product';
            id: string;
            brand: { __typename?: 'ProductBrand'; name: string };
            image?: { __typename?: 'ProductImage'; url: string } | null;
            size?: { __typename?: 'ProductSize'; label: string } | null;
            price: { __typename?: 'Price'; amount: number };
          }>;
          seller: {
            __typename?: 'User';
            id: string;
            displayName: string;
            country: Country;
            rating?: {
              __typename?: 'UserRating';
              rating: number;
              ratingCount: number;
            } | null;
            profileImage: { __typename?: 'UserImage'; url: string };
          };
          buyer: {
            __typename?: 'User';
            id: string;
            displayName: string;
            country: Country;
            rating?: {
              __typename?: 'UserRating';
              rating: number;
              ratingCount: number;
            } | null;
            profileImage: { __typename?: 'UserImage'; url: string };
          };
          bids: Array<{
            __typename?: 'BidGroupBid';
            id: string;
            expiresOn: string;
            disbandedOn?: string | null;
            acceptedOn?: string | null;
            withdrawnOn?: string | null;
            price: { __typename?: 'Price'; amount: number };
            user: { __typename?: 'User'; id: string };
          }>;
        }
      | {
          __typename?: 'ProductBidGroup';
          id: string;
          state: BidGroupState;
          viewerIsSeller: boolean;
          product: {
            __typename?: 'Product';
            id: string;
            hasBid: boolean;
            hasBiddingEnabled: boolean;
            brand: { __typename?: 'ProductBrand'; name: string };
            image?: { __typename?: 'ProductImage'; url: string } | null;
            price: { __typename?: 'Price'; amount: number };
            highestBidPrice?: { __typename?: 'Price'; amount: number } | null;
            minimumBidPrice: { __typename?: 'Price'; amount: number };
          };
          seller: {
            __typename?: 'User';
            id: string;
            displayName: string;
            country: Country;
            rating?: {
              __typename?: 'UserRating';
              rating: number;
              ratingCount: number;
            } | null;
            profileImage: { __typename?: 'UserImage'; url: string };
          };
          buyer: {
            __typename?: 'User';
            id: string;
            displayName: string;
            country: Country;
            rating?: {
              __typename?: 'UserRating';
              rating: number;
              ratingCount: number;
            } | null;
            profileImage: { __typename?: 'UserImage'; url: string };
          };
          bids: Array<{
            __typename?: 'BidGroupBid';
            id: string;
            expiresOn: string;
            disbandedOn?: string | null;
            acceptedOn?: string | null;
            withdrawnOn?: string | null;
            price: { __typename?: 'Price'; amount: number };
            user: { __typename?: 'User'; id: string };
          }>;
        }
      | null;
  } | null;
};

export type FullBlogPostFragment = {
  __typename?: 'BlogPost';
  id: string;
  urlKey: string;
  title: string;
  content: string;
  metaTitle?: string | null;
  metaDescription?: string | null;
  date: string;
  excerpt?: string | null;
  noIndex: boolean;
  tags: Array<{
    __typename?: 'BlogTag';
    id: string;
    title: string;
    urlKey: string;
    description?: string | null;
    metaTitle?: string | null;
    metaDescription?: string | null;
    postsConnection: {
      __typename?: 'BlogPostsConnection';
      nodes: Array<{
        __typename?: 'BlogPost';
        id: string;
        urlKey: string;
        title: string;
        date: string;
        excerpt?: string | null;
        featureImage?: { __typename?: 'Image'; url: string } | null;
        tags: Array<{
          __typename?: 'BlogTag';
          id: string;
          title: string;
          urlKey: string;
          description?: string | null;
          metaTitle?: string | null;
          metaDescription?: string | null;
          featureImage?: { __typename?: 'Image'; url: string } | null;
        }>;
      }>;
    };
    featureImage?: { __typename?: 'Image'; url: string } | null;
  }>;
  featureImage?: { __typename?: 'Image'; url: string } | null;
};

export type FeedBlogPostFragment = {
  __typename?: 'BlogPost';
  id: string;
  urlKey: string;
  title: string;
  date: string;
  excerpt?: string | null;
  featureImage?: { __typename?: 'Image'; url: string } | null;
  tags: Array<{
    __typename?: 'BlogTag';
    id: string;
    title: string;
    urlKey: string;
    description?: string | null;
    metaTitle?: string | null;
    metaDescription?: string | null;
    featureImage?: { __typename?: 'Image'; url: string } | null;
  }>;
};

export type FullBlogTagFragment = {
  __typename?: 'BlogTag';
  id: string;
  title: string;
  urlKey: string;
  description?: string | null;
  metaTitle?: string | null;
  metaDescription?: string | null;
  featureImage?: { __typename?: 'Image'; url: string } | null;
};

export type BlogQueryVariables = Exact<{
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<BlogPostFilters>;
}>;

export type BlogQuery = {
  __typename?: 'Query';
  featured: {
    __typename?: 'BlogPostsConnection';
    nodes: Array<{
      __typename?: 'BlogPost';
      id: string;
      urlKey: string;
      title: string;
      content: string;
      metaTitle?: string | null;
      metaDescription?: string | null;
      date: string;
      excerpt?: string | null;
      noIndex: boolean;
      tags: Array<{
        __typename?: 'BlogTag';
        id: string;
        title: string;
        urlKey: string;
        description?: string | null;
        metaTitle?: string | null;
        metaDescription?: string | null;
        postsConnection: {
          __typename?: 'BlogPostsConnection';
          nodes: Array<{
            __typename?: 'BlogPost';
            id: string;
            urlKey: string;
            title: string;
            date: string;
            excerpt?: string | null;
            featureImage?: { __typename?: 'Image'; url: string } | null;
            tags: Array<{
              __typename?: 'BlogTag';
              id: string;
              title: string;
              urlKey: string;
              description?: string | null;
              metaTitle?: string | null;
              metaDescription?: string | null;
              featureImage?: { __typename?: 'Image'; url: string } | null;
            }>;
          }>;
        };
        featureImage?: { __typename?: 'Image'; url: string } | null;
      }>;
      featureImage?: { __typename?: 'Image'; url: string } | null;
    }>;
  };
  blogPostsConnection: {
    __typename?: 'BlogPostsConnection';
    totalCount: number;
    nodes: Array<{
      __typename?: 'BlogPost';
      id: string;
      urlKey: string;
      title: string;
      content: string;
      metaTitle?: string | null;
      metaDescription?: string | null;
      date: string;
      excerpt?: string | null;
      noIndex: boolean;
      tags: Array<{
        __typename?: 'BlogTag';
        id: string;
        title: string;
        urlKey: string;
        description?: string | null;
        metaTitle?: string | null;
        metaDescription?: string | null;
        postsConnection: {
          __typename?: 'BlogPostsConnection';
          nodes: Array<{
            __typename?: 'BlogPost';
            id: string;
            urlKey: string;
            title: string;
            date: string;
            excerpt?: string | null;
            featureImage?: { __typename?: 'Image'; url: string } | null;
            tags: Array<{
              __typename?: 'BlogTag';
              id: string;
              title: string;
              urlKey: string;
              description?: string | null;
              metaTitle?: string | null;
              metaDescription?: string | null;
              featureImage?: { __typename?: 'Image'; url: string } | null;
            }>;
          }>;
        };
        featureImage?: { __typename?: 'Image'; url: string } | null;
      }>;
      featureImage?: { __typename?: 'Image'; url: string } | null;
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage?: boolean | null;
      endCursor?: string | null;
    };
  };
  blogTags: Array<{
    __typename?: 'BlogTag';
    id: string;
    title: string;
    urlKey: string;
    description?: string | null;
    metaTitle?: string | null;
    metaDescription?: string | null;
    featureImage?: { __typename?: 'Image'; url: string } | null;
  }>;
};

export type BlogTagQueryVariables = Exact<{
  urlKey: Scalars['String'];
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<BlogPostFilters>;
}>;

export type BlogTagQuery = {
  __typename?: 'Query';
  blogTag?: {
    __typename?: 'BlogTag';
    id: string;
    title: string;
    urlKey: string;
    description?: string | null;
    metaTitle?: string | null;
    metaDescription?: string | null;
    postsConnection: {
      __typename?: 'BlogPostsConnection';
      totalCount: number;
      nodes: Array<{
        __typename?: 'BlogPost';
        id: string;
        urlKey: string;
        title: string;
        content: string;
        metaTitle?: string | null;
        metaDescription?: string | null;
        date: string;
        excerpt?: string | null;
        noIndex: boolean;
        tags: Array<{
          __typename?: 'BlogTag';
          id: string;
          title: string;
          urlKey: string;
          description?: string | null;
          metaTitle?: string | null;
          metaDescription?: string | null;
          postsConnection: {
            __typename?: 'BlogPostsConnection';
            nodes: Array<{
              __typename?: 'BlogPost';
              id: string;
              urlKey: string;
              title: string;
              date: string;
              excerpt?: string | null;
              featureImage?: { __typename?: 'Image'; url: string } | null;
              tags: Array<{
                __typename?: 'BlogTag';
                id: string;
                title: string;
                urlKey: string;
                description?: string | null;
                metaTitle?: string | null;
                metaDescription?: string | null;
                featureImage?: { __typename?: 'Image'; url: string } | null;
              }>;
            }>;
          };
          featureImage?: { __typename?: 'Image'; url: string } | null;
        }>;
        featureImage?: { __typename?: 'Image'; url: string } | null;
      }>;
      pageInfo: {
        __typename?: 'PageInfo';
        hasNextPage?: boolean | null;
        endCursor?: string | null;
      };
    };
    featureImage?: { __typename?: 'Image'; url: string } | null;
  } | null;
  blogTags: Array<{
    __typename?: 'BlogTag';
    id: string;
    title: string;
    urlKey: string;
    description?: string | null;
    metaTitle?: string | null;
    metaDescription?: string | null;
    featureImage?: { __typename?: 'Image'; url: string } | null;
  }>;
};

export type BlogPostQueryVariables = Exact<{
  urlKey: Scalars['String'];
}>;

export type BlogPostQuery = {
  __typename?: 'Query';
  blogPost?: {
    __typename?: 'BlogPost';
    id: string;
    urlKey: string;
    title: string;
    content: string;
    metaTitle?: string | null;
    metaDescription?: string | null;
    date: string;
    excerpt?: string | null;
    noIndex: boolean;
    tags: Array<{
      __typename?: 'BlogTag';
      id: string;
      title: string;
      urlKey: string;
      description?: string | null;
      metaTitle?: string | null;
      metaDescription?: string | null;
      postsConnection: {
        __typename?: 'BlogPostsConnection';
        nodes: Array<{
          __typename?: 'BlogPost';
          id: string;
          urlKey: string;
          title: string;
          date: string;
          excerpt?: string | null;
          featureImage?: { __typename?: 'Image'; url: string } | null;
          tags: Array<{
            __typename?: 'BlogTag';
            id: string;
            title: string;
            urlKey: string;
            description?: string | null;
            metaTitle?: string | null;
            metaDescription?: string | null;
            featureImage?: { __typename?: 'Image'; url: string } | null;
          }>;
        }>;
      };
      featureImage?: { __typename?: 'Image'; url: string } | null;
    }>;
    featureImage?: { __typename?: 'Image'; url: string } | null;
  } | null;
};

export type FullServicePointFragment = {
  __typename?: 'ServicePoint';
  externalId: string;
  name: string;
  line1: string;
  postalCode: string;
  city: string;
  country: Country;
  regionCode: string;
  address: string;
};

export type CheckoutCartFragment = {
  __typename?: 'ShoppingCart';
  id: string;
  groups: Array<{
    __typename?: 'ShoppingCartGroup';
    id: string;
    deliveryType: DeliveryType;
    items: Array<
      | {
          __typename?: 'BundleBidGroup';
          bids: Array<{
            __typename?: 'BidGroupBid';
            price: { __typename?: 'Price'; amount: number };
          }>;
          image?: { __typename?: 'Image'; url: string } | null;
          products: Array<{ __typename?: 'Product'; id: string }>;
        }
      | {
          __typename?: 'Product';
          id: string;
          state: ProductState;
          image?: { __typename?: 'ProductImage'; url: string } | null;
          brand: { __typename?: 'ProductBrand'; name: string };
          price: { __typename?: 'Price'; amount: number };
          outletDiscount?: {
            __typename?: 'OutletDiscount';
            discountPercentage: number;
            preOutletPrice: { __typename?: 'Price'; amount: number };
          } | null;
          size?: {
            __typename?: 'ProductSize';
            sizeOptions: Array<{
              __typename?: 'ProductSizeOption';
              title: string;
            }>;
          } | null;
        }
      | {
          __typename?: 'ProductBidGroup';
          bids: Array<{
            __typename?: 'BidGroupBid';
            price: { __typename?: 'Price'; amount: number };
          }>;
          product: {
            __typename?: 'Product';
            id: string;
            state: ProductState;
            image?: { __typename?: 'ProductImage'; url: string } | null;
            brand: { __typename?: 'ProductBrand'; name: string };
            price: { __typename?: 'Price'; amount: number };
            outletDiscount?: {
              __typename?: 'OutletDiscount';
              discountPercentage: number;
              preOutletPrice: { __typename?: 'Price'; amount: number };
            } | null;
            size?: {
              __typename?: 'ProductSize';
              sizeOptions: Array<{
                __typename?: 'ProductSizeOption';
                title: string;
              }>;
            } | null;
          };
        }
    >;
    availableParcels: Array<{
      __typename?: 'Parcel';
      id: string;
      shippingMethod: ShippingMethod;
      price:
        | { __typename?: 'Price'; amount: number }
        | {
            __typename?: 'PriceRange';
            minPrice: { __typename?: 'Price'; amount: number };
            maxPrice: { __typename?: 'Price'; amount: number };
          };
      shipper: {
        __typename?: 'Shipper';
        id: string;
        title: string;
        logo: { __typename?: 'Image'; url: string };
      };
    }>;
    parcel?: { __typename?: 'Parcel'; id: string } | null;
    shipmentDestination?:
      | {
          __typename?: 'ShipmentServicePoint';
          servicePoint: {
            __typename?: 'ServicePoint';
            name: string;
            address: string;
          };
        }
      | {
          __typename?: 'UserAddress';
          id: string;
          name: string;
          line1: string;
          line2?: string | null;
          postalCode: string;
          city: string;
          country: Country;
          isDefault: boolean;
          address: string;
          region: { __typename?: 'AddressRegion'; id: string };
        }
      | null;
  }>;
  paymentMethods: Array<{
    __typename?: 'PaymentMethod';
    id: string;
    label: string;
    type: PaymentMethodType;
    icon: { __typename?: 'Image'; url: string };
  }>;
  creditSummary: {
    __typename?: 'ShoppingCartCredit';
    available: boolean;
    unavailableReasons?: Array<string> | null;
    credit: { __typename?: 'Price'; amount: number };
  };
  prices: {
    __typename?: 'ShoppingCartPrices';
    total: { __typename?: 'Price'; amount: number };
    priceBreakdown: Array<
      | {
          __typename?: 'BasicCartPriceBreakdownItem';
          title: string;
          pricePlaceholder?: string | null;
          price: { __typename?: 'Price'; amount: number };
        }
      | {
          __typename?: 'DescriptionCartPriceBreakdownItem';
          description?: string | null;
          title: string;
          pricePlaceholder?: string | null;
          price: { __typename?: 'Price'; amount: number };
        }
    >;
    subtotal: { __typename?: 'Price'; amount: number };
  };
};

export type CheckoutCartGroupFragment = {
  __typename?: 'ShoppingCartGroup';
  id: string;
  deliveryType: DeliveryType;
  availableParcels: Array<{
    __typename?: 'Parcel';
    id: string;
    shippingMethod: ShippingMethod;
    price:
      | { __typename?: 'Price'; amount: number }
      | {
          __typename?: 'PriceRange';
          minPrice: { __typename?: 'Price'; amount: number };
          maxPrice: { __typename?: 'Price'; amount: number };
        };
    shipper: {
      __typename?: 'Shipper';
      id: string;
      title: string;
      logo: { __typename?: 'Image'; url: string };
    };
  }>;
  parcel?: { __typename?: 'Parcel'; id: string } | null;
  shipmentDestination?:
    | {
        __typename?: 'ShipmentServicePoint';
        servicePoint: {
          __typename?: 'ServicePoint';
          name: string;
          address: string;
        };
      }
    | {
        __typename?: 'UserAddress';
        id: string;
        name: string;
        line1: string;
        line2?: string | null;
        postalCode: string;
        city: string;
        country: Country;
        isDefault: boolean;
        address: string;
        region: { __typename?: 'AddressRegion'; id: string };
      }
    | null;
  items: Array<
    | {
        __typename?: 'BundleBidGroup';
        bids: Array<{
          __typename?: 'BidGroupBid';
          price: { __typename?: 'Price'; amount: number };
        }>;
        image?: { __typename?: 'Image'; url: string } | null;
        products: Array<{ __typename?: 'Product'; id: string }>;
      }
    | {
        __typename?: 'Product';
        id: string;
        state: ProductState;
        image?: { __typename?: 'ProductImage'; url: string } | null;
        brand: { __typename?: 'ProductBrand'; name: string };
        price: { __typename?: 'Price'; amount: number };
        outletDiscount?: {
          __typename?: 'OutletDiscount';
          discountPercentage: number;
          preOutletPrice: { __typename?: 'Price'; amount: number };
        } | null;
        size?: {
          __typename?: 'ProductSize';
          sizeOptions: Array<{
            __typename?: 'ProductSizeOption';
            title: string;
          }>;
        } | null;
      }
    | {
        __typename?: 'ProductBidGroup';
        bids: Array<{
          __typename?: 'BidGroupBid';
          price: { __typename?: 'Price'; amount: number };
        }>;
        product: {
          __typename?: 'Product';
          id: string;
          state: ProductState;
          image?: { __typename?: 'ProductImage'; url: string } | null;
          brand: { __typename?: 'ProductBrand'; name: string };
          price: { __typename?: 'Price'; amount: number };
          outletDiscount?: {
            __typename?: 'OutletDiscount';
            discountPercentage: number;
            preOutletPrice: { __typename?: 'Price'; amount: number };
          } | null;
          size?: {
            __typename?: 'ProductSize';
            sizeOptions: Array<{
              __typename?: 'ProductSizeOption';
              title: string;
            }>;
          } | null;
        };
      }
  >;
};

export type SetParcelForCartGroupMutationVariables = Exact<{
  input: SetParcelForCartGroupInput;
}>;

export type SetParcelForCartGroupMutation = {
  __typename?: 'Mutation';
  setParcelForCartGroup?: {
    __typename?: 'SetParcelForCartGroupPayload';
    shoppingCart: {
      __typename?: 'ShoppingCart';
      id: string;
      groups: Array<{
        __typename?: 'ShoppingCartGroup';
        id: string;
        deliveryType: DeliveryType;
        items: Array<
          | {
              __typename?: 'BundleBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              image?: { __typename?: 'Image'; url: string } | null;
              products: Array<{ __typename?: 'Product'; id: string }>;
            }
          | {
              __typename?: 'Product';
              id: string;
              state: ProductState;
              image?: { __typename?: 'ProductImage'; url: string } | null;
              brand: { __typename?: 'ProductBrand'; name: string };
              price: { __typename?: 'Price'; amount: number };
              outletDiscount?: {
                __typename?: 'OutletDiscount';
                discountPercentage: number;
                preOutletPrice: { __typename?: 'Price'; amount: number };
              } | null;
              size?: {
                __typename?: 'ProductSize';
                sizeOptions: Array<{
                  __typename?: 'ProductSizeOption';
                  title: string;
                }>;
              } | null;
            }
          | {
              __typename?: 'ProductBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              product: {
                __typename?: 'Product';
                id: string;
                state: ProductState;
                image?: { __typename?: 'ProductImage'; url: string } | null;
                brand: { __typename?: 'ProductBrand'; name: string };
                price: { __typename?: 'Price'; amount: number };
                outletDiscount?: {
                  __typename?: 'OutletDiscount';
                  discountPercentage: number;
                  preOutletPrice: { __typename?: 'Price'; amount: number };
                } | null;
                size?: {
                  __typename?: 'ProductSize';
                  sizeOptions: Array<{
                    __typename?: 'ProductSizeOption';
                    title: string;
                  }>;
                } | null;
              };
            }
        >;
        availableParcels: Array<{
          __typename?: 'Parcel';
          id: string;
          shippingMethod: ShippingMethod;
          price:
            | { __typename?: 'Price'; amount: number }
            | {
                __typename?: 'PriceRange';
                minPrice: { __typename?: 'Price'; amount: number };
                maxPrice: { __typename?: 'Price'; amount: number };
              };
          shipper: {
            __typename?: 'Shipper';
            id: string;
            title: string;
            logo: { __typename?: 'Image'; url: string };
          };
        }>;
        parcel?: { __typename?: 'Parcel'; id: string } | null;
        shipmentDestination?:
          | {
              __typename?: 'ShipmentServicePoint';
              servicePoint: {
                __typename?: 'ServicePoint';
                name: string;
                address: string;
              };
            }
          | {
              __typename?: 'UserAddress';
              id: string;
              name: string;
              line1: string;
              line2?: string | null;
              postalCode: string;
              city: string;
              country: Country;
              isDefault: boolean;
              address: string;
              region: { __typename?: 'AddressRegion'; id: string };
            }
          | null;
      }>;
      paymentMethods: Array<{
        __typename?: 'PaymentMethod';
        id: string;
        label: string;
        type: PaymentMethodType;
        icon: { __typename?: 'Image'; url: string };
      }>;
      creditSummary: {
        __typename?: 'ShoppingCartCredit';
        available: boolean;
        unavailableReasons?: Array<string> | null;
        credit: { __typename?: 'Price'; amount: number };
      };
      prices: {
        __typename?: 'ShoppingCartPrices';
        total: { __typename?: 'Price'; amount: number };
        priceBreakdown: Array<
          | {
              __typename?: 'BasicCartPriceBreakdownItem';
              title: string;
              pricePlaceholder?: string | null;
              price: { __typename?: 'Price'; amount: number };
            }
          | {
              __typename?: 'DescriptionCartPriceBreakdownItem';
              description?: string | null;
              title: string;
              pricePlaceholder?: string | null;
              price: { __typename?: 'Price'; amount: number };
            }
        >;
        subtotal: { __typename?: 'Price'; amount: number };
      };
    };
  } | null;
};

export type SetAddressForCartGroupMutationVariables = Exact<{
  input: SetAddressForCartGroupInput;
}>;

export type SetAddressForCartGroupMutation = {
  __typename?: 'Mutation';
  setAddressForCartGroup?: {
    __typename?: 'SetAddressForCartGroupPayload';
    shoppingCart: {
      __typename?: 'ShoppingCart';
      id: string;
      groups: Array<{
        __typename?: 'ShoppingCartGroup';
        id: string;
        deliveryType: DeliveryType;
        items: Array<
          | {
              __typename?: 'BundleBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              image?: { __typename?: 'Image'; url: string } | null;
              products: Array<{ __typename?: 'Product'; id: string }>;
            }
          | {
              __typename?: 'Product';
              id: string;
              state: ProductState;
              image?: { __typename?: 'ProductImage'; url: string } | null;
              brand: { __typename?: 'ProductBrand'; name: string };
              price: { __typename?: 'Price'; amount: number };
              outletDiscount?: {
                __typename?: 'OutletDiscount';
                discountPercentage: number;
                preOutletPrice: { __typename?: 'Price'; amount: number };
              } | null;
              size?: {
                __typename?: 'ProductSize';
                sizeOptions: Array<{
                  __typename?: 'ProductSizeOption';
                  title: string;
                }>;
              } | null;
            }
          | {
              __typename?: 'ProductBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              product: {
                __typename?: 'Product';
                id: string;
                state: ProductState;
                image?: { __typename?: 'ProductImage'; url: string } | null;
                brand: { __typename?: 'ProductBrand'; name: string };
                price: { __typename?: 'Price'; amount: number };
                outletDiscount?: {
                  __typename?: 'OutletDiscount';
                  discountPercentage: number;
                  preOutletPrice: { __typename?: 'Price'; amount: number };
                } | null;
                size?: {
                  __typename?: 'ProductSize';
                  sizeOptions: Array<{
                    __typename?: 'ProductSizeOption';
                    title: string;
                  }>;
                } | null;
              };
            }
        >;
        availableParcels: Array<{
          __typename?: 'Parcel';
          id: string;
          shippingMethod: ShippingMethod;
          price:
            | { __typename?: 'Price'; amount: number }
            | {
                __typename?: 'PriceRange';
                minPrice: { __typename?: 'Price'; amount: number };
                maxPrice: { __typename?: 'Price'; amount: number };
              };
          shipper: {
            __typename?: 'Shipper';
            id: string;
            title: string;
            logo: { __typename?: 'Image'; url: string };
          };
        }>;
        parcel?: { __typename?: 'Parcel'; id: string } | null;
        shipmentDestination?:
          | {
              __typename?: 'ShipmentServicePoint';
              servicePoint: {
                __typename?: 'ServicePoint';
                name: string;
                address: string;
              };
            }
          | {
              __typename?: 'UserAddress';
              id: string;
              name: string;
              line1: string;
              line2?: string | null;
              postalCode: string;
              city: string;
              country: Country;
              isDefault: boolean;
              address: string;
              region: { __typename?: 'AddressRegion'; id: string };
            }
          | null;
      }>;
      paymentMethods: Array<{
        __typename?: 'PaymentMethod';
        id: string;
        label: string;
        type: PaymentMethodType;
        icon: { __typename?: 'Image'; url: string };
      }>;
      creditSummary: {
        __typename?: 'ShoppingCartCredit';
        available: boolean;
        unavailableReasons?: Array<string> | null;
        credit: { __typename?: 'Price'; amount: number };
      };
      prices: {
        __typename?: 'ShoppingCartPrices';
        total: { __typename?: 'Price'; amount: number };
        priceBreakdown: Array<
          | {
              __typename?: 'BasicCartPriceBreakdownItem';
              title: string;
              pricePlaceholder?: string | null;
              price: { __typename?: 'Price'; amount: number };
            }
          | {
              __typename?: 'DescriptionCartPriceBreakdownItem';
              description?: string | null;
              title: string;
              pricePlaceholder?: string | null;
              price: { __typename?: 'Price'; amount: number };
            }
        >;
        subtotal: { __typename?: 'Price'; amount: number };
      };
    };
  } | null;
};

export type SetServicePointForCartGroupMutationVariables = Exact<{
  input: SetServicePointForCartGroupInput;
}>;

export type SetServicePointForCartGroupMutation = {
  __typename?: 'Mutation';
  setServicePointForCartGroup?: {
    __typename?: 'SetServicePointForCartGroupPayload';
    shoppingCart: {
      __typename?: 'ShoppingCart';
      id: string;
      groups: Array<{
        __typename?: 'ShoppingCartGroup';
        id: string;
        deliveryType: DeliveryType;
        items: Array<
          | {
              __typename?: 'BundleBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              image?: { __typename?: 'Image'; url: string } | null;
              products: Array<{ __typename?: 'Product'; id: string }>;
            }
          | {
              __typename?: 'Product';
              id: string;
              state: ProductState;
              image?: { __typename?: 'ProductImage'; url: string } | null;
              brand: { __typename?: 'ProductBrand'; name: string };
              price: { __typename?: 'Price'; amount: number };
              outletDiscount?: {
                __typename?: 'OutletDiscount';
                discountPercentage: number;
                preOutletPrice: { __typename?: 'Price'; amount: number };
              } | null;
              size?: {
                __typename?: 'ProductSize';
                sizeOptions: Array<{
                  __typename?: 'ProductSizeOption';
                  title: string;
                }>;
              } | null;
            }
          | {
              __typename?: 'ProductBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              product: {
                __typename?: 'Product';
                id: string;
                state: ProductState;
                image?: { __typename?: 'ProductImage'; url: string } | null;
                brand: { __typename?: 'ProductBrand'; name: string };
                price: { __typename?: 'Price'; amount: number };
                outletDiscount?: {
                  __typename?: 'OutletDiscount';
                  discountPercentage: number;
                  preOutletPrice: { __typename?: 'Price'; amount: number };
                } | null;
                size?: {
                  __typename?: 'ProductSize';
                  sizeOptions: Array<{
                    __typename?: 'ProductSizeOption';
                    title: string;
                  }>;
                } | null;
              };
            }
        >;
        availableParcels: Array<{
          __typename?: 'Parcel';
          id: string;
          shippingMethod: ShippingMethod;
          price:
            | { __typename?: 'Price'; amount: number }
            | {
                __typename?: 'PriceRange';
                minPrice: { __typename?: 'Price'; amount: number };
                maxPrice: { __typename?: 'Price'; amount: number };
              };
          shipper: {
            __typename?: 'Shipper';
            id: string;
            title: string;
            logo: { __typename?: 'Image'; url: string };
          };
        }>;
        parcel?: { __typename?: 'Parcel'; id: string } | null;
        shipmentDestination?:
          | {
              __typename?: 'ShipmentServicePoint';
              servicePoint: {
                __typename?: 'ServicePoint';
                name: string;
                address: string;
              };
            }
          | {
              __typename?: 'UserAddress';
              id: string;
              name: string;
              line1: string;
              line2?: string | null;
              postalCode: string;
              city: string;
              country: Country;
              isDefault: boolean;
              address: string;
              region: { __typename?: 'AddressRegion'; id: string };
            }
          | null;
      }>;
      paymentMethods: Array<{
        __typename?: 'PaymentMethod';
        id: string;
        label: string;
        type: PaymentMethodType;
        icon: { __typename?: 'Image'; url: string };
      }>;
      creditSummary: {
        __typename?: 'ShoppingCartCredit';
        available: boolean;
        unavailableReasons?: Array<string> | null;
        credit: { __typename?: 'Price'; amount: number };
      };
      prices: {
        __typename?: 'ShoppingCartPrices';
        total: { __typename?: 'Price'; amount: number };
        priceBreakdown: Array<
          | {
              __typename?: 'BasicCartPriceBreakdownItem';
              title: string;
              pricePlaceholder?: string | null;
              price: { __typename?: 'Price'; amount: number };
            }
          | {
              __typename?: 'DescriptionCartPriceBreakdownItem';
              description?: string | null;
              title: string;
              pricePlaceholder?: string | null;
              price: { __typename?: 'Price'; amount: number };
            }
        >;
        subtotal: { __typename?: 'Price'; amount: number };
      };
    };
  } | null;
};

export type CreatePaymentUrlMutationVariables = Exact<{
  input: CreatePaymentUrlInput;
}>;

export type CreatePaymentUrlMutation = {
  __typename?: 'Mutation';
  createPaymentURL?: {
    __typename?: 'CreatePaymentURLPayload';
    url: string;
  } | null;
};

export type CreditCardPaymentMutationVariables = Exact<{
  paymentMethodId: Scalars['String'];
  clientKey: Scalars['String'];
}>;

export type CreditCardPaymentMutation = {
  __typename?: 'Mutation';
  createPaymentUrlWithClientKey?: {
    __typename?: 'CreatePaymentURLPayload';
    url: string;
  } | null;
};

export type CheckoutCartQueryVariables = Exact<{ [key: string]: never }>;

export type CheckoutCartQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Viewer';
    shoppingCart: {
      __typename?: 'ShoppingCart';
      id: string;
      groups: Array<{
        __typename?: 'ShoppingCartGroup';
        id: string;
        deliveryType: DeliveryType;
        items: Array<
          | {
              __typename?: 'BundleBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              image?: { __typename?: 'Image'; url: string } | null;
              products: Array<{ __typename?: 'Product'; id: string }>;
            }
          | {
              __typename?: 'Product';
              id: string;
              state: ProductState;
              image?: { __typename?: 'ProductImage'; url: string } | null;
              brand: { __typename?: 'ProductBrand'; name: string };
              price: { __typename?: 'Price'; amount: number };
              outletDiscount?: {
                __typename?: 'OutletDiscount';
                discountPercentage: number;
                preOutletPrice: { __typename?: 'Price'; amount: number };
              } | null;
              size?: {
                __typename?: 'ProductSize';
                sizeOptions: Array<{
                  __typename?: 'ProductSizeOption';
                  title: string;
                }>;
              } | null;
            }
          | {
              __typename?: 'ProductBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              product: {
                __typename?: 'Product';
                id: string;
                state: ProductState;
                image?: { __typename?: 'ProductImage'; url: string } | null;
                brand: { __typename?: 'ProductBrand'; name: string };
                price: { __typename?: 'Price'; amount: number };
                outletDiscount?: {
                  __typename?: 'OutletDiscount';
                  discountPercentage: number;
                  preOutletPrice: { __typename?: 'Price'; amount: number };
                } | null;
                size?: {
                  __typename?: 'ProductSize';
                  sizeOptions: Array<{
                    __typename?: 'ProductSizeOption';
                    title: string;
                  }>;
                } | null;
              };
            }
        >;
        availableParcels: Array<{
          __typename?: 'Parcel';
          id: string;
          shippingMethod: ShippingMethod;
          price:
            | { __typename?: 'Price'; amount: number }
            | {
                __typename?: 'PriceRange';
                minPrice: { __typename?: 'Price'; amount: number };
                maxPrice: { __typename?: 'Price'; amount: number };
              };
          shipper: {
            __typename?: 'Shipper';
            id: string;
            title: string;
            logo: { __typename?: 'Image'; url: string };
          };
        }>;
        parcel?: { __typename?: 'Parcel'; id: string } | null;
        shipmentDestination?:
          | {
              __typename?: 'ShipmentServicePoint';
              servicePoint: {
                __typename?: 'ServicePoint';
                name: string;
                address: string;
              };
            }
          | {
              __typename?: 'UserAddress';
              id: string;
              name: string;
              line1: string;
              line2?: string | null;
              postalCode: string;
              city: string;
              country: Country;
              isDefault: boolean;
              address: string;
              region: { __typename?: 'AddressRegion'; id: string };
            }
          | null;
      }>;
      paymentMethods: Array<{
        __typename?: 'PaymentMethod';
        id: string;
        label: string;
        type: PaymentMethodType;
        icon: { __typename?: 'Image'; url: string };
      }>;
      creditSummary: {
        __typename?: 'ShoppingCartCredit';
        available: boolean;
        unavailableReasons?: Array<string> | null;
        credit: { __typename?: 'Price'; amount: number };
      };
      prices: {
        __typename?: 'ShoppingCartPrices';
        total: { __typename?: 'Price'; amount: number };
        priceBreakdown: Array<
          | {
              __typename?: 'BasicCartPriceBreakdownItem';
              title: string;
              pricePlaceholder?: string | null;
              price: { __typename?: 'Price'; amount: number };
            }
          | {
              __typename?: 'DescriptionCartPriceBreakdownItem';
              description?: string | null;
              title: string;
              pricePlaceholder?: string | null;
              price: { __typename?: 'Price'; amount: number };
            }
        >;
        subtotal: { __typename?: 'Price'; amount: number };
      };
    };
    account: {
      __typename?: 'Account';
      defaultAddress?: {
        __typename?: 'UserAddress';
        id: string;
        name: string;
        line1: string;
        line2?: string | null;
        postalCode: string;
        city: string;
        country: Country;
        isDefault: boolean;
        address: string;
        region: { __typename?: 'AddressRegion'; id: string };
      } | null;
    };
  } | null;
};

export type AddressRegionsQueryVariables = Exact<{ [key: string]: never }>;

export type AddressRegionsQuery = {
  __typename?: 'Query';
  addressRegions: Array<{
    __typename?: 'AddressRegion';
    id: string;
    country: Country;
    code: string;
    name: string;
  }>;
};

export type SearchServicePointsQueryVariables = Exact<{
  shipperId: Scalars['ID'];
  regionId: Scalars['ID'];
  postalCode: Scalars['String'];
}>;

export type SearchServicePointsQuery = {
  __typename?: 'Query';
  searchServicePoints?: Array<{
    __typename?: 'ServicePointSearchResult';
    distance: { __typename?: 'Distance'; amount: number; unit: DistanceUnit };
    servicePoint: {
      __typename?: 'ServicePoint';
      externalId: string;
      name: string;
      line1: string;
      postalCode: string;
      city: string;
      country: Country;
      regionCode: string;
      address: string;
    };
  }> | null;
};

export type FavoriteProductMutationVariables = Exact<{
  productId: Scalars['ID'];
}>;

export type FavoriteProductMutation = {
  __typename?: 'Mutation';
  favoriteProduct?: {
    __typename?: 'FavoriteProductPayload';
    product?: {
      __typename?: 'Product';
      id: string;
      viewerHasFavorited: boolean;
    } | null;
  } | null;
};

export type UnfavoriteProductMutationVariables = Exact<{
  productId: Scalars['ID'];
}>;

export type UnfavoriteProductMutation = {
  __typename?: 'Mutation';
  unfavoriteProduct?: {
    __typename?: 'UnfavoriteProductPayload';
    product?: {
      __typename?: 'Product';
      id: string;
      viewerHasFavorited: boolean;
    } | null;
  } | null;
};

export type FollowUserMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type FollowUserMutation = {
  __typename?: 'Mutation';
  followUser?: {
    __typename?: 'FollowUserPayload';
    user: { __typename?: 'User'; id: string; viewerIsFollowing: boolean };
  } | null;
};

export type UnfollowUserMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type UnfollowUserMutation = {
  __typename?: 'Mutation';
  unfollowUser?: {
    __typename?: 'UnfollowUserPayload';
    user: { __typename?: 'User'; id: string; viewerIsFollowing: boolean };
  } | null;
};

export type FullEmailSubscriptionFragment = {
  __typename?: 'EmailSubscription';
  id: string;
  title: string;
  viewerHasEnabled: boolean;
};

export type UpdateEmailSubscriptionMutationVariables = Exact<{
  input: UpdateEmailSubscriptionInput;
}>;

export type UpdateEmailSubscriptionMutation = {
  __typename?: 'Mutation';
  updateEmailSubscription?: {
    __typename?: 'UpdateEmailSubscriptionPayload';
    subscriptions: Array<{
      __typename?: 'EmailSubscription';
      id: string;
      title: string;
      viewerHasEnabled: boolean;
    }>;
  } | null;
};

export type UnsubscribeFromAllEmailsMutationVariables = Exact<{
  input: UnsubscribeFromAllEmailsInput;
}>;

export type UnsubscribeFromAllEmailsMutation = {
  __typename?: 'Mutation';
  unsubscribeFromAllEmails?: {
    __typename?: 'UnsubscribeFromAllEmailsPayload';
    subscriptions: Array<{
      __typename?: 'EmailSubscription';
      id: string;
      title: string;
      viewerHasEnabled: boolean;
    }>;
  } | null;
};

export type EmailSubscriptionsQueryVariables = Exact<{
  authorisation?: InputMaybe<EmailSubscriptionAuthorisationInput>;
}>;

export type EmailSubscriptionsQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Viewer';
    emailSubscriptions: Array<{
      __typename?: 'EmailSubscription';
      id: string;
      title: string;
      viewerHasEnabled: boolean;
    }>;
  } | null;
};

export type ProductSimplifiedContentFragment = {
  __typename?: 'Product';
  id: string;
  title: string;
  viewerIsSeller: boolean;
  condition: ProductCondition;
  brand: { __typename?: 'ProductBrand'; name: string };
  category: {
    __typename?: 'ProductCategory';
    id: string;
    title: string;
    parent?: {
      __typename?: 'ProductCategory';
      id: string;
      title: string;
      parent?: {
        __typename?: 'ProductCategory';
        id: string;
        title: string;
        parent?: {
          __typename?: 'ProductCategory';
          id: string;
          title: string;
          parent?: {
            __typename?: 'ProductCategory';
            id: string;
            title: string;
            parent?: {
              __typename?: 'ProductCategory';
              id: string;
              title: string;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  };
  price: { __typename?: 'Price'; amount: number };
  tags: Array<{ __typename?: 'ProductTag'; name: string }>;
  colors: Array<{ __typename?: 'ProductColor'; title: string }>;
  image?: { __typename?: 'ProductImage'; url: string } | null;
  size?: { __typename?: 'ProductSize'; label: string } | null;
};

export type ListProductFragment = {
  __typename?: 'Product';
  id: string;
  state: ProductState;
  title: string;
  viewerIsSeller: boolean;
  condition: ProductCondition;
  viewerHasFavorited: boolean;
  brand: { __typename?: 'ProductBrand'; name: string };
  category: {
    __typename?: 'ProductCategory';
    id: string;
    title: string;
    parent?: {
      __typename?: 'ProductCategory';
      id: string;
      title: string;
      parent?: {
        __typename?: 'ProductCategory';
        id: string;
        title: string;
        parent?: {
          __typename?: 'ProductCategory';
          id: string;
          title: string;
          parent?: {
            __typename?: 'ProductCategory';
            id: string;
            title: string;
            parent?: {
              __typename?: 'ProductCategory';
              id: string;
              title: string;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  };
  price: { __typename?: 'Price'; amount: number };
  tags: Array<{ __typename?: 'ProductTag'; name: string }>;
  colors: Array<{ __typename?: 'ProductColor'; title: string }>;
  image?: { __typename?: 'ProductImage'; url: string } | null;
  size?: { __typename?: 'ProductSize'; label: string } | null;
  outletDiscount?: {
    __typename?: 'OutletDiscount';
    discountPercentage: number;
    preOutletPrice: { __typename?: 'Price'; amount: number };
  } | null;
};

export type FullProductTagFragment = {
  __typename?: 'ProductTag';
  name: string;
};

export type ListProductCategoryFragment = {
  __typename?: 'ProductCategory';
  id: string;
  title: string;
};

export type AlternatingContentItemFragment = {
  __typename?: 'AlternatingContentItem';
  id: string;
  position: number;
  item:
    | {
        __typename?: 'HTMLFeedItem';
        id: string;
        campaignId: string;
        html: string;
      }
    | { __typename?: 'RecommendedSellersFeedItem' }
    | { __typename?: 'UserSizesItem' };
};

export type HtmlFeedItemFragment = {
  __typename?: 'HTMLFeedItem';
  id: string;
  campaignId: string;
  html: string;
};

export type ProductsQueryVariables = Exact<{
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<ProductFilters>;
  orderBy?: InputMaybe<ProductsOrder>;
}>;

export type ProductsQuery = {
  __typename?: 'Query';
  productsConnection: {
    __typename?: 'ProductsConnection';
    totalCount: number;
    nodes: Array<{
      __typename?: 'Product';
      id: string;
      state: ProductState;
      title: string;
      viewerIsSeller: boolean;
      condition: ProductCondition;
      viewerHasFavorited: boolean;
      brand: { __typename?: 'ProductBrand'; name: string };
      category: {
        __typename?: 'ProductCategory';
        id: string;
        title: string;
        parent?: {
          __typename?: 'ProductCategory';
          id: string;
          title: string;
          parent?: {
            __typename?: 'ProductCategory';
            id: string;
            title: string;
            parent?: {
              __typename?: 'ProductCategory';
              id: string;
              title: string;
              parent?: {
                __typename?: 'ProductCategory';
                id: string;
                title: string;
                parent?: {
                  __typename?: 'ProductCategory';
                  id: string;
                  title: string;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      };
      price: { __typename?: 'Price'; amount: number };
      tags: Array<{ __typename?: 'ProductTag'; name: string }>;
      colors: Array<{ __typename?: 'ProductColor'; title: string }>;
      image?: { __typename?: 'ProductImage'; url: string } | null;
      size?: { __typename?: 'ProductSize'; label: string } | null;
      outletDiscount?: {
        __typename?: 'OutletDiscount';
        discountPercentage: number;
        preOutletPrice: { __typename?: 'Price'; amount: number };
      } | null;
    }>;
    pageInfo: { __typename?: 'PageInfo'; hasNextPage?: boolean | null };
  };
  alternatingContent: Array<{
    __typename?: 'AlternatingContentItem';
    id: string;
    position: number;
    item:
      | {
          __typename?: 'HTMLFeedItem';
          id: string;
          campaignId: string;
          html: string;
        }
      | { __typename?: 'RecommendedSellersFeedItem' }
      | { __typename?: 'UserSizesItem' };
  }>;
};

export type UserSizesQueryVariables = Exact<{ [key: string]: never }>;

export type UserSizesQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Viewer';
    account: {
      __typename?: 'Account';
      sizes: Array<{ __typename?: 'ProductSizeOption'; id: string }>;
    };
  } | null;
};

export type ProductCategoryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ProductCategoryQuery = {
  __typename?: 'Query';
  productCategory?: {
    __typename?: 'ProductCategory';
    id: string;
    longDescription?: string | null;
    longTitle?: string | null;
  } | null;
};

export type InactiveProductMessageQueryVariables = Exact<{
  inactiveProductId: Scalars['ID'];
}>;

export type InactiveProductMessageQuery = {
  __typename?: 'Query';
  product?: {
    __typename?: 'Product';
    id: string;
    title: string;
    image?: { __typename?: 'ProductImage'; url: string } | null;
  } | null;
};

export type PopularItemsQueryVariables = Exact<{ [key: string]: never }>;

export type PopularItemsQuery = {
  __typename?: 'Query';
  productsConnection: {
    __typename?: 'ProductsConnection';
    nodes: Array<{
      __typename?: 'Product';
      id: string;
      state: ProductState;
      title: string;
      viewerIsSeller: boolean;
      condition: ProductCondition;
      viewerHasFavorited: boolean;
      brand: { __typename?: 'ProductBrand'; name: string };
      category: {
        __typename?: 'ProductCategory';
        id: string;
        title: string;
        parent?: {
          __typename?: 'ProductCategory';
          id: string;
          title: string;
          parent?: {
            __typename?: 'ProductCategory';
            id: string;
            title: string;
            parent?: {
              __typename?: 'ProductCategory';
              id: string;
              title: string;
              parent?: {
                __typename?: 'ProductCategory';
                id: string;
                title: string;
                parent?: {
                  __typename?: 'ProductCategory';
                  id: string;
                  title: string;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      };
      price: { __typename?: 'Price'; amount: number };
      tags: Array<{ __typename?: 'ProductTag'; name: string }>;
      colors: Array<{ __typename?: 'ProductColor'; title: string }>;
      image?: { __typename?: 'ProductImage'; url: string } | null;
      size?: { __typename?: 'ProductSize'; label: string } | null;
      outletDiscount?: {
        __typename?: 'OutletDiscount';
        discountPercentage: number;
        preOutletPrice: { __typename?: 'Price'; amount: number };
      } | null;
    }>;
  };
};

export type PopularSellersQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type PopularSellersQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    followingConnection: {
      __typename?: 'UsersConnection';
      nodes: Array<{
        __typename?: 'User';
        id: string;
        displayName: string;
        followerCount: number;
        viewerIsFollowing: boolean;
        profileImage: { __typename?: 'UserImage'; url: string };
      }>;
    };
  } | null;
};

export type HtmlDocumentQueryVariables = Exact<{
  name: HtmlDocumentName;
}>;

export type HtmlDocumentQuery = {
  __typename?: 'Query';
  htmlDocument?: { __typename?: 'HtmlDocument'; html: string } | null;
};

export type NotificationFragment = {
  __typename?: 'Notification';
  id: string;
  sentOn: string;
  title?: string | null;
  message: string;
  url: string;
  image: { __typename?: 'Image'; url: string };
};

export type ChatFragment = {
  __typename?: 'Chat';
  id: string;
  unreadCount: number;
  user: {
    __typename?: 'User';
    id: string;
    displayName: string;
    profileImage: { __typename?: 'UserImage'; url: string };
  };
  messagesConnection: {
    __typename?: 'ChatMessagesConnection';
    nodes: Array<{
      __typename?: 'ChatMessage';
      id: string;
      body: string;
      sentOn: string;
      viewerIsSender: boolean;
      sender: {
        __typename?: 'User';
        id: string;
        displayName: string;
        profileImage: { __typename?: 'UserImage'; url: string };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage?: boolean | null;
    };
  };
};

export type ChatMessageFragment = {
  __typename?: 'ChatMessage';
  id: string;
  body: string;
  sentOn: string;
  viewerIsSender: boolean;
  sender: {
    __typename?: 'User';
    id: string;
    displayName: string;
    profileImage: { __typename?: 'UserImage'; url: string };
  };
};

export type SendChatMessageMutationVariables = Exact<{
  input: SendChatMessageInput;
}>;

export type SendChatMessageMutation = {
  __typename?: 'Mutation';
  sendChatMessage?: {
    __typename?: 'SendChatMessagePayload';
    chatMessage: {
      __typename?: 'ChatMessage';
      id: string;
      body: string;
      sentOn: string;
      viewerIsSender: boolean;
      sender: {
        __typename?: 'User';
        id: string;
        displayName: string;
        profileImage: { __typename?: 'UserImage'; url: string };
      };
    };
  } | null;
};

export type NotificationsQueryVariables = Exact<{
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
}>;

export type NotificationsQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Viewer';
    notificationsConnection: {
      __typename?: 'NotificationsConnection';
      nodes: Array<{
        __typename?: 'Notification';
        id: string;
        sentOn: string;
        title?: string | null;
        message: string;
        url: string;
        image: { __typename?: 'Image'; url: string };
      }>;
      pageInfo: {
        __typename?: 'PageInfo';
        hasNextPage?: boolean | null;
        endCursor?: string | null;
      };
    };
  } | null;
};

export type ChatsQueryVariables = Exact<{
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
}>;

export type ChatsQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Viewer';
    chatsConnection: {
      __typename?: 'ChatsConnection';
      totalCount: number;
      nodes: Array<{
        __typename?: 'Chat';
        id: string;
        unreadCount: number;
        user: {
          __typename?: 'User';
          id: string;
          displayName: string;
          profileImage: { __typename?: 'UserImage'; url: string };
        };
        messagesConnection: {
          __typename?: 'ChatMessagesConnection';
          nodes: Array<{
            __typename?: 'ChatMessage';
            id: string;
            body: string;
            sentOn: string;
            viewerIsSender: boolean;
            sender: {
              __typename?: 'User';
              id: string;
              displayName: string;
              profileImage: { __typename?: 'UserImage'; url: string };
            };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string | null;
            hasNextPage?: boolean | null;
          };
        };
      }>;
    };
  } | null;
};

export type ChatQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type ChatQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Viewer';
    chat?: {
      __typename?: 'Chat';
      id: string;
      unreadCount: number;
      user: {
        __typename?: 'User';
        id: string;
        displayName: string;
        profileImage: { __typename?: 'UserImage'; url: string };
      };
      messagesConnection: {
        __typename?: 'ChatMessagesConnection';
        nodes: Array<{
          __typename?: 'ChatMessage';
          id: string;
          body: string;
          sentOn: string;
          viewerIsSender: boolean;
          sender: {
            __typename?: 'User';
            id: string;
            displayName: string;
            profileImage: { __typename?: 'UserImage'; url: string };
          };
        }>;
        pageInfo: {
          __typename?: 'PageInfo';
          endCursor?: string | null;
          hasNextPage?: boolean | null;
        };
      };
    } | null;
  } | null;
};

export type ChatMessagesQueryVariables = Exact<{
  userId: Scalars['ID'];
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
}>;

export type ChatMessagesQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Viewer';
    chat?: {
      __typename?: 'Chat';
      messagesConnection: {
        __typename?: 'ChatMessagesConnection';
        nodes: Array<{
          __typename?: 'ChatMessage';
          id: string;
          body: string;
          sentOn: string;
          viewerIsSender: boolean;
          sender: {
            __typename?: 'User';
            id: string;
            displayName: string;
            profileImage: { __typename?: 'UserImage'; url: string };
          };
        }>;
        pageInfo: {
          __typename?: 'PageInfo';
          endCursor?: string | null;
          hasNextPage?: boolean | null;
        };
      };
    } | null;
  } | null;
};

export type CreateStripeUploadUrlMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CreateStripeUploadUrlMutation = {
  __typename?: 'Mutation';
  frontUploadUrl?: {
    __typename?: 'CreateStripeUploadUrlPayload';
    url: string;
    publishableKey: string;
    purpose: string;
  } | null;
  backUploadUrl?: {
    __typename?: 'CreateStripeUploadUrlPayload';
    url: string;
    publishableKey: string;
    purpose: string;
  } | null;
};

export type CreateKycPhotoIdSubmissionMutationVariables = Exact<{
  input: CreateKycPhotoIdSubmissionInput;
}>;

export type CreateKycPhotoIdSubmissionMutation = {
  __typename?: 'Mutation';
  createKYCPhotoIdSubmission?: {
    __typename?: 'CreateKYCPhotoIdSubmissionPayload';
    account?: { __typename?: 'Account'; kycStatus: KycStatus } | null;
  } | null;
};

export type CreateKycBasicInfoSubmissionMutationVariables = Exact<{
  input: CreateKycBasicInfoSubmissionInput;
}>;

export type CreateKycBasicInfoSubmissionMutation = {
  __typename?: 'Mutation';
  createKYCBasicInfoSubmission?: {
    __typename?: 'CreateKYCBasicInfoSubmissionPayload';
    account?: { __typename?: 'Account'; kycStatus: KycStatus } | null;
  } | null;
};

export type GetKycStatusQueryVariables = Exact<{ [key: string]: never }>;

export type GetKycStatusQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Viewer';
    account: { __typename?: 'Account'; kycStatus: KycStatus };
  } | null;
};

export type CompleteVerificationRequirementsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CompleteVerificationRequirementsQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Viewer';
    account: {
      __typename?: 'Account';
      kycStatus: KycStatus;
      defaultAddress?: { __typename?: 'UserAddress'; id: string } | null;
      partialAddress?: {
        __typename?: 'PartialAddress';
        id: string;
        name: string;
        postalCode: string;
        city: string;
        country: Country;
        region: { __typename?: 'AddressRegion'; id: string };
      } | null;
      defaultBankAccount?: { __typename?: 'BankAccount'; id: string } | null;
      email: {
        __typename?: 'AccountEmail';
        emailAddress: string;
        isVerified: boolean;
      };
    };
  } | null;
  addressRegions: Array<{
    __typename?: 'AddressRegion';
    id: string;
    country: Country;
    code: string;
    name: string;
  }>;
};

export type CartFragment = {
  __typename?: 'ShoppingCart';
  id: string;
  groups: Array<{
    __typename?: 'ShoppingCartGroup';
    id: string;
    items: Array<
      | {
          __typename?: 'BundleBidGroup';
          bids: Array<{
            __typename?: 'BidGroupBid';
            price: { __typename?: 'Price'; amount: number };
          }>;
          image?: { __typename?: 'Image'; url: string } | null;
          products: Array<{ __typename?: 'Product'; id: string }>;
        }
      | {
          __typename?: 'Product';
          id: string;
          state: ProductState;
          image?: { __typename?: 'ProductImage'; url: string } | null;
          brand: { __typename?: 'ProductBrand'; name: string };
          price: { __typename?: 'Price'; amount: number };
          outletDiscount?: {
            __typename?: 'OutletDiscount';
            discountPercentage: number;
            preOutletPrice: { __typename?: 'Price'; amount: number };
          } | null;
          size?: {
            __typename?: 'ProductSize';
            sizeOptions: Array<{
              __typename?: 'ProductSizeOption';
              title: string;
            }>;
          } | null;
        }
      | {
          __typename?: 'ProductBidGroup';
          bids: Array<{
            __typename?: 'BidGroupBid';
            price: { __typename?: 'Price'; amount: number };
          }>;
          product: {
            __typename?: 'Product';
            id: string;
            state: ProductState;
            image?: { __typename?: 'ProductImage'; url: string } | null;
            brand: { __typename?: 'ProductBrand'; name: string };
            price: { __typename?: 'Price'; amount: number };
            outletDiscount?: {
              __typename?: 'OutletDiscount';
              discountPercentage: number;
              preOutletPrice: { __typename?: 'Price'; amount: number };
            } | null;
            size?: {
              __typename?: 'ProductSize';
              sizeOptions: Array<{
                __typename?: 'ProductSizeOption';
                title: string;
              }>;
            } | null;
          };
        }
    >;
  }>;
  prices: {
    __typename?: 'ShoppingCartPrices';
    subtotal: { __typename?: 'Price'; amount: number };
  };
};

export type CartProductFragment = {
  __typename?: 'Product';
  id: string;
  state: ProductState;
  image?: { __typename?: 'ProductImage'; url: string } | null;
  brand: { __typename?: 'ProductBrand'; name: string };
  price: { __typename?: 'Price'; amount: number };
  outletDiscount?: {
    __typename?: 'OutletDiscount';
    discountPercentage: number;
    preOutletPrice: { __typename?: 'Price'; amount: number };
  } | null;
  size?: {
    __typename?: 'ProductSize';
    sizeOptions: Array<{ __typename?: 'ProductSizeOption'; title: string }>;
  } | null;
};

export type UserSearchSuggestionFragment = {
  __typename?: 'User';
  id: string;
  displayName: string;
  viewerIsFollowing: boolean;
  followerCount: number;
  profileImage: { __typename?: 'UserImage'; url: string };
};

export type RemoveFromCartMutationVariables = Exact<{
  input: RemoveFromCartInput;
}>;

export type RemoveFromCartMutation = {
  __typename?: 'Mutation';
  removeFromCart?: {
    __typename?: 'RemoveFromCartPayload';
    shoppingCart: {
      __typename?: 'ShoppingCart';
      id: string;
      groups: Array<{
        __typename?: 'ShoppingCartGroup';
        id: string;
        items: Array<
          | {
              __typename?: 'BundleBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              image?: { __typename?: 'Image'; url: string } | null;
              products: Array<{ __typename?: 'Product'; id: string }>;
            }
          | {
              __typename?: 'Product';
              id: string;
              state: ProductState;
              image?: { __typename?: 'ProductImage'; url: string } | null;
              brand: { __typename?: 'ProductBrand'; name: string };
              price: { __typename?: 'Price'; amount: number };
              outletDiscount?: {
                __typename?: 'OutletDiscount';
                discountPercentage: number;
                preOutletPrice: { __typename?: 'Price'; amount: number };
              } | null;
              size?: {
                __typename?: 'ProductSize';
                sizeOptions: Array<{
                  __typename?: 'ProductSizeOption';
                  title: string;
                }>;
              } | null;
            }
          | {
              __typename?: 'ProductBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              product: {
                __typename?: 'Product';
                id: string;
                state: ProductState;
                image?: { __typename?: 'ProductImage'; url: string } | null;
                brand: { __typename?: 'ProductBrand'; name: string };
                price: { __typename?: 'Price'; amount: number };
                outletDiscount?: {
                  __typename?: 'OutletDiscount';
                  discountPercentage: number;
                  preOutletPrice: { __typename?: 'Price'; amount: number };
                } | null;
                size?: {
                  __typename?: 'ProductSize';
                  sizeOptions: Array<{
                    __typename?: 'ProductSizeOption';
                    title: string;
                  }>;
                } | null;
              };
            }
        >;
      }>;
      prices: {
        __typename?: 'ShoppingCartPrices';
        subtotal: { __typename?: 'Price'; amount: number };
      };
    };
    product: { __typename?: 'Product'; id: string; viewerHasInCart: boolean };
  } | null;
};

export type MarkAllNotificationsAsReadMutationVariables = Exact<{
  [key: string]: never;
}>;

export type MarkAllNotificationsAsReadMutation = {
  __typename?: 'Mutation';
  legacyMarkAllNotificationsAsRead?: any | null;
};

export type CartQueryVariables = Exact<{ [key: string]: never }>;

export type CartQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Viewer';
    shoppingCart: {
      __typename?: 'ShoppingCart';
      id: string;
      groups: Array<{
        __typename?: 'ShoppingCartGroup';
        id: string;
        items: Array<
          | {
              __typename?: 'BundleBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              image?: { __typename?: 'Image'; url: string } | null;
              products: Array<{ __typename?: 'Product'; id: string }>;
            }
          | {
              __typename?: 'Product';
              id: string;
              state: ProductState;
              image?: { __typename?: 'ProductImage'; url: string } | null;
              brand: { __typename?: 'ProductBrand'; name: string };
              price: { __typename?: 'Price'; amount: number };
              outletDiscount?: {
                __typename?: 'OutletDiscount';
                discountPercentage: number;
                preOutletPrice: { __typename?: 'Price'; amount: number };
              } | null;
              size?: {
                __typename?: 'ProductSize';
                sizeOptions: Array<{
                  __typename?: 'ProductSizeOption';
                  title: string;
                }>;
              } | null;
            }
          | {
              __typename?: 'ProductBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              product: {
                __typename?: 'Product';
                id: string;
                state: ProductState;
                image?: { __typename?: 'ProductImage'; url: string } | null;
                brand: { __typename?: 'ProductBrand'; name: string };
                price: { __typename?: 'Price'; amount: number };
                outletDiscount?: {
                  __typename?: 'OutletDiscount';
                  discountPercentage: number;
                  preOutletPrice: { __typename?: 'Price'; amount: number };
                } | null;
                size?: {
                  __typename?: 'ProductSize';
                  sizeOptions: Array<{
                    __typename?: 'ProductSizeOption';
                    title: string;
                  }>;
                } | null;
              };
            }
        >;
      }>;
      prices: {
        __typename?: 'ShoppingCartPrices';
        subtotal: { __typename?: 'Price'; amount: number };
      };
    };
  } | null;
};

export type SearchSuggestionsQueryVariables = Exact<{
  query: Scalars['String'];
}>;

export type SearchSuggestionsQuery = {
  __typename?: 'Query';
  searchSuggestions: Array<{
    __typename?: 'SearchSuggestion';
    type: SearchSuggestionType;
    label: string;
    query: string;
    resultCount: number;
  }>;
  usersConnection: {
    __typename?: 'UsersConnection';
    nodes: Array<{
      __typename?: 'User';
      id: string;
      displayName: string;
      viewerIsFollowing: boolean;
      followerCount: number;
      profileImage: { __typename?: 'UserImage'; url: string };
    }>;
  };
};

export type FullOrderFragment = {
  __typename?: 'Order';
  id: string;
  orderReference: string;
  state: OrderState;
  viewerIsSeller: boolean;
  deliveryType: DeliveryType;
  createdOn: string;
  createCancellationRequestState: CreateCancellationRequestState;
  cancellationRequestReasons?: Array<{
    __typename?: 'CancellationRequestReason';
    id: string;
    title: string;
  }> | null;
  cancellationRequest?: {
    __typename?: 'CancellationRequest';
    id: string;
    viewerCanWithdraw: boolean;
    reason: {
      __typename?: 'CancellationRequestReason';
      id: string;
      title: string;
    };
    history: Array<{
      __typename?: 'CancellationRequestEvent';
      id: string;
      state: CancellationRequestState;
      viewerInitiatedEvent: boolean;
      explanation?: string | null;
      initiatedOn: string;
      user?: {
        __typename?: 'User';
        id: string;
        displayName: string;
        profileImage: { __typename?: 'UserImage'; url: string };
      } | null;
    }>;
  } | null;
  orderRating?: {
    __typename?: 'OrderRating';
    rating: number;
    review?: string | null;
    ratedOn: string;
    ratedBy: {
      __typename?: 'User';
      id: string;
      displayName: string;
      followerCount: number;
      profileImage: { __typename?: 'UserImage'; url: string };
    };
  } | null;
  payment: {
    __typename?: 'OrderPayment';
    value: { __typename?: 'Price'; amount: number };
  };
  returnRequest?: {
    __typename?: 'ReturnRequest';
    id: string;
    state: ReturnRequestState;
    viewerCanCancel: boolean;
    reason: ReturnRequestReason;
    returnAddress?: {
      __typename?: 'UserAddress';
      name: string;
      address: string;
    } | null;
    history: Array<
      | {
          __typename?: 'AdminReturnRequestEvent';
          id: string;
          state: ReturnRequestState;
          initiatedOn: string;
          comment?: string | null;
        }
      | {
          __typename?: 'UserReturnRequestEvent';
          id: string;
          state: ReturnRequestState;
          initiatedOn: string;
          comment?: string | null;
          user: {
            __typename?: 'User';
            id: string;
            displayName: string;
            profileImage: { __typename?: 'UserImage'; url: string };
          };
          attachments: Array<{ __typename?: 'Image'; url: string }>;
        }
    >;
  } | null;
  shipment?:
    | {
        __typename?: 'LabelOrderShipment';
        label: {
          __typename?: 'OrderShipmentLabel';
          status: OrderShipmentLabelStatus;
          shippingLabel?: {
            __typename?: 'ShippingLabel';
            id: string;
            url: string;
          } | null;
        };
        costs: { __typename?: 'Price'; amount: number };
        tracking?: {
          __typename?: 'ShipmentTracking';
          trackingCode?: string | null;
          url?: string | null;
        } | null;
        parcel: {
          __typename?: 'Parcel';
          shippingInstructions: string;
          shippingMethod: ShippingMethod;
          shipper: {
            __typename?: 'Shipper';
            id: string;
            title: string;
            logo: { __typename?: 'Image'; url: string };
          };
        };
        destination:
          | {
              __typename?: 'ShipmentServicePoint';
              userFullName: string;
              servicePoint: { __typename?: 'ServicePoint'; name: string };
            }
          | { __typename?: 'UserAddress'; name: string; address: string };
      }
    | {
        __typename?: 'TrackingCodeOrderShipment';
        costs: { __typename?: 'Price'; amount: number };
        tracking?: {
          __typename?: 'ShipmentTracking';
          trackingCode?: string | null;
          url?: string | null;
        } | null;
        parcel: {
          __typename?: 'Parcel';
          shippingInstructions: string;
          shippingMethod: ShippingMethod;
          shipper: {
            __typename?: 'Shipper';
            id: string;
            title: string;
            logo: { __typename?: 'Image'; url: string };
          };
        };
        destination:
          | {
              __typename?: 'ShipmentServicePoint';
              userFullName: string;
              servicePoint: { __typename?: 'ServicePoint'; name: string };
            }
          | { __typename?: 'UserAddress'; name: string; address: string };
      }
    | null;
  seller: {
    __typename?: 'User';
    id: string;
    displayName: string;
    country: Country;
    rating?: {
      __typename?: 'UserRating';
      rating: number;
      ratingCount: number;
    } | null;
    profileImage: { __typename?: 'UserImage'; url: string };
  };
  buyer: {
    __typename?: 'User';
    id: string;
    displayName: string;
    country: Country;
    rating?: {
      __typename?: 'UserRating';
      rating: number;
      ratingCount: number;
    } | null;
    profileImage: { __typename?: 'UserImage'; url: string };
  };
  cartGroup: {
    __typename?: 'OrderCartGroup';
    items: Array<
      | {
          __typename?: 'BundleBidGroup';
          bids: Array<{
            __typename?: 'BidGroupBid';
            price: { __typename?: 'Price'; amount: number };
          }>;
          products: Array<{
            __typename?: 'Product';
            id: string;
            image?: { __typename?: 'ProductImage'; url: string } | null;
          }>;
        }
      | {
          __typename?: 'Product';
          id: string;
          size?: { __typename?: 'ProductSize'; label: string } | null;
          price: { __typename?: 'Price'; amount: number };
          image?: { __typename?: 'ProductImage'; url: string } | null;
          brand: { __typename?: 'ProductBrand'; name: string };
        }
      | {
          __typename?: 'ProductBidGroup';
          bids: Array<{
            __typename?: 'BidGroupBid';
            price: { __typename?: 'Price'; amount: number };
          }>;
          product: {
            __typename?: 'Product';
            id: string;
            size?: { __typename?: 'ProductSize'; label: string } | null;
            image?: { __typename?: 'ProductImage'; url: string } | null;
            brand: { __typename?: 'ProductBrand'; name: string };
          };
        }
    >;
  };
};

export type ChatPreviewUserFragment = {
  __typename?: 'User';
  id: string;
  displayName: string;
  country: Country;
  rating?: {
    __typename?: 'UserRating';
    rating: number;
    ratingCount: number;
  } | null;
  profileImage: { __typename?: 'UserImage'; url: string };
};

export type ListOrderFragment = {
  __typename?: 'Order';
  id: string;
  orderReference: string;
  state: OrderState;
  createdOn: string;
  cartGroup: {
    __typename?: 'OrderCartGroup';
    items: Array<
      | {
          __typename?: 'BundleBidGroup';
          products: Array<{
            __typename?: 'Product';
            id: string;
            image?: { __typename?: 'ProductImage'; url: string } | null;
          }>;
        }
      | {
          __typename?: 'Product';
          id: string;
          image?: { __typename?: 'ProductImage'; url: string } | null;
        }
      | {
          __typename?: 'ProductBidGroup';
          product: {
            __typename?: 'Product';
            id: string;
            image?: { __typename?: 'ProductImage'; url: string } | null;
          };
        }
    >;
  };
};

export type SendOrderMutationVariables = Exact<{
  input: SendOrderInput;
}>;

export type SendOrderMutation = {
  __typename?: 'Mutation';
  sendOrder?: {
    __typename?: 'SendOrderPayload';
    order: {
      __typename?: 'Order';
      id: string;
      orderReference: string;
      state: OrderState;
      viewerIsSeller: boolean;
      deliveryType: DeliveryType;
      createdOn: string;
      createCancellationRequestState: CreateCancellationRequestState;
      cancellationRequestReasons?: Array<{
        __typename?: 'CancellationRequestReason';
        id: string;
        title: string;
      }> | null;
      cancellationRequest?: {
        __typename?: 'CancellationRequest';
        id: string;
        viewerCanWithdraw: boolean;
        reason: {
          __typename?: 'CancellationRequestReason';
          id: string;
          title: string;
        };
        history: Array<{
          __typename?: 'CancellationRequestEvent';
          id: string;
          state: CancellationRequestState;
          viewerInitiatedEvent: boolean;
          explanation?: string | null;
          initiatedOn: string;
          user?: {
            __typename?: 'User';
            id: string;
            displayName: string;
            profileImage: { __typename?: 'UserImage'; url: string };
          } | null;
        }>;
      } | null;
      orderRating?: {
        __typename?: 'OrderRating';
        rating: number;
        review?: string | null;
        ratedOn: string;
        ratedBy: {
          __typename?: 'User';
          id: string;
          displayName: string;
          followerCount: number;
          profileImage: { __typename?: 'UserImage'; url: string };
        };
      } | null;
      payment: {
        __typename?: 'OrderPayment';
        value: { __typename?: 'Price'; amount: number };
      };
      returnRequest?: {
        __typename?: 'ReturnRequest';
        id: string;
        state: ReturnRequestState;
        viewerCanCancel: boolean;
        reason: ReturnRequestReason;
        returnAddress?: {
          __typename?: 'UserAddress';
          name: string;
          address: string;
        } | null;
        history: Array<
          | {
              __typename?: 'AdminReturnRequestEvent';
              id: string;
              state: ReturnRequestState;
              initiatedOn: string;
              comment?: string | null;
            }
          | {
              __typename?: 'UserReturnRequestEvent';
              id: string;
              state: ReturnRequestState;
              initiatedOn: string;
              comment?: string | null;
              user: {
                __typename?: 'User';
                id: string;
                displayName: string;
                profileImage: { __typename?: 'UserImage'; url: string };
              };
              attachments: Array<{ __typename?: 'Image'; url: string }>;
            }
        >;
      } | null;
      shipment?:
        | {
            __typename?: 'LabelOrderShipment';
            label: {
              __typename?: 'OrderShipmentLabel';
              status: OrderShipmentLabelStatus;
              shippingLabel?: {
                __typename?: 'ShippingLabel';
                id: string;
                url: string;
              } | null;
            };
            costs: { __typename?: 'Price'; amount: number };
            tracking?: {
              __typename?: 'ShipmentTracking';
              trackingCode?: string | null;
              url?: string | null;
            } | null;
            parcel: {
              __typename?: 'Parcel';
              shippingInstructions: string;
              shippingMethod: ShippingMethod;
              shipper: {
                __typename?: 'Shipper';
                id: string;
                title: string;
                logo: { __typename?: 'Image'; url: string };
              };
            };
            destination:
              | {
                  __typename?: 'ShipmentServicePoint';
                  userFullName: string;
                  servicePoint: { __typename?: 'ServicePoint'; name: string };
                }
              | { __typename?: 'UserAddress'; name: string; address: string };
          }
        | {
            __typename?: 'TrackingCodeOrderShipment';
            costs: { __typename?: 'Price'; amount: number };
            tracking?: {
              __typename?: 'ShipmentTracking';
              trackingCode?: string | null;
              url?: string | null;
            } | null;
            parcel: {
              __typename?: 'Parcel';
              shippingInstructions: string;
              shippingMethod: ShippingMethod;
              shipper: {
                __typename?: 'Shipper';
                id: string;
                title: string;
                logo: { __typename?: 'Image'; url: string };
              };
            };
            destination:
              | {
                  __typename?: 'ShipmentServicePoint';
                  userFullName: string;
                  servicePoint: { __typename?: 'ServicePoint'; name: string };
                }
              | { __typename?: 'UserAddress'; name: string; address: string };
          }
        | null;
      seller: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        rating?: {
          __typename?: 'UserRating';
          rating: number;
          ratingCount: number;
        } | null;
        profileImage: { __typename?: 'UserImage'; url: string };
      };
      buyer: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        rating?: {
          __typename?: 'UserRating';
          rating: number;
          ratingCount: number;
        } | null;
        profileImage: { __typename?: 'UserImage'; url: string };
      };
      cartGroup: {
        __typename?: 'OrderCartGroup';
        items: Array<
          | {
              __typename?: 'BundleBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              products: Array<{
                __typename?: 'Product';
                id: string;
                image?: { __typename?: 'ProductImage'; url: string } | null;
              }>;
            }
          | {
              __typename?: 'Product';
              id: string;
              size?: { __typename?: 'ProductSize'; label: string } | null;
              price: { __typename?: 'Price'; amount: number };
              image?: { __typename?: 'ProductImage'; url: string } | null;
              brand: { __typename?: 'ProductBrand'; name: string };
            }
          | {
              __typename?: 'ProductBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              product: {
                __typename?: 'Product';
                id: string;
                size?: { __typename?: 'ProductSize'; label: string } | null;
                image?: { __typename?: 'ProductImage'; url: string } | null;
                brand: { __typename?: 'ProductBrand'; name: string };
              };
            }
        >;
      };
    };
  } | null;
};

export type ReceiveOrderMutationVariables = Exact<{
  input: ReceiveOrderInput;
}>;

export type ReceiveOrderMutation = {
  __typename?: 'Mutation';
  receiveOrder?: {
    __typename?: 'ReceiveOrderPayload';
    order: {
      __typename?: 'Order';
      id: string;
      orderReference: string;
      state: OrderState;
      viewerIsSeller: boolean;
      deliveryType: DeliveryType;
      createdOn: string;
      createCancellationRequestState: CreateCancellationRequestState;
      cancellationRequestReasons?: Array<{
        __typename?: 'CancellationRequestReason';
        id: string;
        title: string;
      }> | null;
      cancellationRequest?: {
        __typename?: 'CancellationRequest';
        id: string;
        viewerCanWithdraw: boolean;
        reason: {
          __typename?: 'CancellationRequestReason';
          id: string;
          title: string;
        };
        history: Array<{
          __typename?: 'CancellationRequestEvent';
          id: string;
          state: CancellationRequestState;
          viewerInitiatedEvent: boolean;
          explanation?: string | null;
          initiatedOn: string;
          user?: {
            __typename?: 'User';
            id: string;
            displayName: string;
            profileImage: { __typename?: 'UserImage'; url: string };
          } | null;
        }>;
      } | null;
      orderRating?: {
        __typename?: 'OrderRating';
        rating: number;
        review?: string | null;
        ratedOn: string;
        ratedBy: {
          __typename?: 'User';
          id: string;
          displayName: string;
          followerCount: number;
          profileImage: { __typename?: 'UserImage'; url: string };
        };
      } | null;
      payment: {
        __typename?: 'OrderPayment';
        value: { __typename?: 'Price'; amount: number };
      };
      returnRequest?: {
        __typename?: 'ReturnRequest';
        id: string;
        state: ReturnRequestState;
        viewerCanCancel: boolean;
        reason: ReturnRequestReason;
        returnAddress?: {
          __typename?: 'UserAddress';
          name: string;
          address: string;
        } | null;
        history: Array<
          | {
              __typename?: 'AdminReturnRequestEvent';
              id: string;
              state: ReturnRequestState;
              initiatedOn: string;
              comment?: string | null;
            }
          | {
              __typename?: 'UserReturnRequestEvent';
              id: string;
              state: ReturnRequestState;
              initiatedOn: string;
              comment?: string | null;
              user: {
                __typename?: 'User';
                id: string;
                displayName: string;
                profileImage: { __typename?: 'UserImage'; url: string };
              };
              attachments: Array<{ __typename?: 'Image'; url: string }>;
            }
        >;
      } | null;
      shipment?:
        | {
            __typename?: 'LabelOrderShipment';
            label: {
              __typename?: 'OrderShipmentLabel';
              status: OrderShipmentLabelStatus;
              shippingLabel?: {
                __typename?: 'ShippingLabel';
                id: string;
                url: string;
              } | null;
            };
            costs: { __typename?: 'Price'; amount: number };
            tracking?: {
              __typename?: 'ShipmentTracking';
              trackingCode?: string | null;
              url?: string | null;
            } | null;
            parcel: {
              __typename?: 'Parcel';
              shippingInstructions: string;
              shippingMethod: ShippingMethod;
              shipper: {
                __typename?: 'Shipper';
                id: string;
                title: string;
                logo: { __typename?: 'Image'; url: string };
              };
            };
            destination:
              | {
                  __typename?: 'ShipmentServicePoint';
                  userFullName: string;
                  servicePoint: { __typename?: 'ServicePoint'; name: string };
                }
              | { __typename?: 'UserAddress'; name: string; address: string };
          }
        | {
            __typename?: 'TrackingCodeOrderShipment';
            costs: { __typename?: 'Price'; amount: number };
            tracking?: {
              __typename?: 'ShipmentTracking';
              trackingCode?: string | null;
              url?: string | null;
            } | null;
            parcel: {
              __typename?: 'Parcel';
              shippingInstructions: string;
              shippingMethod: ShippingMethod;
              shipper: {
                __typename?: 'Shipper';
                id: string;
                title: string;
                logo: { __typename?: 'Image'; url: string };
              };
            };
            destination:
              | {
                  __typename?: 'ShipmentServicePoint';
                  userFullName: string;
                  servicePoint: { __typename?: 'ServicePoint'; name: string };
                }
              | { __typename?: 'UserAddress'; name: string; address: string };
          }
        | null;
      seller: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        rating?: {
          __typename?: 'UserRating';
          rating: number;
          ratingCount: number;
        } | null;
        profileImage: { __typename?: 'UserImage'; url: string };
      };
      buyer: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        rating?: {
          __typename?: 'UserRating';
          rating: number;
          ratingCount: number;
        } | null;
        profileImage: { __typename?: 'UserImage'; url: string };
      };
      cartGroup: {
        __typename?: 'OrderCartGroup';
        items: Array<
          | {
              __typename?: 'BundleBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              products: Array<{
                __typename?: 'Product';
                id: string;
                image?: { __typename?: 'ProductImage'; url: string } | null;
              }>;
            }
          | {
              __typename?: 'Product';
              id: string;
              size?: { __typename?: 'ProductSize'; label: string } | null;
              price: { __typename?: 'Price'; amount: number };
              image?: { __typename?: 'ProductImage'; url: string } | null;
              brand: { __typename?: 'ProductBrand'; name: string };
            }
          | {
              __typename?: 'ProductBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              product: {
                __typename?: 'Product';
                id: string;
                size?: { __typename?: 'ProductSize'; label: string } | null;
                image?: { __typename?: 'ProductImage'; url: string } | null;
                brand: { __typename?: 'ProductBrand'; name: string };
              };
            }
        >;
      };
    };
  } | null;
};

export type ReceiveReturnedOrderMutationVariables = Exact<{
  input: ReceiveReturnedOrderInput;
}>;

export type ReceiveReturnedOrderMutation = {
  __typename?: 'Mutation';
  receiveReturnedOrder?: {
    __typename?: 'ReceiveReturnedOrderPayload';
    order: {
      __typename?: 'Order';
      id: string;
      orderReference: string;
      state: OrderState;
      viewerIsSeller: boolean;
      deliveryType: DeliveryType;
      createdOn: string;
      createCancellationRequestState: CreateCancellationRequestState;
      cancellationRequestReasons?: Array<{
        __typename?: 'CancellationRequestReason';
        id: string;
        title: string;
      }> | null;
      cancellationRequest?: {
        __typename?: 'CancellationRequest';
        id: string;
        viewerCanWithdraw: boolean;
        reason: {
          __typename?: 'CancellationRequestReason';
          id: string;
          title: string;
        };
        history: Array<{
          __typename?: 'CancellationRequestEvent';
          id: string;
          state: CancellationRequestState;
          viewerInitiatedEvent: boolean;
          explanation?: string | null;
          initiatedOn: string;
          user?: {
            __typename?: 'User';
            id: string;
            displayName: string;
            profileImage: { __typename?: 'UserImage'; url: string };
          } | null;
        }>;
      } | null;
      orderRating?: {
        __typename?: 'OrderRating';
        rating: number;
        review?: string | null;
        ratedOn: string;
        ratedBy: {
          __typename?: 'User';
          id: string;
          displayName: string;
          followerCount: number;
          profileImage: { __typename?: 'UserImage'; url: string };
        };
      } | null;
      payment: {
        __typename?: 'OrderPayment';
        value: { __typename?: 'Price'; amount: number };
      };
      returnRequest?: {
        __typename?: 'ReturnRequest';
        id: string;
        state: ReturnRequestState;
        viewerCanCancel: boolean;
        reason: ReturnRequestReason;
        returnAddress?: {
          __typename?: 'UserAddress';
          name: string;
          address: string;
        } | null;
        history: Array<
          | {
              __typename?: 'AdminReturnRequestEvent';
              id: string;
              state: ReturnRequestState;
              initiatedOn: string;
              comment?: string | null;
            }
          | {
              __typename?: 'UserReturnRequestEvent';
              id: string;
              state: ReturnRequestState;
              initiatedOn: string;
              comment?: string | null;
              user: {
                __typename?: 'User';
                id: string;
                displayName: string;
                profileImage: { __typename?: 'UserImage'; url: string };
              };
              attachments: Array<{ __typename?: 'Image'; url: string }>;
            }
        >;
      } | null;
      shipment?:
        | {
            __typename?: 'LabelOrderShipment';
            label: {
              __typename?: 'OrderShipmentLabel';
              status: OrderShipmentLabelStatus;
              shippingLabel?: {
                __typename?: 'ShippingLabel';
                id: string;
                url: string;
              } | null;
            };
            costs: { __typename?: 'Price'; amount: number };
            tracking?: {
              __typename?: 'ShipmentTracking';
              trackingCode?: string | null;
              url?: string | null;
            } | null;
            parcel: {
              __typename?: 'Parcel';
              shippingInstructions: string;
              shippingMethod: ShippingMethod;
              shipper: {
                __typename?: 'Shipper';
                id: string;
                title: string;
                logo: { __typename?: 'Image'; url: string };
              };
            };
            destination:
              | {
                  __typename?: 'ShipmentServicePoint';
                  userFullName: string;
                  servicePoint: { __typename?: 'ServicePoint'; name: string };
                }
              | { __typename?: 'UserAddress'; name: string; address: string };
          }
        | {
            __typename?: 'TrackingCodeOrderShipment';
            costs: { __typename?: 'Price'; amount: number };
            tracking?: {
              __typename?: 'ShipmentTracking';
              trackingCode?: string | null;
              url?: string | null;
            } | null;
            parcel: {
              __typename?: 'Parcel';
              shippingInstructions: string;
              shippingMethod: ShippingMethod;
              shipper: {
                __typename?: 'Shipper';
                id: string;
                title: string;
                logo: { __typename?: 'Image'; url: string };
              };
            };
            destination:
              | {
                  __typename?: 'ShipmentServicePoint';
                  userFullName: string;
                  servicePoint: { __typename?: 'ServicePoint'; name: string };
                }
              | { __typename?: 'UserAddress'; name: string; address: string };
          }
        | null;
      seller: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        rating?: {
          __typename?: 'UserRating';
          rating: number;
          ratingCount: number;
        } | null;
        profileImage: { __typename?: 'UserImage'; url: string };
      };
      buyer: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        rating?: {
          __typename?: 'UserRating';
          rating: number;
          ratingCount: number;
        } | null;
        profileImage: { __typename?: 'UserImage'; url: string };
      };
      cartGroup: {
        __typename?: 'OrderCartGroup';
        items: Array<
          | {
              __typename?: 'BundleBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              products: Array<{
                __typename?: 'Product';
                id: string;
                image?: { __typename?: 'ProductImage'; url: string } | null;
              }>;
            }
          | {
              __typename?: 'Product';
              id: string;
              size?: { __typename?: 'ProductSize'; label: string } | null;
              price: { __typename?: 'Price'; amount: number };
              image?: { __typename?: 'ProductImage'; url: string } | null;
              brand: { __typename?: 'ProductBrand'; name: string };
            }
          | {
              __typename?: 'ProductBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              product: {
                __typename?: 'Product';
                id: string;
                size?: { __typename?: 'ProductSize'; label: string } | null;
                image?: { __typename?: 'ProductImage'; url: string } | null;
                brand: { __typename?: 'ProductBrand'; name: string };
              };
            }
        >;
      };
    };
  } | null;
};

export type KeepOrderMutationVariables = Exact<{
  input: KeepOrderInput;
}>;

export type KeepOrderMutation = {
  __typename?: 'Mutation';
  keepOrder?: {
    __typename?: 'KeepOrderPayload';
    order: {
      __typename?: 'Order';
      id: string;
      orderReference: string;
      state: OrderState;
      viewerIsSeller: boolean;
      deliveryType: DeliveryType;
      createdOn: string;
      createCancellationRequestState: CreateCancellationRequestState;
      cancellationRequestReasons?: Array<{
        __typename?: 'CancellationRequestReason';
        id: string;
        title: string;
      }> | null;
      cancellationRequest?: {
        __typename?: 'CancellationRequest';
        id: string;
        viewerCanWithdraw: boolean;
        reason: {
          __typename?: 'CancellationRequestReason';
          id: string;
          title: string;
        };
        history: Array<{
          __typename?: 'CancellationRequestEvent';
          id: string;
          state: CancellationRequestState;
          viewerInitiatedEvent: boolean;
          explanation?: string | null;
          initiatedOn: string;
          user?: {
            __typename?: 'User';
            id: string;
            displayName: string;
            profileImage: { __typename?: 'UserImage'; url: string };
          } | null;
        }>;
      } | null;
      orderRating?: {
        __typename?: 'OrderRating';
        rating: number;
        review?: string | null;
        ratedOn: string;
        ratedBy: {
          __typename?: 'User';
          id: string;
          displayName: string;
          followerCount: number;
          profileImage: { __typename?: 'UserImage'; url: string };
        };
      } | null;
      payment: {
        __typename?: 'OrderPayment';
        value: { __typename?: 'Price'; amount: number };
      };
      returnRequest?: {
        __typename?: 'ReturnRequest';
        id: string;
        state: ReturnRequestState;
        viewerCanCancel: boolean;
        reason: ReturnRequestReason;
        returnAddress?: {
          __typename?: 'UserAddress';
          name: string;
          address: string;
        } | null;
        history: Array<
          | {
              __typename?: 'AdminReturnRequestEvent';
              id: string;
              state: ReturnRequestState;
              initiatedOn: string;
              comment?: string | null;
            }
          | {
              __typename?: 'UserReturnRequestEvent';
              id: string;
              state: ReturnRequestState;
              initiatedOn: string;
              comment?: string | null;
              user: {
                __typename?: 'User';
                id: string;
                displayName: string;
                profileImage: { __typename?: 'UserImage'; url: string };
              };
              attachments: Array<{ __typename?: 'Image'; url: string }>;
            }
        >;
      } | null;
      shipment?:
        | {
            __typename?: 'LabelOrderShipment';
            label: {
              __typename?: 'OrderShipmentLabel';
              status: OrderShipmentLabelStatus;
              shippingLabel?: {
                __typename?: 'ShippingLabel';
                id: string;
                url: string;
              } | null;
            };
            costs: { __typename?: 'Price'; amount: number };
            tracking?: {
              __typename?: 'ShipmentTracking';
              trackingCode?: string | null;
              url?: string | null;
            } | null;
            parcel: {
              __typename?: 'Parcel';
              shippingInstructions: string;
              shippingMethod: ShippingMethod;
              shipper: {
                __typename?: 'Shipper';
                id: string;
                title: string;
                logo: { __typename?: 'Image'; url: string };
              };
            };
            destination:
              | {
                  __typename?: 'ShipmentServicePoint';
                  userFullName: string;
                  servicePoint: { __typename?: 'ServicePoint'; name: string };
                }
              | { __typename?: 'UserAddress'; name: string; address: string };
          }
        | {
            __typename?: 'TrackingCodeOrderShipment';
            costs: { __typename?: 'Price'; amount: number };
            tracking?: {
              __typename?: 'ShipmentTracking';
              trackingCode?: string | null;
              url?: string | null;
            } | null;
            parcel: {
              __typename?: 'Parcel';
              shippingInstructions: string;
              shippingMethod: ShippingMethod;
              shipper: {
                __typename?: 'Shipper';
                id: string;
                title: string;
                logo: { __typename?: 'Image'; url: string };
              };
            };
            destination:
              | {
                  __typename?: 'ShipmentServicePoint';
                  userFullName: string;
                  servicePoint: { __typename?: 'ServicePoint'; name: string };
                }
              | { __typename?: 'UserAddress'; name: string; address: string };
          }
        | null;
      seller: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        rating?: {
          __typename?: 'UserRating';
          rating: number;
          ratingCount: number;
        } | null;
        profileImage: { __typename?: 'UserImage'; url: string };
      };
      buyer: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        rating?: {
          __typename?: 'UserRating';
          rating: number;
          ratingCount: number;
        } | null;
        profileImage: { __typename?: 'UserImage'; url: string };
      };
      cartGroup: {
        __typename?: 'OrderCartGroup';
        items: Array<
          | {
              __typename?: 'BundleBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              products: Array<{
                __typename?: 'Product';
                id: string;
                image?: { __typename?: 'ProductImage'; url: string } | null;
              }>;
            }
          | {
              __typename?: 'Product';
              id: string;
              size?: { __typename?: 'ProductSize'; label: string } | null;
              price: { __typename?: 'Price'; amount: number };
              image?: { __typename?: 'ProductImage'; url: string } | null;
              brand: { __typename?: 'ProductBrand'; name: string };
            }
          | {
              __typename?: 'ProductBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              product: {
                __typename?: 'Product';
                id: string;
                size?: { __typename?: 'ProductSize'; label: string } | null;
                image?: { __typename?: 'ProductImage'; url: string } | null;
                brand: { __typename?: 'ProductBrand'; name: string };
              };
            }
        >;
      };
    };
  } | null;
};

export type SubmitCancellationRequestMutationVariables = Exact<{
  input: SubmitCancellationRequestInput;
}>;

export type SubmitCancellationRequestMutation = {
  __typename?: 'Mutation';
  submitCancellationRequest?: {
    __typename?: 'SubmitCancellationRequestPayload';
    order: {
      __typename?: 'Order';
      id: string;
      orderReference: string;
      state: OrderState;
      viewerIsSeller: boolean;
      deliveryType: DeliveryType;
      createdOn: string;
      createCancellationRequestState: CreateCancellationRequestState;
      cancellationRequestReasons?: Array<{
        __typename?: 'CancellationRequestReason';
        id: string;
        title: string;
      }> | null;
      cancellationRequest?: {
        __typename?: 'CancellationRequest';
        id: string;
        viewerCanWithdraw: boolean;
        reason: {
          __typename?: 'CancellationRequestReason';
          id: string;
          title: string;
        };
        history: Array<{
          __typename?: 'CancellationRequestEvent';
          id: string;
          state: CancellationRequestState;
          viewerInitiatedEvent: boolean;
          explanation?: string | null;
          initiatedOn: string;
          user?: {
            __typename?: 'User';
            id: string;
            displayName: string;
            profileImage: { __typename?: 'UserImage'; url: string };
          } | null;
        }>;
      } | null;
      orderRating?: {
        __typename?: 'OrderRating';
        rating: number;
        review?: string | null;
        ratedOn: string;
        ratedBy: {
          __typename?: 'User';
          id: string;
          displayName: string;
          followerCount: number;
          profileImage: { __typename?: 'UserImage'; url: string };
        };
      } | null;
      payment: {
        __typename?: 'OrderPayment';
        value: { __typename?: 'Price'; amount: number };
      };
      returnRequest?: {
        __typename?: 'ReturnRequest';
        id: string;
        state: ReturnRequestState;
        viewerCanCancel: boolean;
        reason: ReturnRequestReason;
        returnAddress?: {
          __typename?: 'UserAddress';
          name: string;
          address: string;
        } | null;
        history: Array<
          | {
              __typename?: 'AdminReturnRequestEvent';
              id: string;
              state: ReturnRequestState;
              initiatedOn: string;
              comment?: string | null;
            }
          | {
              __typename?: 'UserReturnRequestEvent';
              id: string;
              state: ReturnRequestState;
              initiatedOn: string;
              comment?: string | null;
              user: {
                __typename?: 'User';
                id: string;
                displayName: string;
                profileImage: { __typename?: 'UserImage'; url: string };
              };
              attachments: Array<{ __typename?: 'Image'; url: string }>;
            }
        >;
      } | null;
      shipment?:
        | {
            __typename?: 'LabelOrderShipment';
            label: {
              __typename?: 'OrderShipmentLabel';
              status: OrderShipmentLabelStatus;
              shippingLabel?: {
                __typename?: 'ShippingLabel';
                id: string;
                url: string;
              } | null;
            };
            costs: { __typename?: 'Price'; amount: number };
            tracking?: {
              __typename?: 'ShipmentTracking';
              trackingCode?: string | null;
              url?: string | null;
            } | null;
            parcel: {
              __typename?: 'Parcel';
              shippingInstructions: string;
              shippingMethod: ShippingMethod;
              shipper: {
                __typename?: 'Shipper';
                id: string;
                title: string;
                logo: { __typename?: 'Image'; url: string };
              };
            };
            destination:
              | {
                  __typename?: 'ShipmentServicePoint';
                  userFullName: string;
                  servicePoint: { __typename?: 'ServicePoint'; name: string };
                }
              | { __typename?: 'UserAddress'; name: string; address: string };
          }
        | {
            __typename?: 'TrackingCodeOrderShipment';
            costs: { __typename?: 'Price'; amount: number };
            tracking?: {
              __typename?: 'ShipmentTracking';
              trackingCode?: string | null;
              url?: string | null;
            } | null;
            parcel: {
              __typename?: 'Parcel';
              shippingInstructions: string;
              shippingMethod: ShippingMethod;
              shipper: {
                __typename?: 'Shipper';
                id: string;
                title: string;
                logo: { __typename?: 'Image'; url: string };
              };
            };
            destination:
              | {
                  __typename?: 'ShipmentServicePoint';
                  userFullName: string;
                  servicePoint: { __typename?: 'ServicePoint'; name: string };
                }
              | { __typename?: 'UserAddress'; name: string; address: string };
          }
        | null;
      seller: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        rating?: {
          __typename?: 'UserRating';
          rating: number;
          ratingCount: number;
        } | null;
        profileImage: { __typename?: 'UserImage'; url: string };
      };
      buyer: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        rating?: {
          __typename?: 'UserRating';
          rating: number;
          ratingCount: number;
        } | null;
        profileImage: { __typename?: 'UserImage'; url: string };
      };
      cartGroup: {
        __typename?: 'OrderCartGroup';
        items: Array<
          | {
              __typename?: 'BundleBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              products: Array<{
                __typename?: 'Product';
                id: string;
                image?: { __typename?: 'ProductImage'; url: string } | null;
              }>;
            }
          | {
              __typename?: 'Product';
              id: string;
              size?: { __typename?: 'ProductSize'; label: string } | null;
              price: { __typename?: 'Price'; amount: number };
              image?: { __typename?: 'ProductImage'; url: string } | null;
              brand: { __typename?: 'ProductBrand'; name: string };
            }
          | {
              __typename?: 'ProductBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              product: {
                __typename?: 'Product';
                id: string;
                size?: { __typename?: 'ProductSize'; label: string } | null;
                image?: { __typename?: 'ProductImage'; url: string } | null;
                brand: { __typename?: 'ProductBrand'; name: string };
              };
            }
        >;
      };
    };
  } | null;
};

export type WithdrawCancellationRequestMutationVariables = Exact<{
  input: WithdrawCancellationRequestInput;
}>;

export type WithdrawCancellationRequestMutation = {
  __typename?: 'Mutation';
  withdrawCancellationRequest?: {
    __typename?: 'WithdrawCancellationRequestPayload';
    order: {
      __typename?: 'Order';
      id: string;
      orderReference: string;
      state: OrderState;
      viewerIsSeller: boolean;
      deliveryType: DeliveryType;
      createdOn: string;
      createCancellationRequestState: CreateCancellationRequestState;
      cancellationRequestReasons?: Array<{
        __typename?: 'CancellationRequestReason';
        id: string;
        title: string;
      }> | null;
      cancellationRequest?: {
        __typename?: 'CancellationRequest';
        id: string;
        viewerCanWithdraw: boolean;
        reason: {
          __typename?: 'CancellationRequestReason';
          id: string;
          title: string;
        };
        history: Array<{
          __typename?: 'CancellationRequestEvent';
          id: string;
          state: CancellationRequestState;
          viewerInitiatedEvent: boolean;
          explanation?: string | null;
          initiatedOn: string;
          user?: {
            __typename?: 'User';
            id: string;
            displayName: string;
            profileImage: { __typename?: 'UserImage'; url: string };
          } | null;
        }>;
      } | null;
      orderRating?: {
        __typename?: 'OrderRating';
        rating: number;
        review?: string | null;
        ratedOn: string;
        ratedBy: {
          __typename?: 'User';
          id: string;
          displayName: string;
          followerCount: number;
          profileImage: { __typename?: 'UserImage'; url: string };
        };
      } | null;
      payment: {
        __typename?: 'OrderPayment';
        value: { __typename?: 'Price'; amount: number };
      };
      returnRequest?: {
        __typename?: 'ReturnRequest';
        id: string;
        state: ReturnRequestState;
        viewerCanCancel: boolean;
        reason: ReturnRequestReason;
        returnAddress?: {
          __typename?: 'UserAddress';
          name: string;
          address: string;
        } | null;
        history: Array<
          | {
              __typename?: 'AdminReturnRequestEvent';
              id: string;
              state: ReturnRequestState;
              initiatedOn: string;
              comment?: string | null;
            }
          | {
              __typename?: 'UserReturnRequestEvent';
              id: string;
              state: ReturnRequestState;
              initiatedOn: string;
              comment?: string | null;
              user: {
                __typename?: 'User';
                id: string;
                displayName: string;
                profileImage: { __typename?: 'UserImage'; url: string };
              };
              attachments: Array<{ __typename?: 'Image'; url: string }>;
            }
        >;
      } | null;
      shipment?:
        | {
            __typename?: 'LabelOrderShipment';
            label: {
              __typename?: 'OrderShipmentLabel';
              status: OrderShipmentLabelStatus;
              shippingLabel?: {
                __typename?: 'ShippingLabel';
                id: string;
                url: string;
              } | null;
            };
            costs: { __typename?: 'Price'; amount: number };
            tracking?: {
              __typename?: 'ShipmentTracking';
              trackingCode?: string | null;
              url?: string | null;
            } | null;
            parcel: {
              __typename?: 'Parcel';
              shippingInstructions: string;
              shippingMethod: ShippingMethod;
              shipper: {
                __typename?: 'Shipper';
                id: string;
                title: string;
                logo: { __typename?: 'Image'; url: string };
              };
            };
            destination:
              | {
                  __typename?: 'ShipmentServicePoint';
                  userFullName: string;
                  servicePoint: { __typename?: 'ServicePoint'; name: string };
                }
              | { __typename?: 'UserAddress'; name: string; address: string };
          }
        | {
            __typename?: 'TrackingCodeOrderShipment';
            costs: { __typename?: 'Price'; amount: number };
            tracking?: {
              __typename?: 'ShipmentTracking';
              trackingCode?: string | null;
              url?: string | null;
            } | null;
            parcel: {
              __typename?: 'Parcel';
              shippingInstructions: string;
              shippingMethod: ShippingMethod;
              shipper: {
                __typename?: 'Shipper';
                id: string;
                title: string;
                logo: { __typename?: 'Image'; url: string };
              };
            };
            destination:
              | {
                  __typename?: 'ShipmentServicePoint';
                  userFullName: string;
                  servicePoint: { __typename?: 'ServicePoint'; name: string };
                }
              | { __typename?: 'UserAddress'; name: string; address: string };
          }
        | null;
      seller: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        rating?: {
          __typename?: 'UserRating';
          rating: number;
          ratingCount: number;
        } | null;
        profileImage: { __typename?: 'UserImage'; url: string };
      };
      buyer: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        rating?: {
          __typename?: 'UserRating';
          rating: number;
          ratingCount: number;
        } | null;
        profileImage: { __typename?: 'UserImage'; url: string };
      };
      cartGroup: {
        __typename?: 'OrderCartGroup';
        items: Array<
          | {
              __typename?: 'BundleBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              products: Array<{
                __typename?: 'Product';
                id: string;
                image?: { __typename?: 'ProductImage'; url: string } | null;
              }>;
            }
          | {
              __typename?: 'Product';
              id: string;
              size?: { __typename?: 'ProductSize'; label: string } | null;
              price: { __typename?: 'Price'; amount: number };
              image?: { __typename?: 'ProductImage'; url: string } | null;
              brand: { __typename?: 'ProductBrand'; name: string };
            }
          | {
              __typename?: 'ProductBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              product: {
                __typename?: 'Product';
                id: string;
                size?: { __typename?: 'ProductSize'; label: string } | null;
                image?: { __typename?: 'ProductImage'; url: string } | null;
                brand: { __typename?: 'ProductBrand'; name: string };
              };
            }
        >;
      };
    };
  } | null;
};

export type AcceptCancellationRequestMutationVariables = Exact<{
  input: AcceptCancellationRequestInput;
}>;

export type AcceptCancellationRequestMutation = {
  __typename?: 'Mutation';
  acceptCancellationRequest?: {
    __typename?: 'AcceptCancellationRequestPayload';
    order: {
      __typename?: 'Order';
      id: string;
      orderReference: string;
      state: OrderState;
      viewerIsSeller: boolean;
      deliveryType: DeliveryType;
      createdOn: string;
      createCancellationRequestState: CreateCancellationRequestState;
      cancellationRequestReasons?: Array<{
        __typename?: 'CancellationRequestReason';
        id: string;
        title: string;
      }> | null;
      cancellationRequest?: {
        __typename?: 'CancellationRequest';
        id: string;
        viewerCanWithdraw: boolean;
        reason: {
          __typename?: 'CancellationRequestReason';
          id: string;
          title: string;
        };
        history: Array<{
          __typename?: 'CancellationRequestEvent';
          id: string;
          state: CancellationRequestState;
          viewerInitiatedEvent: boolean;
          explanation?: string | null;
          initiatedOn: string;
          user?: {
            __typename?: 'User';
            id: string;
            displayName: string;
            profileImage: { __typename?: 'UserImage'; url: string };
          } | null;
        }>;
      } | null;
      orderRating?: {
        __typename?: 'OrderRating';
        rating: number;
        review?: string | null;
        ratedOn: string;
        ratedBy: {
          __typename?: 'User';
          id: string;
          displayName: string;
          followerCount: number;
          profileImage: { __typename?: 'UserImage'; url: string };
        };
      } | null;
      payment: {
        __typename?: 'OrderPayment';
        value: { __typename?: 'Price'; amount: number };
      };
      returnRequest?: {
        __typename?: 'ReturnRequest';
        id: string;
        state: ReturnRequestState;
        viewerCanCancel: boolean;
        reason: ReturnRequestReason;
        returnAddress?: {
          __typename?: 'UserAddress';
          name: string;
          address: string;
        } | null;
        history: Array<
          | {
              __typename?: 'AdminReturnRequestEvent';
              id: string;
              state: ReturnRequestState;
              initiatedOn: string;
              comment?: string | null;
            }
          | {
              __typename?: 'UserReturnRequestEvent';
              id: string;
              state: ReturnRequestState;
              initiatedOn: string;
              comment?: string | null;
              user: {
                __typename?: 'User';
                id: string;
                displayName: string;
                profileImage: { __typename?: 'UserImage'; url: string };
              };
              attachments: Array<{ __typename?: 'Image'; url: string }>;
            }
        >;
      } | null;
      shipment?:
        | {
            __typename?: 'LabelOrderShipment';
            label: {
              __typename?: 'OrderShipmentLabel';
              status: OrderShipmentLabelStatus;
              shippingLabel?: {
                __typename?: 'ShippingLabel';
                id: string;
                url: string;
              } | null;
            };
            costs: { __typename?: 'Price'; amount: number };
            tracking?: {
              __typename?: 'ShipmentTracking';
              trackingCode?: string | null;
              url?: string | null;
            } | null;
            parcel: {
              __typename?: 'Parcel';
              shippingInstructions: string;
              shippingMethod: ShippingMethod;
              shipper: {
                __typename?: 'Shipper';
                id: string;
                title: string;
                logo: { __typename?: 'Image'; url: string };
              };
            };
            destination:
              | {
                  __typename?: 'ShipmentServicePoint';
                  userFullName: string;
                  servicePoint: { __typename?: 'ServicePoint'; name: string };
                }
              | { __typename?: 'UserAddress'; name: string; address: string };
          }
        | {
            __typename?: 'TrackingCodeOrderShipment';
            costs: { __typename?: 'Price'; amount: number };
            tracking?: {
              __typename?: 'ShipmentTracking';
              trackingCode?: string | null;
              url?: string | null;
            } | null;
            parcel: {
              __typename?: 'Parcel';
              shippingInstructions: string;
              shippingMethod: ShippingMethod;
              shipper: {
                __typename?: 'Shipper';
                id: string;
                title: string;
                logo: { __typename?: 'Image'; url: string };
              };
            };
            destination:
              | {
                  __typename?: 'ShipmentServicePoint';
                  userFullName: string;
                  servicePoint: { __typename?: 'ServicePoint'; name: string };
                }
              | { __typename?: 'UserAddress'; name: string; address: string };
          }
        | null;
      seller: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        rating?: {
          __typename?: 'UserRating';
          rating: number;
          ratingCount: number;
        } | null;
        profileImage: { __typename?: 'UserImage'; url: string };
      };
      buyer: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        rating?: {
          __typename?: 'UserRating';
          rating: number;
          ratingCount: number;
        } | null;
        profileImage: { __typename?: 'UserImage'; url: string };
      };
      cartGroup: {
        __typename?: 'OrderCartGroup';
        items: Array<
          | {
              __typename?: 'BundleBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              products: Array<{
                __typename?: 'Product';
                id: string;
                image?: { __typename?: 'ProductImage'; url: string } | null;
              }>;
            }
          | {
              __typename?: 'Product';
              id: string;
              size?: { __typename?: 'ProductSize'; label: string } | null;
              price: { __typename?: 'Price'; amount: number };
              image?: { __typename?: 'ProductImage'; url: string } | null;
              brand: { __typename?: 'ProductBrand'; name: string };
            }
          | {
              __typename?: 'ProductBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              product: {
                __typename?: 'Product';
                id: string;
                size?: { __typename?: 'ProductSize'; label: string } | null;
                image?: { __typename?: 'ProductImage'; url: string } | null;
                brand: { __typename?: 'ProductBrand'; name: string };
              };
            }
        >;
      };
    };
  } | null;
};

export type DisputeCancellationRequestMutationVariables = Exact<{
  input: DisputeCancellationRequestInput;
}>;

export type DisputeCancellationRequestMutation = {
  __typename?: 'Mutation';
  disputeCancellationRequest?: {
    __typename?: 'DisputeCancellationRequestPayload';
    order: {
      __typename?: 'Order';
      id: string;
      orderReference: string;
      state: OrderState;
      viewerIsSeller: boolean;
      deliveryType: DeliveryType;
      createdOn: string;
      createCancellationRequestState: CreateCancellationRequestState;
      cancellationRequestReasons?: Array<{
        __typename?: 'CancellationRequestReason';
        id: string;
        title: string;
      }> | null;
      cancellationRequest?: {
        __typename?: 'CancellationRequest';
        id: string;
        viewerCanWithdraw: boolean;
        reason: {
          __typename?: 'CancellationRequestReason';
          id: string;
          title: string;
        };
        history: Array<{
          __typename?: 'CancellationRequestEvent';
          id: string;
          state: CancellationRequestState;
          viewerInitiatedEvent: boolean;
          explanation?: string | null;
          initiatedOn: string;
          user?: {
            __typename?: 'User';
            id: string;
            displayName: string;
            profileImage: { __typename?: 'UserImage'; url: string };
          } | null;
        }>;
      } | null;
      orderRating?: {
        __typename?: 'OrderRating';
        rating: number;
        review?: string | null;
        ratedOn: string;
        ratedBy: {
          __typename?: 'User';
          id: string;
          displayName: string;
          followerCount: number;
          profileImage: { __typename?: 'UserImage'; url: string };
        };
      } | null;
      payment: {
        __typename?: 'OrderPayment';
        value: { __typename?: 'Price'; amount: number };
      };
      returnRequest?: {
        __typename?: 'ReturnRequest';
        id: string;
        state: ReturnRequestState;
        viewerCanCancel: boolean;
        reason: ReturnRequestReason;
        returnAddress?: {
          __typename?: 'UserAddress';
          name: string;
          address: string;
        } | null;
        history: Array<
          | {
              __typename?: 'AdminReturnRequestEvent';
              id: string;
              state: ReturnRequestState;
              initiatedOn: string;
              comment?: string | null;
            }
          | {
              __typename?: 'UserReturnRequestEvent';
              id: string;
              state: ReturnRequestState;
              initiatedOn: string;
              comment?: string | null;
              user: {
                __typename?: 'User';
                id: string;
                displayName: string;
                profileImage: { __typename?: 'UserImage'; url: string };
              };
              attachments: Array<{ __typename?: 'Image'; url: string }>;
            }
        >;
      } | null;
      shipment?:
        | {
            __typename?: 'LabelOrderShipment';
            label: {
              __typename?: 'OrderShipmentLabel';
              status: OrderShipmentLabelStatus;
              shippingLabel?: {
                __typename?: 'ShippingLabel';
                id: string;
                url: string;
              } | null;
            };
            costs: { __typename?: 'Price'; amount: number };
            tracking?: {
              __typename?: 'ShipmentTracking';
              trackingCode?: string | null;
              url?: string | null;
            } | null;
            parcel: {
              __typename?: 'Parcel';
              shippingInstructions: string;
              shippingMethod: ShippingMethod;
              shipper: {
                __typename?: 'Shipper';
                id: string;
                title: string;
                logo: { __typename?: 'Image'; url: string };
              };
            };
            destination:
              | {
                  __typename?: 'ShipmentServicePoint';
                  userFullName: string;
                  servicePoint: { __typename?: 'ServicePoint'; name: string };
                }
              | { __typename?: 'UserAddress'; name: string; address: string };
          }
        | {
            __typename?: 'TrackingCodeOrderShipment';
            costs: { __typename?: 'Price'; amount: number };
            tracking?: {
              __typename?: 'ShipmentTracking';
              trackingCode?: string | null;
              url?: string | null;
            } | null;
            parcel: {
              __typename?: 'Parcel';
              shippingInstructions: string;
              shippingMethod: ShippingMethod;
              shipper: {
                __typename?: 'Shipper';
                id: string;
                title: string;
                logo: { __typename?: 'Image'; url: string };
              };
            };
            destination:
              | {
                  __typename?: 'ShipmentServicePoint';
                  userFullName: string;
                  servicePoint: { __typename?: 'ServicePoint'; name: string };
                }
              | { __typename?: 'UserAddress'; name: string; address: string };
          }
        | null;
      seller: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        rating?: {
          __typename?: 'UserRating';
          rating: number;
          ratingCount: number;
        } | null;
        profileImage: { __typename?: 'UserImage'; url: string };
      };
      buyer: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        rating?: {
          __typename?: 'UserRating';
          rating: number;
          ratingCount: number;
        } | null;
        profileImage: { __typename?: 'UserImage'; url: string };
      };
      cartGroup: {
        __typename?: 'OrderCartGroup';
        items: Array<
          | {
              __typename?: 'BundleBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              products: Array<{
                __typename?: 'Product';
                id: string;
                image?: { __typename?: 'ProductImage'; url: string } | null;
              }>;
            }
          | {
              __typename?: 'Product';
              id: string;
              size?: { __typename?: 'ProductSize'; label: string } | null;
              price: { __typename?: 'Price'; amount: number };
              image?: { __typename?: 'ProductImage'; url: string } | null;
              brand: { __typename?: 'ProductBrand'; name: string };
            }
          | {
              __typename?: 'ProductBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              product: {
                __typename?: 'Product';
                id: string;
                size?: { __typename?: 'ProductSize'; label: string } | null;
                image?: { __typename?: 'ProductImage'; url: string } | null;
                brand: { __typename?: 'ProductBrand'; name: string };
              };
            }
        >;
      };
    };
  } | null;
};

export type RepostOrderRequestMutationVariables = Exact<{
  input: RepostOrderInput;
}>;

export type RepostOrderRequestMutation = {
  __typename?: 'Mutation';
  repostOrder?: {
    __typename?: 'RepostOrderPayload';
    order: {
      __typename?: 'Order';
      id: string;
      orderReference: string;
      state: OrderState;
      viewerIsSeller: boolean;
      deliveryType: DeliveryType;
      createdOn: string;
      createCancellationRequestState: CreateCancellationRequestState;
      cancellationRequestReasons?: Array<{
        __typename?: 'CancellationRequestReason';
        id: string;
        title: string;
      }> | null;
      cancellationRequest?: {
        __typename?: 'CancellationRequest';
        id: string;
        viewerCanWithdraw: boolean;
        reason: {
          __typename?: 'CancellationRequestReason';
          id: string;
          title: string;
        };
        history: Array<{
          __typename?: 'CancellationRequestEvent';
          id: string;
          state: CancellationRequestState;
          viewerInitiatedEvent: boolean;
          explanation?: string | null;
          initiatedOn: string;
          user?: {
            __typename?: 'User';
            id: string;
            displayName: string;
            profileImage: { __typename?: 'UserImage'; url: string };
          } | null;
        }>;
      } | null;
      orderRating?: {
        __typename?: 'OrderRating';
        rating: number;
        review?: string | null;
        ratedOn: string;
        ratedBy: {
          __typename?: 'User';
          id: string;
          displayName: string;
          followerCount: number;
          profileImage: { __typename?: 'UserImage'; url: string };
        };
      } | null;
      payment: {
        __typename?: 'OrderPayment';
        value: { __typename?: 'Price'; amount: number };
      };
      returnRequest?: {
        __typename?: 'ReturnRequest';
        id: string;
        state: ReturnRequestState;
        viewerCanCancel: boolean;
        reason: ReturnRequestReason;
        returnAddress?: {
          __typename?: 'UserAddress';
          name: string;
          address: string;
        } | null;
        history: Array<
          | {
              __typename?: 'AdminReturnRequestEvent';
              id: string;
              state: ReturnRequestState;
              initiatedOn: string;
              comment?: string | null;
            }
          | {
              __typename?: 'UserReturnRequestEvent';
              id: string;
              state: ReturnRequestState;
              initiatedOn: string;
              comment?: string | null;
              user: {
                __typename?: 'User';
                id: string;
                displayName: string;
                profileImage: { __typename?: 'UserImage'; url: string };
              };
              attachments: Array<{ __typename?: 'Image'; url: string }>;
            }
        >;
      } | null;
      shipment?:
        | {
            __typename?: 'LabelOrderShipment';
            label: {
              __typename?: 'OrderShipmentLabel';
              status: OrderShipmentLabelStatus;
              shippingLabel?: {
                __typename?: 'ShippingLabel';
                id: string;
                url: string;
              } | null;
            };
            costs: { __typename?: 'Price'; amount: number };
            tracking?: {
              __typename?: 'ShipmentTracking';
              trackingCode?: string | null;
              url?: string | null;
            } | null;
            parcel: {
              __typename?: 'Parcel';
              shippingInstructions: string;
              shippingMethod: ShippingMethod;
              shipper: {
                __typename?: 'Shipper';
                id: string;
                title: string;
                logo: { __typename?: 'Image'; url: string };
              };
            };
            destination:
              | {
                  __typename?: 'ShipmentServicePoint';
                  userFullName: string;
                  servicePoint: { __typename?: 'ServicePoint'; name: string };
                }
              | { __typename?: 'UserAddress'; name: string; address: string };
          }
        | {
            __typename?: 'TrackingCodeOrderShipment';
            costs: { __typename?: 'Price'; amount: number };
            tracking?: {
              __typename?: 'ShipmentTracking';
              trackingCode?: string | null;
              url?: string | null;
            } | null;
            parcel: {
              __typename?: 'Parcel';
              shippingInstructions: string;
              shippingMethod: ShippingMethod;
              shipper: {
                __typename?: 'Shipper';
                id: string;
                title: string;
                logo: { __typename?: 'Image'; url: string };
              };
            };
            destination:
              | {
                  __typename?: 'ShipmentServicePoint';
                  userFullName: string;
                  servicePoint: { __typename?: 'ServicePoint'; name: string };
                }
              | { __typename?: 'UserAddress'; name: string; address: string };
          }
        | null;
      seller: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        rating?: {
          __typename?: 'UserRating';
          rating: number;
          ratingCount: number;
        } | null;
        profileImage: { __typename?: 'UserImage'; url: string };
      };
      buyer: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        rating?: {
          __typename?: 'UserRating';
          rating: number;
          ratingCount: number;
        } | null;
        profileImage: { __typename?: 'UserImage'; url: string };
      };
      cartGroup: {
        __typename?: 'OrderCartGroup';
        items: Array<
          | {
              __typename?: 'BundleBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              products: Array<{
                __typename?: 'Product';
                id: string;
                image?: { __typename?: 'ProductImage'; url: string } | null;
              }>;
            }
          | {
              __typename?: 'Product';
              id: string;
              size?: { __typename?: 'ProductSize'; label: string } | null;
              price: { __typename?: 'Price'; amount: number };
              image?: { __typename?: 'ProductImage'; url: string } | null;
              brand: { __typename?: 'ProductBrand'; name: string };
            }
          | {
              __typename?: 'ProductBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              product: {
                __typename?: 'Product';
                id: string;
                size?: { __typename?: 'ProductSize'; label: string } | null;
                image?: { __typename?: 'ProductImage'; url: string } | null;
                brand: { __typename?: 'ProductBrand'; name: string };
              };
            }
        >;
      };
    };
  } | null;
};

export type CreateReturnRequestMutationVariables = Exact<{
  input: CreateReturnRequestInput;
}>;

export type CreateReturnRequestMutation = {
  __typename?: 'Mutation';
  createReturnRequest?: {
    __typename?: 'CreateReturnRequestPayload';
    order: {
      __typename?: 'Order';
      id: string;
      orderReference: string;
      state: OrderState;
      viewerIsSeller: boolean;
      deliveryType: DeliveryType;
      createdOn: string;
      createCancellationRequestState: CreateCancellationRequestState;
      cancellationRequestReasons?: Array<{
        __typename?: 'CancellationRequestReason';
        id: string;
        title: string;
      }> | null;
      cancellationRequest?: {
        __typename?: 'CancellationRequest';
        id: string;
        viewerCanWithdraw: boolean;
        reason: {
          __typename?: 'CancellationRequestReason';
          id: string;
          title: string;
        };
        history: Array<{
          __typename?: 'CancellationRequestEvent';
          id: string;
          state: CancellationRequestState;
          viewerInitiatedEvent: boolean;
          explanation?: string | null;
          initiatedOn: string;
          user?: {
            __typename?: 'User';
            id: string;
            displayName: string;
            profileImage: { __typename?: 'UserImage'; url: string };
          } | null;
        }>;
      } | null;
      orderRating?: {
        __typename?: 'OrderRating';
        rating: number;
        review?: string | null;
        ratedOn: string;
        ratedBy: {
          __typename?: 'User';
          id: string;
          displayName: string;
          followerCount: number;
          profileImage: { __typename?: 'UserImage'; url: string };
        };
      } | null;
      payment: {
        __typename?: 'OrderPayment';
        value: { __typename?: 'Price'; amount: number };
      };
      returnRequest?: {
        __typename?: 'ReturnRequest';
        id: string;
        state: ReturnRequestState;
        viewerCanCancel: boolean;
        reason: ReturnRequestReason;
        returnAddress?: {
          __typename?: 'UserAddress';
          name: string;
          address: string;
        } | null;
        history: Array<
          | {
              __typename?: 'AdminReturnRequestEvent';
              id: string;
              state: ReturnRequestState;
              initiatedOn: string;
              comment?: string | null;
            }
          | {
              __typename?: 'UserReturnRequestEvent';
              id: string;
              state: ReturnRequestState;
              initiatedOn: string;
              comment?: string | null;
              user: {
                __typename?: 'User';
                id: string;
                displayName: string;
                profileImage: { __typename?: 'UserImage'; url: string };
              };
              attachments: Array<{ __typename?: 'Image'; url: string }>;
            }
        >;
      } | null;
      shipment?:
        | {
            __typename?: 'LabelOrderShipment';
            label: {
              __typename?: 'OrderShipmentLabel';
              status: OrderShipmentLabelStatus;
              shippingLabel?: {
                __typename?: 'ShippingLabel';
                id: string;
                url: string;
              } | null;
            };
            costs: { __typename?: 'Price'; amount: number };
            tracking?: {
              __typename?: 'ShipmentTracking';
              trackingCode?: string | null;
              url?: string | null;
            } | null;
            parcel: {
              __typename?: 'Parcel';
              shippingInstructions: string;
              shippingMethod: ShippingMethod;
              shipper: {
                __typename?: 'Shipper';
                id: string;
                title: string;
                logo: { __typename?: 'Image'; url: string };
              };
            };
            destination:
              | {
                  __typename?: 'ShipmentServicePoint';
                  userFullName: string;
                  servicePoint: { __typename?: 'ServicePoint'; name: string };
                }
              | { __typename?: 'UserAddress'; name: string; address: string };
          }
        | {
            __typename?: 'TrackingCodeOrderShipment';
            costs: { __typename?: 'Price'; amount: number };
            tracking?: {
              __typename?: 'ShipmentTracking';
              trackingCode?: string | null;
              url?: string | null;
            } | null;
            parcel: {
              __typename?: 'Parcel';
              shippingInstructions: string;
              shippingMethod: ShippingMethod;
              shipper: {
                __typename?: 'Shipper';
                id: string;
                title: string;
                logo: { __typename?: 'Image'; url: string };
              };
            };
            destination:
              | {
                  __typename?: 'ShipmentServicePoint';
                  userFullName: string;
                  servicePoint: { __typename?: 'ServicePoint'; name: string };
                }
              | { __typename?: 'UserAddress'; name: string; address: string };
          }
        | null;
      seller: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        rating?: {
          __typename?: 'UserRating';
          rating: number;
          ratingCount: number;
        } | null;
        profileImage: { __typename?: 'UserImage'; url: string };
      };
      buyer: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        rating?: {
          __typename?: 'UserRating';
          rating: number;
          ratingCount: number;
        } | null;
        profileImage: { __typename?: 'UserImage'; url: string };
      };
      cartGroup: {
        __typename?: 'OrderCartGroup';
        items: Array<
          | {
              __typename?: 'BundleBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              products: Array<{
                __typename?: 'Product';
                id: string;
                image?: { __typename?: 'ProductImage'; url: string } | null;
              }>;
            }
          | {
              __typename?: 'Product';
              id: string;
              size?: { __typename?: 'ProductSize'; label: string } | null;
              price: { __typename?: 'Price'; amount: number };
              image?: { __typename?: 'ProductImage'; url: string } | null;
              brand: { __typename?: 'ProductBrand'; name: string };
            }
          | {
              __typename?: 'ProductBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              product: {
                __typename?: 'Product';
                id: string;
                size?: { __typename?: 'ProductSize'; label: string } | null;
                image?: { __typename?: 'ProductImage'; url: string } | null;
                brand: { __typename?: 'ProductBrand'; name: string };
              };
            }
        >;
      };
    };
  } | null;
};

export type CancelReturnRequestMutationVariables = Exact<{
  input: CancelReturnRequestInput;
}>;

export type CancelReturnRequestMutation = {
  __typename?: 'Mutation';
  cancelReturnRequest?: {
    __typename?: 'CancelReturnRequestPayload';
    order: {
      __typename?: 'Order';
      id: string;
      orderReference: string;
      state: OrderState;
      viewerIsSeller: boolean;
      deliveryType: DeliveryType;
      createdOn: string;
      createCancellationRequestState: CreateCancellationRequestState;
      cancellationRequestReasons?: Array<{
        __typename?: 'CancellationRequestReason';
        id: string;
        title: string;
      }> | null;
      cancellationRequest?: {
        __typename?: 'CancellationRequest';
        id: string;
        viewerCanWithdraw: boolean;
        reason: {
          __typename?: 'CancellationRequestReason';
          id: string;
          title: string;
        };
        history: Array<{
          __typename?: 'CancellationRequestEvent';
          id: string;
          state: CancellationRequestState;
          viewerInitiatedEvent: boolean;
          explanation?: string | null;
          initiatedOn: string;
          user?: {
            __typename?: 'User';
            id: string;
            displayName: string;
            profileImage: { __typename?: 'UserImage'; url: string };
          } | null;
        }>;
      } | null;
      orderRating?: {
        __typename?: 'OrderRating';
        rating: number;
        review?: string | null;
        ratedOn: string;
        ratedBy: {
          __typename?: 'User';
          id: string;
          displayName: string;
          followerCount: number;
          profileImage: { __typename?: 'UserImage'; url: string };
        };
      } | null;
      payment: {
        __typename?: 'OrderPayment';
        value: { __typename?: 'Price'; amount: number };
      };
      returnRequest?: {
        __typename?: 'ReturnRequest';
        id: string;
        state: ReturnRequestState;
        viewerCanCancel: boolean;
        reason: ReturnRequestReason;
        returnAddress?: {
          __typename?: 'UserAddress';
          name: string;
          address: string;
        } | null;
        history: Array<
          | {
              __typename?: 'AdminReturnRequestEvent';
              id: string;
              state: ReturnRequestState;
              initiatedOn: string;
              comment?: string | null;
            }
          | {
              __typename?: 'UserReturnRequestEvent';
              id: string;
              state: ReturnRequestState;
              initiatedOn: string;
              comment?: string | null;
              user: {
                __typename?: 'User';
                id: string;
                displayName: string;
                profileImage: { __typename?: 'UserImage'; url: string };
              };
              attachments: Array<{ __typename?: 'Image'; url: string }>;
            }
        >;
      } | null;
      shipment?:
        | {
            __typename?: 'LabelOrderShipment';
            label: {
              __typename?: 'OrderShipmentLabel';
              status: OrderShipmentLabelStatus;
              shippingLabel?: {
                __typename?: 'ShippingLabel';
                id: string;
                url: string;
              } | null;
            };
            costs: { __typename?: 'Price'; amount: number };
            tracking?: {
              __typename?: 'ShipmentTracking';
              trackingCode?: string | null;
              url?: string | null;
            } | null;
            parcel: {
              __typename?: 'Parcel';
              shippingInstructions: string;
              shippingMethod: ShippingMethod;
              shipper: {
                __typename?: 'Shipper';
                id: string;
                title: string;
                logo: { __typename?: 'Image'; url: string };
              };
            };
            destination:
              | {
                  __typename?: 'ShipmentServicePoint';
                  userFullName: string;
                  servicePoint: { __typename?: 'ServicePoint'; name: string };
                }
              | { __typename?: 'UserAddress'; name: string; address: string };
          }
        | {
            __typename?: 'TrackingCodeOrderShipment';
            costs: { __typename?: 'Price'; amount: number };
            tracking?: {
              __typename?: 'ShipmentTracking';
              trackingCode?: string | null;
              url?: string | null;
            } | null;
            parcel: {
              __typename?: 'Parcel';
              shippingInstructions: string;
              shippingMethod: ShippingMethod;
              shipper: {
                __typename?: 'Shipper';
                id: string;
                title: string;
                logo: { __typename?: 'Image'; url: string };
              };
            };
            destination:
              | {
                  __typename?: 'ShipmentServicePoint';
                  userFullName: string;
                  servicePoint: { __typename?: 'ServicePoint'; name: string };
                }
              | { __typename?: 'UserAddress'; name: string; address: string };
          }
        | null;
      seller: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        rating?: {
          __typename?: 'UserRating';
          rating: number;
          ratingCount: number;
        } | null;
        profileImage: { __typename?: 'UserImage'; url: string };
      };
      buyer: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        rating?: {
          __typename?: 'UserRating';
          rating: number;
          ratingCount: number;
        } | null;
        profileImage: { __typename?: 'UserImage'; url: string };
      };
      cartGroup: {
        __typename?: 'OrderCartGroup';
        items: Array<
          | {
              __typename?: 'BundleBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              products: Array<{
                __typename?: 'Product';
                id: string;
                image?: { __typename?: 'ProductImage'; url: string } | null;
              }>;
            }
          | {
              __typename?: 'Product';
              id: string;
              size?: { __typename?: 'ProductSize'; label: string } | null;
              price: { __typename?: 'Price'; amount: number };
              image?: { __typename?: 'ProductImage'; url: string } | null;
              brand: { __typename?: 'ProductBrand'; name: string };
            }
          | {
              __typename?: 'ProductBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              product: {
                __typename?: 'Product';
                id: string;
                size?: { __typename?: 'ProductSize'; label: string } | null;
                image?: { __typename?: 'ProductImage'; url: string } | null;
                brand: { __typename?: 'ProductBrand'; name: string };
              };
            }
        >;
      };
    };
  } | null;
};

export type ApproveReturnRequestMutationVariables = Exact<{
  input: ApproveReturnRequestInput;
}>;

export type ApproveReturnRequestMutation = {
  __typename?: 'Mutation';
  approveReturnRequest?: {
    __typename?: 'ApproveReturnRequestPayload';
    order: {
      __typename?: 'Order';
      id: string;
      orderReference: string;
      state: OrderState;
      viewerIsSeller: boolean;
      deliveryType: DeliveryType;
      createdOn: string;
      createCancellationRequestState: CreateCancellationRequestState;
      cancellationRequestReasons?: Array<{
        __typename?: 'CancellationRequestReason';
        id: string;
        title: string;
      }> | null;
      cancellationRequest?: {
        __typename?: 'CancellationRequest';
        id: string;
        viewerCanWithdraw: boolean;
        reason: {
          __typename?: 'CancellationRequestReason';
          id: string;
          title: string;
        };
        history: Array<{
          __typename?: 'CancellationRequestEvent';
          id: string;
          state: CancellationRequestState;
          viewerInitiatedEvent: boolean;
          explanation?: string | null;
          initiatedOn: string;
          user?: {
            __typename?: 'User';
            id: string;
            displayName: string;
            profileImage: { __typename?: 'UserImage'; url: string };
          } | null;
        }>;
      } | null;
      orderRating?: {
        __typename?: 'OrderRating';
        rating: number;
        review?: string | null;
        ratedOn: string;
        ratedBy: {
          __typename?: 'User';
          id: string;
          displayName: string;
          followerCount: number;
          profileImage: { __typename?: 'UserImage'; url: string };
        };
      } | null;
      payment: {
        __typename?: 'OrderPayment';
        value: { __typename?: 'Price'; amount: number };
      };
      returnRequest?: {
        __typename?: 'ReturnRequest';
        id: string;
        state: ReturnRequestState;
        viewerCanCancel: boolean;
        reason: ReturnRequestReason;
        returnAddress?: {
          __typename?: 'UserAddress';
          name: string;
          address: string;
        } | null;
        history: Array<
          | {
              __typename?: 'AdminReturnRequestEvent';
              id: string;
              state: ReturnRequestState;
              initiatedOn: string;
              comment?: string | null;
            }
          | {
              __typename?: 'UserReturnRequestEvent';
              id: string;
              state: ReturnRequestState;
              initiatedOn: string;
              comment?: string | null;
              user: {
                __typename?: 'User';
                id: string;
                displayName: string;
                profileImage: { __typename?: 'UserImage'; url: string };
              };
              attachments: Array<{ __typename?: 'Image'; url: string }>;
            }
        >;
      } | null;
      shipment?:
        | {
            __typename?: 'LabelOrderShipment';
            label: {
              __typename?: 'OrderShipmentLabel';
              status: OrderShipmentLabelStatus;
              shippingLabel?: {
                __typename?: 'ShippingLabel';
                id: string;
                url: string;
              } | null;
            };
            costs: { __typename?: 'Price'; amount: number };
            tracking?: {
              __typename?: 'ShipmentTracking';
              trackingCode?: string | null;
              url?: string | null;
            } | null;
            parcel: {
              __typename?: 'Parcel';
              shippingInstructions: string;
              shippingMethod: ShippingMethod;
              shipper: {
                __typename?: 'Shipper';
                id: string;
                title: string;
                logo: { __typename?: 'Image'; url: string };
              };
            };
            destination:
              | {
                  __typename?: 'ShipmentServicePoint';
                  userFullName: string;
                  servicePoint: { __typename?: 'ServicePoint'; name: string };
                }
              | { __typename?: 'UserAddress'; name: string; address: string };
          }
        | {
            __typename?: 'TrackingCodeOrderShipment';
            costs: { __typename?: 'Price'; amount: number };
            tracking?: {
              __typename?: 'ShipmentTracking';
              trackingCode?: string | null;
              url?: string | null;
            } | null;
            parcel: {
              __typename?: 'Parcel';
              shippingInstructions: string;
              shippingMethod: ShippingMethod;
              shipper: {
                __typename?: 'Shipper';
                id: string;
                title: string;
                logo: { __typename?: 'Image'; url: string };
              };
            };
            destination:
              | {
                  __typename?: 'ShipmentServicePoint';
                  userFullName: string;
                  servicePoint: { __typename?: 'ServicePoint'; name: string };
                }
              | { __typename?: 'UserAddress'; name: string; address: string };
          }
        | null;
      seller: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        rating?: {
          __typename?: 'UserRating';
          rating: number;
          ratingCount: number;
        } | null;
        profileImage: { __typename?: 'UserImage'; url: string };
      };
      buyer: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        rating?: {
          __typename?: 'UserRating';
          rating: number;
          ratingCount: number;
        } | null;
        profileImage: { __typename?: 'UserImage'; url: string };
      };
      cartGroup: {
        __typename?: 'OrderCartGroup';
        items: Array<
          | {
              __typename?: 'BundleBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              products: Array<{
                __typename?: 'Product';
                id: string;
                image?: { __typename?: 'ProductImage'; url: string } | null;
              }>;
            }
          | {
              __typename?: 'Product';
              id: string;
              size?: { __typename?: 'ProductSize'; label: string } | null;
              price: { __typename?: 'Price'; amount: number };
              image?: { __typename?: 'ProductImage'; url: string } | null;
              brand: { __typename?: 'ProductBrand'; name: string };
            }
          | {
              __typename?: 'ProductBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              product: {
                __typename?: 'Product';
                id: string;
                size?: { __typename?: 'ProductSize'; label: string } | null;
                image?: { __typename?: 'ProductImage'; url: string } | null;
                brand: { __typename?: 'ProductBrand'; name: string };
              };
            }
        >;
      };
    };
  } | null;
};

export type DisputeReturnRequestMutationVariables = Exact<{
  input: DisputeReturnRequestInput;
}>;

export type DisputeReturnRequestMutation = {
  __typename?: 'Mutation';
  disputeReturnRequest?: {
    __typename?: 'DisputeReturnRequestPayload';
    order: {
      __typename?: 'Order';
      id: string;
      orderReference: string;
      state: OrderState;
      viewerIsSeller: boolean;
      deliveryType: DeliveryType;
      createdOn: string;
      createCancellationRequestState: CreateCancellationRequestState;
      cancellationRequestReasons?: Array<{
        __typename?: 'CancellationRequestReason';
        id: string;
        title: string;
      }> | null;
      cancellationRequest?: {
        __typename?: 'CancellationRequest';
        id: string;
        viewerCanWithdraw: boolean;
        reason: {
          __typename?: 'CancellationRequestReason';
          id: string;
          title: string;
        };
        history: Array<{
          __typename?: 'CancellationRequestEvent';
          id: string;
          state: CancellationRequestState;
          viewerInitiatedEvent: boolean;
          explanation?: string | null;
          initiatedOn: string;
          user?: {
            __typename?: 'User';
            id: string;
            displayName: string;
            profileImage: { __typename?: 'UserImage'; url: string };
          } | null;
        }>;
      } | null;
      orderRating?: {
        __typename?: 'OrderRating';
        rating: number;
        review?: string | null;
        ratedOn: string;
        ratedBy: {
          __typename?: 'User';
          id: string;
          displayName: string;
          followerCount: number;
          profileImage: { __typename?: 'UserImage'; url: string };
        };
      } | null;
      payment: {
        __typename?: 'OrderPayment';
        value: { __typename?: 'Price'; amount: number };
      };
      returnRequest?: {
        __typename?: 'ReturnRequest';
        id: string;
        state: ReturnRequestState;
        viewerCanCancel: boolean;
        reason: ReturnRequestReason;
        returnAddress?: {
          __typename?: 'UserAddress';
          name: string;
          address: string;
        } | null;
        history: Array<
          | {
              __typename?: 'AdminReturnRequestEvent';
              id: string;
              state: ReturnRequestState;
              initiatedOn: string;
              comment?: string | null;
            }
          | {
              __typename?: 'UserReturnRequestEvent';
              id: string;
              state: ReturnRequestState;
              initiatedOn: string;
              comment?: string | null;
              user: {
                __typename?: 'User';
                id: string;
                displayName: string;
                profileImage: { __typename?: 'UserImage'; url: string };
              };
              attachments: Array<{ __typename?: 'Image'; url: string }>;
            }
        >;
      } | null;
      shipment?:
        | {
            __typename?: 'LabelOrderShipment';
            label: {
              __typename?: 'OrderShipmentLabel';
              status: OrderShipmentLabelStatus;
              shippingLabel?: {
                __typename?: 'ShippingLabel';
                id: string;
                url: string;
              } | null;
            };
            costs: { __typename?: 'Price'; amount: number };
            tracking?: {
              __typename?: 'ShipmentTracking';
              trackingCode?: string | null;
              url?: string | null;
            } | null;
            parcel: {
              __typename?: 'Parcel';
              shippingInstructions: string;
              shippingMethod: ShippingMethod;
              shipper: {
                __typename?: 'Shipper';
                id: string;
                title: string;
                logo: { __typename?: 'Image'; url: string };
              };
            };
            destination:
              | {
                  __typename?: 'ShipmentServicePoint';
                  userFullName: string;
                  servicePoint: { __typename?: 'ServicePoint'; name: string };
                }
              | { __typename?: 'UserAddress'; name: string; address: string };
          }
        | {
            __typename?: 'TrackingCodeOrderShipment';
            costs: { __typename?: 'Price'; amount: number };
            tracking?: {
              __typename?: 'ShipmentTracking';
              trackingCode?: string | null;
              url?: string | null;
            } | null;
            parcel: {
              __typename?: 'Parcel';
              shippingInstructions: string;
              shippingMethod: ShippingMethod;
              shipper: {
                __typename?: 'Shipper';
                id: string;
                title: string;
                logo: { __typename?: 'Image'; url: string };
              };
            };
            destination:
              | {
                  __typename?: 'ShipmentServicePoint';
                  userFullName: string;
                  servicePoint: { __typename?: 'ServicePoint'; name: string };
                }
              | { __typename?: 'UserAddress'; name: string; address: string };
          }
        | null;
      seller: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        rating?: {
          __typename?: 'UserRating';
          rating: number;
          ratingCount: number;
        } | null;
        profileImage: { __typename?: 'UserImage'; url: string };
      };
      buyer: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        rating?: {
          __typename?: 'UserRating';
          rating: number;
          ratingCount: number;
        } | null;
        profileImage: { __typename?: 'UserImage'; url: string };
      };
      cartGroup: {
        __typename?: 'OrderCartGroup';
        items: Array<
          | {
              __typename?: 'BundleBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              products: Array<{
                __typename?: 'Product';
                id: string;
                image?: { __typename?: 'ProductImage'; url: string } | null;
              }>;
            }
          | {
              __typename?: 'Product';
              id: string;
              size?: { __typename?: 'ProductSize'; label: string } | null;
              price: { __typename?: 'Price'; amount: number };
              image?: { __typename?: 'ProductImage'; url: string } | null;
              brand: { __typename?: 'ProductBrand'; name: string };
            }
          | {
              __typename?: 'ProductBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              product: {
                __typename?: 'Product';
                id: string;
                size?: { __typename?: 'ProductSize'; label: string } | null;
                image?: { __typename?: 'ProductImage'; url: string } | null;
                brand: { __typename?: 'ProductBrand'; name: string };
              };
            }
        >;
      };
    };
  } | null;
};

export type CreateShippingLabelMutationVariables = Exact<{
  input: CreateShippingLabelInput;
}>;

export type CreateShippingLabelMutation = {
  __typename?: 'Mutation';
  createShippingLabel?: {
    __typename?: 'CreateShippingLabelPayload';
    order: {
      __typename?: 'Order';
      id: string;
      orderReference: string;
      state: OrderState;
      viewerIsSeller: boolean;
      deliveryType: DeliveryType;
      createdOn: string;
      createCancellationRequestState: CreateCancellationRequestState;
      cancellationRequestReasons?: Array<{
        __typename?: 'CancellationRequestReason';
        id: string;
        title: string;
      }> | null;
      cancellationRequest?: {
        __typename?: 'CancellationRequest';
        id: string;
        viewerCanWithdraw: boolean;
        reason: {
          __typename?: 'CancellationRequestReason';
          id: string;
          title: string;
        };
        history: Array<{
          __typename?: 'CancellationRequestEvent';
          id: string;
          state: CancellationRequestState;
          viewerInitiatedEvent: boolean;
          explanation?: string | null;
          initiatedOn: string;
          user?: {
            __typename?: 'User';
            id: string;
            displayName: string;
            profileImage: { __typename?: 'UserImage'; url: string };
          } | null;
        }>;
      } | null;
      orderRating?: {
        __typename?: 'OrderRating';
        rating: number;
        review?: string | null;
        ratedOn: string;
        ratedBy: {
          __typename?: 'User';
          id: string;
          displayName: string;
          followerCount: number;
          profileImage: { __typename?: 'UserImage'; url: string };
        };
      } | null;
      payment: {
        __typename?: 'OrderPayment';
        value: { __typename?: 'Price'; amount: number };
      };
      returnRequest?: {
        __typename?: 'ReturnRequest';
        id: string;
        state: ReturnRequestState;
        viewerCanCancel: boolean;
        reason: ReturnRequestReason;
        returnAddress?: {
          __typename?: 'UserAddress';
          name: string;
          address: string;
        } | null;
        history: Array<
          | {
              __typename?: 'AdminReturnRequestEvent';
              id: string;
              state: ReturnRequestState;
              initiatedOn: string;
              comment?: string | null;
            }
          | {
              __typename?: 'UserReturnRequestEvent';
              id: string;
              state: ReturnRequestState;
              initiatedOn: string;
              comment?: string | null;
              user: {
                __typename?: 'User';
                id: string;
                displayName: string;
                profileImage: { __typename?: 'UserImage'; url: string };
              };
              attachments: Array<{ __typename?: 'Image'; url: string }>;
            }
        >;
      } | null;
      shipment?:
        | {
            __typename?: 'LabelOrderShipment';
            label: {
              __typename?: 'OrderShipmentLabel';
              status: OrderShipmentLabelStatus;
              shippingLabel?: {
                __typename?: 'ShippingLabel';
                id: string;
                url: string;
              } | null;
            };
            costs: { __typename?: 'Price'; amount: number };
            tracking?: {
              __typename?: 'ShipmentTracking';
              trackingCode?: string | null;
              url?: string | null;
            } | null;
            parcel: {
              __typename?: 'Parcel';
              shippingInstructions: string;
              shippingMethod: ShippingMethod;
              shipper: {
                __typename?: 'Shipper';
                id: string;
                title: string;
                logo: { __typename?: 'Image'; url: string };
              };
            };
            destination:
              | {
                  __typename?: 'ShipmentServicePoint';
                  userFullName: string;
                  servicePoint: { __typename?: 'ServicePoint'; name: string };
                }
              | { __typename?: 'UserAddress'; name: string; address: string };
          }
        | {
            __typename?: 'TrackingCodeOrderShipment';
            costs: { __typename?: 'Price'; amount: number };
            tracking?: {
              __typename?: 'ShipmentTracking';
              trackingCode?: string | null;
              url?: string | null;
            } | null;
            parcel: {
              __typename?: 'Parcel';
              shippingInstructions: string;
              shippingMethod: ShippingMethod;
              shipper: {
                __typename?: 'Shipper';
                id: string;
                title: string;
                logo: { __typename?: 'Image'; url: string };
              };
            };
            destination:
              | {
                  __typename?: 'ShipmentServicePoint';
                  userFullName: string;
                  servicePoint: { __typename?: 'ServicePoint'; name: string };
                }
              | { __typename?: 'UserAddress'; name: string; address: string };
          }
        | null;
      seller: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        rating?: {
          __typename?: 'UserRating';
          rating: number;
          ratingCount: number;
        } | null;
        profileImage: { __typename?: 'UserImage'; url: string };
      };
      buyer: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        rating?: {
          __typename?: 'UserRating';
          rating: number;
          ratingCount: number;
        } | null;
        profileImage: { __typename?: 'UserImage'; url: string };
      };
      cartGroup: {
        __typename?: 'OrderCartGroup';
        items: Array<
          | {
              __typename?: 'BundleBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              products: Array<{
                __typename?: 'Product';
                id: string;
                image?: { __typename?: 'ProductImage'; url: string } | null;
              }>;
            }
          | {
              __typename?: 'Product';
              id: string;
              size?: { __typename?: 'ProductSize'; label: string } | null;
              price: { __typename?: 'Price'; amount: number };
              image?: { __typename?: 'ProductImage'; url: string } | null;
              brand: { __typename?: 'ProductBrand'; name: string };
            }
          | {
              __typename?: 'ProductBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              product: {
                __typename?: 'Product';
                id: string;
                size?: { __typename?: 'ProductSize'; label: string } | null;
                image?: { __typename?: 'ProductImage'; url: string } | null;
                brand: { __typename?: 'ProductBrand'; name: string };
              };
            }
        >;
      };
    };
  } | null;
};

export type SetOrderTrackingCodeMutationVariables = Exact<{
  input: SetOrderTrackingCodeInput;
}>;

export type SetOrderTrackingCodeMutation = {
  __typename?: 'Mutation';
  setOrderTrackingCode?: {
    __typename?: 'SetOrderTrackingCodePayload';
    order: {
      __typename?: 'Order';
      id: string;
      orderReference: string;
      state: OrderState;
      viewerIsSeller: boolean;
      deliveryType: DeliveryType;
      createdOn: string;
      createCancellationRequestState: CreateCancellationRequestState;
      cancellationRequestReasons?: Array<{
        __typename?: 'CancellationRequestReason';
        id: string;
        title: string;
      }> | null;
      cancellationRequest?: {
        __typename?: 'CancellationRequest';
        id: string;
        viewerCanWithdraw: boolean;
        reason: {
          __typename?: 'CancellationRequestReason';
          id: string;
          title: string;
        };
        history: Array<{
          __typename?: 'CancellationRequestEvent';
          id: string;
          state: CancellationRequestState;
          viewerInitiatedEvent: boolean;
          explanation?: string | null;
          initiatedOn: string;
          user?: {
            __typename?: 'User';
            id: string;
            displayName: string;
            profileImage: { __typename?: 'UserImage'; url: string };
          } | null;
        }>;
      } | null;
      orderRating?: {
        __typename?: 'OrderRating';
        rating: number;
        review?: string | null;
        ratedOn: string;
        ratedBy: {
          __typename?: 'User';
          id: string;
          displayName: string;
          followerCount: number;
          profileImage: { __typename?: 'UserImage'; url: string };
        };
      } | null;
      payment: {
        __typename?: 'OrderPayment';
        value: { __typename?: 'Price'; amount: number };
      };
      returnRequest?: {
        __typename?: 'ReturnRequest';
        id: string;
        state: ReturnRequestState;
        viewerCanCancel: boolean;
        reason: ReturnRequestReason;
        returnAddress?: {
          __typename?: 'UserAddress';
          name: string;
          address: string;
        } | null;
        history: Array<
          | {
              __typename?: 'AdminReturnRequestEvent';
              id: string;
              state: ReturnRequestState;
              initiatedOn: string;
              comment?: string | null;
            }
          | {
              __typename?: 'UserReturnRequestEvent';
              id: string;
              state: ReturnRequestState;
              initiatedOn: string;
              comment?: string | null;
              user: {
                __typename?: 'User';
                id: string;
                displayName: string;
                profileImage: { __typename?: 'UserImage'; url: string };
              };
              attachments: Array<{ __typename?: 'Image'; url: string }>;
            }
        >;
      } | null;
      shipment?:
        | {
            __typename?: 'LabelOrderShipment';
            label: {
              __typename?: 'OrderShipmentLabel';
              status: OrderShipmentLabelStatus;
              shippingLabel?: {
                __typename?: 'ShippingLabel';
                id: string;
                url: string;
              } | null;
            };
            costs: { __typename?: 'Price'; amount: number };
            tracking?: {
              __typename?: 'ShipmentTracking';
              trackingCode?: string | null;
              url?: string | null;
            } | null;
            parcel: {
              __typename?: 'Parcel';
              shippingInstructions: string;
              shippingMethod: ShippingMethod;
              shipper: {
                __typename?: 'Shipper';
                id: string;
                title: string;
                logo: { __typename?: 'Image'; url: string };
              };
            };
            destination:
              | {
                  __typename?: 'ShipmentServicePoint';
                  userFullName: string;
                  servicePoint: { __typename?: 'ServicePoint'; name: string };
                }
              | { __typename?: 'UserAddress'; name: string; address: string };
          }
        | {
            __typename?: 'TrackingCodeOrderShipment';
            costs: { __typename?: 'Price'; amount: number };
            tracking?: {
              __typename?: 'ShipmentTracking';
              trackingCode?: string | null;
              url?: string | null;
            } | null;
            parcel: {
              __typename?: 'Parcel';
              shippingInstructions: string;
              shippingMethod: ShippingMethod;
              shipper: {
                __typename?: 'Shipper';
                id: string;
                title: string;
                logo: { __typename?: 'Image'; url: string };
              };
            };
            destination:
              | {
                  __typename?: 'ShipmentServicePoint';
                  userFullName: string;
                  servicePoint: { __typename?: 'ServicePoint'; name: string };
                }
              | { __typename?: 'UserAddress'; name: string; address: string };
          }
        | null;
      seller: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        rating?: {
          __typename?: 'UserRating';
          rating: number;
          ratingCount: number;
        } | null;
        profileImage: { __typename?: 'UserImage'; url: string };
      };
      buyer: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        rating?: {
          __typename?: 'UserRating';
          rating: number;
          ratingCount: number;
        } | null;
        profileImage: { __typename?: 'UserImage'; url: string };
      };
      cartGroup: {
        __typename?: 'OrderCartGroup';
        items: Array<
          | {
              __typename?: 'BundleBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              products: Array<{
                __typename?: 'Product';
                id: string;
                image?: { __typename?: 'ProductImage'; url: string } | null;
              }>;
            }
          | {
              __typename?: 'Product';
              id: string;
              size?: { __typename?: 'ProductSize'; label: string } | null;
              price: { __typename?: 'Price'; amount: number };
              image?: { __typename?: 'ProductImage'; url: string } | null;
              brand: { __typename?: 'ProductBrand'; name: string };
            }
          | {
              __typename?: 'ProductBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              product: {
                __typename?: 'Product';
                id: string;
                size?: { __typename?: 'ProductSize'; label: string } | null;
                image?: { __typename?: 'ProductImage'; url: string } | null;
                brand: { __typename?: 'ProductBrand'; name: string };
              };
            }
        >;
      };
    };
  } | null;
};

export type CreateOrderReviewMutationVariables = Exact<{
  input: CreateOrderReviewInput;
}>;

export type CreateOrderReviewMutation = {
  __typename?: 'Mutation';
  createOrderReview?: {
    __typename?: 'CreateOrderReviewPayload';
    order: {
      __typename?: 'Order';
      id: string;
      orderReference: string;
      state: OrderState;
      viewerIsSeller: boolean;
      deliveryType: DeliveryType;
      createdOn: string;
      createCancellationRequestState: CreateCancellationRequestState;
      cancellationRequestReasons?: Array<{
        __typename?: 'CancellationRequestReason';
        id: string;
        title: string;
      }> | null;
      cancellationRequest?: {
        __typename?: 'CancellationRequest';
        id: string;
        viewerCanWithdraw: boolean;
        reason: {
          __typename?: 'CancellationRequestReason';
          id: string;
          title: string;
        };
        history: Array<{
          __typename?: 'CancellationRequestEvent';
          id: string;
          state: CancellationRequestState;
          viewerInitiatedEvent: boolean;
          explanation?: string | null;
          initiatedOn: string;
          user?: {
            __typename?: 'User';
            id: string;
            displayName: string;
            profileImage: { __typename?: 'UserImage'; url: string };
          } | null;
        }>;
      } | null;
      orderRating?: {
        __typename?: 'OrderRating';
        rating: number;
        review?: string | null;
        ratedOn: string;
        ratedBy: {
          __typename?: 'User';
          id: string;
          displayName: string;
          followerCount: number;
          profileImage: { __typename?: 'UserImage'; url: string };
        };
      } | null;
      payment: {
        __typename?: 'OrderPayment';
        value: { __typename?: 'Price'; amount: number };
      };
      returnRequest?: {
        __typename?: 'ReturnRequest';
        id: string;
        state: ReturnRequestState;
        viewerCanCancel: boolean;
        reason: ReturnRequestReason;
        returnAddress?: {
          __typename?: 'UserAddress';
          name: string;
          address: string;
        } | null;
        history: Array<
          | {
              __typename?: 'AdminReturnRequestEvent';
              id: string;
              state: ReturnRequestState;
              initiatedOn: string;
              comment?: string | null;
            }
          | {
              __typename?: 'UserReturnRequestEvent';
              id: string;
              state: ReturnRequestState;
              initiatedOn: string;
              comment?: string | null;
              user: {
                __typename?: 'User';
                id: string;
                displayName: string;
                profileImage: { __typename?: 'UserImage'; url: string };
              };
              attachments: Array<{ __typename?: 'Image'; url: string }>;
            }
        >;
      } | null;
      shipment?:
        | {
            __typename?: 'LabelOrderShipment';
            label: {
              __typename?: 'OrderShipmentLabel';
              status: OrderShipmentLabelStatus;
              shippingLabel?: {
                __typename?: 'ShippingLabel';
                id: string;
                url: string;
              } | null;
            };
            costs: { __typename?: 'Price'; amount: number };
            tracking?: {
              __typename?: 'ShipmentTracking';
              trackingCode?: string | null;
              url?: string | null;
            } | null;
            parcel: {
              __typename?: 'Parcel';
              shippingInstructions: string;
              shippingMethod: ShippingMethod;
              shipper: {
                __typename?: 'Shipper';
                id: string;
                title: string;
                logo: { __typename?: 'Image'; url: string };
              };
            };
            destination:
              | {
                  __typename?: 'ShipmentServicePoint';
                  userFullName: string;
                  servicePoint: { __typename?: 'ServicePoint'; name: string };
                }
              | { __typename?: 'UserAddress'; name: string; address: string };
          }
        | {
            __typename?: 'TrackingCodeOrderShipment';
            costs: { __typename?: 'Price'; amount: number };
            tracking?: {
              __typename?: 'ShipmentTracking';
              trackingCode?: string | null;
              url?: string | null;
            } | null;
            parcel: {
              __typename?: 'Parcel';
              shippingInstructions: string;
              shippingMethod: ShippingMethod;
              shipper: {
                __typename?: 'Shipper';
                id: string;
                title: string;
                logo: { __typename?: 'Image'; url: string };
              };
            };
            destination:
              | {
                  __typename?: 'ShipmentServicePoint';
                  userFullName: string;
                  servicePoint: { __typename?: 'ServicePoint'; name: string };
                }
              | { __typename?: 'UserAddress'; name: string; address: string };
          }
        | null;
      seller: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        rating?: {
          __typename?: 'UserRating';
          rating: number;
          ratingCount: number;
        } | null;
        profileImage: { __typename?: 'UserImage'; url: string };
      };
      buyer: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        rating?: {
          __typename?: 'UserRating';
          rating: number;
          ratingCount: number;
        } | null;
        profileImage: { __typename?: 'UserImage'; url: string };
      };
      cartGroup: {
        __typename?: 'OrderCartGroup';
        items: Array<
          | {
              __typename?: 'BundleBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              products: Array<{
                __typename?: 'Product';
                id: string;
                image?: { __typename?: 'ProductImage'; url: string } | null;
              }>;
            }
          | {
              __typename?: 'Product';
              id: string;
              size?: { __typename?: 'ProductSize'; label: string } | null;
              price: { __typename?: 'Price'; amount: number };
              image?: { __typename?: 'ProductImage'; url: string } | null;
              brand: { __typename?: 'ProductBrand'; name: string };
            }
          | {
              __typename?: 'ProductBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              product: {
                __typename?: 'Product';
                id: string;
                size?: { __typename?: 'ProductSize'; label: string } | null;
                image?: { __typename?: 'ProductImage'; url: string } | null;
                brand: { __typename?: 'ProductBrand'; name: string };
              };
            }
        >;
      };
    };
  } | null;
};

export type OrderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type OrderQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Viewer';
    order?: {
      __typename?: 'Order';
      id: string;
      orderReference: string;
      state: OrderState;
      viewerIsSeller: boolean;
      deliveryType: DeliveryType;
      createdOn: string;
      createCancellationRequestState: CreateCancellationRequestState;
      cancellationRequestReasons?: Array<{
        __typename?: 'CancellationRequestReason';
        id: string;
        title: string;
      }> | null;
      cancellationRequest?: {
        __typename?: 'CancellationRequest';
        id: string;
        viewerCanWithdraw: boolean;
        reason: {
          __typename?: 'CancellationRequestReason';
          id: string;
          title: string;
        };
        history: Array<{
          __typename?: 'CancellationRequestEvent';
          id: string;
          state: CancellationRequestState;
          viewerInitiatedEvent: boolean;
          explanation?: string | null;
          initiatedOn: string;
          user?: {
            __typename?: 'User';
            id: string;
            displayName: string;
            profileImage: { __typename?: 'UserImage'; url: string };
          } | null;
        }>;
      } | null;
      orderRating?: {
        __typename?: 'OrderRating';
        rating: number;
        review?: string | null;
        ratedOn: string;
        ratedBy: {
          __typename?: 'User';
          id: string;
          displayName: string;
          followerCount: number;
          profileImage: { __typename?: 'UserImage'; url: string };
        };
      } | null;
      payment: {
        __typename?: 'OrderPayment';
        value: { __typename?: 'Price'; amount: number };
      };
      returnRequest?: {
        __typename?: 'ReturnRequest';
        id: string;
        state: ReturnRequestState;
        viewerCanCancel: boolean;
        reason: ReturnRequestReason;
        returnAddress?: {
          __typename?: 'UserAddress';
          name: string;
          address: string;
        } | null;
        history: Array<
          | {
              __typename?: 'AdminReturnRequestEvent';
              id: string;
              state: ReturnRequestState;
              initiatedOn: string;
              comment?: string | null;
            }
          | {
              __typename?: 'UserReturnRequestEvent';
              id: string;
              state: ReturnRequestState;
              initiatedOn: string;
              comment?: string | null;
              user: {
                __typename?: 'User';
                id: string;
                displayName: string;
                profileImage: { __typename?: 'UserImage'; url: string };
              };
              attachments: Array<{ __typename?: 'Image'; url: string }>;
            }
        >;
      } | null;
      shipment?:
        | {
            __typename?: 'LabelOrderShipment';
            label: {
              __typename?: 'OrderShipmentLabel';
              status: OrderShipmentLabelStatus;
              shippingLabel?: {
                __typename?: 'ShippingLabel';
                id: string;
                url: string;
              } | null;
            };
            costs: { __typename?: 'Price'; amount: number };
            tracking?: {
              __typename?: 'ShipmentTracking';
              trackingCode?: string | null;
              url?: string | null;
            } | null;
            parcel: {
              __typename?: 'Parcel';
              shippingInstructions: string;
              shippingMethod: ShippingMethod;
              shipper: {
                __typename?: 'Shipper';
                id: string;
                title: string;
                logo: { __typename?: 'Image'; url: string };
              };
            };
            destination:
              | {
                  __typename?: 'ShipmentServicePoint';
                  userFullName: string;
                  servicePoint: { __typename?: 'ServicePoint'; name: string };
                }
              | { __typename?: 'UserAddress'; name: string; address: string };
          }
        | {
            __typename?: 'TrackingCodeOrderShipment';
            costs: { __typename?: 'Price'; amount: number };
            tracking?: {
              __typename?: 'ShipmentTracking';
              trackingCode?: string | null;
              url?: string | null;
            } | null;
            parcel: {
              __typename?: 'Parcel';
              shippingInstructions: string;
              shippingMethod: ShippingMethod;
              shipper: {
                __typename?: 'Shipper';
                id: string;
                title: string;
                logo: { __typename?: 'Image'; url: string };
              };
            };
            destination:
              | {
                  __typename?: 'ShipmentServicePoint';
                  userFullName: string;
                  servicePoint: { __typename?: 'ServicePoint'; name: string };
                }
              | { __typename?: 'UserAddress'; name: string; address: string };
          }
        | null;
      seller: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        rating?: {
          __typename?: 'UserRating';
          rating: number;
          ratingCount: number;
        } | null;
        profileImage: { __typename?: 'UserImage'; url: string };
      };
      buyer: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        rating?: {
          __typename?: 'UserRating';
          rating: number;
          ratingCount: number;
        } | null;
        profileImage: { __typename?: 'UserImage'; url: string };
      };
      cartGroup: {
        __typename?: 'OrderCartGroup';
        items: Array<
          | {
              __typename?: 'BundleBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              products: Array<{
                __typename?: 'Product';
                id: string;
                image?: { __typename?: 'ProductImage'; url: string } | null;
              }>;
            }
          | {
              __typename?: 'Product';
              id: string;
              size?: { __typename?: 'ProductSize'; label: string } | null;
              price: { __typename?: 'Price'; amount: number };
              image?: { __typename?: 'ProductImage'; url: string } | null;
              brand: { __typename?: 'ProductBrand'; name: string };
            }
          | {
              __typename?: 'ProductBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              product: {
                __typename?: 'Product';
                id: string;
                size?: { __typename?: 'ProductSize'; label: string } | null;
                image?: { __typename?: 'ProductImage'; url: string } | null;
                brand: { __typename?: 'ProductBrand'; name: string };
              };
            }
        >;
      };
    } | null;
  } | null;
};

export type OrdersQueryVariables = Exact<{
  type: OrderType;
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
}>;

export type OrdersQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Viewer';
    ordersConnection: {
      __typename?: 'OrdersConnection';
      totalCount: number;
      nodes: Array<{
        __typename?: 'Order';
        id: string;
        orderReference: string;
        state: OrderState;
        createdOn: string;
        cartGroup: {
          __typename?: 'OrderCartGroup';
          items: Array<
            | {
                __typename?: 'BundleBidGroup';
                products: Array<{
                  __typename?: 'Product';
                  id: string;
                  image?: { __typename?: 'ProductImage'; url: string } | null;
                }>;
              }
            | {
                __typename?: 'Product';
                id: string;
                image?: { __typename?: 'ProductImage'; url: string } | null;
              }
            | {
                __typename?: 'ProductBidGroup';
                product: {
                  __typename?: 'Product';
                  id: string;
                  image?: { __typename?: 'ProductImage'; url: string } | null;
                };
              }
          >;
        };
      }>;
    };
  } | null;
};

export type ProductFragment = {
  __typename?: 'Product';
  title: string;
  description?: string | null;
  condition: ProductCondition;
  viewerIsSeller: boolean;
  viewerHasInCart: boolean;
  hasBid: boolean;
  hasBiddingEnabled: boolean;
  viewCount: number;
  favoriteCount: number;
  id: string;
  state: ProductState;
  viewerHasFavorited: boolean;
  category: {
    __typename?: 'ProductCategory';
    id: string;
    title: string;
    parent?: {
      __typename?: 'ProductCategory';
      id: string;
      title: string;
      parent?: {
        __typename?: 'ProductCategory';
        id: string;
        title: string;
        parent?: {
          __typename?: 'ProductCategory';
          id: string;
          title: string;
          parent?: {
            __typename?: 'ProductCategory';
            id: string;
            title: string;
            parent?: {
              __typename?: 'ProductCategory';
              id: string;
              title: string;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  };
  thumbnails: Array<{ __typename?: 'ProductImage'; url: string }>;
  image?: { __typename?: 'ProductImage'; url: string } | null;
  images: Array<{ __typename?: 'ProductImage'; url: string }>;
  fullImages: Array<{ __typename?: 'ProductImage'; url: string }>;
  bidding?: {
    __typename?: 'ProductBidding';
    hasBid: boolean;
    highestBid?: { __typename?: 'Price'; amount: number } | null;
    minimumBid: { __typename?: 'Price'; amount: number };
  } | null;
  highestBidPrice?: { __typename?: 'Price'; amount: number } | null;
  minimumBidPrice: { __typename?: 'Price'; amount: number };
  availableParcels: Array<{
    __typename?: 'Parcel';
    id: string;
    price:
      | { __typename?: 'Price'; amount: number }
      | {
          __typename?: 'PriceRange';
          minPrice: { __typename?: 'Price'; amount: number };
          maxPrice: { __typename?: 'Price'; amount: number };
        };
    shipper: {
      __typename?: 'Shipper';
      id: string;
      title: string;
      buyerDescription: string;
      logo: { __typename?: 'Image'; url: string };
    };
  }>;
  seller: {
    __typename?: 'User';
    id: string;
    displayName: string;
    followerCount: number;
    viewerIsFollowing: boolean;
    rating?: {
      __typename?: 'UserRating';
      rating: number;
      ratingCount: number;
    } | null;
    profileImage: { __typename?: 'UserImage'; url: string };
    productsConnection: {
      __typename?: 'ProductsConnection';
      nodes: Array<{
        __typename?: 'Product';
        id: string;
        state: ProductState;
        title: string;
        viewerIsSeller: boolean;
        condition: ProductCondition;
        viewerHasFavorited: boolean;
        brand: { __typename?: 'ProductBrand'; name: string };
        category: {
          __typename?: 'ProductCategory';
          id: string;
          title: string;
          parent?: {
            __typename?: 'ProductCategory';
            id: string;
            title: string;
            parent?: {
              __typename?: 'ProductCategory';
              id: string;
              title: string;
              parent?: {
                __typename?: 'ProductCategory';
                id: string;
                title: string;
                parent?: {
                  __typename?: 'ProductCategory';
                  id: string;
                  title: string;
                  parent?: {
                    __typename?: 'ProductCategory';
                    id: string;
                    title: string;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
        };
        price: { __typename?: 'Price'; amount: number };
        tags: Array<{ __typename?: 'ProductTag'; name: string }>;
        colors: Array<{ __typename?: 'ProductColor'; title: string }>;
        image?: { __typename?: 'ProductImage'; url: string } | null;
        size?: { __typename?: 'ProductSize'; label: string } | null;
        outletDiscount?: {
          __typename?: 'OutletDiscount';
          discountPercentage: number;
          preOutletPrice: { __typename?: 'Price'; amount: number };
        } | null;
      }>;
    };
  };
  relatedProductsConnection: {
    __typename?: 'ProductsConnection';
    nodes: Array<{
      __typename?: 'Product';
      id: string;
      state: ProductState;
      title: string;
      viewerIsSeller: boolean;
      condition: ProductCondition;
      viewerHasFavorited: boolean;
      brand: { __typename?: 'ProductBrand'; name: string };
      category: {
        __typename?: 'ProductCategory';
        id: string;
        title: string;
        parent?: {
          __typename?: 'ProductCategory';
          id: string;
          title: string;
          parent?: {
            __typename?: 'ProductCategory';
            id: string;
            title: string;
            parent?: {
              __typename?: 'ProductCategory';
              id: string;
              title: string;
              parent?: {
                __typename?: 'ProductCategory';
                id: string;
                title: string;
                parent?: {
                  __typename?: 'ProductCategory';
                  id: string;
                  title: string;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      };
      price: { __typename?: 'Price'; amount: number };
      tags: Array<{ __typename?: 'ProductTag'; name: string }>;
      colors: Array<{ __typename?: 'ProductColor'; title: string }>;
      image?: { __typename?: 'ProductImage'; url: string } | null;
      size?: { __typename?: 'ProductSize'; label: string } | null;
      outletDiscount?: {
        __typename?: 'OutletDiscount';
        discountPercentage: number;
        preOutletPrice: { __typename?: 'Price'; amount: number };
      } | null;
    }>;
  };
  commentsConnection: {
    __typename?: 'ProductCommentsConnection';
    nodes: Array<{
      __typename?: 'ProductComment';
      id: string;
      sentOn: string;
      body: string;
      sellerIsSender: boolean;
      viewerIsSender: boolean;
      isUnderReview: boolean;
      sender: {
        __typename?: 'User';
        id: string;
        displayName: string;
        profileImage: { __typename?: 'UserImage'; url: string };
      };
      taggedUsers: Array<{
        __typename?: 'User';
        id: string;
        displayName: string;
      }>;
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage?: boolean | null;
    };
  };
  size?: {
    __typename?: 'ProductSize';
    label: string;
    sizeOptions: Array<{
      __typename?: 'ProductSizeOption';
      id: string;
      title: string;
    }>;
  } | null;
  brand: { __typename?: 'ProductBrand'; name: string };
  price: { __typename?: 'Price'; amount: number };
  tags: Array<{ __typename?: 'ProductTag'; name: string }>;
  colors: Array<{ __typename?: 'ProductColor'; title: string }>;
  outletDiscount?: {
    __typename?: 'OutletDiscount';
    discountPercentage: number;
    preOutletPrice: { __typename?: 'Price'; amount: number };
  } | null;
};

export type ProductCommentFragment = {
  __typename?: 'ProductComment';
  id: string;
  sentOn: string;
  body: string;
  sellerIsSender: boolean;
  viewerIsSender: boolean;
  isUnderReview: boolean;
  sender: {
    __typename?: 'User';
    id: string;
    displayName: string;
    profileImage: { __typename?: 'UserImage'; url: string };
  };
  taggedUsers: Array<{ __typename?: 'User'; id: string; displayName: string }>;
};

export type ProductImageFragment = { __typename?: 'ProductImage'; url: string };

export type ProductParcelFragment = {
  __typename?: 'Parcel';
  id: string;
  price:
    | { __typename?: 'Price'; amount: number }
    | {
        __typename?: 'PriceRange';
        minPrice: { __typename?: 'Price'; amount: number };
        maxPrice: { __typename?: 'Price'; amount: number };
      };
  shipper: {
    __typename?: 'Shipper';
    id: string;
    title: string;
    buyerDescription: string;
    logo: { __typename?: 'Image'; url: string };
  };
};

type FullParcelPrice_Price_Fragment = { __typename?: 'Price'; amount: number };

type FullParcelPrice_PriceRange_Fragment = {
  __typename?: 'PriceRange';
  minPrice: { __typename?: 'Price'; amount: number };
  maxPrice: { __typename?: 'Price'; amount: number };
};

export type FullParcelPriceFragment =
  | FullParcelPrice_Price_Fragment
  | FullParcelPrice_PriceRange_Fragment;

export type ProductSellerFragment = {
  __typename?: 'User';
  id: string;
  displayName: string;
  followerCount: number;
  viewerIsFollowing: boolean;
  rating?: {
    __typename?: 'UserRating';
    rating: number;
    ratingCount: number;
  } | null;
  profileImage: { __typename?: 'UserImage'; url: string };
  productsConnection: {
    __typename?: 'ProductsConnection';
    nodes: Array<{
      __typename?: 'Product';
      id: string;
      state: ProductState;
      title: string;
      viewerIsSeller: boolean;
      condition: ProductCondition;
      viewerHasFavorited: boolean;
      brand: { __typename?: 'ProductBrand'; name: string };
      category: {
        __typename?: 'ProductCategory';
        id: string;
        title: string;
        parent?: {
          __typename?: 'ProductCategory';
          id: string;
          title: string;
          parent?: {
            __typename?: 'ProductCategory';
            id: string;
            title: string;
            parent?: {
              __typename?: 'ProductCategory';
              id: string;
              title: string;
              parent?: {
                __typename?: 'ProductCategory';
                id: string;
                title: string;
                parent?: {
                  __typename?: 'ProductCategory';
                  id: string;
                  title: string;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      };
      price: { __typename?: 'Price'; amount: number };
      tags: Array<{ __typename?: 'ProductTag'; name: string }>;
      colors: Array<{ __typename?: 'ProductColor'; title: string }>;
      image?: { __typename?: 'ProductImage'; url: string } | null;
      size?: { __typename?: 'ProductSize'; label: string } | null;
      outletDiscount?: {
        __typename?: 'OutletDiscount';
        discountPercentage: number;
        preOutletPrice: { __typename?: 'Price'; amount: number };
      } | null;
    }>;
  };
};

export type AddProductCommentMutationVariables = Exact<{
  input: AddProductCommentInput;
}>;

export type AddProductCommentMutation = {
  __typename?: 'Mutation';
  addProductComment?: {
    __typename?: 'AddProductCommentPayload';
    comment: {
      __typename?: 'ProductComment';
      id: string;
      sentOn: string;
      body: string;
      sellerIsSender: boolean;
      viewerIsSender: boolean;
      isUnderReview: boolean;
      sender: {
        __typename?: 'User';
        id: string;
        displayName: string;
        profileImage: { __typename?: 'UserImage'; url: string };
      };
      taggedUsers: Array<{
        __typename?: 'User';
        id: string;
        displayName: string;
      }>;
    };
  } | null;
};

export type ReportProductMutationVariables = Exact<{
  input: ReportProductInput;
}>;

export type ReportProductMutation = {
  __typename?: 'Mutation';
  reportProduct?: {
    __typename?: 'ReportProductPayload';
    clientMutationId?: string | null;
    product: {
      __typename?: 'Product';
      title: string;
      description?: string | null;
      condition: ProductCondition;
      viewerIsSeller: boolean;
      viewerHasInCart: boolean;
      hasBid: boolean;
      hasBiddingEnabled: boolean;
      viewCount: number;
      favoriteCount: number;
      id: string;
      state: ProductState;
      viewerHasFavorited: boolean;
      category: {
        __typename?: 'ProductCategory';
        id: string;
        title: string;
        parent?: {
          __typename?: 'ProductCategory';
          id: string;
          title: string;
          parent?: {
            __typename?: 'ProductCategory';
            id: string;
            title: string;
            parent?: {
              __typename?: 'ProductCategory';
              id: string;
              title: string;
              parent?: {
                __typename?: 'ProductCategory';
                id: string;
                title: string;
                parent?: {
                  __typename?: 'ProductCategory';
                  id: string;
                  title: string;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      };
      thumbnails: Array<{ __typename?: 'ProductImage'; url: string }>;
      image?: { __typename?: 'ProductImage'; url: string } | null;
      images: Array<{ __typename?: 'ProductImage'; url: string }>;
      fullImages: Array<{ __typename?: 'ProductImage'; url: string }>;
      bidding?: {
        __typename?: 'ProductBidding';
        hasBid: boolean;
        highestBid?: { __typename?: 'Price'; amount: number } | null;
        minimumBid: { __typename?: 'Price'; amount: number };
      } | null;
      highestBidPrice?: { __typename?: 'Price'; amount: number } | null;
      minimumBidPrice: { __typename?: 'Price'; amount: number };
      availableParcels: Array<{
        __typename?: 'Parcel';
        id: string;
        price:
          | { __typename?: 'Price'; amount: number }
          | {
              __typename?: 'PriceRange';
              minPrice: { __typename?: 'Price'; amount: number };
              maxPrice: { __typename?: 'Price'; amount: number };
            };
        shipper: {
          __typename?: 'Shipper';
          id: string;
          title: string;
          buyerDescription: string;
          logo: { __typename?: 'Image'; url: string };
        };
      }>;
      seller: {
        __typename?: 'User';
        id: string;
        displayName: string;
        followerCount: number;
        viewerIsFollowing: boolean;
        rating?: {
          __typename?: 'UserRating';
          rating: number;
          ratingCount: number;
        } | null;
        profileImage: { __typename?: 'UserImage'; url: string };
        productsConnection: {
          __typename?: 'ProductsConnection';
          nodes: Array<{
            __typename?: 'Product';
            id: string;
            state: ProductState;
            title: string;
            viewerIsSeller: boolean;
            condition: ProductCondition;
            viewerHasFavorited: boolean;
            brand: { __typename?: 'ProductBrand'; name: string };
            category: {
              __typename?: 'ProductCategory';
              id: string;
              title: string;
              parent?: {
                __typename?: 'ProductCategory';
                id: string;
                title: string;
                parent?: {
                  __typename?: 'ProductCategory';
                  id: string;
                  title: string;
                  parent?: {
                    __typename?: 'ProductCategory';
                    id: string;
                    title: string;
                    parent?: {
                      __typename?: 'ProductCategory';
                      id: string;
                      title: string;
                      parent?: {
                        __typename?: 'ProductCategory';
                        id: string;
                        title: string;
                      } | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
            };
            price: { __typename?: 'Price'; amount: number };
            tags: Array<{ __typename?: 'ProductTag'; name: string }>;
            colors: Array<{ __typename?: 'ProductColor'; title: string }>;
            image?: { __typename?: 'ProductImage'; url: string } | null;
            size?: { __typename?: 'ProductSize'; label: string } | null;
            outletDiscount?: {
              __typename?: 'OutletDiscount';
              discountPercentage: number;
              preOutletPrice: { __typename?: 'Price'; amount: number };
            } | null;
          }>;
        };
      };
      relatedProductsConnection: {
        __typename?: 'ProductsConnection';
        nodes: Array<{
          __typename?: 'Product';
          id: string;
          state: ProductState;
          title: string;
          viewerIsSeller: boolean;
          condition: ProductCondition;
          viewerHasFavorited: boolean;
          brand: { __typename?: 'ProductBrand'; name: string };
          category: {
            __typename?: 'ProductCategory';
            id: string;
            title: string;
            parent?: {
              __typename?: 'ProductCategory';
              id: string;
              title: string;
              parent?: {
                __typename?: 'ProductCategory';
                id: string;
                title: string;
                parent?: {
                  __typename?: 'ProductCategory';
                  id: string;
                  title: string;
                  parent?: {
                    __typename?: 'ProductCategory';
                    id: string;
                    title: string;
                    parent?: {
                      __typename?: 'ProductCategory';
                      id: string;
                      title: string;
                    } | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          };
          price: { __typename?: 'Price'; amount: number };
          tags: Array<{ __typename?: 'ProductTag'; name: string }>;
          colors: Array<{ __typename?: 'ProductColor'; title: string }>;
          image?: { __typename?: 'ProductImage'; url: string } | null;
          size?: { __typename?: 'ProductSize'; label: string } | null;
          outletDiscount?: {
            __typename?: 'OutletDiscount';
            discountPercentage: number;
            preOutletPrice: { __typename?: 'Price'; amount: number };
          } | null;
        }>;
      };
      commentsConnection: {
        __typename?: 'ProductCommentsConnection';
        nodes: Array<{
          __typename?: 'ProductComment';
          id: string;
          sentOn: string;
          body: string;
          sellerIsSender: boolean;
          viewerIsSender: boolean;
          isUnderReview: boolean;
          sender: {
            __typename?: 'User';
            id: string;
            displayName: string;
            profileImage: { __typename?: 'UserImage'; url: string };
          };
          taggedUsers: Array<{
            __typename?: 'User';
            id: string;
            displayName: string;
          }>;
        }>;
        pageInfo: {
          __typename?: 'PageInfo';
          endCursor?: string | null;
          hasNextPage?: boolean | null;
        };
      };
      size?: {
        __typename?: 'ProductSize';
        label: string;
        sizeOptions: Array<{
          __typename?: 'ProductSizeOption';
          id: string;
          title: string;
        }>;
      } | null;
      brand: { __typename?: 'ProductBrand'; name: string };
      price: { __typename?: 'Price'; amount: number };
      tags: Array<{ __typename?: 'ProductTag'; name: string }>;
      colors: Array<{ __typename?: 'ProductColor'; title: string }>;
      outletDiscount?: {
        __typename?: 'OutletDiscount';
        discountPercentage: number;
        preOutletPrice: { __typename?: 'Price'; amount: number };
      } | null;
    };
  } | null;
};

export type DeleteProductMutationVariables = Exact<{
  input: DeleteProductInput;
}>;

export type DeleteProductMutation = {
  __typename?: 'Mutation';
  deleteProduct?: {
    __typename?: 'DeleteProductPayload';
    product: {
      __typename?: 'Product';
      title: string;
      description?: string | null;
      condition: ProductCondition;
      viewerIsSeller: boolean;
      viewerHasInCart: boolean;
      hasBid: boolean;
      hasBiddingEnabled: boolean;
      viewCount: number;
      favoriteCount: number;
      id: string;
      state: ProductState;
      viewerHasFavorited: boolean;
      category: {
        __typename?: 'ProductCategory';
        id: string;
        title: string;
        parent?: {
          __typename?: 'ProductCategory';
          id: string;
          title: string;
          parent?: {
            __typename?: 'ProductCategory';
            id: string;
            title: string;
            parent?: {
              __typename?: 'ProductCategory';
              id: string;
              title: string;
              parent?: {
                __typename?: 'ProductCategory';
                id: string;
                title: string;
                parent?: {
                  __typename?: 'ProductCategory';
                  id: string;
                  title: string;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      };
      thumbnails: Array<{ __typename?: 'ProductImage'; url: string }>;
      image?: { __typename?: 'ProductImage'; url: string } | null;
      images: Array<{ __typename?: 'ProductImage'; url: string }>;
      fullImages: Array<{ __typename?: 'ProductImage'; url: string }>;
      bidding?: {
        __typename?: 'ProductBidding';
        hasBid: boolean;
        highestBid?: { __typename?: 'Price'; amount: number } | null;
        minimumBid: { __typename?: 'Price'; amount: number };
      } | null;
      highestBidPrice?: { __typename?: 'Price'; amount: number } | null;
      minimumBidPrice: { __typename?: 'Price'; amount: number };
      availableParcels: Array<{
        __typename?: 'Parcel';
        id: string;
        price:
          | { __typename?: 'Price'; amount: number }
          | {
              __typename?: 'PriceRange';
              minPrice: { __typename?: 'Price'; amount: number };
              maxPrice: { __typename?: 'Price'; amount: number };
            };
        shipper: {
          __typename?: 'Shipper';
          id: string;
          title: string;
          buyerDescription: string;
          logo: { __typename?: 'Image'; url: string };
        };
      }>;
      seller: {
        __typename?: 'User';
        id: string;
        displayName: string;
        followerCount: number;
        viewerIsFollowing: boolean;
        rating?: {
          __typename?: 'UserRating';
          rating: number;
          ratingCount: number;
        } | null;
        profileImage: { __typename?: 'UserImage'; url: string };
        productsConnection: {
          __typename?: 'ProductsConnection';
          nodes: Array<{
            __typename?: 'Product';
            id: string;
            state: ProductState;
            title: string;
            viewerIsSeller: boolean;
            condition: ProductCondition;
            viewerHasFavorited: boolean;
            brand: { __typename?: 'ProductBrand'; name: string };
            category: {
              __typename?: 'ProductCategory';
              id: string;
              title: string;
              parent?: {
                __typename?: 'ProductCategory';
                id: string;
                title: string;
                parent?: {
                  __typename?: 'ProductCategory';
                  id: string;
                  title: string;
                  parent?: {
                    __typename?: 'ProductCategory';
                    id: string;
                    title: string;
                    parent?: {
                      __typename?: 'ProductCategory';
                      id: string;
                      title: string;
                      parent?: {
                        __typename?: 'ProductCategory';
                        id: string;
                        title: string;
                      } | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
            };
            price: { __typename?: 'Price'; amount: number };
            tags: Array<{ __typename?: 'ProductTag'; name: string }>;
            colors: Array<{ __typename?: 'ProductColor'; title: string }>;
            image?: { __typename?: 'ProductImage'; url: string } | null;
            size?: { __typename?: 'ProductSize'; label: string } | null;
            outletDiscount?: {
              __typename?: 'OutletDiscount';
              discountPercentage: number;
              preOutletPrice: { __typename?: 'Price'; amount: number };
            } | null;
          }>;
        };
      };
      relatedProductsConnection: {
        __typename?: 'ProductsConnection';
        nodes: Array<{
          __typename?: 'Product';
          id: string;
          state: ProductState;
          title: string;
          viewerIsSeller: boolean;
          condition: ProductCondition;
          viewerHasFavorited: boolean;
          brand: { __typename?: 'ProductBrand'; name: string };
          category: {
            __typename?: 'ProductCategory';
            id: string;
            title: string;
            parent?: {
              __typename?: 'ProductCategory';
              id: string;
              title: string;
              parent?: {
                __typename?: 'ProductCategory';
                id: string;
                title: string;
                parent?: {
                  __typename?: 'ProductCategory';
                  id: string;
                  title: string;
                  parent?: {
                    __typename?: 'ProductCategory';
                    id: string;
                    title: string;
                    parent?: {
                      __typename?: 'ProductCategory';
                      id: string;
                      title: string;
                    } | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          };
          price: { __typename?: 'Price'; amount: number };
          tags: Array<{ __typename?: 'ProductTag'; name: string }>;
          colors: Array<{ __typename?: 'ProductColor'; title: string }>;
          image?: { __typename?: 'ProductImage'; url: string } | null;
          size?: { __typename?: 'ProductSize'; label: string } | null;
          outletDiscount?: {
            __typename?: 'OutletDiscount';
            discountPercentage: number;
            preOutletPrice: { __typename?: 'Price'; amount: number };
          } | null;
        }>;
      };
      commentsConnection: {
        __typename?: 'ProductCommentsConnection';
        nodes: Array<{
          __typename?: 'ProductComment';
          id: string;
          sentOn: string;
          body: string;
          sellerIsSender: boolean;
          viewerIsSender: boolean;
          isUnderReview: boolean;
          sender: {
            __typename?: 'User';
            id: string;
            displayName: string;
            profileImage: { __typename?: 'UserImage'; url: string };
          };
          taggedUsers: Array<{
            __typename?: 'User';
            id: string;
            displayName: string;
          }>;
        }>;
        pageInfo: {
          __typename?: 'PageInfo';
          endCursor?: string | null;
          hasNextPage?: boolean | null;
        };
      };
      size?: {
        __typename?: 'ProductSize';
        label: string;
        sizeOptions: Array<{
          __typename?: 'ProductSizeOption';
          id: string;
          title: string;
        }>;
      } | null;
      brand: { __typename?: 'ProductBrand'; name: string };
      price: { __typename?: 'Price'; amount: number };
      tags: Array<{ __typename?: 'ProductTag'; name: string }>;
      colors: Array<{ __typename?: 'ProductColor'; title: string }>;
      outletDiscount?: {
        __typename?: 'OutletDiscount';
        discountPercentage: number;
        preOutletPrice: { __typename?: 'Price'; amount: number };
      } | null;
    };
  } | null;
};

export type AddToCartMutationVariables = Exact<{
  input: AddToCartInput;
}>;

export type AddToCartMutation = {
  __typename?: 'Mutation';
  addToCart?: {
    __typename?: 'AddToCartPayload';
    product: { __typename?: 'Product'; id: string; viewerHasInCart: boolean };
    shoppingCart: {
      __typename?: 'ShoppingCart';
      id: string;
      groups: Array<{
        __typename?: 'ShoppingCartGroup';
        id: string;
        items: Array<
          | {
              __typename?: 'BundleBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              image?: { __typename?: 'Image'; url: string } | null;
              products: Array<{ __typename?: 'Product'; id: string }>;
            }
          | {
              __typename?: 'Product';
              id: string;
              state: ProductState;
              image?: { __typename?: 'ProductImage'; url: string } | null;
              brand: { __typename?: 'ProductBrand'; name: string };
              price: { __typename?: 'Price'; amount: number };
              outletDiscount?: {
                __typename?: 'OutletDiscount';
                discountPercentage: number;
                preOutletPrice: { __typename?: 'Price'; amount: number };
              } | null;
              size?: {
                __typename?: 'ProductSize';
                sizeOptions: Array<{
                  __typename?: 'ProductSizeOption';
                  title: string;
                }>;
              } | null;
            }
          | {
              __typename?: 'ProductBidGroup';
              bids: Array<{
                __typename?: 'BidGroupBid';
                price: { __typename?: 'Price'; amount: number };
              }>;
              product: {
                __typename?: 'Product';
                id: string;
                state: ProductState;
                image?: { __typename?: 'ProductImage'; url: string } | null;
                brand: { __typename?: 'ProductBrand'; name: string };
                price: { __typename?: 'Price'; amount: number };
                outletDiscount?: {
                  __typename?: 'OutletDiscount';
                  discountPercentage: number;
                  preOutletPrice: { __typename?: 'Price'; amount: number };
                } | null;
                size?: {
                  __typename?: 'ProductSize';
                  sizeOptions: Array<{
                    __typename?: 'ProductSizeOption';
                    title: string;
                  }>;
                } | null;
              };
            }
        >;
      }>;
      prices: {
        __typename?: 'ShoppingCartPrices';
        subtotal: { __typename?: 'Price'; amount: number };
      };
    };
  } | null;
};

export type RequestMediaUploadsMutationVariables = Exact<{
  input: RequestMediaUploadsInput;
}>;

export type RequestMediaUploadsMutation = {
  __typename?: 'Mutation';
  requestMediaUploads?: {
    __typename?: 'RequestMediaUploadsPayload';
    mediaUploadRequests: Array<{
      __typename?: 'MediaUploadRequest';
      id: string;
      url: string;
    }>;
  } | null;
};

export type ProductQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ProductQuery = {
  __typename?: 'Query';
  product?: {
    __typename?: 'Product';
    title: string;
    description?: string | null;
    condition: ProductCondition;
    viewerIsSeller: boolean;
    viewerHasInCart: boolean;
    hasBid: boolean;
    hasBiddingEnabled: boolean;
    viewCount: number;
    favoriteCount: number;
    id: string;
    state: ProductState;
    viewerHasFavorited: boolean;
    category: {
      __typename?: 'ProductCategory';
      id: string;
      title: string;
      parent?: {
        __typename?: 'ProductCategory';
        id: string;
        title: string;
        parent?: {
          __typename?: 'ProductCategory';
          id: string;
          title: string;
          parent?: {
            __typename?: 'ProductCategory';
            id: string;
            title: string;
            parent?: {
              __typename?: 'ProductCategory';
              id: string;
              title: string;
              parent?: {
                __typename?: 'ProductCategory';
                id: string;
                title: string;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    };
    thumbnails: Array<{ __typename?: 'ProductImage'; url: string }>;
    image?: { __typename?: 'ProductImage'; url: string } | null;
    images: Array<{ __typename?: 'ProductImage'; url: string }>;
    fullImages: Array<{ __typename?: 'ProductImage'; url: string }>;
    bidding?: {
      __typename?: 'ProductBidding';
      hasBid: boolean;
      highestBid?: { __typename?: 'Price'; amount: number } | null;
      minimumBid: { __typename?: 'Price'; amount: number };
    } | null;
    highestBidPrice?: { __typename?: 'Price'; amount: number } | null;
    minimumBidPrice: { __typename?: 'Price'; amount: number };
    availableParcels: Array<{
      __typename?: 'Parcel';
      id: string;
      price:
        | { __typename?: 'Price'; amount: number }
        | {
            __typename?: 'PriceRange';
            minPrice: { __typename?: 'Price'; amount: number };
            maxPrice: { __typename?: 'Price'; amount: number };
          };
      shipper: {
        __typename?: 'Shipper';
        id: string;
        title: string;
        buyerDescription: string;
        logo: { __typename?: 'Image'; url: string };
      };
    }>;
    seller: {
      __typename?: 'User';
      id: string;
      displayName: string;
      followerCount: number;
      viewerIsFollowing: boolean;
      rating?: {
        __typename?: 'UserRating';
        rating: number;
        ratingCount: number;
      } | null;
      profileImage: { __typename?: 'UserImage'; url: string };
      productsConnection: {
        __typename?: 'ProductsConnection';
        nodes: Array<{
          __typename?: 'Product';
          id: string;
          state: ProductState;
          title: string;
          viewerIsSeller: boolean;
          condition: ProductCondition;
          viewerHasFavorited: boolean;
          brand: { __typename?: 'ProductBrand'; name: string };
          category: {
            __typename?: 'ProductCategory';
            id: string;
            title: string;
            parent?: {
              __typename?: 'ProductCategory';
              id: string;
              title: string;
              parent?: {
                __typename?: 'ProductCategory';
                id: string;
                title: string;
                parent?: {
                  __typename?: 'ProductCategory';
                  id: string;
                  title: string;
                  parent?: {
                    __typename?: 'ProductCategory';
                    id: string;
                    title: string;
                    parent?: {
                      __typename?: 'ProductCategory';
                      id: string;
                      title: string;
                    } | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          };
          price: { __typename?: 'Price'; amount: number };
          tags: Array<{ __typename?: 'ProductTag'; name: string }>;
          colors: Array<{ __typename?: 'ProductColor'; title: string }>;
          image?: { __typename?: 'ProductImage'; url: string } | null;
          size?: { __typename?: 'ProductSize'; label: string } | null;
          outletDiscount?: {
            __typename?: 'OutletDiscount';
            discountPercentage: number;
            preOutletPrice: { __typename?: 'Price'; amount: number };
          } | null;
        }>;
      };
    };
    relatedProductsConnection: {
      __typename?: 'ProductsConnection';
      nodes: Array<{
        __typename?: 'Product';
        id: string;
        state: ProductState;
        title: string;
        viewerIsSeller: boolean;
        condition: ProductCondition;
        viewerHasFavorited: boolean;
        brand: { __typename?: 'ProductBrand'; name: string };
        category: {
          __typename?: 'ProductCategory';
          id: string;
          title: string;
          parent?: {
            __typename?: 'ProductCategory';
            id: string;
            title: string;
            parent?: {
              __typename?: 'ProductCategory';
              id: string;
              title: string;
              parent?: {
                __typename?: 'ProductCategory';
                id: string;
                title: string;
                parent?: {
                  __typename?: 'ProductCategory';
                  id: string;
                  title: string;
                  parent?: {
                    __typename?: 'ProductCategory';
                    id: string;
                    title: string;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
        };
        price: { __typename?: 'Price'; amount: number };
        tags: Array<{ __typename?: 'ProductTag'; name: string }>;
        colors: Array<{ __typename?: 'ProductColor'; title: string }>;
        image?: { __typename?: 'ProductImage'; url: string } | null;
        size?: { __typename?: 'ProductSize'; label: string } | null;
        outletDiscount?: {
          __typename?: 'OutletDiscount';
          discountPercentage: number;
          preOutletPrice: { __typename?: 'Price'; amount: number };
        } | null;
      }>;
    };
    commentsConnection: {
      __typename?: 'ProductCommentsConnection';
      nodes: Array<{
        __typename?: 'ProductComment';
        id: string;
        sentOn: string;
        body: string;
        sellerIsSender: boolean;
        viewerIsSender: boolean;
        isUnderReview: boolean;
        sender: {
          __typename?: 'User';
          id: string;
          displayName: string;
          profileImage: { __typename?: 'UserImage'; url: string };
        };
        taggedUsers: Array<{
          __typename?: 'User';
          id: string;
          displayName: string;
        }>;
      }>;
      pageInfo: {
        __typename?: 'PageInfo';
        endCursor?: string | null;
        hasNextPage?: boolean | null;
      };
    };
    size?: {
      __typename?: 'ProductSize';
      label: string;
      sizeOptions: Array<{
        __typename?: 'ProductSizeOption';
        id: string;
        title: string;
      }>;
    } | null;
    brand: { __typename?: 'ProductBrand'; name: string };
    price: { __typename?: 'Price'; amount: number };
    tags: Array<{ __typename?: 'ProductTag'; name: string }>;
    colors: Array<{ __typename?: 'ProductColor'; title: string }>;
    outletDiscount?: {
      __typename?: 'OutletDiscount';
      discountPercentage: number;
      preOutletPrice: { __typename?: 'Price'; amount: number };
    } | null;
  } | null;
};

export type ProductCommentsQueryVariables = Exact<{
  productId: Scalars['ID'];
  after?: InputMaybe<Scalars['String']>;
}>;

export type ProductCommentsQuery = {
  __typename?: 'Query';
  product?: {
    __typename?: 'Product';
    commentsConnection: {
      __typename?: 'ProductCommentsConnection';
      nodes: Array<{
        __typename?: 'ProductComment';
        id: string;
        sentOn: string;
        body: string;
        sellerIsSender: boolean;
        viewerIsSender: boolean;
        isUnderReview: boolean;
        sender: {
          __typename?: 'User';
          id: string;
          displayName: string;
          profileImage: { __typename?: 'UserImage'; url: string };
        };
        taggedUsers: Array<{
          __typename?: 'User';
          id: string;
          displayName: string;
        }>;
      }>;
      pageInfo: {
        __typename?: 'PageInfo';
        endCursor?: string | null;
        hasNextPage?: boolean | null;
      };
    };
  } | null;
};

export type ReactivateProductsMutationVariables = Exact<{
  input: ReactivateProductsInput;
}>;

export type ReactivateProductsMutation = {
  __typename?: 'Mutation';
  reactivateProducts?: {
    __typename?: 'ReactivateProductsPayload';
    products: Array<{
      __typename?: 'Product';
      id: string;
      state: ProductState;
      title: string;
      viewerIsSeller: boolean;
      condition: ProductCondition;
      viewerHasFavorited: boolean;
      brand: { __typename?: 'ProductBrand'; name: string };
      category: {
        __typename?: 'ProductCategory';
        id: string;
        title: string;
        parent?: {
          __typename?: 'ProductCategory';
          id: string;
          title: string;
          parent?: {
            __typename?: 'ProductCategory';
            id: string;
            title: string;
            parent?: {
              __typename?: 'ProductCategory';
              id: string;
              title: string;
              parent?: {
                __typename?: 'ProductCategory';
                id: string;
                title: string;
                parent?: {
                  __typename?: 'ProductCategory';
                  id: string;
                  title: string;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      };
      price: { __typename?: 'Price'; amount: number };
      tags: Array<{ __typename?: 'ProductTag'; name: string }>;
      colors: Array<{ __typename?: 'ProductColor'; title: string }>;
      image?: { __typename?: 'ProductImage'; url: string } | null;
      size?: { __typename?: 'ProductSize'; label: string } | null;
      outletDiscount?: {
        __typename?: 'OutletDiscount';
        discountPercentage: number;
        preOutletPrice: { __typename?: 'Price'; amount: number };
      } | null;
    }>;
  } | null;
};

export type ExpiredProductsQueryVariables = Exact<{
  includeKycStatus: Scalars['Boolean'];
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
}>;

export type ExpiredProductsQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Viewer';
    account?: { __typename?: 'Account'; kycStatus: KycStatus };
    expiredProductsConnection: {
      __typename?: 'ProductsConnection';
      nodes: Array<{
        __typename?: 'Product';
        id: string;
        state: ProductState;
        title: string;
        viewerIsSeller: boolean;
        condition: ProductCondition;
        viewerHasFavorited: boolean;
        brand: { __typename?: 'ProductBrand'; name: string };
        category: {
          __typename?: 'ProductCategory';
          id: string;
          title: string;
          parent?: {
            __typename?: 'ProductCategory';
            id: string;
            title: string;
            parent?: {
              __typename?: 'ProductCategory';
              id: string;
              title: string;
              parent?: {
                __typename?: 'ProductCategory';
                id: string;
                title: string;
                parent?: {
                  __typename?: 'ProductCategory';
                  id: string;
                  title: string;
                  parent?: {
                    __typename?: 'ProductCategory';
                    id: string;
                    title: string;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
        };
        price: { __typename?: 'Price'; amount: number };
        tags: Array<{ __typename?: 'ProductTag'; name: string }>;
        colors: Array<{ __typename?: 'ProductColor'; title: string }>;
        image?: { __typename?: 'ProductImage'; url: string } | null;
        size?: { __typename?: 'ProductSize'; label: string } | null;
        outletDiscount?: {
          __typename?: 'OutletDiscount';
          discountPercentage: number;
          preOutletPrice: { __typename?: 'Price'; amount: number };
        } | null;
      }>;
      pageInfo: {
        __typename?: 'PageInfo';
        endCursor?: string | null;
        hasNextPage?: boolean | null;
      };
    };
  } | null;
};

export type SizesCategoriesAccountFragment = {
  __typename?: 'Account';
  sizes: Array<{ __typename?: 'ProductSizeOption'; id: string }>;
  categories: Array<{ __typename?: 'ProductCategory'; id: string }>;
};

export type SettingsAccountFragment = {
  __typename?: 'Account';
  firstName: string;
  lastName: string;
  dateOfBirth?: any | null;
  holidayModeEnabled: boolean;
  kycStatus: KycStatus;
  email: {
    __typename?: 'AccountEmail';
    isVerified: boolean;
    emailAddress: string;
  };
  phoneNumber: {
    __typename?: 'AccountPhoneNumber';
    phoneNumber: string;
    isVerified: boolean;
  };
  user: {
    __typename?: 'User';
    id: string;
    displayName: string;
    country: Country;
    language: Language;
  };
  bankAccounts: Array<{
    __typename?: 'BankAccount';
    id: string;
    accountHolderName: string;
    accountNumberRedacted: string;
    isDefault: boolean;
  }>;
  stripeAccount?: {
    __typename?: 'StripeAccount';
    firstName: string;
    lastName: string;
  } | null;
};

export type BankAccountFragment = {
  __typename?: 'BankAccount';
  id: string;
  accountHolderName: string;
  accountNumberRedacted: string;
  isDefault: boolean;
};

export type UpdatePersonalDetailsMutationVariables = Exact<{
  displayName?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  dateOfBirth: Scalars['Date'];
  includeDateOfBirth: Scalars['Boolean'];
}>;

export type UpdatePersonalDetailsMutation = {
  __typename?: 'Mutation';
  updateName?: {
    __typename?: 'UpdateNamePayload';
    clientMutationId?: string | null;
  } | null;
  updateDateOfBirth?: {
    __typename?: 'UpdateDateOfBirthPayload';
    clientMutationId?: string | null;
  } | null;
};

export type UpdateEmailAddressMutationVariables = Exact<{
  input: UpdateEmailAddressInput;
}>;

export type UpdateEmailAddressMutation = {
  __typename?: 'Mutation';
  updateEmailAddress?: {
    __typename?: 'UpdateEmailAddressPayload';
    account: {
      __typename?: 'Account';
      firstName: string;
      lastName: string;
      dateOfBirth?: any | null;
      holidayModeEnabled: boolean;
      kycStatus: KycStatus;
      email: {
        __typename?: 'AccountEmail';
        isVerified: boolean;
        emailAddress: string;
      };
      phoneNumber: {
        __typename?: 'AccountPhoneNumber';
        phoneNumber: string;
        isVerified: boolean;
      };
      user: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        language: Language;
      };
      bankAccounts: Array<{
        __typename?: 'BankAccount';
        id: string;
        accountHolderName: string;
        accountNumberRedacted: string;
        isDefault: boolean;
      }>;
      stripeAccount?: {
        __typename?: 'StripeAccount';
        firstName: string;
        lastName: string;
      } | null;
    };
  } | null;
};

export type UpdateLocaleMutationVariables = Exact<{
  input: UpdateLocaleInput;
}>;

export type UpdateLocaleMutation = {
  __typename?: 'Mutation';
  updateLocale?: {
    __typename?: 'UpdateLocalePayload';
    account: {
      __typename?: 'Account';
      firstName: string;
      lastName: string;
      dateOfBirth?: any | null;
      holidayModeEnabled: boolean;
      kycStatus: KycStatus;
      email: {
        __typename?: 'AccountEmail';
        isVerified: boolean;
        emailAddress: string;
      };
      phoneNumber: {
        __typename?: 'AccountPhoneNumber';
        phoneNumber: string;
        isVerified: boolean;
      };
      user: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        language: Language;
      };
      bankAccounts: Array<{
        __typename?: 'BankAccount';
        id: string;
        accountHolderName: string;
        accountNumberRedacted: string;
        isDefault: boolean;
      }>;
      stripeAccount?: {
        __typename?: 'StripeAccount';
        firstName: string;
        lastName: string;
      } | null;
    };
  } | null;
};

export type UpdateHolidayModeMutationVariables = Exact<{
  input: UpdateHolidayModeInput;
}>;

export type UpdateHolidayModeMutation = {
  __typename?: 'Mutation';
  updateHolidayMode?: {
    __typename?: 'UpdateHolidayModePayload';
    account: {
      __typename?: 'Account';
      firstName: string;
      lastName: string;
      dateOfBirth?: any | null;
      holidayModeEnabled: boolean;
      kycStatus: KycStatus;
      email: {
        __typename?: 'AccountEmail';
        isVerified: boolean;
        emailAddress: string;
      };
      phoneNumber: {
        __typename?: 'AccountPhoneNumber';
        phoneNumber: string;
        isVerified: boolean;
      };
      user: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        language: Language;
      };
      bankAccounts: Array<{
        __typename?: 'BankAccount';
        id: string;
        accountHolderName: string;
        accountNumberRedacted: string;
        isDefault: boolean;
      }>;
      stripeAccount?: {
        __typename?: 'StripeAccount';
        firstName: string;
        lastName: string;
      } | null;
    };
  } | null;
};

export type UpdatePhoneNumberMutationVariables = Exact<{
  input: UpdatePhoneNumberInput;
}>;

export type UpdatePhoneNumberMutation = {
  __typename?: 'Mutation';
  updatePhoneNumber?: {
    __typename?: 'UpdatePhoneNumberPayload';
    account: {
      __typename?: 'Account';
      firstName: string;
      lastName: string;
      dateOfBirth?: any | null;
      holidayModeEnabled: boolean;
      kycStatus: KycStatus;
      email: {
        __typename?: 'AccountEmail';
        isVerified: boolean;
        emailAddress: string;
      };
      phoneNumber: {
        __typename?: 'AccountPhoneNumber';
        phoneNumber: string;
        isVerified: boolean;
      };
      user: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        language: Language;
      };
      bankAccounts: Array<{
        __typename?: 'BankAccount';
        id: string;
        accountHolderName: string;
        accountNumberRedacted: string;
        isDefault: boolean;
      }>;
      stripeAccount?: {
        __typename?: 'StripeAccount';
        firstName: string;
        lastName: string;
      } | null;
    };
  } | null;
};

export type VerifyPhoneNumberMutationVariables = Exact<{
  input: VerifyPhoneNumberInput;
}>;

export type VerifyPhoneNumberMutation = {
  __typename?: 'Mutation';
  verifyPhoneNumber?: {
    __typename?: 'VerifyPhoneNumberPayload';
    account: {
      __typename?: 'Account';
      firstName: string;
      lastName: string;
      dateOfBirth?: any | null;
      holidayModeEnabled: boolean;
      kycStatus: KycStatus;
      email: {
        __typename?: 'AccountEmail';
        isVerified: boolean;
        emailAddress: string;
      };
      phoneNumber: {
        __typename?: 'AccountPhoneNumber';
        phoneNumber: string;
        isVerified: boolean;
      };
      user: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        language: Language;
      };
      bankAccounts: Array<{
        __typename?: 'BankAccount';
        id: string;
        accountHolderName: string;
        accountNumberRedacted: string;
        isDefault: boolean;
      }>;
      stripeAccount?: {
        __typename?: 'StripeAccount';
        firstName: string;
        lastName: string;
      } | null;
    };
  } | null;
};

export type AddBankAccountMutationVariables = Exact<{
  input: AddBankAccountInput;
}>;

export type AddBankAccountMutation = {
  __typename?: 'Mutation';
  addBankAccount?: {
    __typename?: 'AddBankAccountPayload';
    account: {
      __typename?: 'Account';
      firstName: string;
      lastName: string;
      dateOfBirth?: any | null;
      holidayModeEnabled: boolean;
      kycStatus: KycStatus;
      email: {
        __typename?: 'AccountEmail';
        isVerified: boolean;
        emailAddress: string;
      };
      phoneNumber: {
        __typename?: 'AccountPhoneNumber';
        phoneNumber: string;
        isVerified: boolean;
      };
      user: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        language: Language;
      };
      bankAccounts: Array<{
        __typename?: 'BankAccount';
        id: string;
        accountHolderName: string;
        accountNumberRedacted: string;
        isDefault: boolean;
      }>;
      stripeAccount?: {
        __typename?: 'StripeAccount';
        firstName: string;
        lastName: string;
      } | null;
    };
  } | null;
};

export type DeleteBankAccountMutationVariables = Exact<{
  input: DeleteBankAccountInput;
}>;

export type DeleteBankAccountMutation = {
  __typename?: 'Mutation';
  deleteBankAccount?: {
    __typename?: 'DeleteBankAccountPayload';
    account: {
      __typename?: 'Account';
      firstName: string;
      lastName: string;
      dateOfBirth?: any | null;
      holidayModeEnabled: boolean;
      kycStatus: KycStatus;
      email: {
        __typename?: 'AccountEmail';
        isVerified: boolean;
        emailAddress: string;
      };
      phoneNumber: {
        __typename?: 'AccountPhoneNumber';
        phoneNumber: string;
        isVerified: boolean;
      };
      user: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        language: Language;
      };
      bankAccounts: Array<{
        __typename?: 'BankAccount';
        id: string;
        accountHolderName: string;
        accountNumberRedacted: string;
        isDefault: boolean;
      }>;
      stripeAccount?: {
        __typename?: 'StripeAccount';
        firstName: string;
        lastName: string;
      } | null;
    };
  } | null;
};

export type MarkBankAccountAsDefaultMutationVariables = Exact<{
  input: MarkBankAccountAsDefaultInput;
}>;

export type MarkBankAccountAsDefaultMutation = {
  __typename?: 'Mutation';
  markBankAccountAsDefault?: {
    __typename?: 'MarkBankAccountAsDefaultPayload';
    account: {
      __typename?: 'Account';
      firstName: string;
      lastName: string;
      dateOfBirth?: any | null;
      holidayModeEnabled: boolean;
      kycStatus: KycStatus;
      email: {
        __typename?: 'AccountEmail';
        isVerified: boolean;
        emailAddress: string;
      };
      phoneNumber: {
        __typename?: 'AccountPhoneNumber';
        phoneNumber: string;
        isVerified: boolean;
      };
      user: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        language: Language;
      };
      bankAccounts: Array<{
        __typename?: 'BankAccount';
        id: string;
        accountHolderName: string;
        accountNumberRedacted: string;
        isDefault: boolean;
      }>;
      stripeAccount?: {
        __typename?: 'StripeAccount';
        firstName: string;
        lastName: string;
      } | null;
    };
  } | null;
};

export type DeleteAccountMutationVariables = Exact<{
  input: DeleteAccountInput;
}>;

export type DeleteAccountMutation = {
  __typename?: 'Mutation';
  deleteAccount?: {
    __typename?: 'DeleteAccountPayload';
    clientMutationId?: string | null;
  } | null;
};

export type UpdateAccountSizesMutationVariables = Exact<{
  input: UpdateAccountSizesInput;
}>;

export type UpdateAccountSizesMutation = {
  __typename?: 'Mutation';
  updateAccountSizes?: {
    __typename?: 'UpdateAccountSizesPayload';
    account: {
      __typename?: 'Account';
      sizes: Array<{ __typename?: 'ProductSizeOption'; id: string }>;
      categories: Array<{ __typename?: 'ProductCategory'; id: string }>;
    };
  } | null;
};

export type UpdateAccountCategoriesMutationVariables = Exact<{
  input: UpdateAccountCategoriesInput;
}>;

export type UpdateAccountCategoriesMutation = {
  __typename?: 'Mutation';
  updateAccountCategories?: {
    __typename?: 'UpdateAccountCategoriesPayload';
    account: {
      __typename?: 'Account';
      sizes: Array<{ __typename?: 'ProductSizeOption'; id: string }>;
      categories: Array<{ __typename?: 'ProductCategory'; id: string }>;
    };
  } | null;
};

export type AccountSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type AccountSettingsQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Viewer';
    account: {
      __typename?: 'Account';
      firstName: string;
      lastName: string;
      dateOfBirth?: any | null;
      holidayModeEnabled: boolean;
      kycStatus: KycStatus;
      email: {
        __typename?: 'AccountEmail';
        isVerified: boolean;
        emailAddress: string;
      };
      phoneNumber: {
        __typename?: 'AccountPhoneNumber';
        phoneNumber: string;
        isVerified: boolean;
      };
      user: {
        __typename?: 'User';
        id: string;
        displayName: string;
        country: Country;
        language: Language;
      };
      bankAccounts: Array<{
        __typename?: 'BankAccount';
        id: string;
        accountHolderName: string;
        accountNumberRedacted: string;
        isDefault: boolean;
      }>;
      stripeAccount?: {
        __typename?: 'StripeAccount';
        firstName: string;
        lastName: string;
      } | null;
    };
  } | null;
};

export type AccountSizesCategoriesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AccountSizesCategoriesQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Viewer';
    account: {
      __typename?: 'Account';
      sizes: Array<{ __typename?: 'ProductSizeOption'; id: string }>;
      categories: Array<{ __typename?: 'ProductCategory'; id: string }>;
    };
  } | null;
};

export type UserTodoStateFragment = {
  __typename?: 'User';
  id: string;
  displayName: string;
  favoriteCount: number;
  productCount: number;
  followingCount: number;
  soldProductCount: number;
  profileImage: { __typename?: 'UserImage'; url: string; isDefault: boolean };
  coverImage: { __typename?: 'UserImage'; url: string; isDefault: boolean };
};

export type SellersTodoFragment = {
  __typename?: 'User';
  id: string;
  displayName: string;
  viewerIsFollowing: boolean;
  followerCount: number;
  profileImage: { __typename?: 'UserImage'; url: string };
  rating?: {
    __typename?: 'UserRating';
    rating: number;
    ratingCount: number;
  } | null;
  productsConnection: {
    __typename?: 'ProductsConnection';
    nodes: Array<{
      __typename?: 'Product';
      id: string;
      state: ProductState;
      title: string;
      viewerIsSeller: boolean;
      condition: ProductCondition;
      viewerHasFavorited: boolean;
      brand: { __typename?: 'ProductBrand'; name: string };
      category: {
        __typename?: 'ProductCategory';
        id: string;
        title: string;
        parent?: {
          __typename?: 'ProductCategory';
          id: string;
          title: string;
          parent?: {
            __typename?: 'ProductCategory';
            id: string;
            title: string;
            parent?: {
              __typename?: 'ProductCategory';
              id: string;
              title: string;
              parent?: {
                __typename?: 'ProductCategory';
                id: string;
                title: string;
                parent?: {
                  __typename?: 'ProductCategory';
                  id: string;
                  title: string;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      };
      price: { __typename?: 'Price'; amount: number };
      tags: Array<{ __typename?: 'ProductTag'; name: string }>;
      colors: Array<{ __typename?: 'ProductColor'; title: string }>;
      image?: { __typename?: 'ProductImage'; url: string } | null;
      size?: { __typename?: 'ProductSize'; label: string } | null;
      outletDiscount?: {
        __typename?: 'OutletDiscount';
        discountPercentage: number;
        preOutletPrice: { __typename?: 'Price'; amount: number };
      } | null;
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage?: boolean | null;
      endCursor?: string | null;
    };
  };
};

export type UserTodoQueryVariables = Exact<{ [key: string]: never }>;

export type UserTodoQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Viewer';
    me: {
      __typename?: 'User';
      id: string;
      displayName: string;
      favoriteCount: number;
      productCount: number;
      followingCount: number;
      soldProductCount: number;
      profileImage: {
        __typename?: 'UserImage';
        url: string;
        isDefault: boolean;
      };
      coverImage: { __typename?: 'UserImage'; url: string; isDefault: boolean };
    };
  } | null;
};

export type SellersTodoQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
}>;

export type SellersTodoQuery = {
  __typename?: 'Query';
  usersConnection: {
    __typename?: 'UsersConnection';
    nodes: Array<{
      __typename?: 'User';
      id: string;
      displayName: string;
      viewerIsFollowing: boolean;
      followerCount: number;
      profileImage: { __typename?: 'UserImage'; url: string };
      rating?: {
        __typename?: 'UserRating';
        rating: number;
        ratingCount: number;
      } | null;
      productsConnection: {
        __typename?: 'ProductsConnection';
        nodes: Array<{
          __typename?: 'Product';
          id: string;
          state: ProductState;
          title: string;
          viewerIsSeller: boolean;
          condition: ProductCondition;
          viewerHasFavorited: boolean;
          brand: { __typename?: 'ProductBrand'; name: string };
          category: {
            __typename?: 'ProductCategory';
            id: string;
            title: string;
            parent?: {
              __typename?: 'ProductCategory';
              id: string;
              title: string;
              parent?: {
                __typename?: 'ProductCategory';
                id: string;
                title: string;
                parent?: {
                  __typename?: 'ProductCategory';
                  id: string;
                  title: string;
                  parent?: {
                    __typename?: 'ProductCategory';
                    id: string;
                    title: string;
                    parent?: {
                      __typename?: 'ProductCategory';
                      id: string;
                      title: string;
                    } | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          };
          price: { __typename?: 'Price'; amount: number };
          tags: Array<{ __typename?: 'ProductTag'; name: string }>;
          colors: Array<{ __typename?: 'ProductColor'; title: string }>;
          image?: { __typename?: 'ProductImage'; url: string } | null;
          size?: { __typename?: 'ProductSize'; label: string } | null;
          outletDiscount?: {
            __typename?: 'OutletDiscount';
            discountPercentage: number;
            preOutletPrice: { __typename?: 'Price'; amount: number };
          } | null;
        }>;
        pageInfo: {
          __typename?: 'PageInfo';
          hasNextPage?: boolean | null;
          endCursor?: string | null;
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage?: boolean | null;
      endCursor?: string | null;
    };
  };
};

export type ProductsTodoQueryVariables = Exact<{
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<ProductFilters>;
  orderBy?: InputMaybe<ProductsOrder>;
}>;

export type ProductsTodoQuery = {
  __typename?: 'Query';
  productsConnection: {
    __typename?: 'ProductsConnection';
    nodes: Array<{
      __typename?: 'Product';
      id: string;
      state: ProductState;
      title: string;
      viewerIsSeller: boolean;
      condition: ProductCondition;
      viewerHasFavorited: boolean;
      brand: { __typename?: 'ProductBrand'; name: string };
      category: {
        __typename?: 'ProductCategory';
        id: string;
        title: string;
        parent?: {
          __typename?: 'ProductCategory';
          id: string;
          title: string;
          parent?: {
            __typename?: 'ProductCategory';
            id: string;
            title: string;
            parent?: {
              __typename?: 'ProductCategory';
              id: string;
              title: string;
              parent?: {
                __typename?: 'ProductCategory';
                id: string;
                title: string;
                parent?: {
                  __typename?: 'ProductCategory';
                  id: string;
                  title: string;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      };
      price: { __typename?: 'Price'; amount: number };
      tags: Array<{ __typename?: 'ProductTag'; name: string }>;
      colors: Array<{ __typename?: 'ProductColor'; title: string }>;
      image?: { __typename?: 'ProductImage'; url: string } | null;
      size?: { __typename?: 'ProductSize'; label: string } | null;
      outletDiscount?: {
        __typename?: 'OutletDiscount';
        discountPercentage: number;
        preOutletPrice: { __typename?: 'Price'; amount: number };
      } | null;
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage?: boolean | null;
      endCursor?: string | null;
    };
  };
};

export type ClosetFragment = {
  __typename?: 'User';
  productCount: number;
  favoriteCount: number;
  followerCount: number;
  followingCount: number;
  soldProductCount: number;
  id: string;
  displayName: string;
  country: Country;
  viewerIsFollowing: boolean;
  isViewer: boolean;
  isDeleted: boolean;
  rating?: {
    __typename?: 'UserRating';
    rating: number;
    ratingCount: number;
  } | null;
  profileImage: { __typename?: 'UserImage'; url: string; isDefault: boolean };
  coverImage: { __typename?: 'UserImage'; url: string; isDefault: boolean };
  badge?: {
    __typename?: 'UserBadge';
    title: string;
    foregroundColor: string;
    backgroundColor: string;
    icon: { __typename?: 'Image'; url: string };
  } | null;
  productsConnection: {
    __typename?: 'ProductsConnection';
    nodes: Array<{
      __typename?: 'Product';
      id: string;
      state: ProductState;
      title: string;
      viewerIsSeller: boolean;
      condition: ProductCondition;
      viewerHasFavorited: boolean;
      brand: { __typename?: 'ProductBrand'; name: string };
      category: {
        __typename?: 'ProductCategory';
        id: string;
        title: string;
        parent?: {
          __typename?: 'ProductCategory';
          id: string;
          title: string;
          parent?: {
            __typename?: 'ProductCategory';
            id: string;
            title: string;
            parent?: {
              __typename?: 'ProductCategory';
              id: string;
              title: string;
              parent?: {
                __typename?: 'ProductCategory';
                id: string;
                title: string;
                parent?: {
                  __typename?: 'ProductCategory';
                  id: string;
                  title: string;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      };
      price: { __typename?: 'Price'; amount: number };
      tags: Array<{ __typename?: 'ProductTag'; name: string }>;
      colors: Array<{ __typename?: 'ProductColor'; title: string }>;
      image?: { __typename?: 'ProductImage'; url: string } | null;
      size?: { __typename?: 'ProductSize'; label: string } | null;
      outletDiscount?: {
        __typename?: 'OutletDiscount';
        discountPercentage: number;
        preOutletPrice: { __typename?: 'Price'; amount: number };
      } | null;
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage?: boolean | null;
    };
  };
  favoritesConnection: {
    __typename?: 'ProductsConnection';
    nodes: Array<{
      __typename?: 'Product';
      id: string;
      state: ProductState;
      title: string;
      viewerIsSeller: boolean;
      condition: ProductCondition;
      viewerHasFavorited: boolean;
      brand: { __typename?: 'ProductBrand'; name: string };
      category: {
        __typename?: 'ProductCategory';
        id: string;
        title: string;
        parent?: {
          __typename?: 'ProductCategory';
          id: string;
          title: string;
          parent?: {
            __typename?: 'ProductCategory';
            id: string;
            title: string;
            parent?: {
              __typename?: 'ProductCategory';
              id: string;
              title: string;
              parent?: {
                __typename?: 'ProductCategory';
                id: string;
                title: string;
                parent?: {
                  __typename?: 'ProductCategory';
                  id: string;
                  title: string;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      };
      price: { __typename?: 'Price'; amount: number };
      tags: Array<{ __typename?: 'ProductTag'; name: string }>;
      colors: Array<{ __typename?: 'ProductColor'; title: string }>;
      image?: { __typename?: 'ProductImage'; url: string } | null;
      size?: { __typename?: 'ProductSize'; label: string } | null;
      outletDiscount?: {
        __typename?: 'OutletDiscount';
        discountPercentage: number;
        preOutletPrice: { __typename?: 'Price'; amount: number };
      } | null;
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage?: boolean | null;
    };
  };
  followersConnection: {
    __typename?: 'UsersConnection';
    nodes: Array<{
      __typename?: 'User';
      id: string;
      firstName: string;
      followerCount: number;
      viewerIsFollowing: boolean;
      isVerifiedUser: boolean;
      profileImage: { __typename?: 'UserImage'; url: string };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage?: boolean | null;
    };
  };
  followingConnection: {
    __typename?: 'UsersConnection';
    nodes: Array<{
      __typename?: 'User';
      id: string;
      firstName: string;
      followerCount: number;
      viewerIsFollowing: boolean;
      isVerifiedUser: boolean;
      profileImage: { __typename?: 'UserImage'; url: string };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage?: boolean | null;
    };
  };
};

export type ClosetCoreFragment = {
  __typename?: 'User';
  productCount: number;
  favoriteCount: number;
  followerCount: number;
  followingCount: number;
  soldProductCount: number;
  id: string;
  displayName: string;
  country: Country;
  viewerIsFollowing: boolean;
  isViewer: boolean;
  isDeleted: boolean;
  rating?: {
    __typename?: 'UserRating';
    rating: number;
    ratingCount: number;
  } | null;
  profileImage: { __typename?: 'UserImage'; url: string; isDefault: boolean };
  coverImage: { __typename?: 'UserImage'; url: string; isDefault: boolean };
  badge?: {
    __typename?: 'UserBadge';
    title: string;
    foregroundColor: string;
    backgroundColor: string;
    icon: { __typename?: 'Image'; url: string };
  } | null;
};

export type ClosetProductsConnectionFragment = {
  __typename?: 'User';
  productsConnection: {
    __typename?: 'ProductsConnection';
    nodes: Array<{
      __typename?: 'Product';
      id: string;
      state: ProductState;
      title: string;
      viewerIsSeller: boolean;
      condition: ProductCondition;
      viewerHasFavorited: boolean;
      brand: { __typename?: 'ProductBrand'; name: string };
      category: {
        __typename?: 'ProductCategory';
        id: string;
        title: string;
        parent?: {
          __typename?: 'ProductCategory';
          id: string;
          title: string;
          parent?: {
            __typename?: 'ProductCategory';
            id: string;
            title: string;
            parent?: {
              __typename?: 'ProductCategory';
              id: string;
              title: string;
              parent?: {
                __typename?: 'ProductCategory';
                id: string;
                title: string;
                parent?: {
                  __typename?: 'ProductCategory';
                  id: string;
                  title: string;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      };
      price: { __typename?: 'Price'; amount: number };
      tags: Array<{ __typename?: 'ProductTag'; name: string }>;
      colors: Array<{ __typename?: 'ProductColor'; title: string }>;
      image?: { __typename?: 'ProductImage'; url: string } | null;
      size?: { __typename?: 'ProductSize'; label: string } | null;
      outletDiscount?: {
        __typename?: 'OutletDiscount';
        discountPercentage: number;
        preOutletPrice: { __typename?: 'Price'; amount: number };
      } | null;
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage?: boolean | null;
    };
  };
};

export type ClosetFavoritesConnectionFragment = {
  __typename?: 'User';
  favoritesConnection: {
    __typename?: 'ProductsConnection';
    nodes: Array<{
      __typename?: 'Product';
      id: string;
      state: ProductState;
      title: string;
      viewerIsSeller: boolean;
      condition: ProductCondition;
      viewerHasFavorited: boolean;
      brand: { __typename?: 'ProductBrand'; name: string };
      category: {
        __typename?: 'ProductCategory';
        id: string;
        title: string;
        parent?: {
          __typename?: 'ProductCategory';
          id: string;
          title: string;
          parent?: {
            __typename?: 'ProductCategory';
            id: string;
            title: string;
            parent?: {
              __typename?: 'ProductCategory';
              id: string;
              title: string;
              parent?: {
                __typename?: 'ProductCategory';
                id: string;
                title: string;
                parent?: {
                  __typename?: 'ProductCategory';
                  id: string;
                  title: string;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      };
      price: { __typename?: 'Price'; amount: number };
      tags: Array<{ __typename?: 'ProductTag'; name: string }>;
      colors: Array<{ __typename?: 'ProductColor'; title: string }>;
      image?: { __typename?: 'ProductImage'; url: string } | null;
      size?: { __typename?: 'ProductSize'; label: string } | null;
      outletDiscount?: {
        __typename?: 'OutletDiscount';
        discountPercentage: number;
        preOutletPrice: { __typename?: 'Price'; amount: number };
      } | null;
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage?: boolean | null;
    };
  };
};

export type ClosetFollowersConnectionFragment = {
  __typename?: 'User';
  followersConnection: {
    __typename?: 'UsersConnection';
    nodes: Array<{
      __typename?: 'User';
      id: string;
      firstName: string;
      followerCount: number;
      viewerIsFollowing: boolean;
      isVerifiedUser: boolean;
      profileImage: { __typename?: 'UserImage'; url: string };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage?: boolean | null;
    };
  };
};

export type ClosetFollowingConnectionFragment = {
  __typename?: 'User';
  followingConnection: {
    __typename?: 'UsersConnection';
    nodes: Array<{
      __typename?: 'User';
      id: string;
      firstName: string;
      followerCount: number;
      viewerIsFollowing: boolean;
      isVerifiedUser: boolean;
      profileImage: { __typename?: 'UserImage'; url: string };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage?: boolean | null;
    };
  };
};

export type FollowerFragment = {
  __typename?: 'User';
  id: string;
  firstName: string;
  followerCount: number;
  viewerIsFollowing: boolean;
  isVerifiedUser: boolean;
  profileImage: { __typename?: 'UserImage'; url: string };
};

export type ClosetUserBadgeFragment = {
  __typename?: 'UserBadge';
  title: string;
  foregroundColor: string;
  backgroundColor: string;
  icon: { __typename?: 'Image'; url: string };
};

export type ClosetUserFragment = {
  __typename?: 'User';
  id: string;
  displayName: string;
  country: Country;
  viewerIsFollowing: boolean;
  isViewer: boolean;
  isDeleted: boolean;
  rating?: {
    __typename?: 'UserRating';
    rating: number;
    ratingCount: number;
  } | null;
  profileImage: { __typename?: 'UserImage'; url: string; isDefault: boolean };
  coverImage: { __typename?: 'UserImage'; url: string; isDefault: boolean };
  badge?: {
    __typename?: 'UserBadge';
    title: string;
    foregroundColor: string;
    backgroundColor: string;
    icon: { __typename?: 'Image'; url: string };
  } | null;
};

export type UpdateProfileImageMutationVariables = Exact<{
  input: UpdateProfileImageInput;
}>;

export type UpdateProfileImageMutation = {
  __typename?: 'Mutation';
  updateProfileImage?: {
    __typename?: 'UpdateProfileImagePayload';
    account: {
      __typename?: 'Account';
      user: {
        __typename?: 'User';
        id: string;
        profileImage: { __typename?: 'UserImage'; url: string };
      };
    };
  } | null;
};

export type UpdateCoverImageMutationVariables = Exact<{
  input: UpdateCoverImageInput;
}>;

export type UpdateCoverImageMutation = {
  __typename?: 'Mutation';
  updateCoverImage?: {
    __typename?: 'UpdateCoverImagePayload';
    account: {
      __typename?: 'Account';
      user: {
        __typename?: 'User';
        id: string;
        coverImage: { __typename?: 'UserImage'; url: string };
      };
    };
  } | null;
};

export type UserClosetQueryVariables = Exact<{
  id: Scalars['ID'];
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
  includeProducts: Scalars['Boolean'];
  includeFavorites: Scalars['Boolean'];
  includeFollowers: Scalars['Boolean'];
  includeFollowing: Scalars['Boolean'];
}>;

export type UserClosetQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    productCount: number;
    favoriteCount: number;
    followerCount: number;
    followingCount: number;
    soldProductCount: number;
    id: string;
    displayName: string;
    country: Country;
    viewerIsFollowing: boolean;
    isViewer: boolean;
    isDeleted: boolean;
    rating?: {
      __typename?: 'UserRating';
      rating: number;
      ratingCount: number;
    } | null;
    profileImage: { __typename?: 'UserImage'; url: string; isDefault: boolean };
    coverImage: { __typename?: 'UserImage'; url: string; isDefault: boolean };
    badge?: {
      __typename?: 'UserBadge';
      title: string;
      foregroundColor: string;
      backgroundColor: string;
      icon: { __typename?: 'Image'; url: string };
    } | null;
    productsConnection: {
      __typename?: 'ProductsConnection';
      nodes: Array<{
        __typename?: 'Product';
        id: string;
        state: ProductState;
        title: string;
        viewerIsSeller: boolean;
        condition: ProductCondition;
        viewerHasFavorited: boolean;
        brand: { __typename?: 'ProductBrand'; name: string };
        category: {
          __typename?: 'ProductCategory';
          id: string;
          title: string;
          parent?: {
            __typename?: 'ProductCategory';
            id: string;
            title: string;
            parent?: {
              __typename?: 'ProductCategory';
              id: string;
              title: string;
              parent?: {
                __typename?: 'ProductCategory';
                id: string;
                title: string;
                parent?: {
                  __typename?: 'ProductCategory';
                  id: string;
                  title: string;
                  parent?: {
                    __typename?: 'ProductCategory';
                    id: string;
                    title: string;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
        };
        price: { __typename?: 'Price'; amount: number };
        tags: Array<{ __typename?: 'ProductTag'; name: string }>;
        colors: Array<{ __typename?: 'ProductColor'; title: string }>;
        image?: { __typename?: 'ProductImage'; url: string } | null;
        size?: { __typename?: 'ProductSize'; label: string } | null;
        outletDiscount?: {
          __typename?: 'OutletDiscount';
          discountPercentage: number;
          preOutletPrice: { __typename?: 'Price'; amount: number };
        } | null;
      }>;
      pageInfo: {
        __typename?: 'PageInfo';
        endCursor?: string | null;
        hasNextPage?: boolean | null;
      };
    };
    favoritesConnection: {
      __typename?: 'ProductsConnection';
      nodes: Array<{
        __typename?: 'Product';
        id: string;
        state: ProductState;
        title: string;
        viewerIsSeller: boolean;
        condition: ProductCondition;
        viewerHasFavorited: boolean;
        brand: { __typename?: 'ProductBrand'; name: string };
        category: {
          __typename?: 'ProductCategory';
          id: string;
          title: string;
          parent?: {
            __typename?: 'ProductCategory';
            id: string;
            title: string;
            parent?: {
              __typename?: 'ProductCategory';
              id: string;
              title: string;
              parent?: {
                __typename?: 'ProductCategory';
                id: string;
                title: string;
                parent?: {
                  __typename?: 'ProductCategory';
                  id: string;
                  title: string;
                  parent?: {
                    __typename?: 'ProductCategory';
                    id: string;
                    title: string;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
        };
        price: { __typename?: 'Price'; amount: number };
        tags: Array<{ __typename?: 'ProductTag'; name: string }>;
        colors: Array<{ __typename?: 'ProductColor'; title: string }>;
        image?: { __typename?: 'ProductImage'; url: string } | null;
        size?: { __typename?: 'ProductSize'; label: string } | null;
        outletDiscount?: {
          __typename?: 'OutletDiscount';
          discountPercentage: number;
          preOutletPrice: { __typename?: 'Price'; amount: number };
        } | null;
      }>;
      pageInfo: {
        __typename?: 'PageInfo';
        endCursor?: string | null;
        hasNextPage?: boolean | null;
      };
    };
    followersConnection: {
      __typename?: 'UsersConnection';
      nodes: Array<{
        __typename?: 'User';
        id: string;
        firstName: string;
        followerCount: number;
        viewerIsFollowing: boolean;
        isVerifiedUser: boolean;
        profileImage: { __typename?: 'UserImage'; url: string };
      }>;
      pageInfo: {
        __typename?: 'PageInfo';
        endCursor?: string | null;
        hasNextPage?: boolean | null;
      };
    };
    followingConnection: {
      __typename?: 'UsersConnection';
      nodes: Array<{
        __typename?: 'User';
        id: string;
        firstName: string;
        followerCount: number;
        viewerIsFollowing: boolean;
        isVerifiedUser: boolean;
        profileImage: { __typename?: 'UserImage'; url: string };
      }>;
      pageInfo: {
        __typename?: 'PageInfo';
        endCursor?: string | null;
        hasNextPage?: boolean | null;
      };
    };
  } | null;
};

export type UserClosetCoreDataQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type UserClosetCoreDataQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    productCount: number;
    favoriteCount: number;
    followerCount: number;
    followingCount: number;
    soldProductCount: number;
    id: string;
    displayName: string;
    country: Country;
    viewerIsFollowing: boolean;
    isViewer: boolean;
    isDeleted: boolean;
    rating?: {
      __typename?: 'UserRating';
      rating: number;
      ratingCount: number;
    } | null;
    profileImage: { __typename?: 'UserImage'; url: string; isDefault: boolean };
    coverImage: { __typename?: 'UserImage'; url: string; isDefault: boolean };
    badge?: {
      __typename?: 'UserBadge';
      title: string;
      foregroundColor: string;
      backgroundColor: string;
      icon: { __typename?: 'Image'; url: string };
    } | null;
  } | null;
};

export type ClosetViewerStateQueryVariables = Exact<{ [key: string]: never }>;

export type ClosetViewerStateQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Viewer';
    account: {
      __typename?: 'Account';
      kycStatus: KycStatus;
      defaultBankAccount?: { __typename?: 'BankAccount'; id: string } | null;
      partialAddress?: {
        __typename?: 'PartialAddress';
        id: string;
        name: string;
        postalCode: string;
        city: string;
        country: Country;
        region: { __typename?: 'AddressRegion'; id: string };
      } | null;
      defaultAddress?: { __typename?: 'UserAddress'; id: string } | null;
    };
  } | null;
};

export type ClosetFavoritesQueryVariables = Exact<{
  id: Scalars['ID'];
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
}>;

export type ClosetFavoritesQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    favoritesConnection: {
      __typename?: 'ProductsConnection';
      nodes: Array<{
        __typename?: 'Product';
        id: string;
        state: ProductState;
        title: string;
        viewerIsSeller: boolean;
        condition: ProductCondition;
        viewerHasFavorited: boolean;
        brand: { __typename?: 'ProductBrand'; name: string };
        category: {
          __typename?: 'ProductCategory';
          id: string;
          title: string;
          parent?: {
            __typename?: 'ProductCategory';
            id: string;
            title: string;
            parent?: {
              __typename?: 'ProductCategory';
              id: string;
              title: string;
              parent?: {
                __typename?: 'ProductCategory';
                id: string;
                title: string;
                parent?: {
                  __typename?: 'ProductCategory';
                  id: string;
                  title: string;
                  parent?: {
                    __typename?: 'ProductCategory';
                    id: string;
                    title: string;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
        };
        price: { __typename?: 'Price'; amount: number };
        tags: Array<{ __typename?: 'ProductTag'; name: string }>;
        colors: Array<{ __typename?: 'ProductColor'; title: string }>;
        image?: { __typename?: 'ProductImage'; url: string } | null;
        size?: { __typename?: 'ProductSize'; label: string } | null;
        outletDiscount?: {
          __typename?: 'OutletDiscount';
          discountPercentage: number;
          preOutletPrice: { __typename?: 'Price'; amount: number };
        } | null;
      }>;
      pageInfo: {
        __typename?: 'PageInfo';
        endCursor?: string | null;
        hasNextPage?: boolean | null;
      };
    };
  } | null;
};

export type ClosetFollowersQueryVariables = Exact<{
  id: Scalars['ID'];
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
}>;

export type ClosetFollowersQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    followersConnection: {
      __typename?: 'UsersConnection';
      nodes: Array<{
        __typename?: 'User';
        id: string;
        firstName: string;
        followerCount: number;
        viewerIsFollowing: boolean;
        isVerifiedUser: boolean;
        profileImage: { __typename?: 'UserImage'; url: string };
      }>;
      pageInfo: {
        __typename?: 'PageInfo';
        endCursor?: string | null;
        hasNextPage?: boolean | null;
      };
    };
  } | null;
};

export type ClosetFollowingQueryVariables = Exact<{
  id: Scalars['ID'];
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
}>;

export type ClosetFollowingQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    followingConnection: {
      __typename?: 'UsersConnection';
      nodes: Array<{
        __typename?: 'User';
        id: string;
        firstName: string;
        followerCount: number;
        viewerIsFollowing: boolean;
        isVerifiedUser: boolean;
        profileImage: { __typename?: 'UserImage'; url: string };
      }>;
      pageInfo: {
        __typename?: 'PageInfo';
        endCursor?: string | null;
        hasNextPage?: boolean | null;
      };
    };
  } | null;
};

export type ClosetProductsQueryVariables = Exact<{
  id: Scalars['ID'];
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
}>;

export type ClosetProductsQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    productsConnection: {
      __typename?: 'ProductsConnection';
      nodes: Array<{
        __typename?: 'Product';
        id: string;
        state: ProductState;
        title: string;
        viewerIsSeller: boolean;
        condition: ProductCondition;
        viewerHasFavorited: boolean;
        brand: { __typename?: 'ProductBrand'; name: string };
        category: {
          __typename?: 'ProductCategory';
          id: string;
          title: string;
          parent?: {
            __typename?: 'ProductCategory';
            id: string;
            title: string;
            parent?: {
              __typename?: 'ProductCategory';
              id: string;
              title: string;
              parent?: {
                __typename?: 'ProductCategory';
                id: string;
                title: string;
                parent?: {
                  __typename?: 'ProductCategory';
                  id: string;
                  title: string;
                  parent?: {
                    __typename?: 'ProductCategory';
                    id: string;
                    title: string;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
        };
        price: { __typename?: 'Price'; amount: number };
        tags: Array<{ __typename?: 'ProductTag'; name: string }>;
        colors: Array<{ __typename?: 'ProductColor'; title: string }>;
        image?: { __typename?: 'ProductImage'; url: string } | null;
        size?: { __typename?: 'ProductSize'; label: string } | null;
        outletDiscount?: {
          __typename?: 'OutletDiscount';
          discountPercentage: number;
          preOutletPrice: { __typename?: 'Price'; amount: number };
        } | null;
      }>;
      pageInfo: {
        __typename?: 'PageInfo';
        endCursor?: string | null;
        hasNextPage?: boolean | null;
      };
    };
  } | null;
};

export const AccountEmailFragmentDoc = gql`
  fragment AccountEmail on AccountEmail {
    isVerified
    emailAddress
  }
`;
export const PartialAddressFragmentDoc = gql`
  fragment PartialAddress on PartialAddress {
    id
    name
    postalCode
    city
    country
    region {
      id
    }
  }
`;
export const DeliveryOptionShipperFragmentDoc = gql`
  fragment DeliveryOptionShipper on Shipper {
    id
    title
    sellerDescription
    logo {
      url
    }
    viewerHasEnabled
    isDefault
  }
`;
export const FavoritedByUserFragmentDoc = gql`
  fragment FavoritedByUser on User {
    id
    displayName
    profileImage {
      url
    }
  }
`;
export const CategoryFieldsFragmentDoc = gql`
  fragment CategoryFields on ProductCategory {
    id
    title
    isUnisex
    sizeTypeIds
    enUrlKey: categoryPageUrlKey(language: "en")
    frUrlKey: categoryPageUrlKey(language: "fr")
    parent {
      id
    }
    icon {
      url
    }
  }
`;
export const SizeTypeFragmentDoc = gql`
  fragment SizeType on ProductSizeType {
    id
    title
    options {
      id
      title
    }
  }
`;
export const ColorFragmentDoc = gql`
  fragment Color on ProductColor {
    id
    title
    colors
    isLight
  }
`;
export const ServerCategoryFragmentDoc = gql`
  fragment ServerCategory on ProductCategory {
    id
    frUrlKey: categoryPageUrlKey(language: "fr")
    enUrlKey: categoryPageUrlKey(language: "en")
  }
`;
export const AddProductParcelTypeFragmentDoc = gql`
  fragment AddProductParcelType on ParcelType {
    id
    title
    description
    compactDescription
  }
`;
export const MeFragmentDoc = gql`
  fragment Me on User {
    id
    displayName
    profileImage {
      url
    }
    coverImage {
      url
    }
    language
  }
`;
export const ChatPreviewUserFragmentDoc = gql`
  fragment ChatPreviewUser on User {
    id
    displayName
    rating {
      rating
      ratingCount
    }
    profileImage {
      url
    }
    country
  }
`;
export const BidProductFragmentDoc = gql`
  fragment BidProduct on Product {
    id
    brand {
      name
    }
    image {
      url
    }
    price {
      amount
    }
    hasBid
    hasBiddingEnabled
    highestBidPrice {
      amount
    }
    minimumBidPrice {
      amount
    }
  }
`;
export const FullBidGroupFragmentDoc = gql`
  fragment FullBidGroup on BidGroup {
    id
    seller {
      ...ChatPreviewUser
    }
    buyer {
      ...ChatPreviewUser
    }
    state
    viewerIsSeller
    bids {
      id
      price {
        amount
      }
      expiresOn
      disbandedOn
      acceptedOn
      withdrawnOn
      disbandedOn
      user {
        id
      }
    }
    ... on ProductBidGroup {
      product {
        ...BidProduct
      }
    }
    ... on BundleBidGroup {
      products {
        id
        brand {
          name
        }
        image {
          url
        }
        size {
          label
        }
        price {
          amount
        }
      }
    }
  }
  ${ChatPreviewUserFragmentDoc}
  ${BidProductFragmentDoc}
`;
export const FullBlogTagFragmentDoc = gql`
  fragment FullBlogTag on BlogTag {
    id
    title
    urlKey
    description
    metaTitle
    metaDescription
    featureImage {
      url
    }
  }
`;
export const FeedBlogPostFragmentDoc = gql`
  fragment FeedBlogPost on BlogPost {
    id
    urlKey
    title
    date
    excerpt
    featureImage {
      url
    }
    tags {
      ...FullBlogTag
    }
  }
  ${FullBlogTagFragmentDoc}
`;
export const FullBlogPostFragmentDoc = gql`
  fragment FullBlogPost on BlogPost {
    id
    urlKey
    title
    content
    metaTitle
    metaDescription
    date
    tags {
      ...FullBlogTag
      postsConnection(first: 3) {
        nodes {
          ...FeedBlogPost
        }
      }
    }
    featureImage {
      url
    }
    excerpt
    noIndex
  }
  ${FullBlogTagFragmentDoc}
  ${FeedBlogPostFragmentDoc}
`;
export const FullServicePointFragmentDoc = gql`
  fragment FullServicePoint on ServicePoint {
    externalId
    name
    line1
    postalCode
    city
    country
    regionCode
    address(compact: true)
  }
`;
export const CartProductFragmentDoc = gql`
  fragment CartProduct on Product {
    id
    image {
      url
    }
    brand {
      name
    }
    state
    price {
      amount
    }
    outletDiscount {
      preOutletPrice {
        amount
      }
      discountPercentage
    }
    size {
      sizeOptions {
        title
      }
    }
  }
`;
export const CartFragmentDoc = gql`
  fragment Cart on ShoppingCart {
    id
    groups {
      id
      items {
        ... on Product {
          ...CartProduct
        }
        ... on ProductBidGroup {
          bids(last: 1) {
            price {
              amount
            }
          }
          product {
            ...CartProduct
          }
        }
        ... on BundleBidGroup {
          bids(last: 1) {
            price {
              amount
            }
          }
          image {
            url
          }
          products {
            id
          }
        }
      }
    }
    prices {
      subtotal {
        amount
      }
    }
  }
  ${CartProductFragmentDoc}
`;
export const FullParcelPriceFragmentDoc = gql`
  fragment FullParcelPrice on ParcelPrice {
    ... on Price {
      amount
    }
    ... on PriceRange {
      minPrice {
        amount
      }
      maxPrice {
        amount
      }
    }
  }
`;
export const FullUserAddressFragmentDoc = gql`
  fragment FullUserAddress on UserAddress {
    id
    name
    line1
    line2
    postalCode
    city
    country
    isDefault
    address(compact: true)
    region {
      id
    }
  }
`;
export const CheckoutCartGroupFragmentDoc = gql`
  fragment CheckoutCartGroup on ShoppingCartGroup {
    id
    availableParcels {
      id
      price {
        ...FullParcelPrice
      }
      shipper {
        id
        logo {
          url
        }
        title
      }
      shippingMethod
    }
    parcel {
      id
    }
    deliveryType
    shipmentDestination {
      ... on UserAddress {
        ...FullUserAddress
      }
      ... on ShipmentServicePoint {
        servicePoint {
          name
          address(compact: true)
        }
      }
    }
    items {
      ... on Product {
        ...CartProduct
      }
      ... on ProductBidGroup {
        bids(last: 1) {
          price {
            amount
          }
        }
        product {
          ...CartProduct
        }
      }
      ... on BundleBidGroup {
        bids(last: 1) {
          price {
            amount
          }
        }
        image {
          url
        }
        products {
          id
        }
      }
    }
  }
  ${FullParcelPriceFragmentDoc}
  ${FullUserAddressFragmentDoc}
  ${CartProductFragmentDoc}
`;
export const CheckoutCartFragmentDoc = gql`
  fragment CheckoutCart on ShoppingCart {
    ...Cart
    id
    groups {
      ...CheckoutCartGroup
    }
    paymentMethods {
      id
      label
      icon(variant: DEFAULT) {
        url
      }
      type
    }
    creditSummary {
      credit {
        amount
      }
      available
      unavailableReasons
    }
    prices {
      total {
        amount
      }
      priceBreakdown {
        title
        price {
          amount
        }
        pricePlaceholder
        ... on DescriptionCartPriceBreakdownItem {
          description
        }
      }
    }
  }
  ${CartFragmentDoc}
  ${CheckoutCartGroupFragmentDoc}
`;
export const FullEmailSubscriptionFragmentDoc = gql`
  fragment FullEmailSubscription on EmailSubscription {
    id
    title
    viewerHasEnabled
  }
`;
export const ListProductCategoryFragmentDoc = gql`
  fragment ListProductCategory on ProductCategory {
    id
    title
  }
`;
export const FullProductTagFragmentDoc = gql`
  fragment FullProductTag on ProductTag {
    name
  }
`;
export const ProductSimplifiedContentFragmentDoc = gql`
  fragment ProductSimplifiedContent on Product {
    id
    title
    brand {
      name
    }
    viewerIsSeller
    category {
      ...ListProductCategory
      parent {
        ...ListProductCategory
        parent {
          ...ListProductCategory
          parent {
            ...ListProductCategory
            parent {
              ...ListProductCategory
              parent {
                ...ListProductCategory
              }
            }
          }
        }
      }
    }
    price {
      amount
    }
    tags {
      ...FullProductTag
    }
    colors {
      title
    }
    image(variant: LEGACY_LARGE) {
      url
    }
    size {
      label
    }
    condition
  }
  ${ListProductCategoryFragmentDoc}
  ${FullProductTagFragmentDoc}
`;
export const HtmlFeedItemFragmentDoc = gql`
  fragment HTMLFeedItem on HTMLFeedItem {
    id
    campaignId
    html
  }
`;
export const AlternatingContentItemFragmentDoc = gql`
  fragment AlternatingContentItem on AlternatingContentItem {
    id
    position
    item {
      ... on HTMLFeedItem {
        ...HTMLFeedItem
      }
    }
  }
  ${HtmlFeedItemFragmentDoc}
`;
export const NotificationFragmentDoc = gql`
  fragment Notification on Notification {
    id
    image {
      url
    }
    sentOn
    title
    message
    url
  }
`;
export const ChatMessageFragmentDoc = gql`
  fragment ChatMessage on ChatMessage {
    id
    sender {
      id
      displayName
      profileImage {
        url
      }
    }
    body
    sentOn
    viewerIsSender
  }
`;
export const ChatFragmentDoc = gql`
  fragment Chat on Chat {
    id
    user {
      id
      displayName
      profileImage {
        url
      }
    }
    unreadCount
    messagesConnection(first: 1) {
      nodes {
        ...ChatMessage
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${ChatMessageFragmentDoc}
`;
export const UserSearchSuggestionFragmentDoc = gql`
  fragment UserSearchSuggestion on User {
    id
    displayName
    profileImage {
      url
    }
    viewerIsFollowing
    followerCount
  }
`;
export const RatingFragmentDoc = gql`
  fragment Rating on OrderRating {
    rating
    review
    ratedOn
    ratedBy {
      id
      displayName
      followerCount
      profileImage {
        url
      }
    }
  }
`;
export const FullOrderFragmentDoc = gql`
  fragment FullOrder on Order {
    id
    orderReference
    state
    viewerIsSeller
    deliveryType
    createdOn
    createCancellationRequestState
    cancellationRequestReasons {
      id
      title
    }
    cancellationRequest {
      id
      viewerCanWithdraw
      reason {
        id
        title
      }
      history {
        id
        state
        viewerInitiatedEvent
        explanation
        initiatedOn
        user {
          id
          displayName
          profileImage {
            url
          }
        }
      }
    }
    orderRating {
      ...Rating
    }
    payment {
      value {
        amount
      }
    }
    returnRequest {
      id
      state
      viewerCanCancel
      reason
      returnAddress {
        name
        address(compact: false)
      }
      history {
        id
        state
        initiatedOn
        comment
        ... on UserReturnRequestEvent {
          user {
            id
            profileImage {
              url
            }
            displayName
          }
          attachments {
            url
          }
        }
      }
    }
    shipment {
      costs {
        amount
      }
      tracking {
        trackingCode
        url
      }
      parcel {
        shippingInstructions
        shippingMethod
        shipper {
          id
          title
          logo {
            url
          }
        }
      }
      destination {
        ... on UserAddress {
          name
          address(compact: false)
        }
        ... on ShipmentServicePoint {
          userFullName
          servicePoint {
            name
          }
        }
      }
      ... on LabelOrderShipment {
        label {
          status
          shippingLabel {
            id
            url
          }
        }
      }
    }
    seller {
      ...ChatPreviewUser
    }
    buyer {
      ...ChatPreviewUser
    }
    cartGroup {
      items {
        ... on Product {
          id
          size {
            label
          }
          price {
            amount
          }
          image {
            url
          }
          brand {
            name
          }
        }
        ... on ProductBidGroup {
          bids(last: 1) {
            price {
              amount
            }
          }
          product {
            id
            size {
              label
            }
            image {
              url
            }
            brand {
              name
            }
          }
        }
        ... on BundleBidGroup {
          bids(last: 1) {
            price {
              amount
            }
          }
          products {
            id
            image {
              url
            }
          }
        }
      }
    }
  }
  ${RatingFragmentDoc}
  ${ChatPreviewUserFragmentDoc}
`;
export const ListOrderFragmentDoc = gql`
  fragment ListOrder on Order {
    id
    orderReference
    state
    createdOn
    cartGroup {
      items {
        ... on Product {
          id
          image {
            url
          }
        }
        ... on ProductBidGroup {
          product {
            id
            image {
              url
            }
          }
        }
        ... on BundleBidGroup {
          products {
            id
            image {
              url
            }
          }
        }
      }
    }
  }
`;
export const ListProductFragmentDoc = gql`
  fragment ListProduct on Product {
    id
    state
    title
    brand {
      name
    }
    viewerIsSeller
    category {
      ...ListProductCategory
      parent {
        ...ListProductCategory
        parent {
          ...ListProductCategory
          parent {
            ...ListProductCategory
            parent {
              ...ListProductCategory
              parent {
                ...ListProductCategory
              }
            }
          }
        }
      }
    }
    price {
      amount
    }
    tags {
      ...FullProductTag
    }
    colors {
      title
    }
    image(variant: LEGACY_LARGE) {
      url
    }
    size {
      label
    }
    condition
    outletDiscount {
      preOutletPrice {
        amount
      }
      discountPercentage
    }
    viewerHasFavorited
  }
  ${ListProductCategoryFragmentDoc}
  ${FullProductTagFragmentDoc}
`;
export const ProductImageFragmentDoc = gql`
  fragment ProductImage on ProductImage {
    url
  }
`;
export const ProductParcelFragmentDoc = gql`
  fragment ProductParcel on Parcel {
    id
    price {
      ...FullParcelPrice
    }
    shipper {
      id
      title
      buyerDescription
      logo {
        url
      }
    }
  }
  ${FullParcelPriceFragmentDoc}
`;
export const ProductSellerFragmentDoc = gql`
  fragment ProductSeller on User {
    id
    displayName
    rating {
      rating
      ratingCount
    }
    followerCount
    viewerIsFollowing
    profileImage {
      url
    }
    productsConnection(first: 4) {
      nodes {
        ...ListProduct
      }
    }
  }
  ${ListProductFragmentDoc}
`;
export const ProductCommentFragmentDoc = gql`
  fragment ProductComment on ProductComment {
    id
    sender {
      id
      displayName
      profileImage {
        url
      }
    }
    sentOn
    taggedUsers {
      id
      displayName
    }
    body
    sellerIsSender
    viewerIsSender
    isUnderReview
  }
`;
export const ProductFragmentDoc = gql`
  fragment Product on Product {
    ...ListProduct
    title
    category {
      id
    }
    description
    condition
    thumbnails: images(variant: LEGACY_SMALL) {
      ...ProductImage
    }
    image(variant: LEGACY_LARGE) {
      url
    }
    images(variant: LEGACY_LARGER) {
      ...ProductImage
    }
    fullImages: images(variant: LEGACY_XLARGE) {
      ...ProductImage
    }
    viewerIsSeller
    viewerHasInCart
    bidding {
      hasBid
      highestBid {
        amount
      }
      minimumBid {
        amount
      }
    }
    hasBid
    hasBiddingEnabled
    highestBidPrice {
      amount
    }
    minimumBidPrice {
      amount
    }
    availableParcels {
      ...ProductParcel
    }
    seller {
      ...ProductSeller
    }
    relatedProductsConnection(first: 4) {
      nodes {
        ...ListProduct
      }
    }
    viewCount
    favoriteCount
    commentsConnection(first: 5) {
      nodes {
        ...ProductComment
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    size {
      label
      sizeOptions {
        id
        title
      }
    }
  }
  ${ListProductFragmentDoc}
  ${ProductImageFragmentDoc}
  ${ProductParcelFragmentDoc}
  ${ProductSellerFragmentDoc}
  ${ProductCommentFragmentDoc}
`;
export const SizesCategoriesAccountFragmentDoc = gql`
  fragment SizesCategoriesAccount on Account {
    sizes {
      id
    }
    categories {
      id
    }
  }
`;
export const BankAccountFragmentDoc = gql`
  fragment BankAccount on BankAccount {
    id
    accountHolderName
    accountNumberRedacted
    isDefault
  }
`;
export const SettingsAccountFragmentDoc = gql`
  fragment SettingsAccount on Account {
    firstName
    lastName
    dateOfBirth
    email {
      isVerified
      emailAddress
    }
    phoneNumber {
      phoneNumber
      isVerified
    }
    holidayModeEnabled
    user {
      id
      displayName
      country
      language
    }
    bankAccounts {
      ...BankAccount
    }
    stripeAccount {
      firstName
      lastName
    }
    kycStatus
  }
  ${BankAccountFragmentDoc}
`;
export const UserTodoStateFragmentDoc = gql`
  fragment UserTodoState on User {
    id
    displayName
    profileImage {
      url
      isDefault
    }
    coverImage {
      url
      isDefault
    }
    favoriteCount
    productCount
    followingCount
    soldProductCount
  }
`;
export const SellersTodoFragmentDoc = gql`
  fragment SellersTodo on User {
    id
    displayName
    profileImage {
      url
    }
    rating {
      rating
      ratingCount
    }
    viewerIsFollowing
    followerCount
    productsConnection(first: 20) {
      nodes {
        ...ListProduct
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${ListProductFragmentDoc}
`;
export const ClosetUserBadgeFragmentDoc = gql`
  fragment ClosetUserBadge on UserBadge {
    title
    foregroundColor
    backgroundColor
    icon {
      url
    }
  }
`;
export const ClosetUserFragmentDoc = gql`
  fragment ClosetUser on User {
    id
    displayName
    country
    rating {
      rating
      ratingCount
    }
    profileImage {
      url
      isDefault
    }
    coverImage {
      url
      isDefault
    }
    viewerIsFollowing
    badge {
      ...ClosetUserBadge
    }
    isViewer
    isDeleted
  }
  ${ClosetUserBadgeFragmentDoc}
`;
export const ClosetProductsConnectionFragmentDoc = gql`
  fragment ClosetProductsConnection on User {
    productsConnection(first: $first, after: $after) {
      nodes {
        ...ListProduct
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${ListProductFragmentDoc}
`;
export const ClosetFavoritesConnectionFragmentDoc = gql`
  fragment ClosetFavoritesConnection on User {
    favoritesConnection(first: $first, after: $after) {
      nodes {
        ...ListProduct
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${ListProductFragmentDoc}
`;
export const FollowerFragmentDoc = gql`
  fragment Follower on User {
    id
    firstName
    followerCount
    viewerIsFollowing
    profileImage {
      url
    }
    isVerifiedUser
  }
`;
export const ClosetFollowersConnectionFragmentDoc = gql`
  fragment ClosetFollowersConnection on User {
    followersConnection(first: $first, after: $after) {
      nodes {
        ...Follower
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${FollowerFragmentDoc}
`;
export const ClosetFollowingConnectionFragmentDoc = gql`
  fragment ClosetFollowingConnection on User {
    followingConnection(first: $first, after: $after) {
      nodes {
        ...Follower
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${FollowerFragmentDoc}
`;
export const ClosetFragmentDoc = gql`
  fragment Closet on User {
    ...ClosetUser
    productCount
    favoriteCount
    followerCount
    followingCount
    soldProductCount
    ...ClosetProductsConnection @include(if: $includeProducts)
    ...ClosetFavoritesConnection @include(if: $includeFavorites)
    ...ClosetFollowersConnection @include(if: $includeFollowers)
    ...ClosetFollowingConnection @include(if: $includeFollowing)
  }
  ${ClosetUserFragmentDoc}
  ${ClosetProductsConnectionFragmentDoc}
  ${ClosetFavoritesConnectionFragmentDoc}
  ${ClosetFollowersConnectionFragmentDoc}
  ${ClosetFollowingConnectionFragmentDoc}
`;
export const ClosetCoreFragmentDoc = gql`
  fragment ClosetCore on User {
    ...ClosetUser
    productCount
    favoriteCount
    followerCount
    followingCount
    soldProductCount
  }
  ${ClosetUserFragmentDoc}
`;
export const AccountEmailDocument = gql`
  query AccountEmail {
    viewer {
      account {
        email {
          ...AccountEmail
        }
      }
    }
  }
  ${AccountEmailFragmentDoc}
`;
export type AccountEmailComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AccountEmailQuery,
    AccountEmailQueryVariables
  >,
  'query'
>;

export const AccountEmailComponent = (props: AccountEmailComponentProps) => (
  <ApolloReactComponents.Query<AccountEmailQuery, AccountEmailQueryVariables>
    query={AccountEmailDocument}
    {...props}
  />
);

/**
 * __useAccountEmailQuery__
 *
 * To run a query within a React component, call `useAccountEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountEmailQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountEmailQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AccountEmailQuery,
    AccountEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountEmailQuery, AccountEmailQueryVariables>(
    AccountEmailDocument,
    options
  );
}
export function useAccountEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountEmailQuery,
    AccountEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountEmailQuery, AccountEmailQueryVariables>(
    AccountEmailDocument,
    options
  );
}
export type AccountEmailQueryHookResult = ReturnType<
  typeof useAccountEmailQuery
>;
export type AccountEmailLazyQueryHookResult = ReturnType<
  typeof useAccountEmailLazyQuery
>;
export type AccountEmailQueryResult = Apollo.QueryResult<
  AccountEmailQuery,
  AccountEmailQueryVariables
>;
export const ResendActivationEmailDocument = gql`
  mutation ResendActivationEmail($input: UpdateEmailAddressInput!) {
    updateEmailAddress(input: $input) {
      account {
        email {
          ...AccountEmail
        }
      }
    }
  }
  ${AccountEmailFragmentDoc}
`;
export type ResendActivationEmailMutationFn = Apollo.MutationFunction<
  ResendActivationEmailMutation,
  ResendActivationEmailMutationVariables
>;
export type ResendActivationEmailComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ResendActivationEmailMutation,
    ResendActivationEmailMutationVariables
  >,
  'mutation'
>;

export const ResendActivationEmailComponent = (
  props: ResendActivationEmailComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ResendActivationEmailMutation,
    ResendActivationEmailMutationVariables
  >
    mutation={ResendActivationEmailDocument}
    {...props}
  />
);

/**
 * __useResendActivationEmailMutation__
 *
 * To run a mutation, you first call `useResendActivationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendActivationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendActivationEmailMutation, { data, loading, error }] = useResendActivationEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendActivationEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendActivationEmailMutation,
    ResendActivationEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResendActivationEmailMutation,
    ResendActivationEmailMutationVariables
  >(ResendActivationEmailDocument, options);
}
export type ResendActivationEmailMutationHookResult = ReturnType<
  typeof useResendActivationEmailMutation
>;
export type ResendActivationEmailMutationResult =
  Apollo.MutationResult<ResendActivationEmailMutation>;
export type ResendActivationEmailMutationOptions = Apollo.BaseMutationOptions<
  ResendActivationEmailMutation,
  ResendActivationEmailMutationVariables
>;
export const AddressesDocument = gql`
  query Addresses($includeAddresses: Boolean!) {
    viewer {
      account {
        firstName
        lastName
        addresses @include(if: $includeAddresses) {
          ...FullUserAddress
        }
        partialAddress {
          ...PartialAddress
        }
      }
    }
    addressRegions {
      id
      country
      code
      name
    }
  }
  ${FullUserAddressFragmentDoc}
  ${PartialAddressFragmentDoc}
`;
export type AddressesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AddressesQuery,
    AddressesQueryVariables
  >,
  'query'
> &
  ({ variables: AddressesQueryVariables; skip?: boolean } | { skip: boolean });

export const AddressesComponent = (props: AddressesComponentProps) => (
  <ApolloReactComponents.Query<AddressesQuery, AddressesQueryVariables>
    query={AddressesDocument}
    {...props}
  />
);

/**
 * __useAddressesQuery__
 *
 * To run a query within a React component, call `useAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressesQuery({
 *   variables: {
 *      includeAddresses: // value for 'includeAddresses'
 *   },
 * });
 */
export function useAddressesQuery(
  baseOptions: Apollo.QueryHookOptions<AddressesQuery, AddressesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AddressesQuery, AddressesQueryVariables>(
    AddressesDocument,
    options
  );
}
export function useAddressesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AddressesQuery,
    AddressesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AddressesQuery, AddressesQueryVariables>(
    AddressesDocument,
    options
  );
}
export type AddressesQueryHookResult = ReturnType<typeof useAddressesQuery>;
export type AddressesLazyQueryHookResult = ReturnType<
  typeof useAddressesLazyQuery
>;
export type AddressesQueryResult = Apollo.QueryResult<
  AddressesQuery,
  AddressesQueryVariables
>;
export const AddUserAddressDocument = gql`
  mutation AddUserAddress($input: AddUserAddressInput!) {
    addUserAddress(input: $input) {
      account {
        addresses {
          ...FullUserAddress
        }
        defaultAddress {
          ...FullUserAddress
        }
      }
      address {
        ...FullUserAddress
      }
    }
  }
  ${FullUserAddressFragmentDoc}
`;
export type AddUserAddressMutationFn = Apollo.MutationFunction<
  AddUserAddressMutation,
  AddUserAddressMutationVariables
>;
export type AddUserAddressComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AddUserAddressMutation,
    AddUserAddressMutationVariables
  >,
  'mutation'
>;

export const AddUserAddressComponent = (
  props: AddUserAddressComponentProps
) => (
  <ApolloReactComponents.Mutation<
    AddUserAddressMutation,
    AddUserAddressMutationVariables
  >
    mutation={AddUserAddressDocument}
    {...props}
  />
);

/**
 * __useAddUserAddressMutation__
 *
 * To run a mutation, you first call `useAddUserAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserAddressMutation, { data, loading, error }] = useAddUserAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddUserAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUserAddressMutation,
    AddUserAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddUserAddressMutation,
    AddUserAddressMutationVariables
  >(AddUserAddressDocument, options);
}
export type AddUserAddressMutationHookResult = ReturnType<
  typeof useAddUserAddressMutation
>;
export type AddUserAddressMutationResult =
  Apollo.MutationResult<AddUserAddressMutation>;
export type AddUserAddressMutationOptions = Apollo.BaseMutationOptions<
  AddUserAddressMutation,
  AddUserAddressMutationVariables
>;
export const UpdateUserAddressDocument = gql`
  mutation UpdateUserAddress($input: UpdateUserAddressInput!) {
    updateUserAddress(input: $input) {
      account {
        addresses {
          ...FullUserAddress
        }
        defaultAddress {
          ...FullUserAddress
        }
      }
      address {
        ...FullUserAddress
      }
    }
  }
  ${FullUserAddressFragmentDoc}
`;
export type UpdateUserAddressMutationFn = Apollo.MutationFunction<
  UpdateUserAddressMutation,
  UpdateUserAddressMutationVariables
>;
export type UpdateUserAddressComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateUserAddressMutation,
    UpdateUserAddressMutationVariables
  >,
  'mutation'
>;

export const UpdateUserAddressComponent = (
  props: UpdateUserAddressComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateUserAddressMutation,
    UpdateUserAddressMutationVariables
  >
    mutation={UpdateUserAddressDocument}
    {...props}
  />
);

/**
 * __useUpdateUserAddressMutation__
 *
 * To run a mutation, you first call `useUpdateUserAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAddressMutation, { data, loading, error }] = useUpdateUserAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserAddressMutation,
    UpdateUserAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserAddressMutation,
    UpdateUserAddressMutationVariables
  >(UpdateUserAddressDocument, options);
}
export type UpdateUserAddressMutationHookResult = ReturnType<
  typeof useUpdateUserAddressMutation
>;
export type UpdateUserAddressMutationResult =
  Apollo.MutationResult<UpdateUserAddressMutation>;
export type UpdateUserAddressMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserAddressMutation,
  UpdateUserAddressMutationVariables
>;
export const DeleteUserAddressDocument = gql`
  mutation DeleteUserAddress($input: DeleteUserAddressInput!) {
    deleteUserAddress(input: $input) {
      account {
        addresses {
          ...FullUserAddress
        }
        defaultAddress {
          ...FullUserAddress
        }
      }
    }
  }
  ${FullUserAddressFragmentDoc}
`;
export type DeleteUserAddressMutationFn = Apollo.MutationFunction<
  DeleteUserAddressMutation,
  DeleteUserAddressMutationVariables
>;
export type DeleteUserAddressComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteUserAddressMutation,
    DeleteUserAddressMutationVariables
  >,
  'mutation'
>;

export const DeleteUserAddressComponent = (
  props: DeleteUserAddressComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeleteUserAddressMutation,
    DeleteUserAddressMutationVariables
  >
    mutation={DeleteUserAddressDocument}
    {...props}
  />
);

/**
 * __useDeleteUserAddressMutation__
 *
 * To run a mutation, you first call `useDeleteUserAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserAddressMutation, { data, loading, error }] = useDeleteUserAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserAddressMutation,
    DeleteUserAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteUserAddressMutation,
    DeleteUserAddressMutationVariables
  >(DeleteUserAddressDocument, options);
}
export type DeleteUserAddressMutationHookResult = ReturnType<
  typeof useDeleteUserAddressMutation
>;
export type DeleteUserAddressMutationResult =
  Apollo.MutationResult<DeleteUserAddressMutation>;
export type DeleteUserAddressMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserAddressMutation,
  DeleteUserAddressMutationVariables
>;
export const ShippersDocument = gql`
  query Shippers {
    shippers {
      ...DeliveryOptionShipper
    }
  }
  ${DeliveryOptionShipperFragmentDoc}
`;
export type ShippersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ShippersQuery,
    ShippersQueryVariables
  >,
  'query'
>;

export const ShippersComponent = (props: ShippersComponentProps) => (
  <ApolloReactComponents.Query<ShippersQuery, ShippersQueryVariables>
    query={ShippersDocument}
    {...props}
  />
);

/**
 * __useShippersQuery__
 *
 * To run a query within a React component, call `useShippersQuery` and pass it any options that fit your needs.
 * When your component renders, `useShippersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShippersQuery({
 *   variables: {
 *   },
 * });
 */
export function useShippersQuery(
  baseOptions?: Apollo.QueryHookOptions<ShippersQuery, ShippersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ShippersQuery, ShippersQueryVariables>(
    ShippersDocument,
    options
  );
}
export function useShippersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ShippersQuery,
    ShippersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ShippersQuery, ShippersQueryVariables>(
    ShippersDocument,
    options
  );
}
export type ShippersQueryHookResult = ReturnType<typeof useShippersQuery>;
export type ShippersLazyQueryHookResult = ReturnType<
  typeof useShippersLazyQuery
>;
export type ShippersQueryResult = Apollo.QueryResult<
  ShippersQuery,
  ShippersQueryVariables
>;
export const DisableShipperServicePointsDocument = gql`
  mutation DisableShipperServicePoints(
    $input: DisableShipperServicePointsInput!
  ) {
    disableShipperServicePoints(input: $input) {
      shipper {
        ...DeliveryOptionShipper
      }
    }
  }
  ${DeliveryOptionShipperFragmentDoc}
`;
export type DisableShipperServicePointsMutationFn = Apollo.MutationFunction<
  DisableShipperServicePointsMutation,
  DisableShipperServicePointsMutationVariables
>;
export type DisableShipperServicePointsComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DisableShipperServicePointsMutation,
    DisableShipperServicePointsMutationVariables
  >,
  'mutation'
>;

export const DisableShipperServicePointsComponent = (
  props: DisableShipperServicePointsComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DisableShipperServicePointsMutation,
    DisableShipperServicePointsMutationVariables
  >
    mutation={DisableShipperServicePointsDocument}
    {...props}
  />
);

/**
 * __useDisableShipperServicePointsMutation__
 *
 * To run a mutation, you first call `useDisableShipperServicePointsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableShipperServicePointsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableShipperServicePointsMutation, { data, loading, error }] = useDisableShipperServicePointsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisableShipperServicePointsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisableShipperServicePointsMutation,
    DisableShipperServicePointsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DisableShipperServicePointsMutation,
    DisableShipperServicePointsMutationVariables
  >(DisableShipperServicePointsDocument, options);
}
export type DisableShipperServicePointsMutationHookResult = ReturnType<
  typeof useDisableShipperServicePointsMutation
>;
export type DisableShipperServicePointsMutationResult =
  Apollo.MutationResult<DisableShipperServicePointsMutation>;
export type DisableShipperServicePointsMutationOptions =
  Apollo.BaseMutationOptions<
    DisableShipperServicePointsMutation,
    DisableShipperServicePointsMutationVariables
  >;
export const EnableShipperServicePointsDocument = gql`
  mutation EnableShipperServicePoints(
    $input: EnableShipperServicePointsInput!
  ) {
    enableShipperServicePoints(input: $input) {
      shipper {
        ...DeliveryOptionShipper
      }
    }
  }
  ${DeliveryOptionShipperFragmentDoc}
`;
export type EnableShipperServicePointsMutationFn = Apollo.MutationFunction<
  EnableShipperServicePointsMutation,
  EnableShipperServicePointsMutationVariables
>;
export type EnableShipperServicePointsComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    EnableShipperServicePointsMutation,
    EnableShipperServicePointsMutationVariables
  >,
  'mutation'
>;

export const EnableShipperServicePointsComponent = (
  props: EnableShipperServicePointsComponentProps
) => (
  <ApolloReactComponents.Mutation<
    EnableShipperServicePointsMutation,
    EnableShipperServicePointsMutationVariables
  >
    mutation={EnableShipperServicePointsDocument}
    {...props}
  />
);

/**
 * __useEnableShipperServicePointsMutation__
 *
 * To run a mutation, you first call `useEnableShipperServicePointsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableShipperServicePointsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableShipperServicePointsMutation, { data, loading, error }] = useEnableShipperServicePointsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnableShipperServicePointsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EnableShipperServicePointsMutation,
    EnableShipperServicePointsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EnableShipperServicePointsMutation,
    EnableShipperServicePointsMutationVariables
  >(EnableShipperServicePointsDocument, options);
}
export type EnableShipperServicePointsMutationHookResult = ReturnType<
  typeof useEnableShipperServicePointsMutation
>;
export type EnableShipperServicePointsMutationResult =
  Apollo.MutationResult<EnableShipperServicePointsMutation>;
export type EnableShipperServicePointsMutationOptions =
  Apollo.BaseMutationOptions<
    EnableShipperServicePointsMutation,
    EnableShipperServicePointsMutationVariables
  >;
export const SellerFavoritesDocument = gql`
  query SellerFavorites($productId: ID!, $first: Int!, $after: String) {
    product(id: $productId) {
      id
      recentFavorites {
        ...FavoritedByUser
      }
      price {
        amount
      }
      minimumBidPrice {
        amount
      }
      previousFavoritesConnection(first: $first, after: $after) {
        nodes {
          user {
            ...FavoritedByUser
          }
          bidGroup {
            id
            state
            bids {
              id
              price {
                amount
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${FavoritedByUserFragmentDoc}
`;
export type SellerFavoritesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    SellerFavoritesQuery,
    SellerFavoritesQueryVariables
  >,
  'query'
> &
  (
    | { variables: SellerFavoritesQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const SellerFavoritesComponent = (
  props: SellerFavoritesComponentProps
) => (
  <ApolloReactComponents.Query<
    SellerFavoritesQuery,
    SellerFavoritesQueryVariables
  >
    query={SellerFavoritesDocument}
    {...props}
  />
);

/**
 * __useSellerFavoritesQuery__
 *
 * To run a query within a React component, call `useSellerFavoritesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellerFavoritesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellerFavoritesQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useSellerFavoritesQuery(
  baseOptions: Apollo.QueryHookOptions<
    SellerFavoritesQuery,
    SellerFavoritesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SellerFavoritesQuery, SellerFavoritesQueryVariables>(
    SellerFavoritesDocument,
    options
  );
}
export function useSellerFavoritesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SellerFavoritesQuery,
    SellerFavoritesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SellerFavoritesQuery,
    SellerFavoritesQueryVariables
  >(SellerFavoritesDocument, options);
}
export type SellerFavoritesQueryHookResult = ReturnType<
  typeof useSellerFavoritesQuery
>;
export type SellerFavoritesLazyQueryHookResult = ReturnType<
  typeof useSellerFavoritesLazyQuery
>;
export type SellerFavoritesQueryResult = Apollo.QueryResult<
  SellerFavoritesQuery,
  SellerFavoritesQueryVariables
>;
export const BuyerFavoritesDocument = gql`
  query BuyerFavorites($productId: ID!, $first: Int!, $after: String) {
    product(id: $productId) {
      id
      favoritedByConnection(first: $first, after: $after) {
        nodes {
          ...FavoritedByUser
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${FavoritedByUserFragmentDoc}
`;
export type BuyerFavoritesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    BuyerFavoritesQuery,
    BuyerFavoritesQueryVariables
  >,
  'query'
> &
  (
    | { variables: BuyerFavoritesQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const BuyerFavoritesComponent = (
  props: BuyerFavoritesComponentProps
) => (
  <ApolloReactComponents.Query<
    BuyerFavoritesQuery,
    BuyerFavoritesQueryVariables
  >
    query={BuyerFavoritesDocument}
    {...props}
  />
);

/**
 * __useBuyerFavoritesQuery__
 *
 * To run a query within a React component, call `useBuyerFavoritesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuyerFavoritesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuyerFavoritesQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useBuyerFavoritesQuery(
  baseOptions: Apollo.QueryHookOptions<
    BuyerFavoritesQuery,
    BuyerFavoritesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BuyerFavoritesQuery, BuyerFavoritesQueryVariables>(
    BuyerFavoritesDocument,
    options
  );
}
export function useBuyerFavoritesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BuyerFavoritesQuery,
    BuyerFavoritesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BuyerFavoritesQuery, BuyerFavoritesQueryVariables>(
    BuyerFavoritesDocument,
    options
  );
}
export type BuyerFavoritesQueryHookResult = ReturnType<
  typeof useBuyerFavoritesQuery
>;
export type BuyerFavoritesLazyQueryHookResult = ReturnType<
  typeof useBuyerFavoritesLazyQuery
>;
export type BuyerFavoritesQueryResult = Apollo.QueryResult<
  BuyerFavoritesQuery,
  BuyerFavoritesQueryVariables
>;
export const RatingsDocument = gql`
  query Ratings($userId: ID!, $first: Int!, $after: String) {
    user(id: $userId) {
      id
      displayName
      country
      profileImage {
        url
      }
      rating {
        ratingCount
        rating
      }
      orderRatingsConnection(first: $first, after: $after) {
        nodes {
          ...Rating
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${RatingFragmentDoc}
`;
export type RatingsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    RatingsQuery,
    RatingsQueryVariables
  >,
  'query'
> &
  ({ variables: RatingsQueryVariables; skip?: boolean } | { skip: boolean });

export const RatingsComponent = (props: RatingsComponentProps) => (
  <ApolloReactComponents.Query<RatingsQuery, RatingsQueryVariables>
    query={RatingsDocument}
    {...props}
  />
);

/**
 * __useRatingsQuery__
 *
 * To run a query within a React component, call `useRatingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRatingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRatingsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useRatingsQuery(
  baseOptions: Apollo.QueryHookOptions<RatingsQuery, RatingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RatingsQuery, RatingsQueryVariables>(
    RatingsDocument,
    options
  );
}
export function useRatingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RatingsQuery, RatingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RatingsQuery, RatingsQueryVariables>(
    RatingsDocument,
    options
  );
}
export type RatingsQueryHookResult = ReturnType<typeof useRatingsQuery>;
export type RatingsLazyQueryHookResult = ReturnType<typeof useRatingsLazyQuery>;
export type RatingsQueryResult = Apollo.QueryResult<
  RatingsQuery,
  RatingsQueryVariables
>;
export const InitialDataDocument = gql`
  query InitialData {
    legacyTranslations
  }
`;
export type InitialDataComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    InitialDataQuery,
    InitialDataQueryVariables
  >,
  'query'
>;

export const InitialDataComponent = (props: InitialDataComponentProps) => (
  <ApolloReactComponents.Query<InitialDataQuery, InitialDataQueryVariables>
    query={InitialDataDocument}
    {...props}
  />
);

/**
 * __useInitialDataQuery__
 *
 * To run a query within a React component, call `useInitialDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useInitialDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInitialDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useInitialDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    InitialDataQuery,
    InitialDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InitialDataQuery, InitialDataQueryVariables>(
    InitialDataDocument,
    options
  );
}
export function useInitialDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InitialDataQuery,
    InitialDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InitialDataQuery, InitialDataQueryVariables>(
    InitialDataDocument,
    options
  );
}
export type InitialDataQueryHookResult = ReturnType<typeof useInitialDataQuery>;
export type InitialDataLazyQueryHookResult = ReturnType<
  typeof useInitialDataLazyQuery
>;
export type InitialDataQueryResult = Apollo.QueryResult<
  InitialDataQuery,
  InitialDataQueryVariables
>;
export const LegacyTranslationsDocument = gql`
  query LegacyTranslations {
    legacyTranslations
  }
`;
export type LegacyTranslationsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    LegacyTranslationsQuery,
    LegacyTranslationsQueryVariables
  >,
  'query'
>;

export const LegacyTranslationsComponent = (
  props: LegacyTranslationsComponentProps
) => (
  <ApolloReactComponents.Query<
    LegacyTranslationsQuery,
    LegacyTranslationsQueryVariables
  >
    query={LegacyTranslationsDocument}
    {...props}
  />
);

/**
 * __useLegacyTranslationsQuery__
 *
 * To run a query within a React component, call `useLegacyTranslationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLegacyTranslationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLegacyTranslationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLegacyTranslationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LegacyTranslationsQuery,
    LegacyTranslationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LegacyTranslationsQuery,
    LegacyTranslationsQueryVariables
  >(LegacyTranslationsDocument, options);
}
export function useLegacyTranslationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LegacyTranslationsQuery,
    LegacyTranslationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LegacyTranslationsQuery,
    LegacyTranslationsQueryVariables
  >(LegacyTranslationsDocument, options);
}
export type LegacyTranslationsQueryHookResult = ReturnType<
  typeof useLegacyTranslationsQuery
>;
export type LegacyTranslationsLazyQueryHookResult = ReturnType<
  typeof useLegacyTranslationsLazyQuery
>;
export type LegacyTranslationsQueryResult = Apollo.QueryResult<
  LegacyTranslationsQuery,
  LegacyTranslationsQueryVariables
>;
export const ServerInitDocument = gql`
  query ServerInit {
    productCategories {
      ...ServerCategory
      children {
        ...ServerCategory
        children {
          ...ServerCategory
          children {
            ...ServerCategory
            children {
              ...ServerCategory
              children {
                ...ServerCategory
              }
            }
          }
        }
      }
    }
  }
  ${ServerCategoryFragmentDoc}
`;
export type ServerInitComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ServerInitQuery,
    ServerInitQueryVariables
  >,
  'query'
>;

export const ServerInitComponent = (props: ServerInitComponentProps) => (
  <ApolloReactComponents.Query<ServerInitQuery, ServerInitQueryVariables>
    query={ServerInitDocument}
    {...props}
  />
);

/**
 * __useServerInitQuery__
 *
 * To run a query within a React component, call `useServerInitQuery` and pass it any options that fit your needs.
 * When your component renders, `useServerInitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServerInitQuery({
 *   variables: {
 *   },
 * });
 */
export function useServerInitQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ServerInitQuery,
    ServerInitQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ServerInitQuery, ServerInitQueryVariables>(
    ServerInitDocument,
    options
  );
}
export function useServerInitLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ServerInitQuery,
    ServerInitQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ServerInitQuery, ServerInitQueryVariables>(
    ServerInitDocument,
    options
  );
}
export type ServerInitQueryHookResult = ReturnType<typeof useServerInitQuery>;
export type ServerInitLazyQueryHookResult = ReturnType<
  typeof useServerInitLazyQuery
>;
export type ServerInitQueryResult = Apollo.QueryResult<
  ServerInitQuery,
  ServerInitQueryVariables
>;
export const AddProductDocument = gql`
  mutation AddProduct($input: AddProductInput!) {
    addProduct(input: $input) {
      product {
        ...Product
      }
    }
  }
  ${ProductFragmentDoc}
`;
export type AddProductMutationFn = Apollo.MutationFunction<
  AddProductMutation,
  AddProductMutationVariables
>;
export type AddProductComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AddProductMutation,
    AddProductMutationVariables
  >,
  'mutation'
>;

export const AddProductComponent = (props: AddProductComponentProps) => (
  <ApolloReactComponents.Mutation<
    AddProductMutation,
    AddProductMutationVariables
  >
    mutation={AddProductDocument}
    {...props}
  />
);

/**
 * __useAddProductMutation__
 *
 * To run a mutation, you first call `useAddProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProductMutation, { data, loading, error }] = useAddProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddProductMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddProductMutation,
    AddProductMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddProductMutation, AddProductMutationVariables>(
    AddProductDocument,
    options
  );
}
export type AddProductMutationHookResult = ReturnType<
  typeof useAddProductMutation
>;
export type AddProductMutationResult =
  Apollo.MutationResult<AddProductMutation>;
export type AddProductMutationOptions = Apollo.BaseMutationOptions<
  AddProductMutation,
  AddProductMutationVariables
>;
export const UpdateProductDocument = gql`
  mutation UpdateProduct($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      product {
        ...Product
      }
    }
  }
  ${ProductFragmentDoc}
`;
export type UpdateProductMutationFn = Apollo.MutationFunction<
  UpdateProductMutation,
  UpdateProductMutationVariables
>;
export type UpdateProductComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateProductMutation,
    UpdateProductMutationVariables
  >,
  'mutation'
>;

export const UpdateProductComponent = (props: UpdateProductComponentProps) => (
  <ApolloReactComponents.Mutation<
    UpdateProductMutation,
    UpdateProductMutationVariables
  >
    mutation={UpdateProductDocument}
    {...props}
  />
);

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProductMutation,
    UpdateProductMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProductMutation,
    UpdateProductMutationVariables
  >(UpdateProductDocument, options);
}
export type UpdateProductMutationHookResult = ReturnType<
  typeof useUpdateProductMutation
>;
export type UpdateProductMutationResult =
  Apollo.MutationResult<UpdateProductMutation>;
export type UpdateProductMutationOptions = Apollo.BaseMutationOptions<
  UpdateProductMutation,
  UpdateProductMutationVariables
>;
export const AddPartialAddressDocument = gql`
  mutation AddPartialAddress($input: AddPartialAddressInput!) {
    addPartialAddress(input: $input) {
      account {
        partialAddress {
          ...PartialAddress
        }
      }
    }
  }
  ${PartialAddressFragmentDoc}
`;
export type AddPartialAddressMutationFn = Apollo.MutationFunction<
  AddPartialAddressMutation,
  AddPartialAddressMutationVariables
>;
export type AddPartialAddressComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AddPartialAddressMutation,
    AddPartialAddressMutationVariables
  >,
  'mutation'
>;

export const AddPartialAddressComponent = (
  props: AddPartialAddressComponentProps
) => (
  <ApolloReactComponents.Mutation<
    AddPartialAddressMutation,
    AddPartialAddressMutationVariables
  >
    mutation={AddPartialAddressDocument}
    {...props}
  />
);

/**
 * __useAddPartialAddressMutation__
 *
 * To run a mutation, you first call `useAddPartialAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPartialAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPartialAddressMutation, { data, loading, error }] = useAddPartialAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPartialAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPartialAddressMutation,
    AddPartialAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddPartialAddressMutation,
    AddPartialAddressMutationVariables
  >(AddPartialAddressDocument, options);
}
export type AddPartialAddressMutationHookResult = ReturnType<
  typeof useAddPartialAddressMutation
>;
export type AddPartialAddressMutationResult =
  Apollo.MutationResult<AddPartialAddressMutation>;
export type AddPartialAddressMutationOptions = Apollo.BaseMutationOptions<
  AddPartialAddressMutation,
  AddPartialAddressMutationVariables
>;
export const AddProductRequirementsDocument = gql`
  query AddProductRequirements {
    viewer {
      account {
        partialAddress {
          id
        }
        defaultAddress {
          id
        }
        kycStatus
      }
      parcelTypes {
        ...AddProductParcelType
      }
    }
    shippers {
      ...DeliveryOptionShipper
    }
  }
  ${AddProductParcelTypeFragmentDoc}
  ${DeliveryOptionShipperFragmentDoc}
`;
export type AddProductRequirementsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AddProductRequirementsQuery,
    AddProductRequirementsQueryVariables
  >,
  'query'
>;

export const AddProductRequirementsComponent = (
  props: AddProductRequirementsComponentProps
) => (
  <ApolloReactComponents.Query<
    AddProductRequirementsQuery,
    AddProductRequirementsQueryVariables
  >
    query={AddProductRequirementsDocument}
    {...props}
  />
);

/**
 * __useAddProductRequirementsQuery__
 *
 * To run a query within a React component, call `useAddProductRequirementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddProductRequirementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddProductRequirementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAddProductRequirementsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AddProductRequirementsQuery,
    AddProductRequirementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AddProductRequirementsQuery,
    AddProductRequirementsQueryVariables
  >(AddProductRequirementsDocument, options);
}
export function useAddProductRequirementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AddProductRequirementsQuery,
    AddProductRequirementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AddProductRequirementsQuery,
    AddProductRequirementsQueryVariables
  >(AddProductRequirementsDocument, options);
}
export type AddProductRequirementsQueryHookResult = ReturnType<
  typeof useAddProductRequirementsQuery
>;
export type AddProductRequirementsLazyQueryHookResult = ReturnType<
  typeof useAddProductRequirementsLazyQuery
>;
export type AddProductRequirementsQueryResult = Apollo.QueryResult<
  AddProductRequirementsQuery,
  AddProductRequirementsQueryVariables
>;
export const EditProductRequirementsDocument = gql`
  query EditProductRequirements($productID: ID!) {
    viewer {
      parcelTypes {
        ...AddProductParcelType
      }
    }
    product(id: $productID) {
      id
      title
      brand {
        name
      }
      hasBiddingEnabled
      category {
        id
      }
      colors {
        id
      }
      size {
        sizeOptions {
          id
        }
      }
      tags {
        name
      }
      condition
      price {
        amount
      }
      originalPrice {
        amount
      }
      images(variant: LEGACY_LARGER) {
        mediaId
        crop {
          x
          y
          width
          height
        }
        url
      }
      originalImages: images(variant: LEGACY_XLARGE) {
        url
      }
      parcelType {
        id
      }
      description
      isUnisex
    }
  }
  ${AddProductParcelTypeFragmentDoc}
`;
export type EditProductRequirementsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    EditProductRequirementsQuery,
    EditProductRequirementsQueryVariables
  >,
  'query'
> &
  (
    | { variables: EditProductRequirementsQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const EditProductRequirementsComponent = (
  props: EditProductRequirementsComponentProps
) => (
  <ApolloReactComponents.Query<
    EditProductRequirementsQuery,
    EditProductRequirementsQueryVariables
  >
    query={EditProductRequirementsDocument}
    {...props}
  />
);

/**
 * __useEditProductRequirementsQuery__
 *
 * To run a query within a React component, call `useEditProductRequirementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditProductRequirementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditProductRequirementsQuery({
 *   variables: {
 *      productID: // value for 'productID'
 *   },
 * });
 */
export function useEditProductRequirementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    EditProductRequirementsQuery,
    EditProductRequirementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EditProductRequirementsQuery,
    EditProductRequirementsQueryVariables
  >(EditProductRequirementsDocument, options);
}
export function useEditProductRequirementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EditProductRequirementsQuery,
    EditProductRequirementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EditProductRequirementsQuery,
    EditProductRequirementsQueryVariables
  >(EditProductRequirementsDocument, options);
}
export type EditProductRequirementsQueryHookResult = ReturnType<
  typeof useEditProductRequirementsQuery
>;
export type EditProductRequirementsLazyQueryHookResult = ReturnType<
  typeof useEditProductRequirementsLazyQuery
>;
export type EditProductRequirementsQueryResult = Apollo.QueryResult<
  EditProductRequirementsQuery,
  EditProductRequirementsQueryVariables
>;
export const CategorySuggestionsDocument = gql`
  query categorySuggestions($title: String!) {
    categorySuggestions(title: $title) {
      id
      title
    }
  }
`;
export type CategorySuggestionsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CategorySuggestionsQuery,
    CategorySuggestionsQueryVariables
  >,
  'query'
> &
  (
    | { variables: CategorySuggestionsQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const CategorySuggestionsComponent = (
  props: CategorySuggestionsComponentProps
) => (
  <ApolloReactComponents.Query<
    CategorySuggestionsQuery,
    CategorySuggestionsQueryVariables
  >
    query={CategorySuggestionsDocument}
    {...props}
  />
);

/**
 * __useCategorySuggestionsQuery__
 *
 * To run a query within a React component, call `useCategorySuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategorySuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategorySuggestionsQuery({
 *   variables: {
 *      title: // value for 'title'
 *   },
 * });
 */
export function useCategorySuggestionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CategorySuggestionsQuery,
    CategorySuggestionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CategorySuggestionsQuery,
    CategorySuggestionsQueryVariables
  >(CategorySuggestionsDocument, options);
}
export function useCategorySuggestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CategorySuggestionsQuery,
    CategorySuggestionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CategorySuggestionsQuery,
    CategorySuggestionsQueryVariables
  >(CategorySuggestionsDocument, options);
}
export type CategorySuggestionsQueryHookResult = ReturnType<
  typeof useCategorySuggestionsQuery
>;
export type CategorySuggestionsLazyQueryHookResult = ReturnType<
  typeof useCategorySuggestionsLazyQuery
>;
export type CategorySuggestionsQueryResult = Apollo.QueryResult<
  CategorySuggestionsQuery,
  CategorySuggestionsQueryVariables
>;
export const SimilarSoldProductsDocument = gql`
  query similarSoldProducts(
    $brand: String!
    $categoryId: ID!
    $sizeIds: [ID!]!
    $condition: ProductCondition!
  ) {
    similarSoldProducts(
      brand: $brand
      categoryId: $categoryId
      sizeIds: $sizeIds
      condition: $condition
    ) {
      ...ProductSimplifiedContent
    }
  }
  ${ProductSimplifiedContentFragmentDoc}
`;
export type SimilarSoldProductsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    SimilarSoldProductsQuery,
    SimilarSoldProductsQueryVariables
  >,
  'query'
> &
  (
    | { variables: SimilarSoldProductsQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const SimilarSoldProductsComponent = (
  props: SimilarSoldProductsComponentProps
) => (
  <ApolloReactComponents.Query<
    SimilarSoldProductsQuery,
    SimilarSoldProductsQueryVariables
  >
    query={SimilarSoldProductsDocument}
    {...props}
  />
);

/**
 * __useSimilarSoldProductsQuery__
 *
 * To run a query within a React component, call `useSimilarSoldProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimilarSoldProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimilarSoldProductsQuery({
 *   variables: {
 *      brand: // value for 'brand'
 *      categoryId: // value for 'categoryId'
 *      sizeIds: // value for 'sizeIds'
 *      condition: // value for 'condition'
 *   },
 * });
 */
export function useSimilarSoldProductsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SimilarSoldProductsQuery,
    SimilarSoldProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SimilarSoldProductsQuery,
    SimilarSoldProductsQueryVariables
  >(SimilarSoldProductsDocument, options);
}
export function useSimilarSoldProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SimilarSoldProductsQuery,
    SimilarSoldProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SimilarSoldProductsQuery,
    SimilarSoldProductsQueryVariables
  >(SimilarSoldProductsDocument, options);
}
export type SimilarSoldProductsQueryHookResult = ReturnType<
  typeof useSimilarSoldProductsQuery
>;
export type SimilarSoldProductsLazyQueryHookResult = ReturnType<
  typeof useSimilarSoldProductsLazyQuery
>;
export type SimilarSoldProductsQueryResult = Apollo.QueryResult<
  SimilarSoldProductsQuery,
  SimilarSoldProductsQueryVariables
>;
export const ConfirmEmailAddressDocument = gql`
  mutation ConfirmEmailAddress($input: ConfirmEmailAddressInput!) {
    confirmEmailAddress(input: $input) {
      clientMutationId
    }
  }
`;
export type ConfirmEmailAddressMutationFn = Apollo.MutationFunction<
  ConfirmEmailAddressMutation,
  ConfirmEmailAddressMutationVariables
>;
export type ConfirmEmailAddressComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ConfirmEmailAddressMutation,
    ConfirmEmailAddressMutationVariables
  >,
  'mutation'
>;

export const ConfirmEmailAddressComponent = (
  props: ConfirmEmailAddressComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ConfirmEmailAddressMutation,
    ConfirmEmailAddressMutationVariables
  >
    mutation={ConfirmEmailAddressDocument}
    {...props}
  />
);

/**
 * __useConfirmEmailAddressMutation__
 *
 * To run a mutation, you first call `useConfirmEmailAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmEmailAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmEmailAddressMutation, { data, loading, error }] = useConfirmEmailAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmEmailAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmEmailAddressMutation,
    ConfirmEmailAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConfirmEmailAddressMutation,
    ConfirmEmailAddressMutationVariables
  >(ConfirmEmailAddressDocument, options);
}
export type ConfirmEmailAddressMutationHookResult = ReturnType<
  typeof useConfirmEmailAddressMutation
>;
export type ConfirmEmailAddressMutationResult =
  Apollo.MutationResult<ConfirmEmailAddressMutation>;
export type ConfirmEmailAddressMutationOptions = Apollo.BaseMutationOptions<
  ConfirmEmailAddressMutation,
  ConfirmEmailAddressMutationVariables
>;
export const LoginDocument = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      clientMutationId
    }
  }
`;
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;
export type LoginComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    LoginMutation,
    LoginMutationVariables
  >,
  'mutation'
>;

export const LoginComponent = (props: LoginComponentProps) => (
  <ApolloReactComponents.Mutation<LoginMutation, LoginMutationVariables>
    mutation={LoginDocument}
    {...props}
  />
);

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    options
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const RegisterAccountDocument = gql`
  mutation RegisterAccount($input: RegisterAccountInput!) {
    registerAccount(input: $input) {
      clientMutationId
    }
  }
`;
export type RegisterAccountMutationFn = Apollo.MutationFunction<
  RegisterAccountMutation,
  RegisterAccountMutationVariables
>;
export type RegisterAccountComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RegisterAccountMutation,
    RegisterAccountMutationVariables
  >,
  'mutation'
>;

export const RegisterAccountComponent = (
  props: RegisterAccountComponentProps
) => (
  <ApolloReactComponents.Mutation<
    RegisterAccountMutation,
    RegisterAccountMutationVariables
  >
    mutation={RegisterAccountDocument}
    {...props}
  />
);

/**
 * __useRegisterAccountMutation__
 *
 * To run a mutation, you first call `useRegisterAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerAccountMutation, { data, loading, error }] = useRegisterAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterAccountMutation,
    RegisterAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterAccountMutation,
    RegisterAccountMutationVariables
  >(RegisterAccountDocument, options);
}
export type RegisterAccountMutationHookResult = ReturnType<
  typeof useRegisterAccountMutation
>;
export type RegisterAccountMutationResult =
  Apollo.MutationResult<RegisterAccountMutation>;
export type RegisterAccountMutationOptions = Apollo.BaseMutationOptions<
  RegisterAccountMutation,
  RegisterAccountMutationVariables
>;
export const ThirdPartyAuthDocument = gql`
  mutation ThirdPartyAuth($input: ThirdPartyAuthInput!) {
    thirdPartyAuth(input: $input) {
      clientMutationId
      isExistingAccount
    }
  }
`;
export type ThirdPartyAuthMutationFn = Apollo.MutationFunction<
  ThirdPartyAuthMutation,
  ThirdPartyAuthMutationVariables
>;
export type ThirdPartyAuthComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ThirdPartyAuthMutation,
    ThirdPartyAuthMutationVariables
  >,
  'mutation'
>;

export const ThirdPartyAuthComponent = (
  props: ThirdPartyAuthComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ThirdPartyAuthMutation,
    ThirdPartyAuthMutationVariables
  >
    mutation={ThirdPartyAuthDocument}
    {...props}
  />
);

/**
 * __useThirdPartyAuthMutation__
 *
 * To run a mutation, you first call `useThirdPartyAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useThirdPartyAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [thirdPartyAuthMutation, { data, loading, error }] = useThirdPartyAuthMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useThirdPartyAuthMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ThirdPartyAuthMutation,
    ThirdPartyAuthMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ThirdPartyAuthMutation,
    ThirdPartyAuthMutationVariables
  >(ThirdPartyAuthDocument, options);
}
export type ThirdPartyAuthMutationHookResult = ReturnType<
  typeof useThirdPartyAuthMutation
>;
export type ThirdPartyAuthMutationResult =
  Apollo.MutationResult<ThirdPartyAuthMutation>;
export type ThirdPartyAuthMutationOptions = Apollo.BaseMutationOptions<
  ThirdPartyAuthMutation,
  ThirdPartyAuthMutationVariables
>;
export const LogoutDocument = gql`
  mutation Logout($input: LogoutInput!) {
    logout(input: $input) {
      clientMutationId
    }
  }
`;
export type LogoutMutationFn = Apollo.MutationFunction<
  LogoutMutation,
  LogoutMutationVariables
>;
export type LogoutComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    LogoutMutation,
    LogoutMutationVariables
  >,
  'mutation'
>;

export const LogoutComponent = (props: LogoutComponentProps) => (
  <ApolloReactComponents.Mutation<LogoutMutation, LogoutMutationVariables>
    mutation={LogoutDocument}
    {...props}
  />
);

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LogoutMutation,
    LogoutMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(
    LogoutDocument,
    options
  );
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<
  LogoutMutation,
  LogoutMutationVariables
>;
export const ForgotPasswordDocument = gql`
  mutation ForgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
      clientMutationId
    }
  }
`;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>;
export type ForgotPasswordComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ForgotPasswordMutation,
    ForgotPasswordMutationVariables
  >,
  'mutation'
>;

export const ForgotPasswordComponent = (
  props: ForgotPasswordComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ForgotPasswordMutation,
    ForgotPasswordMutationVariables
  >
    mutation={ForgotPasswordDocument}
    {...props}
  />
);

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useForgotPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ForgotPasswordMutation,
    ForgotPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ForgotPasswordMutation,
    ForgotPasswordMutationVariables
  >(ForgotPasswordDocument, options);
}
export type ForgotPasswordMutationHookResult = ReturnType<
  typeof useForgotPasswordMutation
>;
export type ForgotPasswordMutationResult =
  Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      clientMutationId
    }
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export type ResetPasswordComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >,
  'mutation'
>;

export const ResetPasswordComponent = (props: ResetPasswordComponentProps) => (
  <ApolloReactComponents.Mutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >
    mutation={ResetPasswordDocument}
    {...props}
  />
);

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>;
export type ResetPasswordMutationResult =
  Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const MarkTutorialAsViewedDocument = gql`
  mutation MarkTutorialAsViewed {
    markTutorialAsViewed(input: {}) {
      clientMutationId
    }
  }
`;
export type MarkTutorialAsViewedMutationFn = Apollo.MutationFunction<
  MarkTutorialAsViewedMutation,
  MarkTutorialAsViewedMutationVariables
>;
export type MarkTutorialAsViewedComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    MarkTutorialAsViewedMutation,
    MarkTutorialAsViewedMutationVariables
  >,
  'mutation'
>;

export const MarkTutorialAsViewedComponent = (
  props: MarkTutorialAsViewedComponentProps
) => (
  <ApolloReactComponents.Mutation<
    MarkTutorialAsViewedMutation,
    MarkTutorialAsViewedMutationVariables
  >
    mutation={MarkTutorialAsViewedDocument}
    {...props}
  />
);

/**
 * __useMarkTutorialAsViewedMutation__
 *
 * To run a mutation, you first call `useMarkTutorialAsViewedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkTutorialAsViewedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markTutorialAsViewedMutation, { data, loading, error }] = useMarkTutorialAsViewedMutation({
 *   variables: {
 *   },
 * });
 */
export function useMarkTutorialAsViewedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkTutorialAsViewedMutation,
    MarkTutorialAsViewedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkTutorialAsViewedMutation,
    MarkTutorialAsViewedMutationVariables
  >(MarkTutorialAsViewedDocument, options);
}
export type MarkTutorialAsViewedMutationHookResult = ReturnType<
  typeof useMarkTutorialAsViewedMutation
>;
export type MarkTutorialAsViewedMutationResult =
  Apollo.MutationResult<MarkTutorialAsViewedMutation>;
export type MarkTutorialAsViewedMutationOptions = Apollo.BaseMutationOptions<
  MarkTutorialAsViewedMutation,
  MarkTutorialAsViewedMutationVariables
>;
export const ViewerDocument = gql`
  query Viewer {
    viewer {
      account {
        hasSeenTutorial
      }
      me {
        ...Me
      }
    }
  }
  ${MeFragmentDoc}
`;
export type ViewerComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ViewerQuery,
    ViewerQueryVariables
  >,
  'query'
>;

export const ViewerComponent = (props: ViewerComponentProps) => (
  <ApolloReactComponents.Query<ViewerQuery, ViewerQueryVariables>
    query={ViewerDocument}
    {...props}
  />
);

/**
 * __useViewerQuery__
 *
 * To run a query within a React component, call `useViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerQuery(
  baseOptions?: Apollo.QueryHookOptions<ViewerQuery, ViewerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ViewerQuery, ViewerQueryVariables>(
    ViewerDocument,
    options
  );
}
export function useViewerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ViewerQuery, ViewerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ViewerQuery, ViewerQueryVariables>(
    ViewerDocument,
    options
  );
}
export type ViewerQueryHookResult = ReturnType<typeof useViewerQuery>;
export type ViewerLazyQueryHookResult = ReturnType<typeof useViewerLazyQuery>;
export type ViewerQueryResult = Apollo.QueryResult<
  ViewerQuery,
  ViewerQueryVariables
>;
export const PlaceBidDocument = gql`
  mutation PlaceBid($input: PlaceProductBidInput!) {
    placeProductBid(input: $input) {
      bidGroup {
        ...FullBidGroup
      }
    }
  }
  ${FullBidGroupFragmentDoc}
`;
export type PlaceBidMutationFn = Apollo.MutationFunction<
  PlaceBidMutation,
  PlaceBidMutationVariables
>;
export type PlaceBidComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    PlaceBidMutation,
    PlaceBidMutationVariables
  >,
  'mutation'
>;

export const PlaceBidComponent = (props: PlaceBidComponentProps) => (
  <ApolloReactComponents.Mutation<PlaceBidMutation, PlaceBidMutationVariables>
    mutation={PlaceBidDocument}
    {...props}
  />
);

/**
 * __usePlaceBidMutation__
 *
 * To run a mutation, you first call `usePlaceBidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlaceBidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [placeBidMutation, { data, loading, error }] = usePlaceBidMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlaceBidMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PlaceBidMutation,
    PlaceBidMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PlaceBidMutation, PlaceBidMutationVariables>(
    PlaceBidDocument,
    options
  );
}
export type PlaceBidMutationHookResult = ReturnType<typeof usePlaceBidMutation>;
export type PlaceBidMutationResult = Apollo.MutationResult<PlaceBidMutation>;
export type PlaceBidMutationOptions = Apollo.BaseMutationOptions<
  PlaceBidMutation,
  PlaceBidMutationVariables
>;
export const WithdrawBidDocument = gql`
  mutation WithdrawBid($input: WithdrawBidInput!) {
    withdrawBid(input: $input) {
      bidGroup {
        ...FullBidGroup
      }
    }
  }
  ${FullBidGroupFragmentDoc}
`;
export type WithdrawBidMutationFn = Apollo.MutationFunction<
  WithdrawBidMutation,
  WithdrawBidMutationVariables
>;
export type WithdrawBidComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    WithdrawBidMutation,
    WithdrawBidMutationVariables
  >,
  'mutation'
>;

export const WithdrawBidComponent = (props: WithdrawBidComponentProps) => (
  <ApolloReactComponents.Mutation<
    WithdrawBidMutation,
    WithdrawBidMutationVariables
  >
    mutation={WithdrawBidDocument}
    {...props}
  />
);

/**
 * __useWithdrawBidMutation__
 *
 * To run a mutation, you first call `useWithdrawBidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWithdrawBidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [withdrawBidMutation, { data, loading, error }] = useWithdrawBidMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWithdrawBidMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WithdrawBidMutation,
    WithdrawBidMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<WithdrawBidMutation, WithdrawBidMutationVariables>(
    WithdrawBidDocument,
    options
  );
}
export type WithdrawBidMutationHookResult = ReturnType<
  typeof useWithdrawBidMutation
>;
export type WithdrawBidMutationResult =
  Apollo.MutationResult<WithdrawBidMutation>;
export type WithdrawBidMutationOptions = Apollo.BaseMutationOptions<
  WithdrawBidMutation,
  WithdrawBidMutationVariables
>;
export const AcceptBidDocument = gql`
  mutation AcceptBid($input: AcceptBidInput!) {
    acceptBid(input: $input) {
      bidGroup {
        ...FullBidGroup
      }
    }
  }
  ${FullBidGroupFragmentDoc}
`;
export type AcceptBidMutationFn = Apollo.MutationFunction<
  AcceptBidMutation,
  AcceptBidMutationVariables
>;
export type AcceptBidComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AcceptBidMutation,
    AcceptBidMutationVariables
  >,
  'mutation'
>;

export const AcceptBidComponent = (props: AcceptBidComponentProps) => (
  <ApolloReactComponents.Mutation<AcceptBidMutation, AcceptBidMutationVariables>
    mutation={AcceptBidDocument}
    {...props}
  />
);

/**
 * __useAcceptBidMutation__
 *
 * To run a mutation, you first call `useAcceptBidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptBidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptBidMutation, { data, loading, error }] = useAcceptBidMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptBidMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptBidMutation,
    AcceptBidMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AcceptBidMutation, AcceptBidMutationVariables>(
    AcceptBidDocument,
    options
  );
}
export type AcceptBidMutationHookResult = ReturnType<
  typeof useAcceptBidMutation
>;
export type AcceptBidMutationResult = Apollo.MutationResult<AcceptBidMutation>;
export type AcceptBidMutationOptions = Apollo.BaseMutationOptions<
  AcceptBidMutation,
  AcceptBidMutationVariables
>;
export const PlaceCounterBidDocument = gql`
  mutation PlaceCounterBid($input: PlaceCounterBidInput!) {
    placeCounterBid(input: $input) {
      bidGroup {
        ...FullBidGroup
      }
    }
  }
  ${FullBidGroupFragmentDoc}
`;
export type PlaceCounterBidMutationFn = Apollo.MutationFunction<
  PlaceCounterBidMutation,
  PlaceCounterBidMutationVariables
>;
export type PlaceCounterBidComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    PlaceCounterBidMutation,
    PlaceCounterBidMutationVariables
  >,
  'mutation'
>;

export const PlaceCounterBidComponent = (
  props: PlaceCounterBidComponentProps
) => (
  <ApolloReactComponents.Mutation<
    PlaceCounterBidMutation,
    PlaceCounterBidMutationVariables
  >
    mutation={PlaceCounterBidDocument}
    {...props}
  />
);

/**
 * __usePlaceCounterBidMutation__
 *
 * To run a mutation, you first call `usePlaceCounterBidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlaceCounterBidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [placeCounterBidMutation, { data, loading, error }] = usePlaceCounterBidMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlaceCounterBidMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PlaceCounterBidMutation,
    PlaceCounterBidMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PlaceCounterBidMutation,
    PlaceCounterBidMutationVariables
  >(PlaceCounterBidDocument, options);
}
export type PlaceCounterBidMutationHookResult = ReturnType<
  typeof usePlaceCounterBidMutation
>;
export type PlaceCounterBidMutationResult =
  Apollo.MutationResult<PlaceCounterBidMutation>;
export type PlaceCounterBidMutationOptions = Apollo.BaseMutationOptions<
  PlaceCounterBidMutation,
  PlaceCounterBidMutationVariables
>;
export const PlaceMassProductBidDocument = gql`
  mutation PlaceMassProductBid($input: PlaceMassProductBidInput!) {
    placeMassProductBid(input: $input) {
      bidGroups {
        ...FullBidGroup
      }
    }
  }
  ${FullBidGroupFragmentDoc}
`;
export type PlaceMassProductBidMutationFn = Apollo.MutationFunction<
  PlaceMassProductBidMutation,
  PlaceMassProductBidMutationVariables
>;
export type PlaceMassProductBidComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    PlaceMassProductBidMutation,
    PlaceMassProductBidMutationVariables
  >,
  'mutation'
>;

export const PlaceMassProductBidComponent = (
  props: PlaceMassProductBidComponentProps
) => (
  <ApolloReactComponents.Mutation<
    PlaceMassProductBidMutation,
    PlaceMassProductBidMutationVariables
  >
    mutation={PlaceMassProductBidDocument}
    {...props}
  />
);

/**
 * __usePlaceMassProductBidMutation__
 *
 * To run a mutation, you first call `usePlaceMassProductBidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlaceMassProductBidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [placeMassProductBidMutation, { data, loading, error }] = usePlaceMassProductBidMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlaceMassProductBidMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PlaceMassProductBidMutation,
    PlaceMassProductBidMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PlaceMassProductBidMutation,
    PlaceMassProductBidMutationVariables
  >(PlaceMassProductBidDocument, options);
}
export type PlaceMassProductBidMutationHookResult = ReturnType<
  typeof usePlaceMassProductBidMutation
>;
export type PlaceMassProductBidMutationResult =
  Apollo.MutationResult<PlaceMassProductBidMutation>;
export type PlaceMassProductBidMutationOptions = Apollo.BaseMutationOptions<
  PlaceMassProductBidMutation,
  PlaceMassProductBidMutationVariables
>;
export const PlaceProActiveProductBidDocument = gql`
  mutation PlaceProActiveProductBid($input: PlaceProActiveProductBidInput!) {
    placeProActiveProductBid(input: $input) {
      bidGroup {
        ...FullBidGroup
      }
    }
  }
  ${FullBidGroupFragmentDoc}
`;
export type PlaceProActiveProductBidMutationFn = Apollo.MutationFunction<
  PlaceProActiveProductBidMutation,
  PlaceProActiveProductBidMutationVariables
>;
export type PlaceProActiveProductBidComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    PlaceProActiveProductBidMutation,
    PlaceProActiveProductBidMutationVariables
  >,
  'mutation'
>;

export const PlaceProActiveProductBidComponent = (
  props: PlaceProActiveProductBidComponentProps
) => (
  <ApolloReactComponents.Mutation<
    PlaceProActiveProductBidMutation,
    PlaceProActiveProductBidMutationVariables
  >
    mutation={PlaceProActiveProductBidDocument}
    {...props}
  />
);

/**
 * __usePlaceProActiveProductBidMutation__
 *
 * To run a mutation, you first call `usePlaceProActiveProductBidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlaceProActiveProductBidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [placeProActiveProductBidMutation, { data, loading, error }] = usePlaceProActiveProductBidMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlaceProActiveProductBidMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PlaceProActiveProductBidMutation,
    PlaceProActiveProductBidMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PlaceProActiveProductBidMutation,
    PlaceProActiveProductBidMutationVariables
  >(PlaceProActiveProductBidDocument, options);
}
export type PlaceProActiveProductBidMutationHookResult = ReturnType<
  typeof usePlaceProActiveProductBidMutation
>;
export type PlaceProActiveProductBidMutationResult =
  Apollo.MutationResult<PlaceProActiveProductBidMutation>;
export type PlaceProActiveProductBidMutationOptions =
  Apollo.BaseMutationOptions<
    PlaceProActiveProductBidMutation,
    PlaceProActiveProductBidMutationVariables
  >;
export const PlaceBundleBidDocument = gql`
  mutation PlaceBundleBid($input: PlaceBundleBidInput!) {
    placeBundleBid(input: $input) {
      bidGroup {
        ...FullBidGroup
      }
    }
  }
  ${FullBidGroupFragmentDoc}
`;
export type PlaceBundleBidMutationFn = Apollo.MutationFunction<
  PlaceBundleBidMutation,
  PlaceBundleBidMutationVariables
>;
export type PlaceBundleBidComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    PlaceBundleBidMutation,
    PlaceBundleBidMutationVariables
  >,
  'mutation'
>;

export const PlaceBundleBidComponent = (
  props: PlaceBundleBidComponentProps
) => (
  <ApolloReactComponents.Mutation<
    PlaceBundleBidMutation,
    PlaceBundleBidMutationVariables
  >
    mutation={PlaceBundleBidDocument}
    {...props}
  />
);

/**
 * __usePlaceBundleBidMutation__
 *
 * To run a mutation, you first call `usePlaceBundleBidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlaceBundleBidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [placeBundleBidMutation, { data, loading, error }] = usePlaceBundleBidMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlaceBundleBidMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PlaceBundleBidMutation,
    PlaceBundleBidMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PlaceBundleBidMutation,
    PlaceBundleBidMutationVariables
  >(PlaceBundleBidDocument, options);
}
export type PlaceBundleBidMutationHookResult = ReturnType<
  typeof usePlaceBundleBidMutation
>;
export type PlaceBundleBidMutationResult =
  Apollo.MutationResult<PlaceBundleBidMutation>;
export type PlaceBundleBidMutationOptions = Apollo.BaseMutationOptions<
  PlaceBundleBidMutation,
  PlaceBundleBidMutationVariables
>;
export const BundleBidProductsDocument = gql`
  query BundleBidProducts($userId: ID!, $first: Int!, $after: String) {
    user(id: $userId) {
      bundleProductsConnection(first: $first, after: $after) {
        nodes {
          ...ListProduct
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
  ${ListProductFragmentDoc}
`;
export type BundleBidProductsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    BundleBidProductsQuery,
    BundleBidProductsQueryVariables
  >,
  'query'
> &
  (
    | { variables: BundleBidProductsQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const BundleBidProductsComponent = (
  props: BundleBidProductsComponentProps
) => (
  <ApolloReactComponents.Query<
    BundleBidProductsQuery,
    BundleBidProductsQueryVariables
  >
    query={BundleBidProductsDocument}
    {...props}
  />
);

/**
 * __useBundleBidProductsQuery__
 *
 * To run a query within a React component, call `useBundleBidProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBundleBidProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBundleBidProductsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useBundleBidProductsQuery(
  baseOptions: Apollo.QueryHookOptions<
    BundleBidProductsQuery,
    BundleBidProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BundleBidProductsQuery,
    BundleBidProductsQueryVariables
  >(BundleBidProductsDocument, options);
}
export function useBundleBidProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BundleBidProductsQuery,
    BundleBidProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BundleBidProductsQuery,
    BundleBidProductsQueryVariables
  >(BundleBidProductsDocument, options);
}
export type BundleBidProductsQueryHookResult = ReturnType<
  typeof useBundleBidProductsQuery
>;
export type BundleBidProductsLazyQueryHookResult = ReturnType<
  typeof useBundleBidProductsLazyQuery
>;
export type BundleBidProductsQueryResult = Apollo.QueryResult<
  BundleBidProductsQuery,
  BundleBidProductsQueryVariables
>;
export const BidsDocument = gql`
  query Bids($type: BidGroupType!, $first: Int!, $after: String) {
    viewer {
      bidGroupsConnection(type: $type, first: $first, after: $after) {
        nodes {
          id
          bids(last: 1) {
            expiresOn
            price {
              amount
            }
          }
          state
          ... on ProductBidGroup {
            product {
              id
              brand {
                name
              }
              image {
                url
              }
            }
          }
          ... on BundleBidGroup {
            products {
              id
              brand {
                name
              }
              image {
                url
              }
              size {
                label
              }
              price {
                amount
              }
            }
          }
        }
        totalCount
      }
    }
  }
`;
export type BidsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<BidsQuery, BidsQueryVariables>,
  'query'
> &
  ({ variables: BidsQueryVariables; skip?: boolean } | { skip: boolean });

export const BidsComponent = (props: BidsComponentProps) => (
  <ApolloReactComponents.Query<BidsQuery, BidsQueryVariables>
    query={BidsDocument}
    {...props}
  />
);

/**
 * __useBidsQuery__
 *
 * To run a query within a React component, call `useBidsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBidsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBidsQuery({
 *   variables: {
 *      type: // value for 'type'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useBidsQuery(
  baseOptions: Apollo.QueryHookOptions<BidsQuery, BidsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BidsQuery, BidsQueryVariables>(BidsDocument, options);
}
export function useBidsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BidsQuery, BidsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BidsQuery, BidsQueryVariables>(
    BidsDocument,
    options
  );
}
export type BidsQueryHookResult = ReturnType<typeof useBidsQuery>;
export type BidsLazyQueryHookResult = ReturnType<typeof useBidsLazyQuery>;
export type BidsQueryResult = Apollo.QueryResult<BidsQuery, BidsQueryVariables>;
export const BidDocument = gql`
  query Bid($id: ID!) {
    viewer {
      bidGroup(id: $id) {
        ...FullBidGroup
      }
    }
  }
  ${FullBidGroupFragmentDoc}
`;
export type BidComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<BidQuery, BidQueryVariables>,
  'query'
> &
  ({ variables: BidQueryVariables; skip?: boolean } | { skip: boolean });

export const BidComponent = (props: BidComponentProps) => (
  <ApolloReactComponents.Query<BidQuery, BidQueryVariables>
    query={BidDocument}
    {...props}
  />
);

/**
 * __useBidQuery__
 *
 * To run a query within a React component, call `useBidQuery` and pass it any options that fit your needs.
 * When your component renders, `useBidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBidQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBidQuery(
  baseOptions: Apollo.QueryHookOptions<BidQuery, BidQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BidQuery, BidQueryVariables>(BidDocument, options);
}
export function useBidLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BidQuery, BidQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BidQuery, BidQueryVariables>(BidDocument, options);
}
export type BidQueryHookResult = ReturnType<typeof useBidQuery>;
export type BidLazyQueryHookResult = ReturnType<typeof useBidLazyQuery>;
export type BidQueryResult = Apollo.QueryResult<BidQuery, BidQueryVariables>;
export const BlogDocument = gql`
  query Blog($first: Int!, $after: String, $filterBy: BlogPostFilters) {
    featured: blogPostsConnection(first: 4, filterBy: { featured: true }) {
      nodes {
        ...FullBlogPost
      }
    }
    blogPostsConnection(first: $first, after: $after, filterBy: $filterBy) {
      nodes {
        ...FullBlogPost
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
    blogTags {
      ...FullBlogTag
    }
  }
  ${FullBlogPostFragmentDoc}
  ${FullBlogTagFragmentDoc}
`;
export type BlogComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<BlogQuery, BlogQueryVariables>,
  'query'
> &
  ({ variables: BlogQueryVariables; skip?: boolean } | { skip: boolean });

export const BlogComponent = (props: BlogComponentProps) => (
  <ApolloReactComponents.Query<BlogQuery, BlogQueryVariables>
    query={BlogDocument}
    {...props}
  />
);

/**
 * __useBlogQuery__
 *
 * To run a query within a React component, call `useBlogQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlogQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useBlogQuery(
  baseOptions: Apollo.QueryHookOptions<BlogQuery, BlogQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BlogQuery, BlogQueryVariables>(BlogDocument, options);
}
export function useBlogLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BlogQuery, BlogQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BlogQuery, BlogQueryVariables>(
    BlogDocument,
    options
  );
}
export type BlogQueryHookResult = ReturnType<typeof useBlogQuery>;
export type BlogLazyQueryHookResult = ReturnType<typeof useBlogLazyQuery>;
export type BlogQueryResult = Apollo.QueryResult<BlogQuery, BlogQueryVariables>;
export const BlogTagDocument = gql`
  query BlogTag(
    $urlKey: String!
    $first: Int!
    $after: String
    $filterBy: BlogPostFilters
  ) {
    blogTag(urlKey: $urlKey) {
      ...FullBlogTag
      postsConnection(first: $first, after: $after, filterBy: $filterBy) {
        nodes {
          ...FullBlogPost
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }
    }
    blogTags {
      ...FullBlogTag
    }
  }
  ${FullBlogTagFragmentDoc}
  ${FullBlogPostFragmentDoc}
`;
export type BlogTagComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    BlogTagQuery,
    BlogTagQueryVariables
  >,
  'query'
> &
  ({ variables: BlogTagQueryVariables; skip?: boolean } | { skip: boolean });

export const BlogTagComponent = (props: BlogTagComponentProps) => (
  <ApolloReactComponents.Query<BlogTagQuery, BlogTagQueryVariables>
    query={BlogTagDocument}
    {...props}
  />
);

/**
 * __useBlogTagQuery__
 *
 * To run a query within a React component, call `useBlogTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlogTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlogTagQuery({
 *   variables: {
 *      urlKey: // value for 'urlKey'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useBlogTagQuery(
  baseOptions: Apollo.QueryHookOptions<BlogTagQuery, BlogTagQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BlogTagQuery, BlogTagQueryVariables>(
    BlogTagDocument,
    options
  );
}
export function useBlogTagLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BlogTagQuery, BlogTagQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BlogTagQuery, BlogTagQueryVariables>(
    BlogTagDocument,
    options
  );
}
export type BlogTagQueryHookResult = ReturnType<typeof useBlogTagQuery>;
export type BlogTagLazyQueryHookResult = ReturnType<typeof useBlogTagLazyQuery>;
export type BlogTagQueryResult = Apollo.QueryResult<
  BlogTagQuery,
  BlogTagQueryVariables
>;
export const BlogPostDocument = gql`
  query BlogPost($urlKey: String!) {
    blogPost(urlKey: $urlKey) {
      ...FullBlogPost
    }
  }
  ${FullBlogPostFragmentDoc}
`;
export type BlogPostComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    BlogPostQuery,
    BlogPostQueryVariables
  >,
  'query'
> &
  ({ variables: BlogPostQueryVariables; skip?: boolean } | { skip: boolean });

export const BlogPostComponent = (props: BlogPostComponentProps) => (
  <ApolloReactComponents.Query<BlogPostQuery, BlogPostQueryVariables>
    query={BlogPostDocument}
    {...props}
  />
);

/**
 * __useBlogPostQuery__
 *
 * To run a query within a React component, call `useBlogPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlogPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlogPostQuery({
 *   variables: {
 *      urlKey: // value for 'urlKey'
 *   },
 * });
 */
export function useBlogPostQuery(
  baseOptions: Apollo.QueryHookOptions<BlogPostQuery, BlogPostQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BlogPostQuery, BlogPostQueryVariables>(
    BlogPostDocument,
    options
  );
}
export function useBlogPostLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BlogPostQuery,
    BlogPostQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BlogPostQuery, BlogPostQueryVariables>(
    BlogPostDocument,
    options
  );
}
export type BlogPostQueryHookResult = ReturnType<typeof useBlogPostQuery>;
export type BlogPostLazyQueryHookResult = ReturnType<
  typeof useBlogPostLazyQuery
>;
export type BlogPostQueryResult = Apollo.QueryResult<
  BlogPostQuery,
  BlogPostQueryVariables
>;
export const SetParcelForCartGroupDocument = gql`
  mutation SetParcelForCartGroup($input: SetParcelForCartGroupInput!) {
    setParcelForCartGroup(input: $input) {
      shoppingCart {
        ...CheckoutCart
      }
    }
  }
  ${CheckoutCartFragmentDoc}
`;
export type SetParcelForCartGroupMutationFn = Apollo.MutationFunction<
  SetParcelForCartGroupMutation,
  SetParcelForCartGroupMutationVariables
>;
export type SetParcelForCartGroupComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    SetParcelForCartGroupMutation,
    SetParcelForCartGroupMutationVariables
  >,
  'mutation'
>;

export const SetParcelForCartGroupComponent = (
  props: SetParcelForCartGroupComponentProps
) => (
  <ApolloReactComponents.Mutation<
    SetParcelForCartGroupMutation,
    SetParcelForCartGroupMutationVariables
  >
    mutation={SetParcelForCartGroupDocument}
    {...props}
  />
);

/**
 * __useSetParcelForCartGroupMutation__
 *
 * To run a mutation, you first call `useSetParcelForCartGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetParcelForCartGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setParcelForCartGroupMutation, { data, loading, error }] = useSetParcelForCartGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetParcelForCartGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetParcelForCartGroupMutation,
    SetParcelForCartGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetParcelForCartGroupMutation,
    SetParcelForCartGroupMutationVariables
  >(SetParcelForCartGroupDocument, options);
}
export type SetParcelForCartGroupMutationHookResult = ReturnType<
  typeof useSetParcelForCartGroupMutation
>;
export type SetParcelForCartGroupMutationResult =
  Apollo.MutationResult<SetParcelForCartGroupMutation>;
export type SetParcelForCartGroupMutationOptions = Apollo.BaseMutationOptions<
  SetParcelForCartGroupMutation,
  SetParcelForCartGroupMutationVariables
>;
export const SetAddressForCartGroupDocument = gql`
  mutation SetAddressForCartGroup($input: SetAddressForCartGroupInput!) {
    setAddressForCartGroup(input: $input) {
      shoppingCart {
        ...CheckoutCart
      }
    }
  }
  ${CheckoutCartFragmentDoc}
`;
export type SetAddressForCartGroupMutationFn = Apollo.MutationFunction<
  SetAddressForCartGroupMutation,
  SetAddressForCartGroupMutationVariables
>;
export type SetAddressForCartGroupComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    SetAddressForCartGroupMutation,
    SetAddressForCartGroupMutationVariables
  >,
  'mutation'
>;

export const SetAddressForCartGroupComponent = (
  props: SetAddressForCartGroupComponentProps
) => (
  <ApolloReactComponents.Mutation<
    SetAddressForCartGroupMutation,
    SetAddressForCartGroupMutationVariables
  >
    mutation={SetAddressForCartGroupDocument}
    {...props}
  />
);

/**
 * __useSetAddressForCartGroupMutation__
 *
 * To run a mutation, you first call `useSetAddressForCartGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAddressForCartGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAddressForCartGroupMutation, { data, loading, error }] = useSetAddressForCartGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetAddressForCartGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetAddressForCartGroupMutation,
    SetAddressForCartGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetAddressForCartGroupMutation,
    SetAddressForCartGroupMutationVariables
  >(SetAddressForCartGroupDocument, options);
}
export type SetAddressForCartGroupMutationHookResult = ReturnType<
  typeof useSetAddressForCartGroupMutation
>;
export type SetAddressForCartGroupMutationResult =
  Apollo.MutationResult<SetAddressForCartGroupMutation>;
export type SetAddressForCartGroupMutationOptions = Apollo.BaseMutationOptions<
  SetAddressForCartGroupMutation,
  SetAddressForCartGroupMutationVariables
>;
export const SetServicePointForCartGroupDocument = gql`
  mutation SetServicePointForCartGroup(
    $input: SetServicePointForCartGroupInput!
  ) {
    setServicePointForCartGroup(input: $input) {
      shoppingCart {
        ...CheckoutCart
      }
    }
  }
  ${CheckoutCartFragmentDoc}
`;
export type SetServicePointForCartGroupMutationFn = Apollo.MutationFunction<
  SetServicePointForCartGroupMutation,
  SetServicePointForCartGroupMutationVariables
>;
export type SetServicePointForCartGroupComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    SetServicePointForCartGroupMutation,
    SetServicePointForCartGroupMutationVariables
  >,
  'mutation'
>;

export const SetServicePointForCartGroupComponent = (
  props: SetServicePointForCartGroupComponentProps
) => (
  <ApolloReactComponents.Mutation<
    SetServicePointForCartGroupMutation,
    SetServicePointForCartGroupMutationVariables
  >
    mutation={SetServicePointForCartGroupDocument}
    {...props}
  />
);

/**
 * __useSetServicePointForCartGroupMutation__
 *
 * To run a mutation, you first call `useSetServicePointForCartGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetServicePointForCartGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setServicePointForCartGroupMutation, { data, loading, error }] = useSetServicePointForCartGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetServicePointForCartGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetServicePointForCartGroupMutation,
    SetServicePointForCartGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetServicePointForCartGroupMutation,
    SetServicePointForCartGroupMutationVariables
  >(SetServicePointForCartGroupDocument, options);
}
export type SetServicePointForCartGroupMutationHookResult = ReturnType<
  typeof useSetServicePointForCartGroupMutation
>;
export type SetServicePointForCartGroupMutationResult =
  Apollo.MutationResult<SetServicePointForCartGroupMutation>;
export type SetServicePointForCartGroupMutationOptions =
  Apollo.BaseMutationOptions<
    SetServicePointForCartGroupMutation,
    SetServicePointForCartGroupMutationVariables
  >;
export const CreatePaymentUrlDocument = gql`
  mutation CreatePaymentURL($input: CreatePaymentURLInput!) {
    createPaymentURL(input: $input) {
      url
    }
  }
`;
export type CreatePaymentUrlMutationFn = Apollo.MutationFunction<
  CreatePaymentUrlMutation,
  CreatePaymentUrlMutationVariables
>;
export type CreatePaymentUrlComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreatePaymentUrlMutation,
    CreatePaymentUrlMutationVariables
  >,
  'mutation'
>;

export const CreatePaymentUrlComponent = (
  props: CreatePaymentUrlComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreatePaymentUrlMutation,
    CreatePaymentUrlMutationVariables
  >
    mutation={CreatePaymentUrlDocument}
    {...props}
  />
);

/**
 * __useCreatePaymentUrlMutation__
 *
 * To run a mutation, you first call `useCreatePaymentUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentUrlMutation, { data, loading, error }] = useCreatePaymentUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaymentUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePaymentUrlMutation,
    CreatePaymentUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePaymentUrlMutation,
    CreatePaymentUrlMutationVariables
  >(CreatePaymentUrlDocument, options);
}
export type CreatePaymentUrlMutationHookResult = ReturnType<
  typeof useCreatePaymentUrlMutation
>;
export type CreatePaymentUrlMutationResult =
  Apollo.MutationResult<CreatePaymentUrlMutation>;
export type CreatePaymentUrlMutationOptions = Apollo.BaseMutationOptions<
  CreatePaymentUrlMutation,
  CreatePaymentUrlMutationVariables
>;
export const CreditCardPaymentDocument = gql`
  mutation CreditCardPayment($paymentMethodId: String!, $clientKey: String!) {
    createPaymentUrlWithClientKey(
      input: { paymentMethodId: $paymentMethodId, clientKey: $clientKey }
    ) {
      url
    }
  }
`;
export type CreditCardPaymentMutationFn = Apollo.MutationFunction<
  CreditCardPaymentMutation,
  CreditCardPaymentMutationVariables
>;
export type CreditCardPaymentComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreditCardPaymentMutation,
    CreditCardPaymentMutationVariables
  >,
  'mutation'
>;

export const CreditCardPaymentComponent = (
  props: CreditCardPaymentComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreditCardPaymentMutation,
    CreditCardPaymentMutationVariables
  >
    mutation={CreditCardPaymentDocument}
    {...props}
  />
);

/**
 * __useCreditCardPaymentMutation__
 *
 * To run a mutation, you first call `useCreditCardPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreditCardPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [creditCardPaymentMutation, { data, loading, error }] = useCreditCardPaymentMutation({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *      clientKey: // value for 'clientKey'
 *   },
 * });
 */
export function useCreditCardPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreditCardPaymentMutation,
    CreditCardPaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreditCardPaymentMutation,
    CreditCardPaymentMutationVariables
  >(CreditCardPaymentDocument, options);
}
export type CreditCardPaymentMutationHookResult = ReturnType<
  typeof useCreditCardPaymentMutation
>;
export type CreditCardPaymentMutationResult =
  Apollo.MutationResult<CreditCardPaymentMutation>;
export type CreditCardPaymentMutationOptions = Apollo.BaseMutationOptions<
  CreditCardPaymentMutation,
  CreditCardPaymentMutationVariables
>;
export const CheckoutCartDocument = gql`
  query CheckoutCart {
    viewer {
      shoppingCart {
        ...CheckoutCart
      }
      account {
        defaultAddress {
          ...FullUserAddress
        }
      }
    }
  }
  ${CheckoutCartFragmentDoc}
  ${FullUserAddressFragmentDoc}
`;
export type CheckoutCartComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CheckoutCartQuery,
    CheckoutCartQueryVariables
  >,
  'query'
>;

export const CheckoutCartComponent = (props: CheckoutCartComponentProps) => (
  <ApolloReactComponents.Query<CheckoutCartQuery, CheckoutCartQueryVariables>
    query={CheckoutCartDocument}
    {...props}
  />
);

/**
 * __useCheckoutCartQuery__
 *
 * To run a query within a React component, call `useCheckoutCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckoutCartQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckoutCartQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CheckoutCartQuery,
    CheckoutCartQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CheckoutCartQuery, CheckoutCartQueryVariables>(
    CheckoutCartDocument,
    options
  );
}
export function useCheckoutCartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckoutCartQuery,
    CheckoutCartQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CheckoutCartQuery, CheckoutCartQueryVariables>(
    CheckoutCartDocument,
    options
  );
}
export type CheckoutCartQueryHookResult = ReturnType<
  typeof useCheckoutCartQuery
>;
export type CheckoutCartLazyQueryHookResult = ReturnType<
  typeof useCheckoutCartLazyQuery
>;
export type CheckoutCartQueryResult = Apollo.QueryResult<
  CheckoutCartQuery,
  CheckoutCartQueryVariables
>;
export const AddressRegionsDocument = gql`
  query AddressRegions {
    addressRegions {
      id
      country
      code
      name
    }
  }
`;
export type AddressRegionsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AddressRegionsQuery,
    AddressRegionsQueryVariables
  >,
  'query'
>;

export const AddressRegionsComponent = (
  props: AddressRegionsComponentProps
) => (
  <ApolloReactComponents.Query<
    AddressRegionsQuery,
    AddressRegionsQueryVariables
  >
    query={AddressRegionsDocument}
    {...props}
  />
);

/**
 * __useAddressRegionsQuery__
 *
 * To run a query within a React component, call `useAddressRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressRegionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAddressRegionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AddressRegionsQuery,
    AddressRegionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AddressRegionsQuery, AddressRegionsQueryVariables>(
    AddressRegionsDocument,
    options
  );
}
export function useAddressRegionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AddressRegionsQuery,
    AddressRegionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AddressRegionsQuery, AddressRegionsQueryVariables>(
    AddressRegionsDocument,
    options
  );
}
export type AddressRegionsQueryHookResult = ReturnType<
  typeof useAddressRegionsQuery
>;
export type AddressRegionsLazyQueryHookResult = ReturnType<
  typeof useAddressRegionsLazyQuery
>;
export type AddressRegionsQueryResult = Apollo.QueryResult<
  AddressRegionsQuery,
  AddressRegionsQueryVariables
>;
export const SearchServicePointsDocument = gql`
  query SearchServicePoints(
    $shipperId: ID!
    $regionId: ID!
    $postalCode: String!
  ) {
    searchServicePoints(
      shipperId: $shipperId
      regionId: $regionId
      postalCode: $postalCode
    ) {
      distance {
        amount
        unit
      }
      servicePoint {
        ...FullServicePoint
      }
    }
  }
  ${FullServicePointFragmentDoc}
`;
export type SearchServicePointsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    SearchServicePointsQuery,
    SearchServicePointsQueryVariables
  >,
  'query'
> &
  (
    | { variables: SearchServicePointsQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const SearchServicePointsComponent = (
  props: SearchServicePointsComponentProps
) => (
  <ApolloReactComponents.Query<
    SearchServicePointsQuery,
    SearchServicePointsQueryVariables
  >
    query={SearchServicePointsDocument}
    {...props}
  />
);

/**
 * __useSearchServicePointsQuery__
 *
 * To run a query within a React component, call `useSearchServicePointsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchServicePointsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchServicePointsQuery({
 *   variables: {
 *      shipperId: // value for 'shipperId'
 *      regionId: // value for 'regionId'
 *      postalCode: // value for 'postalCode'
 *   },
 * });
 */
export function useSearchServicePointsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchServicePointsQuery,
    SearchServicePointsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchServicePointsQuery,
    SearchServicePointsQueryVariables
  >(SearchServicePointsDocument, options);
}
export function useSearchServicePointsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchServicePointsQuery,
    SearchServicePointsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchServicePointsQuery,
    SearchServicePointsQueryVariables
  >(SearchServicePointsDocument, options);
}
export type SearchServicePointsQueryHookResult = ReturnType<
  typeof useSearchServicePointsQuery
>;
export type SearchServicePointsLazyQueryHookResult = ReturnType<
  typeof useSearchServicePointsLazyQuery
>;
export type SearchServicePointsQueryResult = Apollo.QueryResult<
  SearchServicePointsQuery,
  SearchServicePointsQueryVariables
>;
export const FavoriteProductDocument = gql`
  mutation FavoriteProduct($productId: ID!) {
    favoriteProduct(input: { productId: $productId }) {
      product {
        id
        viewerHasFavorited
      }
    }
  }
`;
export type FavoriteProductMutationFn = Apollo.MutationFunction<
  FavoriteProductMutation,
  FavoriteProductMutationVariables
>;
export type FavoriteProductComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    FavoriteProductMutation,
    FavoriteProductMutationVariables
  >,
  'mutation'
>;

export const FavoriteProductComponent = (
  props: FavoriteProductComponentProps
) => (
  <ApolloReactComponents.Mutation<
    FavoriteProductMutation,
    FavoriteProductMutationVariables
  >
    mutation={FavoriteProductDocument}
    {...props}
  />
);

/**
 * __useFavoriteProductMutation__
 *
 * To run a mutation, you first call `useFavoriteProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFavoriteProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [favoriteProductMutation, { data, loading, error }] = useFavoriteProductMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useFavoriteProductMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FavoriteProductMutation,
    FavoriteProductMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FavoriteProductMutation,
    FavoriteProductMutationVariables
  >(FavoriteProductDocument, options);
}
export type FavoriteProductMutationHookResult = ReturnType<
  typeof useFavoriteProductMutation
>;
export type FavoriteProductMutationResult =
  Apollo.MutationResult<FavoriteProductMutation>;
export type FavoriteProductMutationOptions = Apollo.BaseMutationOptions<
  FavoriteProductMutation,
  FavoriteProductMutationVariables
>;
export const UnfavoriteProductDocument = gql`
  mutation UnfavoriteProduct($productId: ID!) {
    unfavoriteProduct(input: { productId: $productId }) {
      product {
        id
        viewerHasFavorited
      }
    }
  }
`;
export type UnfavoriteProductMutationFn = Apollo.MutationFunction<
  UnfavoriteProductMutation,
  UnfavoriteProductMutationVariables
>;
export type UnfavoriteProductComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UnfavoriteProductMutation,
    UnfavoriteProductMutationVariables
  >,
  'mutation'
>;

export const UnfavoriteProductComponent = (
  props: UnfavoriteProductComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UnfavoriteProductMutation,
    UnfavoriteProductMutationVariables
  >
    mutation={UnfavoriteProductDocument}
    {...props}
  />
);

/**
 * __useUnfavoriteProductMutation__
 *
 * To run a mutation, you first call `useUnfavoriteProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnfavoriteProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unfavoriteProductMutation, { data, loading, error }] = useUnfavoriteProductMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useUnfavoriteProductMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnfavoriteProductMutation,
    UnfavoriteProductMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnfavoriteProductMutation,
    UnfavoriteProductMutationVariables
  >(UnfavoriteProductDocument, options);
}
export type UnfavoriteProductMutationHookResult = ReturnType<
  typeof useUnfavoriteProductMutation
>;
export type UnfavoriteProductMutationResult =
  Apollo.MutationResult<UnfavoriteProductMutation>;
export type UnfavoriteProductMutationOptions = Apollo.BaseMutationOptions<
  UnfavoriteProductMutation,
  UnfavoriteProductMutationVariables
>;
export const FollowUserDocument = gql`
  mutation FollowUser($userId: ID!) {
    followUser(input: { userId: $userId }) {
      user {
        id
        viewerIsFollowing
      }
    }
  }
`;
export type FollowUserMutationFn = Apollo.MutationFunction<
  FollowUserMutation,
  FollowUserMutationVariables
>;
export type FollowUserComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    FollowUserMutation,
    FollowUserMutationVariables
  >,
  'mutation'
>;

export const FollowUserComponent = (props: FollowUserComponentProps) => (
  <ApolloReactComponents.Mutation<
    FollowUserMutation,
    FollowUserMutationVariables
  >
    mutation={FollowUserDocument}
    {...props}
  />
);

/**
 * __useFollowUserMutation__
 *
 * To run a mutation, you first call `useFollowUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followUserMutation, { data, loading, error }] = useFollowUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useFollowUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FollowUserMutation,
    FollowUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FollowUserMutation, FollowUserMutationVariables>(
    FollowUserDocument,
    options
  );
}
export type FollowUserMutationHookResult = ReturnType<
  typeof useFollowUserMutation
>;
export type FollowUserMutationResult =
  Apollo.MutationResult<FollowUserMutation>;
export type FollowUserMutationOptions = Apollo.BaseMutationOptions<
  FollowUserMutation,
  FollowUserMutationVariables
>;
export const UnfollowUserDocument = gql`
  mutation UnfollowUser($userId: ID!) {
    unfollowUser(input: { userId: $userId }) {
      user {
        id
        viewerIsFollowing
      }
    }
  }
`;
export type UnfollowUserMutationFn = Apollo.MutationFunction<
  UnfollowUserMutation,
  UnfollowUserMutationVariables
>;
export type UnfollowUserComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UnfollowUserMutation,
    UnfollowUserMutationVariables
  >,
  'mutation'
>;

export const UnfollowUserComponent = (props: UnfollowUserComponentProps) => (
  <ApolloReactComponents.Mutation<
    UnfollowUserMutation,
    UnfollowUserMutationVariables
  >
    mutation={UnfollowUserDocument}
    {...props}
  />
);

/**
 * __useUnfollowUserMutation__
 *
 * To run a mutation, you first call `useUnfollowUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnfollowUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unfollowUserMutation, { data, loading, error }] = useUnfollowUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUnfollowUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnfollowUserMutation,
    UnfollowUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnfollowUserMutation,
    UnfollowUserMutationVariables
  >(UnfollowUserDocument, options);
}
export type UnfollowUserMutationHookResult = ReturnType<
  typeof useUnfollowUserMutation
>;
export type UnfollowUserMutationResult =
  Apollo.MutationResult<UnfollowUserMutation>;
export type UnfollowUserMutationOptions = Apollo.BaseMutationOptions<
  UnfollowUserMutation,
  UnfollowUserMutationVariables
>;
export const UpdateEmailSubscriptionDocument = gql`
  mutation UpdateEmailSubscription($input: UpdateEmailSubscriptionInput!) {
    updateEmailSubscription(input: $input) {
      subscriptions {
        ...FullEmailSubscription
      }
    }
  }
  ${FullEmailSubscriptionFragmentDoc}
`;
export type UpdateEmailSubscriptionMutationFn = Apollo.MutationFunction<
  UpdateEmailSubscriptionMutation,
  UpdateEmailSubscriptionMutationVariables
>;
export type UpdateEmailSubscriptionComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateEmailSubscriptionMutation,
    UpdateEmailSubscriptionMutationVariables
  >,
  'mutation'
>;

export const UpdateEmailSubscriptionComponent = (
  props: UpdateEmailSubscriptionComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateEmailSubscriptionMutation,
    UpdateEmailSubscriptionMutationVariables
  >
    mutation={UpdateEmailSubscriptionDocument}
    {...props}
  />
);

/**
 * __useUpdateEmailSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpdateEmailSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailSubscriptionMutation, { data, loading, error }] = useUpdateEmailSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmailSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEmailSubscriptionMutation,
    UpdateEmailSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateEmailSubscriptionMutation,
    UpdateEmailSubscriptionMutationVariables
  >(UpdateEmailSubscriptionDocument, options);
}
export type UpdateEmailSubscriptionMutationHookResult = ReturnType<
  typeof useUpdateEmailSubscriptionMutation
>;
export type UpdateEmailSubscriptionMutationResult =
  Apollo.MutationResult<UpdateEmailSubscriptionMutation>;
export type UpdateEmailSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  UpdateEmailSubscriptionMutation,
  UpdateEmailSubscriptionMutationVariables
>;
export const UnsubscribeFromAllEmailsDocument = gql`
  mutation UnsubscribeFromAllEmails($input: UnsubscribeFromAllEmailsInput!) {
    unsubscribeFromAllEmails(input: $input) {
      subscriptions {
        ...FullEmailSubscription
      }
    }
  }
  ${FullEmailSubscriptionFragmentDoc}
`;
export type UnsubscribeFromAllEmailsMutationFn = Apollo.MutationFunction<
  UnsubscribeFromAllEmailsMutation,
  UnsubscribeFromAllEmailsMutationVariables
>;
export type UnsubscribeFromAllEmailsComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UnsubscribeFromAllEmailsMutation,
    UnsubscribeFromAllEmailsMutationVariables
  >,
  'mutation'
>;

export const UnsubscribeFromAllEmailsComponent = (
  props: UnsubscribeFromAllEmailsComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UnsubscribeFromAllEmailsMutation,
    UnsubscribeFromAllEmailsMutationVariables
  >
    mutation={UnsubscribeFromAllEmailsDocument}
    {...props}
  />
);

/**
 * __useUnsubscribeFromAllEmailsMutation__
 *
 * To run a mutation, you first call `useUnsubscribeFromAllEmailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeFromAllEmailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeFromAllEmailsMutation, { data, loading, error }] = useUnsubscribeFromAllEmailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnsubscribeFromAllEmailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnsubscribeFromAllEmailsMutation,
    UnsubscribeFromAllEmailsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnsubscribeFromAllEmailsMutation,
    UnsubscribeFromAllEmailsMutationVariables
  >(UnsubscribeFromAllEmailsDocument, options);
}
export type UnsubscribeFromAllEmailsMutationHookResult = ReturnType<
  typeof useUnsubscribeFromAllEmailsMutation
>;
export type UnsubscribeFromAllEmailsMutationResult =
  Apollo.MutationResult<UnsubscribeFromAllEmailsMutation>;
export type UnsubscribeFromAllEmailsMutationOptions =
  Apollo.BaseMutationOptions<
    UnsubscribeFromAllEmailsMutation,
    UnsubscribeFromAllEmailsMutationVariables
  >;
export const EmailSubscriptionsDocument = gql`
  query EmailSubscriptions(
    $authorisation: EmailSubscriptionAuthorisationInput
  ) {
    viewer {
      emailSubscriptions(authorisation: $authorisation) {
        ...FullEmailSubscription
      }
    }
  }
  ${FullEmailSubscriptionFragmentDoc}
`;
export type EmailSubscriptionsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    EmailSubscriptionsQuery,
    EmailSubscriptionsQueryVariables
  >,
  'query'
>;

export const EmailSubscriptionsComponent = (
  props: EmailSubscriptionsComponentProps
) => (
  <ApolloReactComponents.Query<
    EmailSubscriptionsQuery,
    EmailSubscriptionsQueryVariables
  >
    query={EmailSubscriptionsDocument}
    {...props}
  />
);

/**
 * __useEmailSubscriptionsQuery__
 *
 * To run a query within a React component, call `useEmailSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailSubscriptionsQuery({
 *   variables: {
 *      authorisation: // value for 'authorisation'
 *   },
 * });
 */
export function useEmailSubscriptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EmailSubscriptionsQuery,
    EmailSubscriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EmailSubscriptionsQuery,
    EmailSubscriptionsQueryVariables
  >(EmailSubscriptionsDocument, options);
}
export function useEmailSubscriptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EmailSubscriptionsQuery,
    EmailSubscriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EmailSubscriptionsQuery,
    EmailSubscriptionsQueryVariables
  >(EmailSubscriptionsDocument, options);
}
export type EmailSubscriptionsQueryHookResult = ReturnType<
  typeof useEmailSubscriptionsQuery
>;
export type EmailSubscriptionsLazyQueryHookResult = ReturnType<
  typeof useEmailSubscriptionsLazyQuery
>;
export type EmailSubscriptionsQueryResult = Apollo.QueryResult<
  EmailSubscriptionsQuery,
  EmailSubscriptionsQueryVariables
>;
export const ProductsDocument = gql`
  query Products(
    $first: Int!
    $after: String
    $filterBy: ProductFilters
    $orderBy: ProductsOrder
  ) {
    productsConnection(
      first: $first
      after: $after
      filterBy: $filterBy
      orderBy: $orderBy
    ) {
      nodes {
        ...ListProduct
      }
      totalCount
      pageInfo {
        hasNextPage
      }
    }
    alternatingContent(feedFilters: $filterBy, feedOrderBy: $orderBy) {
      ...AlternatingContentItem
    }
  }
  ${ListProductFragmentDoc}
  ${AlternatingContentItemFragmentDoc}
`;
export type ProductsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ProductsQuery,
    ProductsQueryVariables
  >,
  'query'
> &
  ({ variables: ProductsQueryVariables; skip?: boolean } | { skip: boolean });

export const ProductsComponent = (props: ProductsComponentProps) => (
  <ApolloReactComponents.Query<ProductsQuery, ProductsQueryVariables>
    query={ProductsDocument}
    {...props}
  />
);

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useProductsQuery(
  baseOptions: Apollo.QueryHookOptions<ProductsQuery, ProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductsQuery, ProductsQueryVariables>(
    ProductsDocument,
    options
  );
}
export function useProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductsQuery,
    ProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductsQuery, ProductsQueryVariables>(
    ProductsDocument,
    options
  );
}
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<
  typeof useProductsLazyQuery
>;
export type ProductsQueryResult = Apollo.QueryResult<
  ProductsQuery,
  ProductsQueryVariables
>;
export const UserSizesDocument = gql`
  query UserSizes {
    viewer {
      account {
        sizes {
          id
        }
      }
    }
  }
`;
export type UserSizesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    UserSizesQuery,
    UserSizesQueryVariables
  >,
  'query'
>;

export const UserSizesComponent = (props: UserSizesComponentProps) => (
  <ApolloReactComponents.Query<UserSizesQuery, UserSizesQueryVariables>
    query={UserSizesDocument}
    {...props}
  />
);

/**
 * __useUserSizesQuery__
 *
 * To run a query within a React component, call `useUserSizesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSizesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSizesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserSizesQuery(
  baseOptions?: Apollo.QueryHookOptions<UserSizesQuery, UserSizesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserSizesQuery, UserSizesQueryVariables>(
    UserSizesDocument,
    options
  );
}
export function useUserSizesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserSizesQuery,
    UserSizesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserSizesQuery, UserSizesQueryVariables>(
    UserSizesDocument,
    options
  );
}
export type UserSizesQueryHookResult = ReturnType<typeof useUserSizesQuery>;
export type UserSizesLazyQueryHookResult = ReturnType<
  typeof useUserSizesLazyQuery
>;
export type UserSizesQueryResult = Apollo.QueryResult<
  UserSizesQuery,
  UserSizesQueryVariables
>;
export const ProductCategoryDocument = gql`
  query ProductCategory($id: ID!) {
    productCategory(id: $id) {
      id
      longDescription
      longTitle
    }
  }
`;
export type ProductCategoryComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ProductCategoryQuery,
    ProductCategoryQueryVariables
  >,
  'query'
> &
  (
    | { variables: ProductCategoryQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const ProductCategoryComponent = (
  props: ProductCategoryComponentProps
) => (
  <ApolloReactComponents.Query<
    ProductCategoryQuery,
    ProductCategoryQueryVariables
  >
    query={ProductCategoryDocument}
    {...props}
  />
);

/**
 * __useProductCategoryQuery__
 *
 * To run a query within a React component, call `useProductCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductCategoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProductCategoryQuery,
    ProductCategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductCategoryQuery, ProductCategoryQueryVariables>(
    ProductCategoryDocument,
    options
  );
}
export function useProductCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductCategoryQuery,
    ProductCategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProductCategoryQuery,
    ProductCategoryQueryVariables
  >(ProductCategoryDocument, options);
}
export type ProductCategoryQueryHookResult = ReturnType<
  typeof useProductCategoryQuery
>;
export type ProductCategoryLazyQueryHookResult = ReturnType<
  typeof useProductCategoryLazyQuery
>;
export type ProductCategoryQueryResult = Apollo.QueryResult<
  ProductCategoryQuery,
  ProductCategoryQueryVariables
>;
export const InactiveProductMessageDocument = gql`
  query InactiveProductMessage($inactiveProductId: ID!) {
    product(id: $inactiveProductId) {
      id
      title
      image(variant: LEGACY_SMALL) {
        url
      }
    }
  }
`;
export type InactiveProductMessageComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    InactiveProductMessageQuery,
    InactiveProductMessageQueryVariables
  >,
  'query'
> &
  (
    | { variables: InactiveProductMessageQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const InactiveProductMessageComponent = (
  props: InactiveProductMessageComponentProps
) => (
  <ApolloReactComponents.Query<
    InactiveProductMessageQuery,
    InactiveProductMessageQueryVariables
  >
    query={InactiveProductMessageDocument}
    {...props}
  />
);

/**
 * __useInactiveProductMessageQuery__
 *
 * To run a query within a React component, call `useInactiveProductMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useInactiveProductMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInactiveProductMessageQuery({
 *   variables: {
 *      inactiveProductId: // value for 'inactiveProductId'
 *   },
 * });
 */
export function useInactiveProductMessageQuery(
  baseOptions: Apollo.QueryHookOptions<
    InactiveProductMessageQuery,
    InactiveProductMessageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InactiveProductMessageQuery,
    InactiveProductMessageQueryVariables
  >(InactiveProductMessageDocument, options);
}
export function useInactiveProductMessageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InactiveProductMessageQuery,
    InactiveProductMessageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InactiveProductMessageQuery,
    InactiveProductMessageQueryVariables
  >(InactiveProductMessageDocument, options);
}
export type InactiveProductMessageQueryHookResult = ReturnType<
  typeof useInactiveProductMessageQuery
>;
export type InactiveProductMessageLazyQueryHookResult = ReturnType<
  typeof useInactiveProductMessageLazyQuery
>;
export type InactiveProductMessageQueryResult = Apollo.QueryResult<
  InactiveProductMessageQuery,
  InactiveProductMessageQueryVariables
>;
export const PopularItemsDocument = gql`
  query PopularItems {
    productsConnection(first: 12) {
      nodes {
        ...ListProduct
      }
    }
  }
  ${ListProductFragmentDoc}
`;
export type PopularItemsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    PopularItemsQuery,
    PopularItemsQueryVariables
  >,
  'query'
>;

export const PopularItemsComponent = (props: PopularItemsComponentProps) => (
  <ApolloReactComponents.Query<PopularItemsQuery, PopularItemsQueryVariables>
    query={PopularItemsDocument}
    {...props}
  />
);

/**
 * __usePopularItemsQuery__
 *
 * To run a query within a React component, call `usePopularItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePopularItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePopularItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePopularItemsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PopularItemsQuery,
    PopularItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PopularItemsQuery, PopularItemsQueryVariables>(
    PopularItemsDocument,
    options
  );
}
export function usePopularItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PopularItemsQuery,
    PopularItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PopularItemsQuery, PopularItemsQueryVariables>(
    PopularItemsDocument,
    options
  );
}
export type PopularItemsQueryHookResult = ReturnType<
  typeof usePopularItemsQuery
>;
export type PopularItemsLazyQueryHookResult = ReturnType<
  typeof usePopularItemsLazyQuery
>;
export type PopularItemsQueryResult = Apollo.QueryResult<
  PopularItemsQuery,
  PopularItemsQueryVariables
>;
export const PopularSellersDocument = gql`
  query PopularSellers($userId: ID!) {
    user(id: $userId) {
      followingConnection(first: 6) {
        nodes {
          profileImage {
            url
          }
          id
          displayName
          followerCount
          viewerIsFollowing
        }
      }
    }
  }
`;
export type PopularSellersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    PopularSellersQuery,
    PopularSellersQueryVariables
  >,
  'query'
> &
  (
    | { variables: PopularSellersQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const PopularSellersComponent = (
  props: PopularSellersComponentProps
) => (
  <ApolloReactComponents.Query<
    PopularSellersQuery,
    PopularSellersQueryVariables
  >
    query={PopularSellersDocument}
    {...props}
  />
);

/**
 * __usePopularSellersQuery__
 *
 * To run a query within a React component, call `usePopularSellersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePopularSellersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePopularSellersQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function usePopularSellersQuery(
  baseOptions: Apollo.QueryHookOptions<
    PopularSellersQuery,
    PopularSellersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PopularSellersQuery, PopularSellersQueryVariables>(
    PopularSellersDocument,
    options
  );
}
export function usePopularSellersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PopularSellersQuery,
    PopularSellersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PopularSellersQuery, PopularSellersQueryVariables>(
    PopularSellersDocument,
    options
  );
}
export type PopularSellersQueryHookResult = ReturnType<
  typeof usePopularSellersQuery
>;
export type PopularSellersLazyQueryHookResult = ReturnType<
  typeof usePopularSellersLazyQuery
>;
export type PopularSellersQueryResult = Apollo.QueryResult<
  PopularSellersQuery,
  PopularSellersQueryVariables
>;
export const HtmlDocumentDocument = gql`
  query HtmlDocument($name: HtmlDocumentName!) {
    htmlDocument(name: $name) {
      html
    }
  }
`;
export type HtmlDocumentComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    HtmlDocumentQuery,
    HtmlDocumentQueryVariables
  >,
  'query'
> &
  (
    | { variables: HtmlDocumentQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const HtmlDocumentComponent = (props: HtmlDocumentComponentProps) => (
  <ApolloReactComponents.Query<HtmlDocumentQuery, HtmlDocumentQueryVariables>
    query={HtmlDocumentDocument}
    {...props}
  />
);

/**
 * __useHtmlDocumentQuery__
 *
 * To run a query within a React component, call `useHtmlDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useHtmlDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHtmlDocumentQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useHtmlDocumentQuery(
  baseOptions: Apollo.QueryHookOptions<
    HtmlDocumentQuery,
    HtmlDocumentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HtmlDocumentQuery, HtmlDocumentQueryVariables>(
    HtmlDocumentDocument,
    options
  );
}
export function useHtmlDocumentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HtmlDocumentQuery,
    HtmlDocumentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HtmlDocumentQuery, HtmlDocumentQueryVariables>(
    HtmlDocumentDocument,
    options
  );
}
export type HtmlDocumentQueryHookResult = ReturnType<
  typeof useHtmlDocumentQuery
>;
export type HtmlDocumentLazyQueryHookResult = ReturnType<
  typeof useHtmlDocumentLazyQuery
>;
export type HtmlDocumentQueryResult = Apollo.QueryResult<
  HtmlDocumentQuery,
  HtmlDocumentQueryVariables
>;
export const SendChatMessageDocument = gql`
  mutation SendChatMessage($input: SendChatMessageInput!) {
    sendChatMessage(input: $input) {
      chatMessage {
        ...ChatMessage
      }
    }
  }
  ${ChatMessageFragmentDoc}
`;
export type SendChatMessageMutationFn = Apollo.MutationFunction<
  SendChatMessageMutation,
  SendChatMessageMutationVariables
>;
export type SendChatMessageComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    SendChatMessageMutation,
    SendChatMessageMutationVariables
  >,
  'mutation'
>;

export const SendChatMessageComponent = (
  props: SendChatMessageComponentProps
) => (
  <ApolloReactComponents.Mutation<
    SendChatMessageMutation,
    SendChatMessageMutationVariables
  >
    mutation={SendChatMessageDocument}
    {...props}
  />
);

/**
 * __useSendChatMessageMutation__
 *
 * To run a mutation, you first call `useSendChatMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendChatMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendChatMessageMutation, { data, loading, error }] = useSendChatMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendChatMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendChatMessageMutation,
    SendChatMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendChatMessageMutation,
    SendChatMessageMutationVariables
  >(SendChatMessageDocument, options);
}
export type SendChatMessageMutationHookResult = ReturnType<
  typeof useSendChatMessageMutation
>;
export type SendChatMessageMutationResult =
  Apollo.MutationResult<SendChatMessageMutation>;
export type SendChatMessageMutationOptions = Apollo.BaseMutationOptions<
  SendChatMessageMutation,
  SendChatMessageMutationVariables
>;
export const NotificationsDocument = gql`
  query Notifications($first: Int!, $after: String) {
    viewer {
      notificationsConnection(first: $first, after: $after) {
        nodes {
          ...Notification
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${NotificationFragmentDoc}
`;
export type NotificationsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    NotificationsQuery,
    NotificationsQueryVariables
  >,
  'query'
> &
  (
    | { variables: NotificationsQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const NotificationsComponent = (props: NotificationsComponentProps) => (
  <ApolloReactComponents.Query<NotificationsQuery, NotificationsQueryVariables>
    query={NotificationsDocument}
    {...props}
  />
);

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useNotificationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    NotificationsQuery,
    NotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(
    NotificationsDocument,
    options
  );
}
export function useNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NotificationsQuery,
    NotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(
    NotificationsDocument,
    options
  );
}
export type NotificationsQueryHookResult = ReturnType<
  typeof useNotificationsQuery
>;
export type NotificationsLazyQueryHookResult = ReturnType<
  typeof useNotificationsLazyQuery
>;
export type NotificationsQueryResult = Apollo.QueryResult<
  NotificationsQuery,
  NotificationsQueryVariables
>;
export const ChatsDocument = gql`
  query Chats($first: Int!, $after: String) {
    viewer {
      chatsConnection(first: $first, after: $after) {
        nodes {
          ...Chat
        }
        totalCount
      }
    }
  }
  ${ChatFragmentDoc}
`;
export type ChatsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<ChatsQuery, ChatsQueryVariables>,
  'query'
> &
  ({ variables: ChatsQueryVariables; skip?: boolean } | { skip: boolean });

export const ChatsComponent = (props: ChatsComponentProps) => (
  <ApolloReactComponents.Query<ChatsQuery, ChatsQueryVariables>
    query={ChatsDocument}
    {...props}
  />
);

/**
 * __useChatsQuery__
 *
 * To run a query within a React component, call `useChatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useChatsQuery(
  baseOptions: Apollo.QueryHookOptions<ChatsQuery, ChatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChatsQuery, ChatsQueryVariables>(
    ChatsDocument,
    options
  );
}
export function useChatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ChatsQuery, ChatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ChatsQuery, ChatsQueryVariables>(
    ChatsDocument,
    options
  );
}
export type ChatsQueryHookResult = ReturnType<typeof useChatsQuery>;
export type ChatsLazyQueryHookResult = ReturnType<typeof useChatsLazyQuery>;
export type ChatsQueryResult = Apollo.QueryResult<
  ChatsQuery,
  ChatsQueryVariables
>;
export const ChatDocument = gql`
  query Chat($userId: ID!) {
    viewer {
      chat(userId: $userId) {
        ...Chat
      }
    }
  }
  ${ChatFragmentDoc}
`;
export type ChatComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<ChatQuery, ChatQueryVariables>,
  'query'
> &
  ({ variables: ChatQueryVariables; skip?: boolean } | { skip: boolean });

export const ChatComponent = (props: ChatComponentProps) => (
  <ApolloReactComponents.Query<ChatQuery, ChatQueryVariables>
    query={ChatDocument}
    {...props}
  />
);

/**
 * __useChatQuery__
 *
 * To run a query within a React component, call `useChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useChatQuery(
  baseOptions: Apollo.QueryHookOptions<ChatQuery, ChatQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChatQuery, ChatQueryVariables>(ChatDocument, options);
}
export function useChatLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ChatQuery, ChatQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ChatQuery, ChatQueryVariables>(
    ChatDocument,
    options
  );
}
export type ChatQueryHookResult = ReturnType<typeof useChatQuery>;
export type ChatLazyQueryHookResult = ReturnType<typeof useChatLazyQuery>;
export type ChatQueryResult = Apollo.QueryResult<ChatQuery, ChatQueryVariables>;
export const ChatMessagesDocument = gql`
  query ChatMessages($userId: ID!, $first: Int!, $after: String) {
    viewer {
      chat(userId: $userId) {
        messagesConnection(first: $first, after: $after) {
          nodes {
            ...ChatMessage
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
    }
  }
  ${ChatMessageFragmentDoc}
`;
export type ChatMessagesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ChatMessagesQuery,
    ChatMessagesQueryVariables
  >,
  'query'
> &
  (
    | { variables: ChatMessagesQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const ChatMessagesComponent = (props: ChatMessagesComponentProps) => (
  <ApolloReactComponents.Query<ChatMessagesQuery, ChatMessagesQueryVariables>
    query={ChatMessagesDocument}
    {...props}
  />
);

/**
 * __useChatMessagesQuery__
 *
 * To run a query within a React component, call `useChatMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatMessagesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useChatMessagesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ChatMessagesQuery,
    ChatMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChatMessagesQuery, ChatMessagesQueryVariables>(
    ChatMessagesDocument,
    options
  );
}
export function useChatMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChatMessagesQuery,
    ChatMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ChatMessagesQuery, ChatMessagesQueryVariables>(
    ChatMessagesDocument,
    options
  );
}
export type ChatMessagesQueryHookResult = ReturnType<
  typeof useChatMessagesQuery
>;
export type ChatMessagesLazyQueryHookResult = ReturnType<
  typeof useChatMessagesLazyQuery
>;
export type ChatMessagesQueryResult = Apollo.QueryResult<
  ChatMessagesQuery,
  ChatMessagesQueryVariables
>;
export const CreateStripeUploadUrlDocument = gql`
  mutation CreateStripeUploadUrl {
    frontUploadUrl: createStripeUploadUrl(input: { type: PHOTO_ID }) {
      url
      publishableKey
      purpose
    }
    backUploadUrl: createStripeUploadUrl(input: { type: PHOTO_ID }) {
      url
      publishableKey
      purpose
    }
  }
`;
export type CreateStripeUploadUrlMutationFn = Apollo.MutationFunction<
  CreateStripeUploadUrlMutation,
  CreateStripeUploadUrlMutationVariables
>;
export type CreateStripeUploadUrlComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateStripeUploadUrlMutation,
    CreateStripeUploadUrlMutationVariables
  >,
  'mutation'
>;

export const CreateStripeUploadUrlComponent = (
  props: CreateStripeUploadUrlComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateStripeUploadUrlMutation,
    CreateStripeUploadUrlMutationVariables
  >
    mutation={CreateStripeUploadUrlDocument}
    {...props}
  />
);

/**
 * __useCreateStripeUploadUrlMutation__
 *
 * To run a mutation, you first call `useCreateStripeUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeUploadUrlMutation, { data, loading, error }] = useCreateStripeUploadUrlMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateStripeUploadUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateStripeUploadUrlMutation,
    CreateStripeUploadUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateStripeUploadUrlMutation,
    CreateStripeUploadUrlMutationVariables
  >(CreateStripeUploadUrlDocument, options);
}
export type CreateStripeUploadUrlMutationHookResult = ReturnType<
  typeof useCreateStripeUploadUrlMutation
>;
export type CreateStripeUploadUrlMutationResult =
  Apollo.MutationResult<CreateStripeUploadUrlMutation>;
export type CreateStripeUploadUrlMutationOptions = Apollo.BaseMutationOptions<
  CreateStripeUploadUrlMutation,
  CreateStripeUploadUrlMutationVariables
>;
export const CreateKycPhotoIdSubmissionDocument = gql`
  mutation CreateKYCPhotoIdSubmission(
    $input: CreateKYCPhotoIdSubmissionInput!
  ) {
    createKYCPhotoIdSubmission(input: $input) {
      account {
        kycStatus
      }
    }
  }
`;
export type CreateKycPhotoIdSubmissionMutationFn = Apollo.MutationFunction<
  CreateKycPhotoIdSubmissionMutation,
  CreateKycPhotoIdSubmissionMutationVariables
>;
export type CreateKycPhotoIdSubmissionComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateKycPhotoIdSubmissionMutation,
    CreateKycPhotoIdSubmissionMutationVariables
  >,
  'mutation'
>;

export const CreateKycPhotoIdSubmissionComponent = (
  props: CreateKycPhotoIdSubmissionComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateKycPhotoIdSubmissionMutation,
    CreateKycPhotoIdSubmissionMutationVariables
  >
    mutation={CreateKycPhotoIdSubmissionDocument}
    {...props}
  />
);

/**
 * __useCreateKycPhotoIdSubmissionMutation__
 *
 * To run a mutation, you first call `useCreateKycPhotoIdSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKycPhotoIdSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKycPhotoIdSubmissionMutation, { data, loading, error }] = useCreateKycPhotoIdSubmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateKycPhotoIdSubmissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateKycPhotoIdSubmissionMutation,
    CreateKycPhotoIdSubmissionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateKycPhotoIdSubmissionMutation,
    CreateKycPhotoIdSubmissionMutationVariables
  >(CreateKycPhotoIdSubmissionDocument, options);
}
export type CreateKycPhotoIdSubmissionMutationHookResult = ReturnType<
  typeof useCreateKycPhotoIdSubmissionMutation
>;
export type CreateKycPhotoIdSubmissionMutationResult =
  Apollo.MutationResult<CreateKycPhotoIdSubmissionMutation>;
export type CreateKycPhotoIdSubmissionMutationOptions =
  Apollo.BaseMutationOptions<
    CreateKycPhotoIdSubmissionMutation,
    CreateKycPhotoIdSubmissionMutationVariables
  >;
export const CreateKycBasicInfoSubmissionDocument = gql`
  mutation CreateKYCBasicInfoSubmission(
    $input: CreateKYCBasicInfoSubmissionInput!
  ) {
    createKYCBasicInfoSubmission(input: $input) {
      account {
        kycStatus
      }
    }
  }
`;
export type CreateKycBasicInfoSubmissionMutationFn = Apollo.MutationFunction<
  CreateKycBasicInfoSubmissionMutation,
  CreateKycBasicInfoSubmissionMutationVariables
>;
export type CreateKycBasicInfoSubmissionComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateKycBasicInfoSubmissionMutation,
    CreateKycBasicInfoSubmissionMutationVariables
  >,
  'mutation'
>;

export const CreateKycBasicInfoSubmissionComponent = (
  props: CreateKycBasicInfoSubmissionComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateKycBasicInfoSubmissionMutation,
    CreateKycBasicInfoSubmissionMutationVariables
  >
    mutation={CreateKycBasicInfoSubmissionDocument}
    {...props}
  />
);

/**
 * __useCreateKycBasicInfoSubmissionMutation__
 *
 * To run a mutation, you first call `useCreateKycBasicInfoSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKycBasicInfoSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKycBasicInfoSubmissionMutation, { data, loading, error }] = useCreateKycBasicInfoSubmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateKycBasicInfoSubmissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateKycBasicInfoSubmissionMutation,
    CreateKycBasicInfoSubmissionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateKycBasicInfoSubmissionMutation,
    CreateKycBasicInfoSubmissionMutationVariables
  >(CreateKycBasicInfoSubmissionDocument, options);
}
export type CreateKycBasicInfoSubmissionMutationHookResult = ReturnType<
  typeof useCreateKycBasicInfoSubmissionMutation
>;
export type CreateKycBasicInfoSubmissionMutationResult =
  Apollo.MutationResult<CreateKycBasicInfoSubmissionMutation>;
export type CreateKycBasicInfoSubmissionMutationOptions =
  Apollo.BaseMutationOptions<
    CreateKycBasicInfoSubmissionMutation,
    CreateKycBasicInfoSubmissionMutationVariables
  >;
export const GetKycStatusDocument = gql`
  query GetKycStatus {
    viewer {
      account {
        kycStatus
      }
    }
  }
`;
export type GetKycStatusComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetKycStatusQuery,
    GetKycStatusQueryVariables
  >,
  'query'
>;

export const GetKycStatusComponent = (props: GetKycStatusComponentProps) => (
  <ApolloReactComponents.Query<GetKycStatusQuery, GetKycStatusQueryVariables>
    query={GetKycStatusDocument}
    {...props}
  />
);

/**
 * __useGetKycStatusQuery__
 *
 * To run a query within a React component, call `useGetKycStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKycStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKycStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetKycStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetKycStatusQuery,
    GetKycStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetKycStatusQuery, GetKycStatusQueryVariables>(
    GetKycStatusDocument,
    options
  );
}
export function useGetKycStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetKycStatusQuery,
    GetKycStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetKycStatusQuery, GetKycStatusQueryVariables>(
    GetKycStatusDocument,
    options
  );
}
export type GetKycStatusQueryHookResult = ReturnType<
  typeof useGetKycStatusQuery
>;
export type GetKycStatusLazyQueryHookResult = ReturnType<
  typeof useGetKycStatusLazyQuery
>;
export type GetKycStatusQueryResult = Apollo.QueryResult<
  GetKycStatusQuery,
  GetKycStatusQueryVariables
>;
export const CompleteVerificationRequirementsDocument = gql`
  query CompleteVerificationRequirements {
    viewer {
      account {
        defaultAddress {
          id
        }
        partialAddress {
          ...PartialAddress
        }
        defaultBankAccount {
          id
        }
        kycStatus
        email {
          emailAddress
          isVerified
        }
      }
    }
    addressRegions {
      id
      country
      code
      name
    }
  }
  ${PartialAddressFragmentDoc}
`;
export type CompleteVerificationRequirementsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CompleteVerificationRequirementsQuery,
    CompleteVerificationRequirementsQueryVariables
  >,
  'query'
>;

export const CompleteVerificationRequirementsComponent = (
  props: CompleteVerificationRequirementsComponentProps
) => (
  <ApolloReactComponents.Query<
    CompleteVerificationRequirementsQuery,
    CompleteVerificationRequirementsQueryVariables
  >
    query={CompleteVerificationRequirementsDocument}
    {...props}
  />
);

/**
 * __useCompleteVerificationRequirementsQuery__
 *
 * To run a query within a React component, call `useCompleteVerificationRequirementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompleteVerificationRequirementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompleteVerificationRequirementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompleteVerificationRequirementsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompleteVerificationRequirementsQuery,
    CompleteVerificationRequirementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompleteVerificationRequirementsQuery,
    CompleteVerificationRequirementsQueryVariables
  >(CompleteVerificationRequirementsDocument, options);
}
export function useCompleteVerificationRequirementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompleteVerificationRequirementsQuery,
    CompleteVerificationRequirementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompleteVerificationRequirementsQuery,
    CompleteVerificationRequirementsQueryVariables
  >(CompleteVerificationRequirementsDocument, options);
}
export type CompleteVerificationRequirementsQueryHookResult = ReturnType<
  typeof useCompleteVerificationRequirementsQuery
>;
export type CompleteVerificationRequirementsLazyQueryHookResult = ReturnType<
  typeof useCompleteVerificationRequirementsLazyQuery
>;
export type CompleteVerificationRequirementsQueryResult = Apollo.QueryResult<
  CompleteVerificationRequirementsQuery,
  CompleteVerificationRequirementsQueryVariables
>;
export const RemoveFromCartDocument = gql`
  mutation RemoveFromCart($input: RemoveFromCartInput!) {
    removeFromCart(input: $input) {
      shoppingCart {
        ...Cart
      }
      product {
        id
        viewerHasInCart
      }
    }
  }
  ${CartFragmentDoc}
`;
export type RemoveFromCartMutationFn = Apollo.MutationFunction<
  RemoveFromCartMutation,
  RemoveFromCartMutationVariables
>;
export type RemoveFromCartComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RemoveFromCartMutation,
    RemoveFromCartMutationVariables
  >,
  'mutation'
>;

export const RemoveFromCartComponent = (
  props: RemoveFromCartComponentProps
) => (
  <ApolloReactComponents.Mutation<
    RemoveFromCartMutation,
    RemoveFromCartMutationVariables
  >
    mutation={RemoveFromCartDocument}
    {...props}
  />
);

/**
 * __useRemoveFromCartMutation__
 *
 * To run a mutation, you first call `useRemoveFromCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFromCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFromCartMutation, { data, loading, error }] = useRemoveFromCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveFromCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveFromCartMutation,
    RemoveFromCartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveFromCartMutation,
    RemoveFromCartMutationVariables
  >(RemoveFromCartDocument, options);
}
export type RemoveFromCartMutationHookResult = ReturnType<
  typeof useRemoveFromCartMutation
>;
export type RemoveFromCartMutationResult =
  Apollo.MutationResult<RemoveFromCartMutation>;
export type RemoveFromCartMutationOptions = Apollo.BaseMutationOptions<
  RemoveFromCartMutation,
  RemoveFromCartMutationVariables
>;
export const MarkAllNotificationsAsReadDocument = gql`
  mutation MarkAllNotificationsAsRead {
    legacyMarkAllNotificationsAsRead
  }
`;
export type MarkAllNotificationsAsReadMutationFn = Apollo.MutationFunction<
  MarkAllNotificationsAsReadMutation,
  MarkAllNotificationsAsReadMutationVariables
>;
export type MarkAllNotificationsAsReadComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    MarkAllNotificationsAsReadMutation,
    MarkAllNotificationsAsReadMutationVariables
  >,
  'mutation'
>;

export const MarkAllNotificationsAsReadComponent = (
  props: MarkAllNotificationsAsReadComponentProps
) => (
  <ApolloReactComponents.Mutation<
    MarkAllNotificationsAsReadMutation,
    MarkAllNotificationsAsReadMutationVariables
  >
    mutation={MarkAllNotificationsAsReadDocument}
    {...props}
  />
);

/**
 * __useMarkAllNotificationsAsReadMutation__
 *
 * To run a mutation, you first call `useMarkAllNotificationsAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAllNotificationsAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAllNotificationsAsReadMutation, { data, loading, error }] = useMarkAllNotificationsAsReadMutation({
 *   variables: {
 *   },
 * });
 */
export function useMarkAllNotificationsAsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkAllNotificationsAsReadMutation,
    MarkAllNotificationsAsReadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkAllNotificationsAsReadMutation,
    MarkAllNotificationsAsReadMutationVariables
  >(MarkAllNotificationsAsReadDocument, options);
}
export type MarkAllNotificationsAsReadMutationHookResult = ReturnType<
  typeof useMarkAllNotificationsAsReadMutation
>;
export type MarkAllNotificationsAsReadMutationResult =
  Apollo.MutationResult<MarkAllNotificationsAsReadMutation>;
export type MarkAllNotificationsAsReadMutationOptions =
  Apollo.BaseMutationOptions<
    MarkAllNotificationsAsReadMutation,
    MarkAllNotificationsAsReadMutationVariables
  >;
export const CartDocument = gql`
  query Cart {
    viewer {
      shoppingCart {
        ...Cart
      }
    }
  }
  ${CartFragmentDoc}
`;
export type CartComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<CartQuery, CartQueryVariables>,
  'query'
>;

export const CartComponent = (props: CartComponentProps) => (
  <ApolloReactComponents.Query<CartQuery, CartQueryVariables>
    query={CartDocument}
    {...props}
  />
);

/**
 * __useCartQuery__
 *
 * To run a query within a React component, call `useCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartQuery({
 *   variables: {
 *   },
 * });
 */
export function useCartQuery(
  baseOptions?: Apollo.QueryHookOptions<CartQuery, CartQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CartQuery, CartQueryVariables>(CartDocument, options);
}
export function useCartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CartQuery, CartQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CartQuery, CartQueryVariables>(
    CartDocument,
    options
  );
}
export type CartQueryHookResult = ReturnType<typeof useCartQuery>;
export type CartLazyQueryHookResult = ReturnType<typeof useCartLazyQuery>;
export type CartQueryResult = Apollo.QueryResult<CartQuery, CartQueryVariables>;
export const SearchSuggestionsDocument = gql`
  query SearchSuggestions($query: String!) {
    searchSuggestions(first: 3, query: $query) {
      type
      label
      query
      resultCount
    }
    usersConnection(first: 3, filterBy: { query: $query }) {
      nodes {
        ...UserSearchSuggestion
      }
    }
  }
  ${UserSearchSuggestionFragmentDoc}
`;
export type SearchSuggestionsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    SearchSuggestionsQuery,
    SearchSuggestionsQueryVariables
  >,
  'query'
> &
  (
    | { variables: SearchSuggestionsQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const SearchSuggestionsComponent = (
  props: SearchSuggestionsComponentProps
) => (
  <ApolloReactComponents.Query<
    SearchSuggestionsQuery,
    SearchSuggestionsQueryVariables
  >
    query={SearchSuggestionsDocument}
    {...props}
  />
);

/**
 * __useSearchSuggestionsQuery__
 *
 * To run a query within a React component, call `useSearchSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchSuggestionsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchSuggestionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchSuggestionsQuery,
    SearchSuggestionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchSuggestionsQuery,
    SearchSuggestionsQueryVariables
  >(SearchSuggestionsDocument, options);
}
export function useSearchSuggestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchSuggestionsQuery,
    SearchSuggestionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchSuggestionsQuery,
    SearchSuggestionsQueryVariables
  >(SearchSuggestionsDocument, options);
}
export type SearchSuggestionsQueryHookResult = ReturnType<
  typeof useSearchSuggestionsQuery
>;
export type SearchSuggestionsLazyQueryHookResult = ReturnType<
  typeof useSearchSuggestionsLazyQuery
>;
export type SearchSuggestionsQueryResult = Apollo.QueryResult<
  SearchSuggestionsQuery,
  SearchSuggestionsQueryVariables
>;
export const SendOrderDocument = gql`
  mutation SendOrder($input: SendOrderInput!) {
    sendOrder(input: $input) {
      order {
        ...FullOrder
      }
    }
  }
  ${FullOrderFragmentDoc}
`;
export type SendOrderMutationFn = Apollo.MutationFunction<
  SendOrderMutation,
  SendOrderMutationVariables
>;
export type SendOrderComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    SendOrderMutation,
    SendOrderMutationVariables
  >,
  'mutation'
>;

export const SendOrderComponent = (props: SendOrderComponentProps) => (
  <ApolloReactComponents.Mutation<SendOrderMutation, SendOrderMutationVariables>
    mutation={SendOrderDocument}
    {...props}
  />
);

/**
 * __useSendOrderMutation__
 *
 * To run a mutation, you first call `useSendOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendOrderMutation, { data, loading, error }] = useSendOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendOrderMutation,
    SendOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendOrderMutation, SendOrderMutationVariables>(
    SendOrderDocument,
    options
  );
}
export type SendOrderMutationHookResult = ReturnType<
  typeof useSendOrderMutation
>;
export type SendOrderMutationResult = Apollo.MutationResult<SendOrderMutation>;
export type SendOrderMutationOptions = Apollo.BaseMutationOptions<
  SendOrderMutation,
  SendOrderMutationVariables
>;
export const ReceiveOrderDocument = gql`
  mutation ReceiveOrder($input: ReceiveOrderInput!) {
    receiveOrder(input: $input) {
      order {
        ...FullOrder
      }
    }
  }
  ${FullOrderFragmentDoc}
`;
export type ReceiveOrderMutationFn = Apollo.MutationFunction<
  ReceiveOrderMutation,
  ReceiveOrderMutationVariables
>;
export type ReceiveOrderComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ReceiveOrderMutation,
    ReceiveOrderMutationVariables
  >,
  'mutation'
>;

export const ReceiveOrderComponent = (props: ReceiveOrderComponentProps) => (
  <ApolloReactComponents.Mutation<
    ReceiveOrderMutation,
    ReceiveOrderMutationVariables
  >
    mutation={ReceiveOrderDocument}
    {...props}
  />
);

/**
 * __useReceiveOrderMutation__
 *
 * To run a mutation, you first call `useReceiveOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReceiveOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [receiveOrderMutation, { data, loading, error }] = useReceiveOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReceiveOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReceiveOrderMutation,
    ReceiveOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReceiveOrderMutation,
    ReceiveOrderMutationVariables
  >(ReceiveOrderDocument, options);
}
export type ReceiveOrderMutationHookResult = ReturnType<
  typeof useReceiveOrderMutation
>;
export type ReceiveOrderMutationResult =
  Apollo.MutationResult<ReceiveOrderMutation>;
export type ReceiveOrderMutationOptions = Apollo.BaseMutationOptions<
  ReceiveOrderMutation,
  ReceiveOrderMutationVariables
>;
export const ReceiveReturnedOrderDocument = gql`
  mutation ReceiveReturnedOrder($input: ReceiveReturnedOrderInput!) {
    receiveReturnedOrder(input: $input) {
      order {
        ...FullOrder
      }
    }
  }
  ${FullOrderFragmentDoc}
`;
export type ReceiveReturnedOrderMutationFn = Apollo.MutationFunction<
  ReceiveReturnedOrderMutation,
  ReceiveReturnedOrderMutationVariables
>;
export type ReceiveReturnedOrderComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ReceiveReturnedOrderMutation,
    ReceiveReturnedOrderMutationVariables
  >,
  'mutation'
>;

export const ReceiveReturnedOrderComponent = (
  props: ReceiveReturnedOrderComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ReceiveReturnedOrderMutation,
    ReceiveReturnedOrderMutationVariables
  >
    mutation={ReceiveReturnedOrderDocument}
    {...props}
  />
);

/**
 * __useReceiveReturnedOrderMutation__
 *
 * To run a mutation, you first call `useReceiveReturnedOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReceiveReturnedOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [receiveReturnedOrderMutation, { data, loading, error }] = useReceiveReturnedOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReceiveReturnedOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReceiveReturnedOrderMutation,
    ReceiveReturnedOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReceiveReturnedOrderMutation,
    ReceiveReturnedOrderMutationVariables
  >(ReceiveReturnedOrderDocument, options);
}
export type ReceiveReturnedOrderMutationHookResult = ReturnType<
  typeof useReceiveReturnedOrderMutation
>;
export type ReceiveReturnedOrderMutationResult =
  Apollo.MutationResult<ReceiveReturnedOrderMutation>;
export type ReceiveReturnedOrderMutationOptions = Apollo.BaseMutationOptions<
  ReceiveReturnedOrderMutation,
  ReceiveReturnedOrderMutationVariables
>;
export const KeepOrderDocument = gql`
  mutation KeepOrder($input: KeepOrderInput!) {
    keepOrder(input: $input) {
      order {
        ...FullOrder
      }
    }
  }
  ${FullOrderFragmentDoc}
`;
export type KeepOrderMutationFn = Apollo.MutationFunction<
  KeepOrderMutation,
  KeepOrderMutationVariables
>;
export type KeepOrderComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    KeepOrderMutation,
    KeepOrderMutationVariables
  >,
  'mutation'
>;

export const KeepOrderComponent = (props: KeepOrderComponentProps) => (
  <ApolloReactComponents.Mutation<KeepOrderMutation, KeepOrderMutationVariables>
    mutation={KeepOrderDocument}
    {...props}
  />
);

/**
 * __useKeepOrderMutation__
 *
 * To run a mutation, you first call `useKeepOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKeepOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [keepOrderMutation, { data, loading, error }] = useKeepOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useKeepOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    KeepOrderMutation,
    KeepOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<KeepOrderMutation, KeepOrderMutationVariables>(
    KeepOrderDocument,
    options
  );
}
export type KeepOrderMutationHookResult = ReturnType<
  typeof useKeepOrderMutation
>;
export type KeepOrderMutationResult = Apollo.MutationResult<KeepOrderMutation>;
export type KeepOrderMutationOptions = Apollo.BaseMutationOptions<
  KeepOrderMutation,
  KeepOrderMutationVariables
>;
export const SubmitCancellationRequestDocument = gql`
  mutation SubmitCancellationRequest($input: SubmitCancellationRequestInput!) {
    submitCancellationRequest(input: $input) {
      order {
        ...FullOrder
      }
    }
  }
  ${FullOrderFragmentDoc}
`;
export type SubmitCancellationRequestMutationFn = Apollo.MutationFunction<
  SubmitCancellationRequestMutation,
  SubmitCancellationRequestMutationVariables
>;
export type SubmitCancellationRequestComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    SubmitCancellationRequestMutation,
    SubmitCancellationRequestMutationVariables
  >,
  'mutation'
>;

export const SubmitCancellationRequestComponent = (
  props: SubmitCancellationRequestComponentProps
) => (
  <ApolloReactComponents.Mutation<
    SubmitCancellationRequestMutation,
    SubmitCancellationRequestMutationVariables
  >
    mutation={SubmitCancellationRequestDocument}
    {...props}
  />
);

/**
 * __useSubmitCancellationRequestMutation__
 *
 * To run a mutation, you first call `useSubmitCancellationRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitCancellationRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitCancellationRequestMutation, { data, loading, error }] = useSubmitCancellationRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitCancellationRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitCancellationRequestMutation,
    SubmitCancellationRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubmitCancellationRequestMutation,
    SubmitCancellationRequestMutationVariables
  >(SubmitCancellationRequestDocument, options);
}
export type SubmitCancellationRequestMutationHookResult = ReturnType<
  typeof useSubmitCancellationRequestMutation
>;
export type SubmitCancellationRequestMutationResult =
  Apollo.MutationResult<SubmitCancellationRequestMutation>;
export type SubmitCancellationRequestMutationOptions =
  Apollo.BaseMutationOptions<
    SubmitCancellationRequestMutation,
    SubmitCancellationRequestMutationVariables
  >;
export const WithdrawCancellationRequestDocument = gql`
  mutation WithdrawCancellationRequest(
    $input: WithdrawCancellationRequestInput!
  ) {
    withdrawCancellationRequest(input: $input) {
      order {
        ...FullOrder
      }
    }
  }
  ${FullOrderFragmentDoc}
`;
export type WithdrawCancellationRequestMutationFn = Apollo.MutationFunction<
  WithdrawCancellationRequestMutation,
  WithdrawCancellationRequestMutationVariables
>;
export type WithdrawCancellationRequestComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    WithdrawCancellationRequestMutation,
    WithdrawCancellationRequestMutationVariables
  >,
  'mutation'
>;

export const WithdrawCancellationRequestComponent = (
  props: WithdrawCancellationRequestComponentProps
) => (
  <ApolloReactComponents.Mutation<
    WithdrawCancellationRequestMutation,
    WithdrawCancellationRequestMutationVariables
  >
    mutation={WithdrawCancellationRequestDocument}
    {...props}
  />
);

/**
 * __useWithdrawCancellationRequestMutation__
 *
 * To run a mutation, you first call `useWithdrawCancellationRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWithdrawCancellationRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [withdrawCancellationRequestMutation, { data, loading, error }] = useWithdrawCancellationRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWithdrawCancellationRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WithdrawCancellationRequestMutation,
    WithdrawCancellationRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    WithdrawCancellationRequestMutation,
    WithdrawCancellationRequestMutationVariables
  >(WithdrawCancellationRequestDocument, options);
}
export type WithdrawCancellationRequestMutationHookResult = ReturnType<
  typeof useWithdrawCancellationRequestMutation
>;
export type WithdrawCancellationRequestMutationResult =
  Apollo.MutationResult<WithdrawCancellationRequestMutation>;
export type WithdrawCancellationRequestMutationOptions =
  Apollo.BaseMutationOptions<
    WithdrawCancellationRequestMutation,
    WithdrawCancellationRequestMutationVariables
  >;
export const AcceptCancellationRequestDocument = gql`
  mutation AcceptCancellationRequest($input: AcceptCancellationRequestInput!) {
    acceptCancellationRequest(input: $input) {
      order {
        ...FullOrder
      }
    }
  }
  ${FullOrderFragmentDoc}
`;
export type AcceptCancellationRequestMutationFn = Apollo.MutationFunction<
  AcceptCancellationRequestMutation,
  AcceptCancellationRequestMutationVariables
>;
export type AcceptCancellationRequestComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AcceptCancellationRequestMutation,
    AcceptCancellationRequestMutationVariables
  >,
  'mutation'
>;

export const AcceptCancellationRequestComponent = (
  props: AcceptCancellationRequestComponentProps
) => (
  <ApolloReactComponents.Mutation<
    AcceptCancellationRequestMutation,
    AcceptCancellationRequestMutationVariables
  >
    mutation={AcceptCancellationRequestDocument}
    {...props}
  />
);

/**
 * __useAcceptCancellationRequestMutation__
 *
 * To run a mutation, you first call `useAcceptCancellationRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptCancellationRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptCancellationRequestMutation, { data, loading, error }] = useAcceptCancellationRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptCancellationRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptCancellationRequestMutation,
    AcceptCancellationRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AcceptCancellationRequestMutation,
    AcceptCancellationRequestMutationVariables
  >(AcceptCancellationRequestDocument, options);
}
export type AcceptCancellationRequestMutationHookResult = ReturnType<
  typeof useAcceptCancellationRequestMutation
>;
export type AcceptCancellationRequestMutationResult =
  Apollo.MutationResult<AcceptCancellationRequestMutation>;
export type AcceptCancellationRequestMutationOptions =
  Apollo.BaseMutationOptions<
    AcceptCancellationRequestMutation,
    AcceptCancellationRequestMutationVariables
  >;
export const DisputeCancellationRequestDocument = gql`
  mutation DisputeCancellationRequest(
    $input: DisputeCancellationRequestInput!
  ) {
    disputeCancellationRequest(input: $input) {
      order {
        ...FullOrder
      }
    }
  }
  ${FullOrderFragmentDoc}
`;
export type DisputeCancellationRequestMutationFn = Apollo.MutationFunction<
  DisputeCancellationRequestMutation,
  DisputeCancellationRequestMutationVariables
>;
export type DisputeCancellationRequestComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DisputeCancellationRequestMutation,
    DisputeCancellationRequestMutationVariables
  >,
  'mutation'
>;

export const DisputeCancellationRequestComponent = (
  props: DisputeCancellationRequestComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DisputeCancellationRequestMutation,
    DisputeCancellationRequestMutationVariables
  >
    mutation={DisputeCancellationRequestDocument}
    {...props}
  />
);

/**
 * __useDisputeCancellationRequestMutation__
 *
 * To run a mutation, you first call `useDisputeCancellationRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisputeCancellationRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disputeCancellationRequestMutation, { data, loading, error }] = useDisputeCancellationRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisputeCancellationRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisputeCancellationRequestMutation,
    DisputeCancellationRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DisputeCancellationRequestMutation,
    DisputeCancellationRequestMutationVariables
  >(DisputeCancellationRequestDocument, options);
}
export type DisputeCancellationRequestMutationHookResult = ReturnType<
  typeof useDisputeCancellationRequestMutation
>;
export type DisputeCancellationRequestMutationResult =
  Apollo.MutationResult<DisputeCancellationRequestMutation>;
export type DisputeCancellationRequestMutationOptions =
  Apollo.BaseMutationOptions<
    DisputeCancellationRequestMutation,
    DisputeCancellationRequestMutationVariables
  >;
export const RepostOrderRequestDocument = gql`
  mutation RepostOrderRequest($input: RepostOrderInput!) {
    repostOrder(input: $input) {
      order {
        ...FullOrder
      }
    }
  }
  ${FullOrderFragmentDoc}
`;
export type RepostOrderRequestMutationFn = Apollo.MutationFunction<
  RepostOrderRequestMutation,
  RepostOrderRequestMutationVariables
>;
export type RepostOrderRequestComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RepostOrderRequestMutation,
    RepostOrderRequestMutationVariables
  >,
  'mutation'
>;

export const RepostOrderRequestComponent = (
  props: RepostOrderRequestComponentProps
) => (
  <ApolloReactComponents.Mutation<
    RepostOrderRequestMutation,
    RepostOrderRequestMutationVariables
  >
    mutation={RepostOrderRequestDocument}
    {...props}
  />
);

/**
 * __useRepostOrderRequestMutation__
 *
 * To run a mutation, you first call `useRepostOrderRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRepostOrderRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [repostOrderRequestMutation, { data, loading, error }] = useRepostOrderRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRepostOrderRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RepostOrderRequestMutation,
    RepostOrderRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RepostOrderRequestMutation,
    RepostOrderRequestMutationVariables
  >(RepostOrderRequestDocument, options);
}
export type RepostOrderRequestMutationHookResult = ReturnType<
  typeof useRepostOrderRequestMutation
>;
export type RepostOrderRequestMutationResult =
  Apollo.MutationResult<RepostOrderRequestMutation>;
export type RepostOrderRequestMutationOptions = Apollo.BaseMutationOptions<
  RepostOrderRequestMutation,
  RepostOrderRequestMutationVariables
>;
export const CreateReturnRequestDocument = gql`
  mutation CreateReturnRequest($input: CreateReturnRequestInput!) {
    createReturnRequest(input: $input) {
      order {
        ...FullOrder
      }
    }
  }
  ${FullOrderFragmentDoc}
`;
export type CreateReturnRequestMutationFn = Apollo.MutationFunction<
  CreateReturnRequestMutation,
  CreateReturnRequestMutationVariables
>;
export type CreateReturnRequestComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateReturnRequestMutation,
    CreateReturnRequestMutationVariables
  >,
  'mutation'
>;

export const CreateReturnRequestComponent = (
  props: CreateReturnRequestComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateReturnRequestMutation,
    CreateReturnRequestMutationVariables
  >
    mutation={CreateReturnRequestDocument}
    {...props}
  />
);

/**
 * __useCreateReturnRequestMutation__
 *
 * To run a mutation, you first call `useCreateReturnRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReturnRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReturnRequestMutation, { data, loading, error }] = useCreateReturnRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReturnRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateReturnRequestMutation,
    CreateReturnRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateReturnRequestMutation,
    CreateReturnRequestMutationVariables
  >(CreateReturnRequestDocument, options);
}
export type CreateReturnRequestMutationHookResult = ReturnType<
  typeof useCreateReturnRequestMutation
>;
export type CreateReturnRequestMutationResult =
  Apollo.MutationResult<CreateReturnRequestMutation>;
export type CreateReturnRequestMutationOptions = Apollo.BaseMutationOptions<
  CreateReturnRequestMutation,
  CreateReturnRequestMutationVariables
>;
export const CancelReturnRequestDocument = gql`
  mutation CancelReturnRequest($input: CancelReturnRequestInput!) {
    cancelReturnRequest(input: $input) {
      order {
        ...FullOrder
      }
    }
  }
  ${FullOrderFragmentDoc}
`;
export type CancelReturnRequestMutationFn = Apollo.MutationFunction<
  CancelReturnRequestMutation,
  CancelReturnRequestMutationVariables
>;
export type CancelReturnRequestComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CancelReturnRequestMutation,
    CancelReturnRequestMutationVariables
  >,
  'mutation'
>;

export const CancelReturnRequestComponent = (
  props: CancelReturnRequestComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CancelReturnRequestMutation,
    CancelReturnRequestMutationVariables
  >
    mutation={CancelReturnRequestDocument}
    {...props}
  />
);

/**
 * __useCancelReturnRequestMutation__
 *
 * To run a mutation, you first call `useCancelReturnRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelReturnRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelReturnRequestMutation, { data, loading, error }] = useCancelReturnRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelReturnRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelReturnRequestMutation,
    CancelReturnRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelReturnRequestMutation,
    CancelReturnRequestMutationVariables
  >(CancelReturnRequestDocument, options);
}
export type CancelReturnRequestMutationHookResult = ReturnType<
  typeof useCancelReturnRequestMutation
>;
export type CancelReturnRequestMutationResult =
  Apollo.MutationResult<CancelReturnRequestMutation>;
export type CancelReturnRequestMutationOptions = Apollo.BaseMutationOptions<
  CancelReturnRequestMutation,
  CancelReturnRequestMutationVariables
>;
export const ApproveReturnRequestDocument = gql`
  mutation ApproveReturnRequest($input: ApproveReturnRequestInput!) {
    approveReturnRequest(input: $input) {
      order {
        ...FullOrder
      }
    }
  }
  ${FullOrderFragmentDoc}
`;
export type ApproveReturnRequestMutationFn = Apollo.MutationFunction<
  ApproveReturnRequestMutation,
  ApproveReturnRequestMutationVariables
>;
export type ApproveReturnRequestComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ApproveReturnRequestMutation,
    ApproveReturnRequestMutationVariables
  >,
  'mutation'
>;

export const ApproveReturnRequestComponent = (
  props: ApproveReturnRequestComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ApproveReturnRequestMutation,
    ApproveReturnRequestMutationVariables
  >
    mutation={ApproveReturnRequestDocument}
    {...props}
  />
);

/**
 * __useApproveReturnRequestMutation__
 *
 * To run a mutation, you first call `useApproveReturnRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveReturnRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveReturnRequestMutation, { data, loading, error }] = useApproveReturnRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveReturnRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApproveReturnRequestMutation,
    ApproveReturnRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApproveReturnRequestMutation,
    ApproveReturnRequestMutationVariables
  >(ApproveReturnRequestDocument, options);
}
export type ApproveReturnRequestMutationHookResult = ReturnType<
  typeof useApproveReturnRequestMutation
>;
export type ApproveReturnRequestMutationResult =
  Apollo.MutationResult<ApproveReturnRequestMutation>;
export type ApproveReturnRequestMutationOptions = Apollo.BaseMutationOptions<
  ApproveReturnRequestMutation,
  ApproveReturnRequestMutationVariables
>;
export const DisputeReturnRequestDocument = gql`
  mutation DisputeReturnRequest($input: DisputeReturnRequestInput!) {
    disputeReturnRequest(input: $input) {
      order {
        ...FullOrder
      }
    }
  }
  ${FullOrderFragmentDoc}
`;
export type DisputeReturnRequestMutationFn = Apollo.MutationFunction<
  DisputeReturnRequestMutation,
  DisputeReturnRequestMutationVariables
>;
export type DisputeReturnRequestComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DisputeReturnRequestMutation,
    DisputeReturnRequestMutationVariables
  >,
  'mutation'
>;

export const DisputeReturnRequestComponent = (
  props: DisputeReturnRequestComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DisputeReturnRequestMutation,
    DisputeReturnRequestMutationVariables
  >
    mutation={DisputeReturnRequestDocument}
    {...props}
  />
);

/**
 * __useDisputeReturnRequestMutation__
 *
 * To run a mutation, you first call `useDisputeReturnRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisputeReturnRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disputeReturnRequestMutation, { data, loading, error }] = useDisputeReturnRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisputeReturnRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisputeReturnRequestMutation,
    DisputeReturnRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DisputeReturnRequestMutation,
    DisputeReturnRequestMutationVariables
  >(DisputeReturnRequestDocument, options);
}
export type DisputeReturnRequestMutationHookResult = ReturnType<
  typeof useDisputeReturnRequestMutation
>;
export type DisputeReturnRequestMutationResult =
  Apollo.MutationResult<DisputeReturnRequestMutation>;
export type DisputeReturnRequestMutationOptions = Apollo.BaseMutationOptions<
  DisputeReturnRequestMutation,
  DisputeReturnRequestMutationVariables
>;
export const CreateShippingLabelDocument = gql`
  mutation CreateShippingLabel($input: CreateShippingLabelInput!) {
    createShippingLabel(input: $input) {
      order {
        ...FullOrder
      }
    }
  }
  ${FullOrderFragmentDoc}
`;
export type CreateShippingLabelMutationFn = Apollo.MutationFunction<
  CreateShippingLabelMutation,
  CreateShippingLabelMutationVariables
>;
export type CreateShippingLabelComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateShippingLabelMutation,
    CreateShippingLabelMutationVariables
  >,
  'mutation'
>;

export const CreateShippingLabelComponent = (
  props: CreateShippingLabelComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateShippingLabelMutation,
    CreateShippingLabelMutationVariables
  >
    mutation={CreateShippingLabelDocument}
    {...props}
  />
);

/**
 * __useCreateShippingLabelMutation__
 *
 * To run a mutation, you first call `useCreateShippingLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShippingLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShippingLabelMutation, { data, loading, error }] = useCreateShippingLabelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateShippingLabelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateShippingLabelMutation,
    CreateShippingLabelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateShippingLabelMutation,
    CreateShippingLabelMutationVariables
  >(CreateShippingLabelDocument, options);
}
export type CreateShippingLabelMutationHookResult = ReturnType<
  typeof useCreateShippingLabelMutation
>;
export type CreateShippingLabelMutationResult =
  Apollo.MutationResult<CreateShippingLabelMutation>;
export type CreateShippingLabelMutationOptions = Apollo.BaseMutationOptions<
  CreateShippingLabelMutation,
  CreateShippingLabelMutationVariables
>;
export const SetOrderTrackingCodeDocument = gql`
  mutation SetOrderTrackingCode($input: SetOrderTrackingCodeInput!) {
    setOrderTrackingCode(input: $input) {
      order {
        ...FullOrder
      }
    }
  }
  ${FullOrderFragmentDoc}
`;
export type SetOrderTrackingCodeMutationFn = Apollo.MutationFunction<
  SetOrderTrackingCodeMutation,
  SetOrderTrackingCodeMutationVariables
>;
export type SetOrderTrackingCodeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    SetOrderTrackingCodeMutation,
    SetOrderTrackingCodeMutationVariables
  >,
  'mutation'
>;

export const SetOrderTrackingCodeComponent = (
  props: SetOrderTrackingCodeComponentProps
) => (
  <ApolloReactComponents.Mutation<
    SetOrderTrackingCodeMutation,
    SetOrderTrackingCodeMutationVariables
  >
    mutation={SetOrderTrackingCodeDocument}
    {...props}
  />
);

/**
 * __useSetOrderTrackingCodeMutation__
 *
 * To run a mutation, you first call `useSetOrderTrackingCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOrderTrackingCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOrderTrackingCodeMutation, { data, loading, error }] = useSetOrderTrackingCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetOrderTrackingCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetOrderTrackingCodeMutation,
    SetOrderTrackingCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetOrderTrackingCodeMutation,
    SetOrderTrackingCodeMutationVariables
  >(SetOrderTrackingCodeDocument, options);
}
export type SetOrderTrackingCodeMutationHookResult = ReturnType<
  typeof useSetOrderTrackingCodeMutation
>;
export type SetOrderTrackingCodeMutationResult =
  Apollo.MutationResult<SetOrderTrackingCodeMutation>;
export type SetOrderTrackingCodeMutationOptions = Apollo.BaseMutationOptions<
  SetOrderTrackingCodeMutation,
  SetOrderTrackingCodeMutationVariables
>;
export const CreateOrderReviewDocument = gql`
  mutation CreateOrderReview($input: CreateOrderReviewInput!) {
    createOrderReview(input: $input) {
      order {
        ...FullOrder
      }
    }
  }
  ${FullOrderFragmentDoc}
`;
export type CreateOrderReviewMutationFn = Apollo.MutationFunction<
  CreateOrderReviewMutation,
  CreateOrderReviewMutationVariables
>;
export type CreateOrderReviewComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateOrderReviewMutation,
    CreateOrderReviewMutationVariables
  >,
  'mutation'
>;

export const CreateOrderReviewComponent = (
  props: CreateOrderReviewComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateOrderReviewMutation,
    CreateOrderReviewMutationVariables
  >
    mutation={CreateOrderReviewDocument}
    {...props}
  />
);

/**
 * __useCreateOrderReviewMutation__
 *
 * To run a mutation, you first call `useCreateOrderReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderReviewMutation, { data, loading, error }] = useCreateOrderReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrderReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrderReviewMutation,
    CreateOrderReviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrderReviewMutation,
    CreateOrderReviewMutationVariables
  >(CreateOrderReviewDocument, options);
}
export type CreateOrderReviewMutationHookResult = ReturnType<
  typeof useCreateOrderReviewMutation
>;
export type CreateOrderReviewMutationResult =
  Apollo.MutationResult<CreateOrderReviewMutation>;
export type CreateOrderReviewMutationOptions = Apollo.BaseMutationOptions<
  CreateOrderReviewMutation,
  CreateOrderReviewMutationVariables
>;
export const OrderDocument = gql`
  query Order($id: ID!) {
    viewer {
      order(id: $id) {
        ...FullOrder
      }
    }
  }
  ${FullOrderFragmentDoc}
`;
export type OrderComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<OrderQuery, OrderQueryVariables>,
  'query'
> &
  ({ variables: OrderQueryVariables; skip?: boolean } | { skip: boolean });

export const OrderComponent = (props: OrderComponentProps) => (
  <ApolloReactComponents.Query<OrderQuery, OrderQueryVariables>
    query={OrderDocument}
    {...props}
  />
);

/**
 * __useOrderQuery__
 *
 * To run a query within a React component, call `useOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderQuery(
  baseOptions: Apollo.QueryHookOptions<OrderQuery, OrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderQuery, OrderQueryVariables>(
    OrderDocument,
    options
  );
}
export function useOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderQuery, OrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderQuery, OrderQueryVariables>(
    OrderDocument,
    options
  );
}
export type OrderQueryHookResult = ReturnType<typeof useOrderQuery>;
export type OrderLazyQueryHookResult = ReturnType<typeof useOrderLazyQuery>;
export type OrderQueryResult = Apollo.QueryResult<
  OrderQuery,
  OrderQueryVariables
>;
export const OrdersDocument = gql`
  query Orders($type: OrderType!, $first: Int!, $after: String) {
    viewer {
      ordersConnection(type: $type, first: $first, after: $after) {
        nodes {
          ...ListOrder
        }
        totalCount
      }
    }
  }
  ${ListOrderFragmentDoc}
`;
export type OrdersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    OrdersQuery,
    OrdersQueryVariables
  >,
  'query'
> &
  ({ variables: OrdersQueryVariables; skip?: boolean } | { skip: boolean });

export const OrdersComponent = (props: OrdersComponentProps) => (
  <ApolloReactComponents.Query<OrdersQuery, OrdersQueryVariables>
    query={OrdersDocument}
    {...props}
  />
);

/**
 * __useOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersQuery({
 *   variables: {
 *      type: // value for 'type'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useOrdersQuery(
  baseOptions: Apollo.QueryHookOptions<OrdersQuery, OrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrdersQuery, OrdersQueryVariables>(
    OrdersDocument,
    options
  );
}
export function useOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrdersQuery, OrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrdersQuery, OrdersQueryVariables>(
    OrdersDocument,
    options
  );
}
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersQueryResult = Apollo.QueryResult<
  OrdersQuery,
  OrdersQueryVariables
>;
export const AddProductCommentDocument = gql`
  mutation AddProductComment($input: AddProductCommentInput!) {
    addProductComment(input: $input) {
      comment {
        ...ProductComment
      }
    }
  }
  ${ProductCommentFragmentDoc}
`;
export type AddProductCommentMutationFn = Apollo.MutationFunction<
  AddProductCommentMutation,
  AddProductCommentMutationVariables
>;
export type AddProductCommentComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AddProductCommentMutation,
    AddProductCommentMutationVariables
  >,
  'mutation'
>;

export const AddProductCommentComponent = (
  props: AddProductCommentComponentProps
) => (
  <ApolloReactComponents.Mutation<
    AddProductCommentMutation,
    AddProductCommentMutationVariables
  >
    mutation={AddProductCommentDocument}
    {...props}
  />
);

/**
 * __useAddProductCommentMutation__
 *
 * To run a mutation, you first call `useAddProductCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProductCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProductCommentMutation, { data, loading, error }] = useAddProductCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddProductCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddProductCommentMutation,
    AddProductCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddProductCommentMutation,
    AddProductCommentMutationVariables
  >(AddProductCommentDocument, options);
}
export type AddProductCommentMutationHookResult = ReturnType<
  typeof useAddProductCommentMutation
>;
export type AddProductCommentMutationResult =
  Apollo.MutationResult<AddProductCommentMutation>;
export type AddProductCommentMutationOptions = Apollo.BaseMutationOptions<
  AddProductCommentMutation,
  AddProductCommentMutationVariables
>;
export const ReportProductDocument = gql`
  mutation ReportProduct($input: ReportProductInput!) {
    reportProduct(input: $input) {
      clientMutationId
      product {
        ...Product
      }
    }
  }
  ${ProductFragmentDoc}
`;
export type ReportProductMutationFn = Apollo.MutationFunction<
  ReportProductMutation,
  ReportProductMutationVariables
>;
export type ReportProductComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ReportProductMutation,
    ReportProductMutationVariables
  >,
  'mutation'
>;

export const ReportProductComponent = (props: ReportProductComponentProps) => (
  <ApolloReactComponents.Mutation<
    ReportProductMutation,
    ReportProductMutationVariables
  >
    mutation={ReportProductDocument}
    {...props}
  />
);

/**
 * __useReportProductMutation__
 *
 * To run a mutation, you first call `useReportProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportProductMutation, { data, loading, error }] = useReportProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportProductMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReportProductMutation,
    ReportProductMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReportProductMutation,
    ReportProductMutationVariables
  >(ReportProductDocument, options);
}
export type ReportProductMutationHookResult = ReturnType<
  typeof useReportProductMutation
>;
export type ReportProductMutationResult =
  Apollo.MutationResult<ReportProductMutation>;
export type ReportProductMutationOptions = Apollo.BaseMutationOptions<
  ReportProductMutation,
  ReportProductMutationVariables
>;
export const DeleteProductDocument = gql`
  mutation DeleteProduct($input: DeleteProductInput!) {
    deleteProduct(input: $input) {
      product {
        ...Product
      }
    }
  }
  ${ProductFragmentDoc}
`;
export type DeleteProductMutationFn = Apollo.MutationFunction<
  DeleteProductMutation,
  DeleteProductMutationVariables
>;
export type DeleteProductComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteProductMutation,
    DeleteProductMutationVariables
  >,
  'mutation'
>;

export const DeleteProductComponent = (props: DeleteProductComponentProps) => (
  <ApolloReactComponents.Mutation<
    DeleteProductMutation,
    DeleteProductMutationVariables
  >
    mutation={DeleteProductDocument}
    {...props}
  />
);

/**
 * __useDeleteProductMutation__
 *
 * To run a mutation, you first call `useDeleteProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductMutation, { data, loading, error }] = useDeleteProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProductMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProductMutation,
    DeleteProductMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteProductMutation,
    DeleteProductMutationVariables
  >(DeleteProductDocument, options);
}
export type DeleteProductMutationHookResult = ReturnType<
  typeof useDeleteProductMutation
>;
export type DeleteProductMutationResult =
  Apollo.MutationResult<DeleteProductMutation>;
export type DeleteProductMutationOptions = Apollo.BaseMutationOptions<
  DeleteProductMutation,
  DeleteProductMutationVariables
>;
export const AddToCartDocument = gql`
  mutation AddToCart($input: AddToCartInput!) {
    addToCart(input: $input) {
      product {
        id
        viewerHasInCart
      }
      shoppingCart {
        ...Cart
      }
    }
  }
  ${CartFragmentDoc}
`;
export type AddToCartMutationFn = Apollo.MutationFunction<
  AddToCartMutation,
  AddToCartMutationVariables
>;
export type AddToCartComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AddToCartMutation,
    AddToCartMutationVariables
  >,
  'mutation'
>;

export const AddToCartComponent = (props: AddToCartComponentProps) => (
  <ApolloReactComponents.Mutation<AddToCartMutation, AddToCartMutationVariables>
    mutation={AddToCartDocument}
    {...props}
  />
);

/**
 * __useAddToCartMutation__
 *
 * To run a mutation, you first call `useAddToCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToCartMutation, { data, loading, error }] = useAddToCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddToCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddToCartMutation,
    AddToCartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddToCartMutation, AddToCartMutationVariables>(
    AddToCartDocument,
    options
  );
}
export type AddToCartMutationHookResult = ReturnType<
  typeof useAddToCartMutation
>;
export type AddToCartMutationResult = Apollo.MutationResult<AddToCartMutation>;
export type AddToCartMutationOptions = Apollo.BaseMutationOptions<
  AddToCartMutation,
  AddToCartMutationVariables
>;
export const RequestMediaUploadsDocument = gql`
  mutation RequestMediaUploads($input: RequestMediaUploadsInput!) {
    requestMediaUploads(input: $input) {
      mediaUploadRequests {
        id
        url
      }
    }
  }
`;
export type RequestMediaUploadsMutationFn = Apollo.MutationFunction<
  RequestMediaUploadsMutation,
  RequestMediaUploadsMutationVariables
>;
export type RequestMediaUploadsComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RequestMediaUploadsMutation,
    RequestMediaUploadsMutationVariables
  >,
  'mutation'
>;

export const RequestMediaUploadsComponent = (
  props: RequestMediaUploadsComponentProps
) => (
  <ApolloReactComponents.Mutation<
    RequestMediaUploadsMutation,
    RequestMediaUploadsMutationVariables
  >
    mutation={RequestMediaUploadsDocument}
    {...props}
  />
);

/**
 * __useRequestMediaUploadsMutation__
 *
 * To run a mutation, you first call `useRequestMediaUploadsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestMediaUploadsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestMediaUploadsMutation, { data, loading, error }] = useRequestMediaUploadsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestMediaUploadsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestMediaUploadsMutation,
    RequestMediaUploadsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestMediaUploadsMutation,
    RequestMediaUploadsMutationVariables
  >(RequestMediaUploadsDocument, options);
}
export type RequestMediaUploadsMutationHookResult = ReturnType<
  typeof useRequestMediaUploadsMutation
>;
export type RequestMediaUploadsMutationResult =
  Apollo.MutationResult<RequestMediaUploadsMutation>;
export type RequestMediaUploadsMutationOptions = Apollo.BaseMutationOptions<
  RequestMediaUploadsMutation,
  RequestMediaUploadsMutationVariables
>;
export const ProductDocument = gql`
  query Product($id: ID!) {
    product(id: $id) {
      ...Product
    }
  }
  ${ProductFragmentDoc}
`;
export type ProductComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ProductQuery,
    ProductQueryVariables
  >,
  'query'
> &
  ({ variables: ProductQueryVariables; skip?: boolean } | { skip: boolean });

export const ProductComponent = (props: ProductComponentProps) => (
  <ApolloReactComponents.Query<ProductQuery, ProductQueryVariables>
    query={ProductDocument}
    {...props}
  />
);

/**
 * __useProductQuery__
 *
 * To run a query within a React component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductQuery(
  baseOptions: Apollo.QueryHookOptions<ProductQuery, ProductQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductQuery, ProductQueryVariables>(
    ProductDocument,
    options
  );
}
export function useProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProductQuery, ProductQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductQuery, ProductQueryVariables>(
    ProductDocument,
    options
  );
}
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>;
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>;
export type ProductQueryResult = Apollo.QueryResult<
  ProductQuery,
  ProductQueryVariables
>;
export const ProductCommentsDocument = gql`
  query ProductComments($productId: ID!, $after: String) {
    product(id: $productId) {
      commentsConnection(first: 10, after: $after) {
        nodes {
          ...ProductComment
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
  ${ProductCommentFragmentDoc}
`;
export type ProductCommentsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ProductCommentsQuery,
    ProductCommentsQueryVariables
  >,
  'query'
> &
  (
    | { variables: ProductCommentsQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const ProductCommentsComponent = (
  props: ProductCommentsComponentProps
) => (
  <ApolloReactComponents.Query<
    ProductCommentsQuery,
    ProductCommentsQueryVariables
  >
    query={ProductCommentsDocument}
    {...props}
  />
);

/**
 * __useProductCommentsQuery__
 *
 * To run a query within a React component, call `useProductCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductCommentsQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useProductCommentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProductCommentsQuery,
    ProductCommentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductCommentsQuery, ProductCommentsQueryVariables>(
    ProductCommentsDocument,
    options
  );
}
export function useProductCommentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductCommentsQuery,
    ProductCommentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProductCommentsQuery,
    ProductCommentsQueryVariables
  >(ProductCommentsDocument, options);
}
export type ProductCommentsQueryHookResult = ReturnType<
  typeof useProductCommentsQuery
>;
export type ProductCommentsLazyQueryHookResult = ReturnType<
  typeof useProductCommentsLazyQuery
>;
export type ProductCommentsQueryResult = Apollo.QueryResult<
  ProductCommentsQuery,
  ProductCommentsQueryVariables
>;
export const ReactivateProductsDocument = gql`
  mutation ReactivateProducts($input: ReactivateProductsInput!) {
    reactivateProducts(input: $input) {
      products {
        ...ListProduct
      }
    }
  }
  ${ListProductFragmentDoc}
`;
export type ReactivateProductsMutationFn = Apollo.MutationFunction<
  ReactivateProductsMutation,
  ReactivateProductsMutationVariables
>;
export type ReactivateProductsComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ReactivateProductsMutation,
    ReactivateProductsMutationVariables
  >,
  'mutation'
>;

export const ReactivateProductsComponent = (
  props: ReactivateProductsComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ReactivateProductsMutation,
    ReactivateProductsMutationVariables
  >
    mutation={ReactivateProductsDocument}
    {...props}
  />
);

/**
 * __useReactivateProductsMutation__
 *
 * To run a mutation, you first call `useReactivateProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateProductsMutation, { data, loading, error }] = useReactivateProductsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReactivateProductsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReactivateProductsMutation,
    ReactivateProductsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReactivateProductsMutation,
    ReactivateProductsMutationVariables
  >(ReactivateProductsDocument, options);
}
export type ReactivateProductsMutationHookResult = ReturnType<
  typeof useReactivateProductsMutation
>;
export type ReactivateProductsMutationResult =
  Apollo.MutationResult<ReactivateProductsMutation>;
export type ReactivateProductsMutationOptions = Apollo.BaseMutationOptions<
  ReactivateProductsMutation,
  ReactivateProductsMutationVariables
>;
export const ExpiredProductsDocument = gql`
  query ExpiredProducts(
    $includeKycStatus: Boolean!
    $first: Int!
    $after: String
  ) {
    viewer {
      account @include(if: $includeKycStatus) {
        kycStatus
      }
      expiredProductsConnection(first: $first, after: $after) {
        nodes {
          ...ListProduct
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
  ${ListProductFragmentDoc}
`;
export type ExpiredProductsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ExpiredProductsQuery,
    ExpiredProductsQueryVariables
  >,
  'query'
> &
  (
    | { variables: ExpiredProductsQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const ExpiredProductsComponent = (
  props: ExpiredProductsComponentProps
) => (
  <ApolloReactComponents.Query<
    ExpiredProductsQuery,
    ExpiredProductsQueryVariables
  >
    query={ExpiredProductsDocument}
    {...props}
  />
);

/**
 * __useExpiredProductsQuery__
 *
 * To run a query within a React component, call `useExpiredProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpiredProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpiredProductsQuery({
 *   variables: {
 *      includeKycStatus: // value for 'includeKycStatus'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useExpiredProductsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExpiredProductsQuery,
    ExpiredProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExpiredProductsQuery, ExpiredProductsQueryVariables>(
    ExpiredProductsDocument,
    options
  );
}
export function useExpiredProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExpiredProductsQuery,
    ExpiredProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExpiredProductsQuery,
    ExpiredProductsQueryVariables
  >(ExpiredProductsDocument, options);
}
export type ExpiredProductsQueryHookResult = ReturnType<
  typeof useExpiredProductsQuery
>;
export type ExpiredProductsLazyQueryHookResult = ReturnType<
  typeof useExpiredProductsLazyQuery
>;
export type ExpiredProductsQueryResult = Apollo.QueryResult<
  ExpiredProductsQuery,
  ExpiredProductsQueryVariables
>;
export const UpdatePersonalDetailsDocument = gql`
  mutation UpdatePersonalDetails(
    $displayName: String
    $firstName: String!
    $lastName: String!
    $dateOfBirth: Date!
    $includeDateOfBirth: Boolean!
  ) {
    updateName(
      input: {
        firstName: $firstName
        lastName: $lastName
        displayName: $displayName
      }
    ) {
      clientMutationId
    }
    updateDateOfBirth(input: { dateOfBirth: $dateOfBirth })
      @include(if: $includeDateOfBirth) {
      clientMutationId
    }
  }
`;
export type UpdatePersonalDetailsMutationFn = Apollo.MutationFunction<
  UpdatePersonalDetailsMutation,
  UpdatePersonalDetailsMutationVariables
>;
export type UpdatePersonalDetailsComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdatePersonalDetailsMutation,
    UpdatePersonalDetailsMutationVariables
  >,
  'mutation'
>;

export const UpdatePersonalDetailsComponent = (
  props: UpdatePersonalDetailsComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdatePersonalDetailsMutation,
    UpdatePersonalDetailsMutationVariables
  >
    mutation={UpdatePersonalDetailsDocument}
    {...props}
  />
);

/**
 * __useUpdatePersonalDetailsMutation__
 *
 * To run a mutation, you first call `useUpdatePersonalDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonalDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonalDetailsMutation, { data, loading, error }] = useUpdatePersonalDetailsMutation({
 *   variables: {
 *      displayName: // value for 'displayName'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      dateOfBirth: // value for 'dateOfBirth'
 *      includeDateOfBirth: // value for 'includeDateOfBirth'
 *   },
 * });
 */
export function useUpdatePersonalDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePersonalDetailsMutation,
    UpdatePersonalDetailsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePersonalDetailsMutation,
    UpdatePersonalDetailsMutationVariables
  >(UpdatePersonalDetailsDocument, options);
}
export type UpdatePersonalDetailsMutationHookResult = ReturnType<
  typeof useUpdatePersonalDetailsMutation
>;
export type UpdatePersonalDetailsMutationResult =
  Apollo.MutationResult<UpdatePersonalDetailsMutation>;
export type UpdatePersonalDetailsMutationOptions = Apollo.BaseMutationOptions<
  UpdatePersonalDetailsMutation,
  UpdatePersonalDetailsMutationVariables
>;
export const UpdateEmailAddressDocument = gql`
  mutation UpdateEmailAddress($input: UpdateEmailAddressInput!) {
    updateEmailAddress(input: $input) {
      account {
        ...SettingsAccount
      }
    }
  }
  ${SettingsAccountFragmentDoc}
`;
export type UpdateEmailAddressMutationFn = Apollo.MutationFunction<
  UpdateEmailAddressMutation,
  UpdateEmailAddressMutationVariables
>;
export type UpdateEmailAddressComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateEmailAddressMutation,
    UpdateEmailAddressMutationVariables
  >,
  'mutation'
>;

export const UpdateEmailAddressComponent = (
  props: UpdateEmailAddressComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateEmailAddressMutation,
    UpdateEmailAddressMutationVariables
  >
    mutation={UpdateEmailAddressDocument}
    {...props}
  />
);

/**
 * __useUpdateEmailAddressMutation__
 *
 * To run a mutation, you first call `useUpdateEmailAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailAddressMutation, { data, loading, error }] = useUpdateEmailAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmailAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEmailAddressMutation,
    UpdateEmailAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateEmailAddressMutation,
    UpdateEmailAddressMutationVariables
  >(UpdateEmailAddressDocument, options);
}
export type UpdateEmailAddressMutationHookResult = ReturnType<
  typeof useUpdateEmailAddressMutation
>;
export type UpdateEmailAddressMutationResult =
  Apollo.MutationResult<UpdateEmailAddressMutation>;
export type UpdateEmailAddressMutationOptions = Apollo.BaseMutationOptions<
  UpdateEmailAddressMutation,
  UpdateEmailAddressMutationVariables
>;
export const UpdateLocaleDocument = gql`
  mutation UpdateLocale($input: UpdateLocaleInput!) {
    updateLocale(input: $input) {
      account {
        ...SettingsAccount
      }
    }
  }
  ${SettingsAccountFragmentDoc}
`;
export type UpdateLocaleMutationFn = Apollo.MutationFunction<
  UpdateLocaleMutation,
  UpdateLocaleMutationVariables
>;
export type UpdateLocaleComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateLocaleMutation,
    UpdateLocaleMutationVariables
  >,
  'mutation'
>;

export const UpdateLocaleComponent = (props: UpdateLocaleComponentProps) => (
  <ApolloReactComponents.Mutation<
    UpdateLocaleMutation,
    UpdateLocaleMutationVariables
  >
    mutation={UpdateLocaleDocument}
    {...props}
  />
);

/**
 * __useUpdateLocaleMutation__
 *
 * To run a mutation, you first call `useUpdateLocaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocaleMutation, { data, loading, error }] = useUpdateLocaleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLocaleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLocaleMutation,
    UpdateLocaleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateLocaleMutation,
    UpdateLocaleMutationVariables
  >(UpdateLocaleDocument, options);
}
export type UpdateLocaleMutationHookResult = ReturnType<
  typeof useUpdateLocaleMutation
>;
export type UpdateLocaleMutationResult =
  Apollo.MutationResult<UpdateLocaleMutation>;
export type UpdateLocaleMutationOptions = Apollo.BaseMutationOptions<
  UpdateLocaleMutation,
  UpdateLocaleMutationVariables
>;
export const UpdateHolidayModeDocument = gql`
  mutation UpdateHolidayMode($input: UpdateHolidayModeInput!) {
    updateHolidayMode(input: $input) {
      account {
        ...SettingsAccount
      }
    }
  }
  ${SettingsAccountFragmentDoc}
`;
export type UpdateHolidayModeMutationFn = Apollo.MutationFunction<
  UpdateHolidayModeMutation,
  UpdateHolidayModeMutationVariables
>;
export type UpdateHolidayModeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateHolidayModeMutation,
    UpdateHolidayModeMutationVariables
  >,
  'mutation'
>;

export const UpdateHolidayModeComponent = (
  props: UpdateHolidayModeComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateHolidayModeMutation,
    UpdateHolidayModeMutationVariables
  >
    mutation={UpdateHolidayModeDocument}
    {...props}
  />
);

/**
 * __useUpdateHolidayModeMutation__
 *
 * To run a mutation, you first call `useUpdateHolidayModeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHolidayModeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHolidayModeMutation, { data, loading, error }] = useUpdateHolidayModeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateHolidayModeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateHolidayModeMutation,
    UpdateHolidayModeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateHolidayModeMutation,
    UpdateHolidayModeMutationVariables
  >(UpdateHolidayModeDocument, options);
}
export type UpdateHolidayModeMutationHookResult = ReturnType<
  typeof useUpdateHolidayModeMutation
>;
export type UpdateHolidayModeMutationResult =
  Apollo.MutationResult<UpdateHolidayModeMutation>;
export type UpdateHolidayModeMutationOptions = Apollo.BaseMutationOptions<
  UpdateHolidayModeMutation,
  UpdateHolidayModeMutationVariables
>;
export const UpdatePhoneNumberDocument = gql`
  mutation UpdatePhoneNumber($input: UpdatePhoneNumberInput!) {
    updatePhoneNumber(input: $input) {
      account {
        ...SettingsAccount
      }
    }
  }
  ${SettingsAccountFragmentDoc}
`;
export type UpdatePhoneNumberMutationFn = Apollo.MutationFunction<
  UpdatePhoneNumberMutation,
  UpdatePhoneNumberMutationVariables
>;
export type UpdatePhoneNumberComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdatePhoneNumberMutation,
    UpdatePhoneNumberMutationVariables
  >,
  'mutation'
>;

export const UpdatePhoneNumberComponent = (
  props: UpdatePhoneNumberComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdatePhoneNumberMutation,
    UpdatePhoneNumberMutationVariables
  >
    mutation={UpdatePhoneNumberDocument}
    {...props}
  />
);

/**
 * __useUpdatePhoneNumberMutation__
 *
 * To run a mutation, you first call `useUpdatePhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhoneNumberMutation, { data, loading, error }] = useUpdatePhoneNumberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePhoneNumberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePhoneNumberMutation,
    UpdatePhoneNumberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePhoneNumberMutation,
    UpdatePhoneNumberMutationVariables
  >(UpdatePhoneNumberDocument, options);
}
export type UpdatePhoneNumberMutationHookResult = ReturnType<
  typeof useUpdatePhoneNumberMutation
>;
export type UpdatePhoneNumberMutationResult =
  Apollo.MutationResult<UpdatePhoneNumberMutation>;
export type UpdatePhoneNumberMutationOptions = Apollo.BaseMutationOptions<
  UpdatePhoneNumberMutation,
  UpdatePhoneNumberMutationVariables
>;
export const VerifyPhoneNumberDocument = gql`
  mutation VerifyPhoneNumber($input: VerifyPhoneNumberInput!) {
    verifyPhoneNumber(input: $input) {
      account {
        ...SettingsAccount
      }
    }
  }
  ${SettingsAccountFragmentDoc}
`;
export type VerifyPhoneNumberMutationFn = Apollo.MutationFunction<
  VerifyPhoneNumberMutation,
  VerifyPhoneNumberMutationVariables
>;
export type VerifyPhoneNumberComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    VerifyPhoneNumberMutation,
    VerifyPhoneNumberMutationVariables
  >,
  'mutation'
>;

export const VerifyPhoneNumberComponent = (
  props: VerifyPhoneNumberComponentProps
) => (
  <ApolloReactComponents.Mutation<
    VerifyPhoneNumberMutation,
    VerifyPhoneNumberMutationVariables
  >
    mutation={VerifyPhoneNumberDocument}
    {...props}
  />
);

/**
 * __useVerifyPhoneNumberMutation__
 *
 * To run a mutation, you first call `useVerifyPhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyPhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyPhoneNumberMutation, { data, loading, error }] = useVerifyPhoneNumberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyPhoneNumberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyPhoneNumberMutation,
    VerifyPhoneNumberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VerifyPhoneNumberMutation,
    VerifyPhoneNumberMutationVariables
  >(VerifyPhoneNumberDocument, options);
}
export type VerifyPhoneNumberMutationHookResult = ReturnType<
  typeof useVerifyPhoneNumberMutation
>;
export type VerifyPhoneNumberMutationResult =
  Apollo.MutationResult<VerifyPhoneNumberMutation>;
export type VerifyPhoneNumberMutationOptions = Apollo.BaseMutationOptions<
  VerifyPhoneNumberMutation,
  VerifyPhoneNumberMutationVariables
>;
export const AddBankAccountDocument = gql`
  mutation AddBankAccount($input: AddBankAccountInput!) {
    addBankAccount(input: $input) {
      account {
        ...SettingsAccount
      }
    }
  }
  ${SettingsAccountFragmentDoc}
`;
export type AddBankAccountMutationFn = Apollo.MutationFunction<
  AddBankAccountMutation,
  AddBankAccountMutationVariables
>;
export type AddBankAccountComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AddBankAccountMutation,
    AddBankAccountMutationVariables
  >,
  'mutation'
>;

export const AddBankAccountComponent = (
  props: AddBankAccountComponentProps
) => (
  <ApolloReactComponents.Mutation<
    AddBankAccountMutation,
    AddBankAccountMutationVariables
  >
    mutation={AddBankAccountDocument}
    {...props}
  />
);

/**
 * __useAddBankAccountMutation__
 *
 * To run a mutation, you first call `useAddBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBankAccountMutation, { data, loading, error }] = useAddBankAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddBankAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddBankAccountMutation,
    AddBankAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddBankAccountMutation,
    AddBankAccountMutationVariables
  >(AddBankAccountDocument, options);
}
export type AddBankAccountMutationHookResult = ReturnType<
  typeof useAddBankAccountMutation
>;
export type AddBankAccountMutationResult =
  Apollo.MutationResult<AddBankAccountMutation>;
export type AddBankAccountMutationOptions = Apollo.BaseMutationOptions<
  AddBankAccountMutation,
  AddBankAccountMutationVariables
>;
export const DeleteBankAccountDocument = gql`
  mutation DeleteBankAccount($input: DeleteBankAccountInput!) {
    deleteBankAccount(input: $input) {
      account {
        ...SettingsAccount
      }
    }
  }
  ${SettingsAccountFragmentDoc}
`;
export type DeleteBankAccountMutationFn = Apollo.MutationFunction<
  DeleteBankAccountMutation,
  DeleteBankAccountMutationVariables
>;
export type DeleteBankAccountComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteBankAccountMutation,
    DeleteBankAccountMutationVariables
  >,
  'mutation'
>;

export const DeleteBankAccountComponent = (
  props: DeleteBankAccountComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeleteBankAccountMutation,
    DeleteBankAccountMutationVariables
  >
    mutation={DeleteBankAccountDocument}
    {...props}
  />
);

/**
 * __useDeleteBankAccountMutation__
 *
 * To run a mutation, you first call `useDeleteBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBankAccountMutation, { data, loading, error }] = useDeleteBankAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBankAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteBankAccountMutation,
    DeleteBankAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteBankAccountMutation,
    DeleteBankAccountMutationVariables
  >(DeleteBankAccountDocument, options);
}
export type DeleteBankAccountMutationHookResult = ReturnType<
  typeof useDeleteBankAccountMutation
>;
export type DeleteBankAccountMutationResult =
  Apollo.MutationResult<DeleteBankAccountMutation>;
export type DeleteBankAccountMutationOptions = Apollo.BaseMutationOptions<
  DeleteBankAccountMutation,
  DeleteBankAccountMutationVariables
>;
export const MarkBankAccountAsDefaultDocument = gql`
  mutation MarkBankAccountAsDefault($input: MarkBankAccountAsDefaultInput!) {
    markBankAccountAsDefault(input: $input) {
      account {
        ...SettingsAccount
      }
    }
  }
  ${SettingsAccountFragmentDoc}
`;
export type MarkBankAccountAsDefaultMutationFn = Apollo.MutationFunction<
  MarkBankAccountAsDefaultMutation,
  MarkBankAccountAsDefaultMutationVariables
>;
export type MarkBankAccountAsDefaultComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    MarkBankAccountAsDefaultMutation,
    MarkBankAccountAsDefaultMutationVariables
  >,
  'mutation'
>;

export const MarkBankAccountAsDefaultComponent = (
  props: MarkBankAccountAsDefaultComponentProps
) => (
  <ApolloReactComponents.Mutation<
    MarkBankAccountAsDefaultMutation,
    MarkBankAccountAsDefaultMutationVariables
  >
    mutation={MarkBankAccountAsDefaultDocument}
    {...props}
  />
);

/**
 * __useMarkBankAccountAsDefaultMutation__
 *
 * To run a mutation, you first call `useMarkBankAccountAsDefaultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkBankAccountAsDefaultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markBankAccountAsDefaultMutation, { data, loading, error }] = useMarkBankAccountAsDefaultMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkBankAccountAsDefaultMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkBankAccountAsDefaultMutation,
    MarkBankAccountAsDefaultMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkBankAccountAsDefaultMutation,
    MarkBankAccountAsDefaultMutationVariables
  >(MarkBankAccountAsDefaultDocument, options);
}
export type MarkBankAccountAsDefaultMutationHookResult = ReturnType<
  typeof useMarkBankAccountAsDefaultMutation
>;
export type MarkBankAccountAsDefaultMutationResult =
  Apollo.MutationResult<MarkBankAccountAsDefaultMutation>;
export type MarkBankAccountAsDefaultMutationOptions =
  Apollo.BaseMutationOptions<
    MarkBankAccountAsDefaultMutation,
    MarkBankAccountAsDefaultMutationVariables
  >;
export const DeleteAccountDocument = gql`
  mutation DeleteAccount($input: DeleteAccountInput!) {
    deleteAccount(input: $input) {
      clientMutationId
    }
  }
`;
export type DeleteAccountMutationFn = Apollo.MutationFunction<
  DeleteAccountMutation,
  DeleteAccountMutationVariables
>;
export type DeleteAccountComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteAccountMutation,
    DeleteAccountMutationVariables
  >,
  'mutation'
>;

export const DeleteAccountComponent = (props: DeleteAccountComponentProps) => (
  <ApolloReactComponents.Mutation<
    DeleteAccountMutation,
    DeleteAccountMutationVariables
  >
    mutation={DeleteAccountDocument}
    {...props}
  />
);

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAccountMutation,
    DeleteAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAccountMutation,
    DeleteAccountMutationVariables
  >(DeleteAccountDocument, options);
}
export type DeleteAccountMutationHookResult = ReturnType<
  typeof useDeleteAccountMutation
>;
export type DeleteAccountMutationResult =
  Apollo.MutationResult<DeleteAccountMutation>;
export type DeleteAccountMutationOptions = Apollo.BaseMutationOptions<
  DeleteAccountMutation,
  DeleteAccountMutationVariables
>;
export const UpdateAccountSizesDocument = gql`
  mutation UpdateAccountSizes($input: UpdateAccountSizesInput!) {
    updateAccountSizes(input: $input) {
      account {
        ...SizesCategoriesAccount
      }
    }
  }
  ${SizesCategoriesAccountFragmentDoc}
`;
export type UpdateAccountSizesMutationFn = Apollo.MutationFunction<
  UpdateAccountSizesMutation,
  UpdateAccountSizesMutationVariables
>;
export type UpdateAccountSizesComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateAccountSizesMutation,
    UpdateAccountSizesMutationVariables
  >,
  'mutation'
>;

export const UpdateAccountSizesComponent = (
  props: UpdateAccountSizesComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateAccountSizesMutation,
    UpdateAccountSizesMutationVariables
  >
    mutation={UpdateAccountSizesDocument}
    {...props}
  />
);

/**
 * __useUpdateAccountSizesMutation__
 *
 * To run a mutation, you first call `useUpdateAccountSizesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountSizesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountSizesMutation, { data, loading, error }] = useUpdateAccountSizesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountSizesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAccountSizesMutation,
    UpdateAccountSizesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAccountSizesMutation,
    UpdateAccountSizesMutationVariables
  >(UpdateAccountSizesDocument, options);
}
export type UpdateAccountSizesMutationHookResult = ReturnType<
  typeof useUpdateAccountSizesMutation
>;
export type UpdateAccountSizesMutationResult =
  Apollo.MutationResult<UpdateAccountSizesMutation>;
export type UpdateAccountSizesMutationOptions = Apollo.BaseMutationOptions<
  UpdateAccountSizesMutation,
  UpdateAccountSizesMutationVariables
>;
export const UpdateAccountCategoriesDocument = gql`
  mutation UpdateAccountCategories($input: UpdateAccountCategoriesInput!) {
    updateAccountCategories(input: $input) {
      account {
        ...SizesCategoriesAccount
      }
    }
  }
  ${SizesCategoriesAccountFragmentDoc}
`;
export type UpdateAccountCategoriesMutationFn = Apollo.MutationFunction<
  UpdateAccountCategoriesMutation,
  UpdateAccountCategoriesMutationVariables
>;
export type UpdateAccountCategoriesComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateAccountCategoriesMutation,
    UpdateAccountCategoriesMutationVariables
  >,
  'mutation'
>;

export const UpdateAccountCategoriesComponent = (
  props: UpdateAccountCategoriesComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateAccountCategoriesMutation,
    UpdateAccountCategoriesMutationVariables
  >
    mutation={UpdateAccountCategoriesDocument}
    {...props}
  />
);

/**
 * __useUpdateAccountCategoriesMutation__
 *
 * To run a mutation, you first call `useUpdateAccountCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountCategoriesMutation, { data, loading, error }] = useUpdateAccountCategoriesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountCategoriesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAccountCategoriesMutation,
    UpdateAccountCategoriesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAccountCategoriesMutation,
    UpdateAccountCategoriesMutationVariables
  >(UpdateAccountCategoriesDocument, options);
}
export type UpdateAccountCategoriesMutationHookResult = ReturnType<
  typeof useUpdateAccountCategoriesMutation
>;
export type UpdateAccountCategoriesMutationResult =
  Apollo.MutationResult<UpdateAccountCategoriesMutation>;
export type UpdateAccountCategoriesMutationOptions = Apollo.BaseMutationOptions<
  UpdateAccountCategoriesMutation,
  UpdateAccountCategoriesMutationVariables
>;
export const AccountSettingsDocument = gql`
  query AccountSettings {
    viewer {
      account {
        ...SettingsAccount
      }
    }
  }
  ${SettingsAccountFragmentDoc}
`;
export type AccountSettingsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AccountSettingsQuery,
    AccountSettingsQueryVariables
  >,
  'query'
>;

export const AccountSettingsComponent = (
  props: AccountSettingsComponentProps
) => (
  <ApolloReactComponents.Query<
    AccountSettingsQuery,
    AccountSettingsQueryVariables
  >
    query={AccountSettingsDocument}
    {...props}
  />
);

/**
 * __useAccountSettingsQuery__
 *
 * To run a query within a React component, call `useAccountSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AccountSettingsQuery,
    AccountSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountSettingsQuery, AccountSettingsQueryVariables>(
    AccountSettingsDocument,
    options
  );
}
export function useAccountSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountSettingsQuery,
    AccountSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccountSettingsQuery,
    AccountSettingsQueryVariables
  >(AccountSettingsDocument, options);
}
export type AccountSettingsQueryHookResult = ReturnType<
  typeof useAccountSettingsQuery
>;
export type AccountSettingsLazyQueryHookResult = ReturnType<
  typeof useAccountSettingsLazyQuery
>;
export type AccountSettingsQueryResult = Apollo.QueryResult<
  AccountSettingsQuery,
  AccountSettingsQueryVariables
>;
export const AccountSizesCategoriesDocument = gql`
  query AccountSizesCategories {
    viewer {
      account {
        ...SizesCategoriesAccount
      }
    }
  }
  ${SizesCategoriesAccountFragmentDoc}
`;
export type AccountSizesCategoriesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AccountSizesCategoriesQuery,
    AccountSizesCategoriesQueryVariables
  >,
  'query'
>;

export const AccountSizesCategoriesComponent = (
  props: AccountSizesCategoriesComponentProps
) => (
  <ApolloReactComponents.Query<
    AccountSizesCategoriesQuery,
    AccountSizesCategoriesQueryVariables
  >
    query={AccountSizesCategoriesDocument}
    {...props}
  />
);

/**
 * __useAccountSizesCategoriesQuery__
 *
 * To run a query within a React component, call `useAccountSizesCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountSizesCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountSizesCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountSizesCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AccountSizesCategoriesQuery,
    AccountSizesCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccountSizesCategoriesQuery,
    AccountSizesCategoriesQueryVariables
  >(AccountSizesCategoriesDocument, options);
}
export function useAccountSizesCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountSizesCategoriesQuery,
    AccountSizesCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccountSizesCategoriesQuery,
    AccountSizesCategoriesQueryVariables
  >(AccountSizesCategoriesDocument, options);
}
export type AccountSizesCategoriesQueryHookResult = ReturnType<
  typeof useAccountSizesCategoriesQuery
>;
export type AccountSizesCategoriesLazyQueryHookResult = ReturnType<
  typeof useAccountSizesCategoriesLazyQuery
>;
export type AccountSizesCategoriesQueryResult = Apollo.QueryResult<
  AccountSizesCategoriesQuery,
  AccountSizesCategoriesQueryVariables
>;
export const UserTodoDocument = gql`
  query UserTodo {
    viewer {
      me {
        ...UserTodoState
      }
    }
  }
  ${UserTodoStateFragmentDoc}
`;
export type UserTodoComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    UserTodoQuery,
    UserTodoQueryVariables
  >,
  'query'
>;

export const UserTodoComponent = (props: UserTodoComponentProps) => (
  <ApolloReactComponents.Query<UserTodoQuery, UserTodoQueryVariables>
    query={UserTodoDocument}
    {...props}
  />
);

/**
 * __useUserTodoQuery__
 *
 * To run a query within a React component, call `useUserTodoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTodoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTodoQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserTodoQuery(
  baseOptions?: Apollo.QueryHookOptions<UserTodoQuery, UserTodoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserTodoQuery, UserTodoQueryVariables>(
    UserTodoDocument,
    options
  );
}
export function useUserTodoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserTodoQuery,
    UserTodoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserTodoQuery, UserTodoQueryVariables>(
    UserTodoDocument,
    options
  );
}
export type UserTodoQueryHookResult = ReturnType<typeof useUserTodoQuery>;
export type UserTodoLazyQueryHookResult = ReturnType<
  typeof useUserTodoLazyQuery
>;
export type UserTodoQueryResult = Apollo.QueryResult<
  UserTodoQuery,
  UserTodoQueryVariables
>;
export const SellersTodoDocument = gql`
  query SellersTodo($after: String) {
    usersConnection(first: 6, after: $after) {
      nodes {
        ...SellersTodo
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${SellersTodoFragmentDoc}
`;
export type SellersTodoComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    SellersTodoQuery,
    SellersTodoQueryVariables
  >,
  'query'
>;

export const SellersTodoComponent = (props: SellersTodoComponentProps) => (
  <ApolloReactComponents.Query<SellersTodoQuery, SellersTodoQueryVariables>
    query={SellersTodoDocument}
    {...props}
  />
);

/**
 * __useSellersTodoQuery__
 *
 * To run a query within a React component, call `useSellersTodoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellersTodoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellersTodoQuery({
 *   variables: {
 *      after: // value for 'after'
 *   },
 * });
 */
export function useSellersTodoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SellersTodoQuery,
    SellersTodoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SellersTodoQuery, SellersTodoQueryVariables>(
    SellersTodoDocument,
    options
  );
}
export function useSellersTodoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SellersTodoQuery,
    SellersTodoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SellersTodoQuery, SellersTodoQueryVariables>(
    SellersTodoDocument,
    options
  );
}
export type SellersTodoQueryHookResult = ReturnType<typeof useSellersTodoQuery>;
export type SellersTodoLazyQueryHookResult = ReturnType<
  typeof useSellersTodoLazyQuery
>;
export type SellersTodoQueryResult = Apollo.QueryResult<
  SellersTodoQuery,
  SellersTodoQueryVariables
>;
export const ProductsTodoDocument = gql`
  query ProductsTodo(
    $first: Int!
    $after: String
    $filterBy: ProductFilters
    $orderBy: ProductsOrder
  ) {
    productsConnection(
      first: $first
      after: $after
      filterBy: $filterBy
      orderBy: $orderBy
    ) {
      nodes {
        ...ListProduct
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${ListProductFragmentDoc}
`;
export type ProductsTodoComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ProductsTodoQuery,
    ProductsTodoQueryVariables
  >,
  'query'
> &
  (
    | { variables: ProductsTodoQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const ProductsTodoComponent = (props: ProductsTodoComponentProps) => (
  <ApolloReactComponents.Query<ProductsTodoQuery, ProductsTodoQueryVariables>
    query={ProductsTodoDocument}
    {...props}
  />
);

/**
 * __useProductsTodoQuery__
 *
 * To run a query within a React component, call `useProductsTodoQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsTodoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsTodoQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useProductsTodoQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProductsTodoQuery,
    ProductsTodoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductsTodoQuery, ProductsTodoQueryVariables>(
    ProductsTodoDocument,
    options
  );
}
export function useProductsTodoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductsTodoQuery,
    ProductsTodoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductsTodoQuery, ProductsTodoQueryVariables>(
    ProductsTodoDocument,
    options
  );
}
export type ProductsTodoQueryHookResult = ReturnType<
  typeof useProductsTodoQuery
>;
export type ProductsTodoLazyQueryHookResult = ReturnType<
  typeof useProductsTodoLazyQuery
>;
export type ProductsTodoQueryResult = Apollo.QueryResult<
  ProductsTodoQuery,
  ProductsTodoQueryVariables
>;
export const UpdateProfileImageDocument = gql`
  mutation UpdateProfileImage($input: UpdateProfileImageInput!) {
    updateProfileImage(input: $input) {
      account {
        user {
          id
          profileImage {
            url
          }
        }
      }
    }
  }
`;
export type UpdateProfileImageMutationFn = Apollo.MutationFunction<
  UpdateProfileImageMutation,
  UpdateProfileImageMutationVariables
>;
export type UpdateProfileImageComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateProfileImageMutation,
    UpdateProfileImageMutationVariables
  >,
  'mutation'
>;

export const UpdateProfileImageComponent = (
  props: UpdateProfileImageComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateProfileImageMutation,
    UpdateProfileImageMutationVariables
  >
    mutation={UpdateProfileImageDocument}
    {...props}
  />
);

/**
 * __useUpdateProfileImageMutation__
 *
 * To run a mutation, you first call `useUpdateProfileImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileImageMutation, { data, loading, error }] = useUpdateProfileImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileImageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProfileImageMutation,
    UpdateProfileImageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProfileImageMutation,
    UpdateProfileImageMutationVariables
  >(UpdateProfileImageDocument, options);
}
export type UpdateProfileImageMutationHookResult = ReturnType<
  typeof useUpdateProfileImageMutation
>;
export type UpdateProfileImageMutationResult =
  Apollo.MutationResult<UpdateProfileImageMutation>;
export type UpdateProfileImageMutationOptions = Apollo.BaseMutationOptions<
  UpdateProfileImageMutation,
  UpdateProfileImageMutationVariables
>;
export const UpdateCoverImageDocument = gql`
  mutation UpdateCoverImage($input: UpdateCoverImageInput!) {
    updateCoverImage(input: $input) {
      account {
        user {
          id
          coverImage {
            url
          }
        }
      }
    }
  }
`;
export type UpdateCoverImageMutationFn = Apollo.MutationFunction<
  UpdateCoverImageMutation,
  UpdateCoverImageMutationVariables
>;
export type UpdateCoverImageComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateCoverImageMutation,
    UpdateCoverImageMutationVariables
  >,
  'mutation'
>;

export const UpdateCoverImageComponent = (
  props: UpdateCoverImageComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateCoverImageMutation,
    UpdateCoverImageMutationVariables
  >
    mutation={UpdateCoverImageDocument}
    {...props}
  />
);

/**
 * __useUpdateCoverImageMutation__
 *
 * To run a mutation, you first call `useUpdateCoverImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCoverImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCoverImageMutation, { data, loading, error }] = useUpdateCoverImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCoverImageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCoverImageMutation,
    UpdateCoverImageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCoverImageMutation,
    UpdateCoverImageMutationVariables
  >(UpdateCoverImageDocument, options);
}
export type UpdateCoverImageMutationHookResult = ReturnType<
  typeof useUpdateCoverImageMutation
>;
export type UpdateCoverImageMutationResult =
  Apollo.MutationResult<UpdateCoverImageMutation>;
export type UpdateCoverImageMutationOptions = Apollo.BaseMutationOptions<
  UpdateCoverImageMutation,
  UpdateCoverImageMutationVariables
>;
export const UserClosetDocument = gql`
  query UserCloset(
    $id: ID!
    $first: Int!
    $after: String
    $includeProducts: Boolean!
    $includeFavorites: Boolean!
    $includeFollowers: Boolean!
    $includeFollowing: Boolean!
  ) {
    user(id: $id) {
      ...Closet
    }
  }
  ${ClosetFragmentDoc}
`;
export type UserClosetComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    UserClosetQuery,
    UserClosetQueryVariables
  >,
  'query'
> &
  ({ variables: UserClosetQueryVariables; skip?: boolean } | { skip: boolean });

export const UserClosetComponent = (props: UserClosetComponentProps) => (
  <ApolloReactComponents.Query<UserClosetQuery, UserClosetQueryVariables>
    query={UserClosetDocument}
    {...props}
  />
);

/**
 * __useUserClosetQuery__
 *
 * To run a query within a React component, call `useUserClosetQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserClosetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserClosetQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      includeProducts: // value for 'includeProducts'
 *      includeFavorites: // value for 'includeFavorites'
 *      includeFollowers: // value for 'includeFollowers'
 *      includeFollowing: // value for 'includeFollowing'
 *   },
 * });
 */
export function useUserClosetQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserClosetQuery,
    UserClosetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserClosetQuery, UserClosetQueryVariables>(
    UserClosetDocument,
    options
  );
}
export function useUserClosetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserClosetQuery,
    UserClosetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserClosetQuery, UserClosetQueryVariables>(
    UserClosetDocument,
    options
  );
}
export type UserClosetQueryHookResult = ReturnType<typeof useUserClosetQuery>;
export type UserClosetLazyQueryHookResult = ReturnType<
  typeof useUserClosetLazyQuery
>;
export type UserClosetQueryResult = Apollo.QueryResult<
  UserClosetQuery,
  UserClosetQueryVariables
>;
export const UserClosetCoreDataDocument = gql`
  query UserClosetCoreData($id: ID!) {
    user(id: $id) {
      ...ClosetCore
    }
  }
  ${ClosetCoreFragmentDoc}
`;
export type UserClosetCoreDataComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    UserClosetCoreDataQuery,
    UserClosetCoreDataQueryVariables
  >,
  'query'
> &
  (
    | { variables: UserClosetCoreDataQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const UserClosetCoreDataComponent = (
  props: UserClosetCoreDataComponentProps
) => (
  <ApolloReactComponents.Query<
    UserClosetCoreDataQuery,
    UserClosetCoreDataQueryVariables
  >
    query={UserClosetCoreDataDocument}
    {...props}
  />
);

/**
 * __useUserClosetCoreDataQuery__
 *
 * To run a query within a React component, call `useUserClosetCoreDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserClosetCoreDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserClosetCoreDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserClosetCoreDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserClosetCoreDataQuery,
    UserClosetCoreDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UserClosetCoreDataQuery,
    UserClosetCoreDataQueryVariables
  >(UserClosetCoreDataDocument, options);
}
export function useUserClosetCoreDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserClosetCoreDataQuery,
    UserClosetCoreDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserClosetCoreDataQuery,
    UserClosetCoreDataQueryVariables
  >(UserClosetCoreDataDocument, options);
}
export type UserClosetCoreDataQueryHookResult = ReturnType<
  typeof useUserClosetCoreDataQuery
>;
export type UserClosetCoreDataLazyQueryHookResult = ReturnType<
  typeof useUserClosetCoreDataLazyQuery
>;
export type UserClosetCoreDataQueryResult = Apollo.QueryResult<
  UserClosetCoreDataQuery,
  UserClosetCoreDataQueryVariables
>;
export const ClosetViewerStateDocument = gql`
  query ClosetViewerState {
    viewer {
      account {
        kycStatus
        defaultBankAccount {
          id
        }
        partialAddress {
          ...PartialAddress
        }
        defaultAddress {
          id
        }
      }
    }
  }
  ${PartialAddressFragmentDoc}
`;
export type ClosetViewerStateComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ClosetViewerStateQuery,
    ClosetViewerStateQueryVariables
  >,
  'query'
>;

export const ClosetViewerStateComponent = (
  props: ClosetViewerStateComponentProps
) => (
  <ApolloReactComponents.Query<
    ClosetViewerStateQuery,
    ClosetViewerStateQueryVariables
  >
    query={ClosetViewerStateDocument}
    {...props}
  />
);

/**
 * __useClosetViewerStateQuery__
 *
 * To run a query within a React component, call `useClosetViewerStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useClosetViewerStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClosetViewerStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useClosetViewerStateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ClosetViewerStateQuery,
    ClosetViewerStateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ClosetViewerStateQuery,
    ClosetViewerStateQueryVariables
  >(ClosetViewerStateDocument, options);
}
export function useClosetViewerStateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClosetViewerStateQuery,
    ClosetViewerStateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClosetViewerStateQuery,
    ClosetViewerStateQueryVariables
  >(ClosetViewerStateDocument, options);
}
export type ClosetViewerStateQueryHookResult = ReturnType<
  typeof useClosetViewerStateQuery
>;
export type ClosetViewerStateLazyQueryHookResult = ReturnType<
  typeof useClosetViewerStateLazyQuery
>;
export type ClosetViewerStateQueryResult = Apollo.QueryResult<
  ClosetViewerStateQuery,
  ClosetViewerStateQueryVariables
>;
export const ClosetFavoritesDocument = gql`
  query ClosetFavorites($id: ID!, $first: Int!, $after: String) {
    user(id: $id) {
      id
      ...ClosetFavoritesConnection
    }
  }
  ${ClosetFavoritesConnectionFragmentDoc}
`;
export type ClosetFavoritesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ClosetFavoritesQuery,
    ClosetFavoritesQueryVariables
  >,
  'query'
> &
  (
    | { variables: ClosetFavoritesQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const ClosetFavoritesComponent = (
  props: ClosetFavoritesComponentProps
) => (
  <ApolloReactComponents.Query<
    ClosetFavoritesQuery,
    ClosetFavoritesQueryVariables
  >
    query={ClosetFavoritesDocument}
    {...props}
  />
);

/**
 * __useClosetFavoritesQuery__
 *
 * To run a query within a React component, call `useClosetFavoritesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClosetFavoritesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClosetFavoritesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useClosetFavoritesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClosetFavoritesQuery,
    ClosetFavoritesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClosetFavoritesQuery, ClosetFavoritesQueryVariables>(
    ClosetFavoritesDocument,
    options
  );
}
export function useClosetFavoritesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClosetFavoritesQuery,
    ClosetFavoritesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClosetFavoritesQuery,
    ClosetFavoritesQueryVariables
  >(ClosetFavoritesDocument, options);
}
export type ClosetFavoritesQueryHookResult = ReturnType<
  typeof useClosetFavoritesQuery
>;
export type ClosetFavoritesLazyQueryHookResult = ReturnType<
  typeof useClosetFavoritesLazyQuery
>;
export type ClosetFavoritesQueryResult = Apollo.QueryResult<
  ClosetFavoritesQuery,
  ClosetFavoritesQueryVariables
>;
export const ClosetFollowersDocument = gql`
  query ClosetFollowers($id: ID!, $first: Int!, $after: String) {
    user(id: $id) {
      id
      ...ClosetFollowersConnection
    }
  }
  ${ClosetFollowersConnectionFragmentDoc}
`;
export type ClosetFollowersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ClosetFollowersQuery,
    ClosetFollowersQueryVariables
  >,
  'query'
> &
  (
    | { variables: ClosetFollowersQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const ClosetFollowersComponent = (
  props: ClosetFollowersComponentProps
) => (
  <ApolloReactComponents.Query<
    ClosetFollowersQuery,
    ClosetFollowersQueryVariables
  >
    query={ClosetFollowersDocument}
    {...props}
  />
);

/**
 * __useClosetFollowersQuery__
 *
 * To run a query within a React component, call `useClosetFollowersQuery` and pass it any options that fit your needs.
 * When your component renders, `useClosetFollowersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClosetFollowersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useClosetFollowersQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClosetFollowersQuery,
    ClosetFollowersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClosetFollowersQuery, ClosetFollowersQueryVariables>(
    ClosetFollowersDocument,
    options
  );
}
export function useClosetFollowersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClosetFollowersQuery,
    ClosetFollowersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClosetFollowersQuery,
    ClosetFollowersQueryVariables
  >(ClosetFollowersDocument, options);
}
export type ClosetFollowersQueryHookResult = ReturnType<
  typeof useClosetFollowersQuery
>;
export type ClosetFollowersLazyQueryHookResult = ReturnType<
  typeof useClosetFollowersLazyQuery
>;
export type ClosetFollowersQueryResult = Apollo.QueryResult<
  ClosetFollowersQuery,
  ClosetFollowersQueryVariables
>;
export const ClosetFollowingDocument = gql`
  query ClosetFollowing($id: ID!, $first: Int!, $after: String) {
    user(id: $id) {
      id
      ...ClosetFollowingConnection
    }
  }
  ${ClosetFollowingConnectionFragmentDoc}
`;
export type ClosetFollowingComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ClosetFollowingQuery,
    ClosetFollowingQueryVariables
  >,
  'query'
> &
  (
    | { variables: ClosetFollowingQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const ClosetFollowingComponent = (
  props: ClosetFollowingComponentProps
) => (
  <ApolloReactComponents.Query<
    ClosetFollowingQuery,
    ClosetFollowingQueryVariables
  >
    query={ClosetFollowingDocument}
    {...props}
  />
);

/**
 * __useClosetFollowingQuery__
 *
 * To run a query within a React component, call `useClosetFollowingQuery` and pass it any options that fit your needs.
 * When your component renders, `useClosetFollowingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClosetFollowingQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useClosetFollowingQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClosetFollowingQuery,
    ClosetFollowingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClosetFollowingQuery, ClosetFollowingQueryVariables>(
    ClosetFollowingDocument,
    options
  );
}
export function useClosetFollowingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClosetFollowingQuery,
    ClosetFollowingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClosetFollowingQuery,
    ClosetFollowingQueryVariables
  >(ClosetFollowingDocument, options);
}
export type ClosetFollowingQueryHookResult = ReturnType<
  typeof useClosetFollowingQuery
>;
export type ClosetFollowingLazyQueryHookResult = ReturnType<
  typeof useClosetFollowingLazyQuery
>;
export type ClosetFollowingQueryResult = Apollo.QueryResult<
  ClosetFollowingQuery,
  ClosetFollowingQueryVariables
>;
export const ClosetProductsDocument = gql`
  query ClosetProducts($id: ID!, $first: Int!, $after: String) {
    user(id: $id) {
      id
      ...ClosetProductsConnection
    }
  }
  ${ClosetProductsConnectionFragmentDoc}
`;
export type ClosetProductsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ClosetProductsQuery,
    ClosetProductsQueryVariables
  >,
  'query'
> &
  (
    | { variables: ClosetProductsQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const ClosetProductsComponent = (
  props: ClosetProductsComponentProps
) => (
  <ApolloReactComponents.Query<
    ClosetProductsQuery,
    ClosetProductsQueryVariables
  >
    query={ClosetProductsDocument}
    {...props}
  />
);

/**
 * __useClosetProductsQuery__
 *
 * To run a query within a React component, call `useClosetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClosetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClosetProductsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useClosetProductsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClosetProductsQuery,
    ClosetProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClosetProductsQuery, ClosetProductsQueryVariables>(
    ClosetProductsDocument,
    options
  );
}
export function useClosetProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClosetProductsQuery,
    ClosetProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClosetProductsQuery, ClosetProductsQueryVariables>(
    ClosetProductsDocument,
    options
  );
}
export type ClosetProductsQueryHookResult = ReturnType<
  typeof useClosetProductsQuery
>;
export type ClosetProductsLazyQueryHookResult = ReturnType<
  typeof useClosetProductsLazyQuery
>;
export type ClosetProductsQueryResult = Apollo.QueryResult<
  ClosetProductsQuery,
  ClosetProductsQueryVariables
>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Addressable: ['ServicePoint', 'UserAddress'],
    Badge: ['ProductBadge', 'UserBadge'],
    BidGroup: ['BundleBidGroup', 'ProductBidGroup'],
    CartGroup: ['OrderCartGroup', 'ShoppingCartGroup'],
    CartPriceBreakdownItem: [
      'BasicCartPriceBreakdownItem',
      'DescriptionCartPriceBreakdownItem',
    ],
    CommunicationSubscription: [
      'EmailSubscription',
      'PushNotificationSubscription',
    ],
    FeedItem: ['HTMLFeedItem', 'RecommendedSellersFeedItem', 'UserSizesItem'],
    OrderShipment: ['LabelOrderShipment', 'TrackingCodeOrderShipment'],
    ParcelPrice: ['Price', 'PriceRange'],
    ProductSizeInterface: ['ProductSize'],
    ReturnRequestEvent: ['AdminReturnRequestEvent', 'UserReturnRequestEvent'],
    ShipmentDestination: ['ShipmentServicePoint', 'UserAddress'],
    ShoppingCartItem: ['BundleBidGroup', 'Product', 'ProductBidGroup'],
  },
};
export default result;
