import { LanguageContext } from '@/common/context/LanguageContext';
import { StaticDataContext } from '@/common/context/StaticDataContext';
import {
  Omit,
  ProductFilters,
  ProductsOrder,
} from '@/common/generated/graphql';
import { buildAs, buildHref, buildPath } from '@/common/utils/filters';
import Link, { LinkProps } from 'next/link';
import { FC, PropsWithChildren, useContext } from 'react';

export type Props = {
  filters?: Partial<ProductFilters>;
  page?: number;
  orderBy?: ProductsOrder;
} & Omit<LinkProps, 'href' | 'as'> &
  PropsWithChildren;

export const FilterLink: FC<Props> = ({ filters, page, orderBy, ...props }) => {
  const { findCategoryById } = useContext(StaticDataContext);
  const { language } = useContext(LanguageContext);

  const path = buildPath(language, filters, findCategoryById);
  return (
    <Link
      href={buildHref(filters, {
        page,
        orderBy,
      })}
      as={buildAs(filters, { path, page, orderBy })}
      {...props}
    />
  );
};
