import { rgba } from 'polished';
import { FC } from 'react';
import styled from 'styled-components';
import Heart from '../icons/Heart';
import ToggleFavoriteButton, {
  FavoriteEntryPoint,
} from './ToggleFavoriteButton';

const StyledToggleFavoriteButton = styled(ToggleFavoriteButton)`
  display: flex;
  border-radius: 50%;
  border: none;
  background-color: #fff;
  width: 36px;
  height: 36px;
  padding: 0;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 6px ${rgba('#000', 0.4)};
  outline: none;
  cursor: pointer;
  transition: box-shadow 0.2s ease-in;

  &:hover {
    box-shadow: 0 3px 8px ${rgba('#000', 0.6)};
  }
`;

const StyledHeart = styled(Heart)`
  width: 24px;
  height: 24px;
`;

const FavoriteButton: FC<{
  productId: string;
  viewerHasFavorited?: boolean;
  entryPoint: FavoriteEntryPoint;
}> = ({ viewerHasFavorited, productId, ...props }) => {
  return (
    <StyledToggleFavoriteButton
      viewerHasFavorited={viewerHasFavorited}
      productId={productId}
      {...props}
    >
      <StyledHeart filled={viewerHasFavorited} />
    </StyledToggleFavoriteButton>
  );
};

export default FavoriteButton;
