import { webUrl } from '@/common/utils/url';
import getConfig from 'next/config';
import Script from 'next/script';
import { FC, PropsWithChildren, useContext, useRef, useState } from 'react';
import { LanguageContext } from '../LanguageContext';
import { AppleContext } from '../ThirdPartyScriptContext';

const { publicRuntimeConfig } = getConfig();

const AppleProvider: FC<PropsWithChildren> = ({ children }) => {
  const { language } = useContext(LanguageContext);
  const [isSDKInBody, setIsSDKInBody] = useState(false);
  const resolveRef = useRef<() => void>();

  const initialize = async () => {
    if (!isSDKInBody) {
      return new Promise<void>((resolve) => {
        resolveRef.current = resolve;
        setIsSDKInBody(true);
      });
    }
  };

  const handleOnLoad = () => {
    AppleID.auth.init({
      clientId: publicRuntimeConfig.SIGN_IN_WITH_APPLE_CLIENT_ID,
      redirectURI: webUrl(language, 'register'),
    });
    resolveRef.current();
  };

  return (
    <AppleContext.Provider value={{ initialize }}>
      {isSDKInBody ? (
        <Script
          type="text/javascript"
          src="https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
          onLoad={handleOnLoad}
        />
      ) : null}
      {children}
    </AppleContext.Provider>
  );
};

export default AppleProvider;
