import styled from 'styled-components';

const ContextMenuButton = styled.div`
  background-color: ${({ theme }) => theme.colors.CG7};
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-image: ${() =>
    `url(${require('@/modules/assets/icons/vertical-dots.svg')})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px;
  cursor: pointer;
`;

export default ContextMenuButton;
