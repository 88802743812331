import { currency } from '@/common/constants';
import { LanguageContext } from '@/common/context/LanguageContext';
import useFormatPrice from '@/common/hooks/useFormatPrice';
import { FC, useContext } from 'react';

const ProductPrice: FC<{
  amount: number;
  className?: string;
}> = ({ amount, ...props }) => {
  const { locale } = useContext(LanguageContext);
  const formatPrice = useFormatPrice();
  const formattedPrice = formatPrice(amount, locale);

  return (
    <span
      itemProp="offers"
      itemScope
      itemType="http://schema.org/Offer"
      {...props}
    >
      {formattedPrice}

      <meta itemProp="priceCurrency" content={currency} />
      <meta itemProp="price" content={(amount / 100).toFixed(2)} />
    </span>
  );
};

export default ProductPrice;
