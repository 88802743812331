import { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const StyledError = styled.span<{ visible: boolean }>`
  display: block;
  color: ${({ theme }) => theme.colors.CoralCS3};
  margin-top: ${({ visible }) => (visible ? '8px' : 0)};
  font-size: 1.2rem;
  transition: margin-top 0.1s ease-in-out, height 0.1s ease-in-out;
  overflow: hidden;
`;

interface Props {
  message?: string;
  className?: string;
}

const FormError: FC<Props> = ({ message, className }) => {
  const ref = useRef<HTMLSpanElement>();
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (ref.current) {
      setHeight(message ? ref.current.scrollHeight : 0);
    }
  }, [message]);

  return (
    <StyledError
      ref={ref}
      visible={!!message}
      style={{ height }}
      className={className}
    >
      {message}
    </StyledError>
  );
};
export default FormError;
