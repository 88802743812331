import { createContext } from 'react';
export type Event =
  | 'add_product'
  | 'completed_step_add_address'
  | 'completed_step_connected_account'
  | 'completed_step_verify_email'
  | 'completed_add_photo'
  | 'enter_postal_code'
  | 'leave_postal_code'
  | 'leave_postal_code_closet'
  | 'open_add_product_other'
  | 'product_viewed'
  | 'open_complete_verification'
  | 'open_payout_details'
  | 'bank_account_added'
  | 'open_html_feed_item_url'
  | 'guest_app_download'
  | 'guest_continue_website'
  | 'favorite'
  | 'follow'
  | 'login'
  | 'register'
  | 'error_not_possible'
  | 'close_info_closure_banner'
  | 'info_closure_banner';

export type EventObject = { [key: string]: string | number };
export type EventPayload = {
  eventItem: Event;
  data?: EventObject;
};

interface EventContextType {
  logEvent: (event: Event, data?: EventObject) => Promise<void>;
  sendQueuedEvents: (sessionId: string) => void;
}

const EventsContext = createContext<EventContextType>({
  logEvent: undefined,
  sendQueuedEvents: undefined,
});

export { EventsContext };
