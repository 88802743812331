import AutoSubmit from '@/common/components/AutoSubmit';
import { FiltersContext } from '@/common/context/FiltersContext';
import { LanguageContext } from '@/common/context/LanguageContext';
import { StaticDataContext } from '@/common/context/StaticDataContext';
import {
  ProductsDocument,
  ProductsQuery,
  ProductsQueryVariables,
  UpdateAccountSizesDocument,
  UpdateAccountSizesMutation,
  UpdateAccountSizesMutationVariables,
  UserSizesComponent,
} from '@/common/generated/graphql';
import {
  getCookie,
  longExpiryDate,
  setCookie,
  uwCookies,
} from '@/common/utils/cookies';
import PrimaryButton from '@/modules/design/components/buttons/PrimaryButton';
import SizeTypeInput from '@/modules/design/components/form/SizeTypeInput';
import Cross from '@/modules/design/components/icons/Cross';
import { BodyText, Heading3 } from '@/modules/design/components/textstyles';
import { useApolloClient } from '@apollo/client';
import { Formik } from 'formik';
import Link from 'next/link';
import { rgba } from 'polished';
import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

const StyledUserSizesItem = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 32px 16px;
  background-color: #007782;

  @media (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobileMaxWidth}) {
    border: none;
    min-height: 250px;
    max-height: 250px;
    padding: 16px;
  }
`;

const CloseButton = styled.div`
  border-radius: 50%;
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: ${({ theme }) => theme.colors.CG7};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 6px ${rgba('#000', 0.4)};
  width: 36px;
  height: 36px;
`;

const StyledCross = styled(Cross)`
  width: 12px;
  height: 12px;
`;

const Title = styled(Heading3)`
  @media (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.smallDesktopMaxWidth}) {
    font-size: 2rem;
    line-height: 2.8rem;
  }
  color: ${({ theme }) => theme.colors.CG7};
`;

const Subtitle = styled(BodyText)`
  margin-top: 8px;
  color: ${({ theme }) => theme.colors.CG7};
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  display: none;
  margin-top: 20px;
  background-color: ${({ theme }) => theme.colors.CG7};
  color: ${({ theme }) => theme.colors.CG1};

  @media (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobileMaxWidth}) {
    display: flex;
  }
`;

const StyledSizeTypeInput = styled(SizeTypeInput)`
  margin-top: 24px;
`;

const Form = styled.form`
  @media (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobileMaxWidth}) {
    display: none;
  }
`;

interface SizesFormValues {
  sizes: string[];
}

const UserSizesItem: FC<{ className?: string }> = ({ className }) => {
  const { translate } = useContext(LanguageContext);
  const [isDismissed, setIsDismissed] = useState(
    getCookie(uwCookies.userSizesDismissed) === '1'
  );
  const { sizeTypes: allSizeTypes } = useContext(StaticDataContext);
  const apolloClient = useApolloClient();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const isSubmittedRef = useRef<boolean>();
  const { filters, orderBy, first, after } = useContext(FiltersContext);

  useEffect(() => {
    isSubmittedRef.current = isSubmitted;
  }, [isSubmitted]);

  // Invalidate the current page's alternating content to remove this item is the form was submitted
  useEffect(
    () => () => {
      if (isSubmittedRef.current) {
        apolloClient.query<ProductsQuery, ProductsQueryVariables>({
          query: ProductsDocument,
          variables: { filterBy: filters, orderBy, first, after },
          fetchPolicy: 'network-only',
        });
      }
    },
    []
  );

  const sizeTypes = useMemo(() => allSizeTypes.slice(0, 3), [allSizeTypes]);

  const updateSizes = async (values: SizesFormValues) => {
    await apolloClient.mutate<
      UpdateAccountSizesMutation,
      UpdateAccountSizesMutationVariables
    >({
      mutation: UpdateAccountSizesDocument,
      variables: { input: { ids: values.sizes } },
    });
    setIsSubmitted(true);
  };

  if (isDismissed) {
    return null;
  }

  const dismiss = () => {
    setIsDismissed(true);
    setCookie(uwCookies.userSizesDismissed, '1', '/', longExpiryDate);
  };

  return (
    <StyledUserSizesItem className={className}>
      <CloseButton onClick={dismiss}>
        <StyledCross />
      </CloseButton>
      <Title>{translate('add_sizes_campaign_title')}</Title>
      <Subtitle>{translate('add_sizes_campaign_expl')}</Subtitle>
      <UserSizesComponent>
        {({ data }) => {
          if (!data) {
            return null;
          }
          const sizes = data.viewer.account.sizes.map((size) => size.id);
          return (
            <Formik onSubmit={updateSizes} initialValues={{ sizes }}>
              {() => (
                <Form>
                  <AutoSubmit debounce={1000} />
                  {sizeTypes.map((sizeType) => (
                    <StyledSizeTypeInput
                      key={sizeType.id}
                      name={'sizes'}
                      sizeType={sizeType}
                    />
                  ))}
                </Form>
              )}
            </Formik>
          );
        }}
      </UserSizesComponent>
      <Link
        href={{ pathname: '/settings', query: { tab: 'clothing_preferences' } }}
        passHref
      >
        <StyledPrimaryButton as={'a'}>
          {translate('add_sizes_campaign_cta')}
        </StyledPrimaryButton>
      </Link>
    </StyledUserSizesItem>
  );
};

export default UserSizesItem;
