import { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

const StyledSpinner = styled.div<{ size: number }>`
  animation: spinnerAnimation 1.2s infinite linear;
  transform: translateZ(0);
  text-indent: -9999px;
  border-radius: 50%;
  ${({ theme, size }) => {
    return css`
      width: ${size}px;
      height: ${size}px;
      border: ${size * 0.1}px solid ${theme.colors.CG6};
      border-left: ${size * 0.1}px solid ${theme.colors.CalypsoCP1};
    `;
  }}

  @keyframes spinnerAnimation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Spinner: FC<{ size?: number; wait?: number; className?: string }> = ({
  size = 64,
  wait = 0,
  className,
}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), wait);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [wait]);

  if (!show) {
    return null;
  }

  return <StyledSpinner size={size} className={className} />;
};

export const SpinnerContainer = styled.div`
  display: flex;
  margin: 32px;
  justify-content: center;
`;

export default Spinner;
