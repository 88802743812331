import { createContext } from 'react';

interface TodoContext {
  isPhotosLoading: boolean;
  togglePhotosLoading: () => void;
  todoState: TodoState;
  handleTodoFetching: () => void;
  handleMessageRenderer: (key?: string) => void;
}
export type Steps = 0 | 1 | 2 | 3 | 4;

export type TodoState = {
  listing: Steps;
  hasError: boolean;
  favs: Steps;
  sellers: Steps;
  photosUpload: Steps;
  step: Steps;
  loading: boolean;
  userId: string;
  isFinished: boolean;
  profileImage: {
    url: string;
  };
  coverImage: {
    url: string;
  };
};

export const TodoContext = createContext<TodoContext>({
  isPhotosLoading: false,
  togglePhotosLoading: undefined,
  todoState: undefined,
  handleTodoFetching: () => Promise.resolve(null),
  handleMessageRenderer: undefined,
});
