import { ServerError, ServerParseError } from '@apollo/client';
import { GraphQLError } from 'graphql';

type LocalizedError = Omit<GraphQLError, 'extensions'> & {
  extensions: {
    localizedDescription: string;
  };
};

const isLocalizedError = (error: GraphQLError): error is LocalizedError =>
  typeof error?.extensions?.localizedDescription === 'string';

export const getLocalizedErrorMessage = (errors?: readonly GraphQLError[]) =>
  errors &&
  errors.length &&
  errors.find(isLocalizedError)?.extensions?.localizedDescription;

export const isServerError = (
  error: Error | ServerError | ServerParseError
): error is ServerError => error?.name === 'ServerError';
