import { createContext, ReactNode } from 'react';

interface MessageContextType {
  showMessage: (
    getNode: (props: { hideMessage: () => void }) => ReactNode,
    options?: {
      removeDelay?: number;
      hideOnRouteChange?: boolean;
      hideCloseButton?: boolean;
      isAnimated?: boolean;
    }
  ) => void;
}

export const MessageContext = createContext<MessageContextType>(undefined);
