import { FiltersContext } from '@/common/context/FiltersContext';
import {
  LanguageContext,
  TranslationKey,
} from '@/common/context/LanguageContext';
import { StaticDataContext } from '@/common/context/StaticDataContext';
import { ProductFilters, ProductsOrder } from '@/common/generated/graphql';
import { buildAs, buildHref, buildPath } from '@/common/utils/filters';
import Router from 'next/router';
import { ChangeEvent, FC, useContext } from 'react';
import styled from 'styled-components';

const StyledSelect = styled.select`
  appearance: none;
  &:-ms-expand {
    display: none;
  }
  border: none;
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  padding: 8px 32px 8px 8px;
  background-repeat: no-repeat;
  background-position: center right;
  background-color: transparent;
  background-image: ${() =>
    `url(${require('@/modules/assets/icons/angle-down.svg')})`};
  @media (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobileMaxWidth}) {
    color: ${({ theme }) => theme.colors.CalypsoCP1};
  }
`;

const orderByOptions: {
  translationKey: TranslationKey;
  value: ProductsOrder;
}[] = [
  {
    translationKey: 'products_recommended',
    value: ProductsOrder.Recommended,
  },
  {
    translationKey: 'products_date_added_desc',
    value: ProductsOrder.Newest,
  },
  {
    translationKey: 'products_views_desc',
    value: ProductsOrder.MostViewed,
  },
  {
    translationKey: 'products_price_asc',
    value: ProductsOrder.Cheapest,
  },
  {
    translationKey: 'products_price_desc',
    value: ProductsOrder.MostExpensive,
  },
];

interface Props {
  value?: ProductsOrder;
  filters: ProductFilters;
  className?: string;
}

const OrderBySelect: FC<Props> = ({ className }) => {
  const { language, translate } = useContext(LanguageContext);
  const { filters, orderBy } = useContext(FiltersContext);
  const { findCategoryById } = useContext(StaticDataContext);

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newOrderBy = event.target.value as ProductsOrder;

    const path = buildPath(language, filters, findCategoryById);

    Router.push(
      buildHref(filters, {
        orderBy: newOrderBy,
      }),
      buildAs(filters, { path, orderBy: newOrderBy }),
      {
        shallow: true,
      }
    );
  };

  return (
    <span className={className}>
      {translate('gen_order_by_c')}
      <StyledSelect onChange={handleChange} value={orderBy}>
        {orderByOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {translate(option.translationKey)}
          </option>
        ))}
      </StyledSelect>
    </span>
  );
};

export default OrderBySelect;
