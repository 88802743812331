import { NextjsOptions } from '@sentry/nextjs/types/utils/nextjsOptions';
import getConfig from 'next/config';

const publicRuntimeConfig = getConfig()?.publicRuntimeConfig;

let enabled = true;
if (typeof publicRuntimeConfig !== 'undefined') {
  enabled = !publicRuntimeConfig.SENTRY_DISABLED;
} else if (typeof process.env.SENTRY_DISABLED !== 'undefined') {
  enabled = process.env.SENTRY_DISABLED !== '1';
}

export const defaultSentryOptions: NextjsOptions = {
  enabled,
  dsn: publicRuntimeConfig?.SENTRY_DSN ?? process.env.SENTRY_DSN,
  tracesSampleRate: 0.01,
  environment:
    publicRuntimeConfig?.SENTRY_ENVIRONMENT ??
    process.env.SENTRY_ENVIRONMENT ??
    'local',
  release: publicRuntimeConfig?.SENTRY_RELEASE ?? process.env.SENTRY_RELEASE,
};
