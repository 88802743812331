import { Heading5 } from '@/modules/design/components/textstyles';
import { transparentize } from 'polished';
import styled from 'styled-components';

const ContextMenuItem = styled(Heading5)`
  display: block;
  padding: 8px 20px;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    background-color: ${({ theme }) => transparentize(0.98, theme.colors.CG1)};
  }

  &:active {
    background-color: ${({ theme }) => transparentize(0.92, theme.colors.CG1)};
  }
`;

export default ContextMenuItem;
