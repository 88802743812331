import { createContext } from 'react';
// This JSON file is only used to be able to show typehints
import translations from '../generated/translations.json';

// TODO: remove "| string" when migration TK's are added
export type TranslationKey = keyof typeof translations | string;
export type TranslateFunc = (key: TranslationKey, ...args: any[]) => string;
export type Translations = { [key in TranslationKey]: string };

interface LanguageContextType {
  translate: TranslateFunc;
  locale: string;
  language: string;
  setLocale: (locale: string) => void;
}

const LanguageContext = createContext<LanguageContextType>({
  translate: (...args) => args.join(' '),
  locale: 'xx_XX',
  language: 'en',
  setLocale: undefined,
});

export { LanguageContext };
