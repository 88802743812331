import { FC } from 'react';
import { IconProps, ICON_COLOR_CLASS } from './icon';

const Cross: FC<IconProps> = ({ color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    {...props}
  >
    <g fill={color || '#082229'} className={ICON_COLOR_CLASS}>
      <path d="M1.147.14l.08.07L9 7.984 16.773.211a.719.719 0 011.086.936l-.07.08L10.017 9l7.772 7.773a.719.719 0 01-.936 1.086l-.08-.07L9 10.017l-7.773 7.772a.719.719 0 01-1.086-.936l.07-.08L7.983 9 .211 1.227A.719.719 0 011.147.141z" />
    </g>
  </svg>
);

export default Cross;
