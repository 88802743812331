import { FavoriteEntryPoint } from '@/modules/design/components/buttons/ToggleFavoriteButton';
import AlternatingContentItem from '@/modules/feed/components/AlternatingContent/AlternatingContentItem';
import { productsPerPage } from '@/modules/feed/components/ProductResults';
import { FC, Fragment } from 'react';
import styled from 'styled-components';
import {
  AlternatingContentItemFragment,
  ListProductFragment,
} from '../../generated/graphql';
import ProductListItem from '../ProductListItem/ProductListItem';

// By using minmax() below, the grid cells will maintain their size in Safari
const StyledProductsGrid = styled.section`
  display: grid;
  grid-template-columns: repeat(4, minmax(100px, 1fr));
  gap: 16px;

  @media (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.smallDesktopMaxWidth}) {
    grid-template-columns: repeat(3, minmax(100px, 1fr));
  }

  @media (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tabletMaxWidth}) {
    grid-template-columns: repeat(2, minmax(100px, 1fr));
  }
`;

export interface Props {
  products: ListProductFragment[];
  alternatingContentItems?: AlternatingContentItemFragment[];
  page?: number;
  entryPoint: FavoriteEntryPoint;
}

const ProductsGrid: FC<Props> = ({
  products,
  alternatingContentItems,
  page,
  entryPoint,
  ...props
}) => {
  const offset = productsPerPage * (page - 1);
  return (
    <StyledProductsGrid {...props}>
      {products.map((product, index) => {
        const alternatingContentItem = alternatingContentItems?.find(
          (item) => item.position === offset + index
        );
        if (alternatingContentItem) {
          return (
            <Fragment key={`${product.id}:${alternatingContentItem.id}`}>
              <StyledAlternatingContentItem
                alternatingContentItem={alternatingContentItem}
                position={alternatingContentItem.position - offset}
              />
              <ProductListItem entryPoint={entryPoint} product={product} />
            </Fragment>
          );
        }
        return (
          <ProductListItem
            entryPoint={entryPoint}
            key={product.id}
            product={product}
          />
        );
      })}
    </StyledProductsGrid>
  );
};

const StyledAlternatingContentItem = styled(AlternatingContentItem)<{
  position: number;
}>`
  // 4 items per row
  grid-column: ${({ position }) =>
    position % 4 === 3
      ? '1 / 3'
      : `${(position % 4) + 1} / ${(position % 4) + 3}`};
  grid-row: ${({ position }) => Math.floor((position + 1) / 4) + 1};

  @media (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.smallDesktopMaxWidth}) {
    // 3 items per row
    grid-column: ${({ position }) =>
      position % 3 === 2
        ? '1 / 3'
        : `${(position % 4) + 1} / ${(position % 3) + 3}`};
    grid-row: ${({ position }) => Math.floor((position + 1) / 3) + 1};
  }

  @media (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tabletMaxWidth}) {
    // 2 items per row
    grid-column: 1 / 3;
    grid-row: ${({ position }) => Math.floor((position + 1) / 2) + 1};
  }
`;

export default ProductsGrid;
