import { useFormikContext } from 'formik';
import { FC, useEffect, useState } from 'react';
import useDebounce from '../hooks/useDebounce';

const AutoSubmit: FC<{ debounce?: number }> = ({ debounce }) => {
  const { submitForm, values } = useFormikContext();
  const [previousValues, setPreviousValues] = useState(values);
  const nextValues = useDebounce(values, debounce || 0);

  useEffect(() => {
    if (previousValues !== nextValues) {
      setPreviousValues(nextValues);
      submitForm();
    }
  }, [nextValues, previousValues, submitForm]);

  return null;
};

export default AutoSubmit;
