export const uwCookies = {
  deviceId: 'deviceId',
  sessionId: 'sessionId',
  attribution: 'attribution',
  auth: 'auth',
  authExpiresOn: 'authExpiresOn',
  language: 'language',
  userSizesDismissed: 'userSizesDismissed',
  mobileOverlayDismissed: 'mobileOverlayDismissed',
  todo: 'todo',
};

export const getCookies = () => {
  if (typeof document === 'undefined') {
    return new Map<string, string>();
  }
  const cookies = document.cookie
    .split(/\s?;\s?/)
    .map((c) => c.split('=', 2))
    .map(([k, v]) => [k, decodeURIComponent(v)] as [string, string]);

  return new Map(cookies);
};

export const getCookie = (name: string) => {
  return getCookies().get(name);
};

export const setCookie = (
  name: string,
  value: string,
  path: string = '/',
  expires?: Date
) => {
  if (typeof document === 'undefined') {
    return;
  }
  const secure = location.protocol === 'https:';

  document.cookie = `${name}=${encodeURIComponent(value)}${
    path ? `; path=${path}` : ''
  }${expires ? `; expires=${expires.toUTCString()}` : ''}${
    secure ? '; secure' : ''
  }`;
};

export const clearCookie = (name: string, path: string = '/') => {
  setCookie(name, '', path, new Date('Thu, 01 Jan 1970 00:00:00 GMT'));
};

export const longExpiryDate = new Date('Tue, 01 Jan 2038 00:00:00 GMT');

export const isAuthorized = (cookies: { [key: string]: string }) =>
  !!cookies[uwCookies.auth];

export const getDefaultCookieOptions = (protocol: string) => ({
  path: '/',
  expires: longExpiryDate,
  secure: protocol === 'https:',
  httpOnly: false,
});
