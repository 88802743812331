import { TranslationKey } from '../context/LanguageContext';
import { ProductFragment, ProductState } from '../generated/graphql';

export const translationKeyForState = (state: ProductState): TranslationKey => {
  switch (state) {
    case ProductState.Blocked:
      return 'product_blocked';
    case ProductState.UnderReview:
      return 'product_under_review';
    case ProductState.Deleted:
      return 'gen_deleted';
    case ProductState.Expired:
      return 'product_expired';
    case ProductState.Sold:
      return 'product_sold';
    case ProductState.UserOnHoliday:
      return 'product_user_holiday';
    case ProductState.UserPendingKyc:
      return 'product_verification_pending';
  }
  return null;
};

export const showFavoriteButton = (product: {
  state: ProductState;
  viewerIsSeller: boolean;
  viewerHasFavorited: boolean;
}) =>
  !product.viewerIsSeller &&
  ([
    ProductState.Active,
    ProductState.Expired,
    ProductState.UserOnHoliday,
  ].includes(product.state) ||
    product.viewerHasFavorited);

export const getCheapestParcel = (product: ProductFragment) =>
  product.availableParcels.reduce((prev, cur) => {
    const prevPrice =
      prev.price.__typename === 'PriceRange'
        ? prev.price.minPrice.amount
        : prev.price.amount;
    const curPrice =
      cur.price.__typename === 'PriceRange'
        ? cur.price.minPrice.amount
        : cur.price.amount;
    return curPrice < prevPrice ? cur : prev;
  }, product.availableParcels[0]);
