import { EventsContext } from '@/common/context/EventsContext';
import { HtmlFeedItemFragment } from '@/common/generated/graphql';
import getConfig from 'next/config';
import { FC, useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';

const { publicRuntimeConfig } = getConfig();

const Container = styled.div`
  position: relative;
`;

const StyledHTMLFeedItem = styled.iframe`
  min-height: 300px;
  height: 100%;
  width: 100%;

  @media (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobileMaxWidth}) {
    min-height: 250px;
    max-height: 250px;
  }
`;

const iframeStyle = `
<style type="text/css">
  html, body {
    margin: 0;
    padding: 0;
  }
  @font-face {
      font-family: "Maison Neue APP";
      font-weight: 400;
      font-style: "Book";
      src: url('${require('@/modules/assets/fonts/MaisonNeueWEB-Book.woff2')}') format('woff2'),
        url('${require('@/modules/assets/fonts/MaisonNeueWEB-Book.woff')}') format('woff');
  }
  @font-face {
      font-family: "Maison Neue APP";
      font-style: "Medium";
      font-weight: 600;
      src: url('${require('@/modules/assets/fonts/MaisonNeueWEB-Medium.woff2')}') format('woff2'),
        url('${require('@/modules/assets/fonts/MaisonNeueWEB-Medium.woff')}') format('woff');
  }
  @font-face {
      font-family: "Maison Neue APP";
      font-weight: 700;
      font-style: "Demi";
      src: url('${require('@/modules/assets/fonts/MaisonNeueWEB-Demi.woff2')}') format('woff2'),
        url('${require('@/modules/assets/fonts/MaisonNeueWEB-Demi.woff')}') format('woff');
  }
</style>
`;

const HTMLFeedItem: FC<{ item: HtmlFeedItemFragment; className?: string }> = ({
  item,
  className,
}) => {
  const ref = useRef<HTMLIFrameElement>();
  const { logEvent } = useContext(EventsContext);

  const handleOnClick = async (event: MouseEvent) => {
    event.preventDefault();

    // Transform URL to have the right scheme
    const originalHref = (event.target as HTMLAnchorElement).getAttribute(
      'href'
    );
    const transformedHref = originalHref.replace(
      `${publicRuntimeConfig.APP_DEEP_LINK_SCHEME}://`,
      'https://'
    );

    await logEvent('open_html_feed_item_url', {
      name: item.campaignId,
      url: originalHref,
    });

    if (event.ctrlKey || event.metaKey) {
      window.open(transformedHref, '_blank');
    } else {
      window.location.href = transformedHref;
    }
  };

  useEffect(() => {
    if (ref.current) {
      const doc = ref.current.contentDocument;
      doc.open();
      doc.write(item.html, iframeStyle);
      doc.close();

      // Override anchor behavior
      const anchors = doc.querySelectorAll('a');
      anchors.forEach((anchor) => {
        anchor.addEventListener('click', handleOnClick);
      });

      return () => {
        anchors.forEach((anchor) => {
          anchor.removeEventListener('click', handleOnClick);
        });
      };
    }
  }, [ref.current]);

  return (
    <Container className={className}>
      <StyledHTMLFeedItem ref={ref} />
    </Container>
  );
};

export default HTMLFeedItem;
