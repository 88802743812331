export const formatNumber = (
  value: number,
  options?: {
    locale?: string;
    abbreviate?: boolean;
  }
) => {
  const opts = {
    locale: 'nl_NL',
    abbreviate: false,
    ...options,
  };

  const locale = opts.locale.replace('_', '-');

  if (opts.abbreviate) {
    if (value > 1000000) {
      return Math.round(value / 1000000) + 'M';
    }

    if (value > 1000) {
      if (value > 2000) {
        return Math.round(value / 1000) + 'k';
      }

      return (
        (value / 1000).toLocaleString(locale, {
          maximumFractionDigits: 1,
        }) + 'k'
      );
    }
  }

  return value.toLocaleString(locale);
};
