import { createContext } from 'react';

interface AuthContextType {
  isAuthorized: boolean;
  setAuthorization: (showTutorial: () => void, route?: string) => Promise<void>;
}

export const AuthContext = createContext<AuthContextType>({
  isAuthorized: false,
  setAuthorization: () => Promise.resolve(),
});
