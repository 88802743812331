import ProductsGrid from '@/common/components/Products/ProductsGrid';
import { LanguageContext } from '@/common/context/LanguageContext';
import {
  AlternatingContentItemFragment,
  ListProductFragment,
  ProductFilters,
  ProductsOrder,
} from '@/common/generated/graphql';
import { formatNumber } from '@/common/utils/number';
import { FC, useContext } from 'react';
import styled from 'styled-components';
import Spinner, {
  SpinnerContainer,
} from '../../design/components/spinner/Spinner';
import OrderBySelect from './OrderBySelect';
import ProductPagination from './ProductPagination';

export const productsPerPage = 36;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  height: 1.4rem;
  font-size: 1.4rem;
`;

const Header: FC<{
  filters: ProductFilters;
  totalCount: number;
}> = ({ totalCount, filters }) => {
  const { translate, locale } = useContext(LanguageContext);

  return (
    <StyledHeader>
      <span>
        {translate('products_n_results', formatNumber(totalCount, { locale }))}
      </span>

      <OrderBySelect filters={filters} />
    </StyledHeader>
  );
};

const StyledProductPagination = styled(ProductPagination)`
  margin-top: 24px;
  align-self: center;
`;

export const ProductResults: FC<{
  productsConnection?: {
    nodes: ListProductFragment[];
    totalCount: number;
  };
  error?: Error;
  loading: boolean;
  orderBy?: ProductsOrder;
  filters: ProductFilters;
  alternatingContentItems: AlternatingContentItemFragment[];
  page: number;
}> = ({
  page,
  alternatingContentItems,
  filters,
  orderBy,
  error,
  loading,
  productsConnection,
}) => {
  if (error) {
    return <p>Error: {error.message}</p>;
  }

  const totalCount = productsConnection ? productsConnection.totalCount : 0;

  if (loading) {
    return (
      <>
        <Header totalCount={totalCount} filters={filters} />
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      </>
    );
  }

  if (!productsConnection) {
    return <p>No data</p>;
  }

  return (
    <>
      <Header totalCount={totalCount} filters={filters} />
      <ProductsGrid
        alternatingContentItems={alternatingContentItems}
        products={productsConnection.nodes}
        page={page}
        entryPoint="Feed"
      />
      <StyledProductPagination
        page={page}
        orderBy={orderBy}
        filters={filters}
        productsPerPage={productsPerPage}
        totalResultsCount={totalCount}
        pagesPreviewCount={7}
      />
    </>
  );
};
