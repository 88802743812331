import { ApolloClient } from '@apollo/client';
import { NormalizedCacheObject } from '@apollo/client/cache';
import { getDataFromTree } from '@apollo/client/react/ssr';
import { Request } from 'express';
import { NextComponentType } from 'next';
import { AppContext, AppInitialProps, AppProps } from 'next/app';
import { Component } from 'react';
import AppError from '../components/AppError';
import getApolloClient from './apollo';
import { buildHeaders } from './headers';
import logger from './logger';

declare module 'next' {
  interface NextPageContext {
    apolloClient: ApolloClient<NormalizedCacheObject>;
  }
}

export interface ApolloAppProps extends AppInitialProps {
  apolloClient: ApolloClient<NormalizedCacheObject>;
}

type AppComponentType<P = unknown, IP = P, C = AppContext> = NextComponentType<
  C,
  IP,
  P & AppProps
>;

export default function withApolloClient<P extends Record<string, unknown>>(
  App: AppComponentType<ApolloAppProps>
) {
  return class Apollo extends Component<P & AppProps> {
    static displayName = 'withApollo(App)';

    static async getInitialProps(appContext: AppContext) {
      const { AppTree, ctx } = appContext;

      // Run all GraphQL queries in the component tree
      // and extract the resulting data
      const apollo = getApolloClient(
        {
          getHeaders: () => buildHeaders(ctx.req as Request),
        },
        ctx.req?.data?.initialCache?.[ctx.req?.language]
      );

      ctx.apolloClient = apollo;

      let appProps: ApolloAppProps = {} as any;
      if (App.getInitialProps) {
        appProps = await App.getInitialProps(appContext as any);
      }

      if (!process.browser) {
        try {
          // Run all GraphQL queries
          await getDataFromTree(
            <AppTree {...appProps} apolloClient={apollo} />
          );
        } catch (error) {
          // Prevent Apollo Client GraphQL errors from crashing SSR.
          // Handle them in components via the data.error prop:
          // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
          logger.error(error);
          // TODO error reporting
          // if (!process.browser) {
          //   const Sentry = require('@sentry/node');
          //   Sentry.captureException(error);
          // }

          if (ctx.res) {
            ctx.res.statusCode = 500;
          }
        }

        const error = AppError.rewind();

        if (error && ctx.res) {
          ctx.res.statusCode = error.statusCode;
        }
      }

      // Extract query data from the Apollo store
      const apolloState = apollo.cache.extract();

      return {
        ...appProps,
        apolloState,
        headers: ctx.req && buildHeaders(ctx.req as Request),
      };
    }

    public apolloClient: ApolloClient<NormalizedCacheObject>;

    constructor(props: any) {
      super(props);
      this.apolloClient = getApolloClient(
        { getHeaders: () => props.headers },
        props.apolloState
      );
    }

    render() {
      return <App apolloClient={this.apolloClient} {...this.props} />;
    }
  };
}
