import { createContext } from 'react';

// The placeholder experiment is there for boilerplate purposes but has no effect on performance
export const experimentNames = ['placeholder'] as const;

type ExperimentName = (typeof experimentNames)[number];

export const supportedExperiments: Record<
  ExperimentName,
  {
    experimentId: string;
    controlGroupId: string;
    variantId: string;
  }
> = {
  placeholder: {
    experimentId: '9040b7b0-838e-4c83-8a1d-43c4ba4d9236',
    controlGroupId: '8a9a0f85-7a1a-4268-bf43-713526a95ae2',
    variantId: '0d571180-81ba-4036-950e-18c386bc7933',
  },
} as const;

export type Experiments = Record<
  ExperimentName | 'preComms' | 'restriction' | 'closure',
  {
    isParticipating: boolean;
    isInControlGroup: boolean;
    isInVariant: boolean;
    participate: () => Promise<void>;
  }
>;

export const ExperimentContext = createContext<Experiments>(null);
