import { FC } from 'react';

const AngleDown: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <path
        id="a"
        d="M16.692 13.692v-10a1 1 0 1 1 2 0v11a1 1 0 0 1-1 1h-11a1 1 0 0 1 0-2h10z"
      />
    </defs>
    <use
      fill="#D8D8D8"
      fillRule="evenodd"
      transform="rotate(45 12.192 9.192)"
      xlinkHref="#a"
    />
  </svg>
);

export default AngleDown;
