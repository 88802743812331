import getConfig from 'next/config';
import Script from 'next/script';
import { FC, PropsWithChildren, useContext, useRef, useState } from 'react';
import { LanguageContext } from '../LanguageContext';
import { FacebookContext } from '../ThirdPartyScriptContext';

const { publicRuntimeConfig } = getConfig();

const FacebookProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isSDKInBody, setIsSDKInBody] = useState(false);
  const { locale } = useContext(LanguageContext);
  const resolveRef = useRef<() => void>();

  const initialize = async () => {
    if (!isSDKInBody) {
      return new Promise<void>((resolve) => {
        resolveRef.current = resolve;
        setIsSDKInBody(true);
      });
    }
  };

  const handleOnLoad = () => {
    FB.init({
      appId: publicRuntimeConfig.FB_APP_ID,
      autoLogAppEvents: false,
      xfbml: false,
      version: 'v13.0',
    });
    resolveRef.current();
  };

  const sdkLanguage = locale === 'fr' ? 'fr_FR' : 'en_US';

  return (
    <FacebookContext.Provider value={{ initialize }}>
      {isSDKInBody && (
        <Script
          async
          defer
          crossOrigin="anonymous"
          src={`https://connect.facebook.net/${sdkLanguage}/sdk.js`}
          onLoad={handleOnLoad}
        />
      )}
      {children}
    </FacebookContext.Provider>
  );
};

export default FacebookProvider;
