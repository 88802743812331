import { useApolloClient } from '@apollo/client';
import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { FC, PropsWithChildren, useContext, useState } from 'react';
import urljoin from 'url-join';
import { locales } from '../../constants';
import { ViewerDocument, ViewerQuery } from '../../generated/graphql';
import { AuthContext } from '../AuthContext';
import { LanguageContext } from '../LanguageContext';

const { publicRuntimeConfig } = getConfig();

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const apolloClient = useApolloClient();
  const { setLocale } = useContext(LanguageContext);
  const router = useRouter();
  const hasAuth = router.query.hasAuthCookie === '1';

  // hasAuth doesn't ensure that the session is still valid but can be used as an initial best guess
  const [isAuthorized, setIsAuthorized] = useState(hasAuth);

  const setAuthorization = async (
    showTutorial?: () => void,
    route?: string
  ) => {
    const { data } = await apolloClient.query<ViewerQuery>({
      query: ViewerDocument,
      fetchPolicy: 'network-only',
    });
    const user = data.viewer.me;

    setIsAuthorized(true);
    setLocale(locales[user.language]);

    if (!data.viewer.account.hasSeenTutorial && showTutorial) {
      showTutorial();
    } else if (route) {
      window.location.href = urljoin(
        publicRuntimeConfig.BASE_URL,
        data.viewer?.me?.language,
        route
      );
    } else {
      router.push('/feed');
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthorized,
        setAuthorization,
      }}
    >
      {hasAuth ? <NoSSR>{children}</NoSSR> : children}
    </AuthContext.Provider>
  );
};

const Children: FC<PropsWithChildren> = ({ children }) => <>{children}</>;

const NoSSR = dynamic(() => Promise.resolve(Children), { ssr: false });

export const UserConsumer = AuthContext.Consumer;

export default AuthProvider;
