import { useField } from 'formik';
import { FC, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import FormError from './FormError';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputContainer = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.CG7}; ;
`;

const Placeholder = styled.span<{
  minified: boolean;
  isRequired: boolean;
}>`
  position: absolute;
  transform: translateY(-50%);
  pointer-events: none;
  margin: 0 12px;
  padding: 0 4px;
  background-color: ${({ theme }) => theme.colors.CG7};
  top: ${({ minified }) => (minified ? 0 : '24px')};
  font-size: ${({ minified }) => (minified ? '1.3rem' : '1.6rem')};
  color: ${({ theme }) => theme.colors.CG3};
  transition: all 0.1s ease-in-out;
  z-index: 1;

  &:after {
    display: ${({ isRequired }) => (isRequired ? 'inline' : 'none')};
    content: ' *';
    color: ${({ theme }) => theme.colors.CoralCS3};
  }
`;

const BaseInput: FC<{
  name?: string;
  className?: string;
  placeholder?: string;
  children: (props: {
    minimizePlaceholder: () => void;
    expandPlaceholder: () => void;
    isPlaceholderMinified: boolean;
    hidePlaceholder: () => void;
    showPlaceholder: () => void;
  }) => ReactNode;
  isRequired?: boolean;
}> = ({ className, placeholder, children, name, isRequired }) => {
  const [field, { touched, error, initialValue }] = useField(name);
  const [isPlaceholderMinified, setPlaceholderMinified] = useState(
    !!field.value
  );
  const [renderPlaceholder, setRenderPlaceholder] = useState(true);

  // Invalidate placeholder on initial value change
  useEffect(() => {
    if (isPlaceholderMinified !== !!field.value) {
      setPlaceholderMinified(!!field.value);
    }
    setRenderPlaceholder(true);
  }, [initialValue]);

  const minimizePlaceholder = () => {
    if (!isPlaceholderMinified) setPlaceholderMinified(true);
  };
  const expandPlaceholder = () => {
    if (isPlaceholderMinified) setPlaceholderMinified(false);
  };

  const showPlaceholder = () => {
    if (!renderPlaceholder) setRenderPlaceholder(true);
  };

  const hidePlaceholder = () => {
    if (renderPlaceholder) setRenderPlaceholder(false);
  };

  return (
    <Container className={className}>
      <InputContainer>
        {placeholder && renderPlaceholder && (
          <Placeholder minified={isPlaceholderMinified} isRequired={isRequired}>
            {placeholder}
          </Placeholder>
        )}
        {children({
          minimizePlaceholder,
          expandPlaceholder,
          isPlaceholderMinified,
          showPlaceholder,
          hidePlaceholder,
        })}
      </InputContainer>
      {touched ? <FormError message={error} /> : null}
    </Container>
  );
};

export default BaseInput;
