import { locales } from '@/common/constants';
import { useApolloClient } from '@apollo/client';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { FC, PropsWithChildren, useMemo, useState } from 'react';
import { setCookie, uwCookies } from '../../utils/cookies';
import {
  LanguageContext,
  TranslateFunc,
  Translations,
} from '../LanguageContext';

const { publicRuntimeConfig } = getConfig();

type Props = {
  translations: Translations;
} & PropsWithChildren;

export interface TranslationsResponse {
  up_to_date: boolean;
  latest_update: string; // '2019-03-19 16:54:26';
  translations: Translations;
}

const LanguageProvider: FC<Props> = ({ children, translations }) => {
  const router = useRouter();
  const language = router.locale;
  const { pathname, asPath, query } = router;
  const apolloClient = useApolloClient();
  const [locale, setLocale] = useState(locales[language]);

  const setLocaleAndCookie = async (newLocale: string) => {
    const newLanguage = newLocale.slice(0, 2);
    setCookie(uwCookies.language, newLanguage);
    if (newLanguage !== language) {
      router.push({ pathname, query }, asPath, { locale: newLanguage });
      setLocale(newLocale);
      if (typeof window.OneTrust !== 'undefined') {
        window.OneTrust.changeLanguage(newLanguage);
      }
      await apolloClient.resetStore();
    }
  };

  const translate = useMemo<TranslateFunc>(
    () =>
      (key, ...args) => {
        let translation = translations[key];

        if (translation && args && args.length) {
          // Replace %1$s values
          args.forEach((arg, index) => {
            translation = translation.replace(`%${index + 1}$s`, arg);
          });

          return translation;
        }

        if (translation) {
          return translation;
        }

        if (publicRuntimeConfig.SHOW_TRANSLATION_KEYS) {
          return key;
        }

        return '';
      },
    [locale, translations]
  );

  return (
    <LanguageContext.Provider
      value={{
        locale,
        translate,
        language,
        setLocale: setLocaleAndCookie,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const LanguageConsumer = LanguageContext.Consumer;

export default LanguageProvider;
