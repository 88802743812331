import { transparentize } from 'polished';
import { cloneElement, FC, ReactElement, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import useOutsideClick from '../../hooks/useOutsideClick';

type Alignment = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';

export interface ContextMenuProps {
  className?: string;
  alignContextMenu: Alignment;
  mobileAlignContextMenu?: Alignment;
}

const StyledContextMenu = styled.div`
  position: relative;
`;

const ContextMenu: FC<
  ContextMenuProps & {
    children: [ReactElement, ReactElement];
  }
> = ({ className, alignContextMenu, mobileAlignContextMenu, children }) => {
  const containerRef = useRef<HTMLDivElement>();
  const [isContextMenuOpened, setIsContextMenuOpened] = useState(false);
  useOutsideClick(containerRef, () => setIsContextMenuOpened(false));

  const [buttonNode, contentNode] = children;

  return (
    <StyledContextMenu className={className} ref={containerRef}>
      {cloneElement(buttonNode, {
        onClick: () => setIsContextMenuOpened(!isContextMenuOpened),
      })}
      {isContextMenuOpened ? (
        <InnerContextMenu
          alignContextMenu={alignContextMenu}
          mobileAlignContextMenu={mobileAlignContextMenu}
          onClick={() => setIsContextMenuOpened(false)}
        >
          {contentNode}
        </InnerContextMenu>
      ) : null}
    </StyledContextMenu>
  );
};

export default ContextMenu;

const InnerContextMenu = styled.div<{
  alignContextMenu: Alignment;
  mobileAlignContextMenu?: Alignment;
}>`
  ${({ alignContextMenu }) => contextMenuCss(alignContextMenu)}

  @media (max-width: ${({ theme: { breakpoints } }) =>
    breakpoints.mobileMaxWidth}) {
    ${({ mobileAlignContextMenu }) =>
      mobileAlignContextMenu && contextMenuCss(mobileAlignContextMenu)}
  }
`;

const contextMenuCss = (alignContextMenu: Alignment) => css`
  position: absolute;
  right: ${['bottom-right', 'top-right'].includes(alignContextMenu)
    ? 0
    : 'auto'};
  left: ${['bottom-left', 'top-left'].includes(alignContextMenu) ? 0 : 'auto'};
  top: ${['bottom-left', 'bottom-right'].includes(alignContextMenu)
    ? '100%'
    : 'auto'};
  bottom: ${['top-left', 'top-right'].includes(alignContextMenu)
    ? '100%'
    : 'auto'};
  background-color: ${({ theme }) => theme.colors.CG7};
  border-radius: 2px;
  box-shadow: 0 1px 8px 0
    ${({ theme }) => transparentize(0.84, theme.colors.CG1)};
  z-index: 2;

  &:after {
    display: block;
    content: '';
    position: absolute;
    right: 16px;
    top: ${['bottom-left', 'bottom-right'].includes(alignContextMenu)
      ? '-6px'
      : 'auto'};
    bottom: ${['top-left', 'top-right'].includes(alignContextMenu)
      ? '-6px'
      : 'auto'};
    border-bottom: ${({ theme }) =>
      ['bottom-left', 'bottom-right'].includes(alignContextMenu)
        ? `6px solid ${theme.colors.CG7}`
        : 'none'};
    border-top: ${({ theme }) =>
      ['top-left', 'top-right'].includes(alignContextMenu)
        ? `6px solid ${theme.colors.CG7}`
        : 'none'};
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
  }
`;
