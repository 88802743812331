import { androidPackage, appStoreId } from '../constants';

export const getVacanciesUrl = () => 'https://www.vinted.com/jobs';

export const getHelpCenterUrl = (language: string) =>
  `https://help.vinted.ca/hc/${language}-ca`;

export const getVisaUrl = () => 'https://www.visa.com';

export const getMasterCardUrl = () => 'https://www.mastercard.com';

export const getIdealUrl = () => 'https://www.ideal.nl';

export const getPayPalUrl = () => 'https://www.paypal.com';

export const getBancontactUrl = () => 'https://www.bancontact.com';

export const getSofortUrl = () => 'https://www.sofort.com';

export const getAppStoreUrl = () =>
  `https://apps.apple.com/us/app/vinted-sell-buy-second-hand/id${appStoreId}`;

export const getPlayStoreUrl = () =>
  `https://play.google.com/store/apps/details?id=${androidPackage}`;

export const getFacebookUrl = () => 'https://www.facebook.com/vinted';

export const getLinkedInUrl = () => 'https://www.linkedin.com/company/vinted/';

export const getInstagramUrl = () => 'https://www.instagram.com/vinted/';
