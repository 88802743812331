import { createContext } from 'react';
import {
  ColorFragment,
  ProductFilters,
  ProductSizeOption,
  ProductsOrder,
} from '../generated/graphql';
import { FilterCategory } from './StaticDataContext';

interface FiltersContextType {
  filters: ProductFilters;
  orderBy: ProductsOrder;
  category?: FilterCategory;
  colors: ColorFragment[];
  sizes: ProductSizeOption[];
  first: number;
  after: string;
  page: number;
}

export const FiltersContext = createContext<FiltersContextType>(undefined);
