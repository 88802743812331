import { stringify } from 'querystring';
import urljoin from 'url-join';
import { FilterCategory } from '../context/StaticDataContext';
import {
  CategoryPageProductFilters,
  ColorFragment,
  Country,
  ProductCondition,
  ProductFilters,
  ProductsOrder,
  ServerCategoryFragment,
} from '../generated/graphql';

type QueryOrder =
  | 'recommended'
  | 'newest'
  | 'most-viewed'
  | 'cheapest'
  | 'most-expensive'
  | ProductsOrder;

type QueryBool = 'true' | 'false' | 'TRUE' | 'FALSE';

export interface ProductFiltersQuery {
  q?: string;
  category?: string;
  department?: string;
  conditions?: string;
  countries?: string;
  colors?: string;
  sizes?: string;
  min_price?: string;
  max_price?: string;
  distance?: string;
  outlet?: QueryBool;
  pick_up_points?: QueryBool;
  include_unisex?: QueryBool;
  order?: QueryOrder;
}

export const MAX_PRICE_LIMIT = 999999;

export const buildFilters = (
  colors: ColorFragment[],
  query?: ProductFiltersQuery
): CategoryPageProductFilters => {
  const filters: ProductFilters = {};

  if (query) {
    if (query.q) {
      filters.query = query.q;
    }

    if (query.category) {
      filters.categoryId = query.category;
    }

    if (query.colors) {
      filters.colorIds = query.colors
        .split(',')
        .map(
          (idOrTitle) =>
            colors.find((c) => c.title === idOrTitle || c.id === idOrTitle)
              ?.id || idOrTitle
        )
        .filter((c) => !!c);
    }

    if (query.sizes) {
      filters.sizeIds = query.sizes.split(',');
    }

    if (query.conditions) {
      filters.conditions = query.conditions.split(',').map((condition) => {
        condition = condition.toUpperCase();
        switch (condition) {
          case ProductCondition.NewWithTags:
          case ProductCondition.NewWithoutTags:
          case ProductCondition.VeryGood:
          case ProductCondition.Good:
          case ProductCondition.Satisfactory:
            return condition;
        }
      });
    }

    if (query.countries) {
      filters.countries = query.countries.split(',').map((country) => {
        country = country.toUpperCase();
        switch (country) {
          case Country.Ca:
            return country;
        }
      });
    }

    if (query.min_price) {
      const price = parseInt(query.min_price, 10);
      if (!isNaN(price)) {
        filters.minPrice = price;
      }
    }

    if (query.max_price) {
      const price = parseInt(query.max_price, 10);
      if (!isNaN(price)) {
        filters.maxPrice = Math.min(price, MAX_PRICE_LIMIT);
      }
    }

    if (query.pick_up_points?.toLowerCase() === 'true') {
      filters.onlyPickUpPoints = true;
    }

    if (query.include_unisex?.toLowerCase() === 'true') {
      filters.includeUnisex = true;
    }
  }

  return filters;
};

export const parseOrder = (order: string) => {
  switch (order) {
    case ProductsOrder.Recommended:
    case ProductsOrder.Newest:
    case ProductsOrder.MostViewed:
    case ProductsOrder.Cheapest:
    case ProductsOrder.MostExpensive:
      return order;
    case 'recommended':
      return ProductsOrder.Recommended;
    case 'newest':
      return ProductsOrder.Newest;
    case 'most-viewed':
      return ProductsOrder.MostViewed;
    case 'cheapest':
      return ProductsOrder.Cheapest;
    case 'most-expensive':
      return ProductsOrder.MostExpensive;
    default:
      return ProductsOrder.Recommended;
  }
};

export const buildHref = (
  filters: ProductFilters,
  options?: {
    page?: number;
    orderBy?: ProductsOrder;
  }
) => {
  const params = buildQueryParams(filters, options);
  return `/feed?${stringify(params)}`;
};

export const buildAs = (
  filters: ProductFilters,
  options?: {
    orderBy?: ProductsOrder;
    path?: string;
    page?: number;
  }
) => {
  const params = buildQueryParams(
    { ...filters, categoryId: undefined },
    options
  );
  const path = options?.path || '/';

  const query = stringify(params);
  if (query) {
    return `${path}?${query}`;
  }

  return path;
};

export const buildQueryParams = (
  filters: ProductFilters,
  options?: {
    orderBy?: ProductsOrder;
    page?: number;
  }
) => {
  const params: { [key: string]: string } = {};

  if (filters.colorIds?.length) {
    params.colors = filters.colorIds.join(',');
  }

  if (filters.categoryId) {
    params.category = filters.categoryId;
  }

  if (filters.sizeIds?.length) {
    params.sizes = filters.sizeIds.join(',');
  }

  if (filters.conditions) {
    params.conditions = filters.conditions.join(',');
  }

  if (filters.minPrice) {
    params.min_price = String(filters.minPrice);
  }

  if (filters.maxPrice) {
    params.max_price = String(filters.maxPrice);
  }

  if (filters.query) {
    params.q = filters.query;
  }

  if (filters.countries) {
    params.countries = filters.countries.join(',');
  }

  if (options?.orderBy && options.orderBy !== ProductsOrder.Recommended) {
    params.order_by = options.orderBy;
  }

  if (filters.query) {
    params.q = filters.query;
  }

  if (filters.distance) {
    params.distance = String(filters.distance);
  }

  if (filters.onlyOutlet) {
    params.outlet = 'true';
  }

  if (filters.includeUnisex) {
    params.include_unisex = 'true';
  }

  if (filters.onlyPickUpPoints) {
    params.pick_up_points = 'true';
  }

  if (options?.page && options.page !== 1) {
    params.page = String(options.page);
  }

  return params;
};

export const containsNoFeatureFilters = (
  filters: ProductFilters,
  orderBy?: ProductsOrder
) => {
  if (filters.sizeIds?.length) {
    return false;
  }

  if (
    typeof filters.minPrice !== 'undefined' ||
    typeof filters.maxPrice !== 'undefined'
  ) {
    return false;
  }

  if (filters.conditions?.length) {
    return false;
  }

  if (orderBy && orderBy !== ProductsOrder.Recommended) {
    return false;
  }

  if (filters.colorIds?.length) {
    return false;
  }

  if (filters.onlyOutlet) {
    return false;
  }

  if (filters.onlyPickUpPoints) {
    return false;
  }

  if (filters.includeUnisex) {
    return false;
  }

  if (filters.countries?.length) {
    return false;
  }

  if (filters.query) {
    return false;
  }

  return true;
};

export const buildPath = (
  language: string,
  filters: Partial<ProductFilters>,
  findCategoryById: (id: string) => FilterCategory
) => {
  let categoryId = filters.categoryId;
  const pathParts: string[] = [];
  if (categoryId) {
    while (categoryId) {
      const category = findCategoryById(categoryId);
      pathParts.unshift(localizedCategoryUrlKey(language, category));
      categoryId = category?.parent?.id;
    }
  } else {
    pathParts.unshift('feed');
  }

  return `/${urljoin(pathParts.filter(Boolean))}`;
};

export const localizedCategoryUrlKey = (
  language: string,
  category: ServerCategoryFragment
) => {
  switch (language) {
    case 'fr':
      return category.frUrlKey;
    case 'en':
      return category.enUrlKey;
    default:
      return null;
  }
};
