import { useEffect, useState } from 'react';
import { formatPrice } from '../utils/price';

type FormatPriceParams = (
  amount: number,
  locale: string,
  options?: {
    compact?: boolean;
    includeCurrencySymbol?: boolean;
  }
) => string;

export default function useFormatPrice() {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  const formatPriceOnClient: FormatPriceParams = (amount, locale, options) => {
    if (!hasMounted) return '';
    return formatPrice(amount, locale, options);
  };

  return formatPriceOnClient;
}
