import { FC, PropsWithChildren } from 'react';
import { TodoContext, TodoState } from '../TodoContext';

const finishedState: TodoState = {
  listing: 1,
  favs: 1,
  sellers: 1,
  photosUpload: 1,
  step: 4,
  loading: false,
  userId: '0',
  isFinished: true,
  hasError: false,
  profileImage: { url: '' },
  coverImage: { url: '' },
};

const TodoProvider: FC<PropsWithChildren> = ({ children }) => (
  <TodoContext.Provider
    value={{
      todoState: finishedState,
      isPhotosLoading: false,
      togglePhotosLoading: () => undefined,
      handleTodoFetching: () => undefined,
      handleMessageRenderer: () => undefined,
    }}
  >
    {children}
  </TodoContext.Provider>
);

export default TodoProvider;
