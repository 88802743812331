import { FC, PropsWithChildren } from 'react';
import { ExperimentContext, Experiments } from '../ExperimentContext';

const ExperimentProvider: FC<PropsWithChildren> = ({ children }) => {
  const experiments: Partial<Experiments> = {
    closure: {
      isParticipating: true,
      isInControlGroup: false,
      isInVariant: true,
      participate: () => Promise.resolve(),
    },
    restriction: {
      isParticipating: true,
      isInControlGroup: false,
      isInVariant: true,
      participate: () => Promise.resolve(),
    },
    preComms: {
      isParticipating: true,
      isInControlGroup: false,
      isInVariant: true,
      participate: () => Promise.resolve(),
    },
  };

  return (
    <ExperimentContext.Provider value={experiments as Experiments}>
      {children}
    </ExperimentContext.Provider>
  );
};

export const ExperimentConsumer = ExperimentContext.Consumer;

export default ExperimentProvider;
