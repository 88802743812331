import { MutableRefObject, useEffect } from 'react';

export default function useOutsideClick(
  ref: MutableRefObject<Node>,
  callback: () => void
) {
  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const clickHandler = (event: MouseEvent) => {
      if (
        event.button === 0 &&
        event.target instanceof Node &&
        !ref.current.contains(event.target)
      ) {
        callback();
      }
    };

    document.addEventListener('mousedown', clickHandler);
    return () => {
      document.removeEventListener('mousedown', clickHandler);
    };
  }, [ref.current, callback]);
}
