import { createContext } from 'react';
import {
  CategoryFieldsFragment,
  ColorFragment,
  Scalars,
  SizeTypeFragment,
} from '../generated/graphql';

export interface FilterCategory extends CategoryFieldsFragment {
  children?: FilterCategory[];
}

export type CategoryType = Pick<
  FilterCategory,
  'children' | 'title' | 'parent' | 'id'
>;

interface StaticDataContextType {
  categories: FilterCategory[];
  colors: ColorFragment[];
  sizeTypes: SizeTypeFragment[];
  findCategoryById(id: string): FilterCategory;
  searchCategoriesByTitle(
    title: Scalars['String'],
    category: CategoryType
  ): CategoryType[];
  getRootCategory(id: string): FilterCategory;
}

export const StaticDataContext = createContext<StaticDataContextType>({
  categories: [],
  colors: [],
  sizeTypes: [],
  findCategoryById() {
    throw new Error('context not initialized');
  },
  searchCategoriesByTitle() {
    throw new Error('context not initialized');
  },
  getRootCategory() {
    throw new Error('context not initialized');
  },
});
