import Link, { LinkProps } from 'next/link';
import { FC, PropsWithChildren, useContext, useMemo } from 'react';
import { menCategoryId } from '../constants';
import { StaticDataContext } from '../context/StaticDataContext';
import {
  ListProductFragment,
  ProductSimplifiedContentFragment,
  ProductState,
} from '../generated/graphql';

type Props = {
  product: ListProductFragment | ProductSimplifiedContentFragment;
  withAnchor?: boolean;
} & Omit<LinkProps, 'href'> &
  PropsWithChildren;

export const ProductLink: FC<Props> = ({
  withAnchor,
  product,
  children,
  ...props
}) => {
  const { getRootCategory } = useContext(StaticDataContext);

  if (!withAnchor) {
    return (
      <Link href={`/product/${product.id}`} {...props}>
        {children}
      </Link>
    );
  }

  const productItem = product as ListProductFragment;
  const hasAnchor = useMemo(
    () =>
      productItem.state === ProductState.Active &&
      productItem.category &&
      getRootCategory(productItem.category.id)?.id === menCategoryId,
    [productItem.category, productItem.state, getRootCategory]
  );

  if (hasAnchor) {
    return (
      <Link href={`/product/${productItem.id}`} {...props} passHref>
        <a>{children}</a>
      </Link>
    );
  }
  return (
    <Link href={`/product/${productItem.id}`} {...props}>
      {children}
    </Link>
  );
};
