import { TranslationKey } from '../context/LanguageContext';
import { Country, Language, ProductCondition } from '../generated/graphql';

export const translationKeyForCondition = (
  condition: ProductCondition
): TranslationKey => {
  switch (condition) {
    case ProductCondition.NewWithTags:
      return 'gen_condition_new_with_tags';
    case ProductCondition.NewWithoutTags:
      return 'gen_condition_new_without_tags';
    case ProductCondition.VeryGood:
      return 'gen_condition_very_good';
    case ProductCondition.Good:
      return 'gen_condition_good';
    case ProductCondition.Satisfactory:
      return 'gen_condition_satisfactory';
    default:
      return condition;
  }
};

export const translationKeyForCountry = (country: Country): TranslationKey => {
  switch (country) {
    case Country.Ca:
      return 'gen_canada';
    default:
      return country;
  }
};

export const translationKeyForLanguage = (
  language: Language
): TranslationKey => {
  switch (language) {
    case Language.En:
      return 'gen_english';
    case Language.Fr:
      return 'gen_french';
    default:
      return language;
  }
};

export const convertToJSLocale = (locale: string) => locale.replace('_', '-');
