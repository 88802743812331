import { createGlobalStyle } from 'styled-components';
import { APP_MENU_BAR_HEIGHT } from './theme';

const GlobalStyle = createGlobalStyle`
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body{
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* END OF RESET */

/* fonts */

@font-face {
  font-family: 'MaisonNeue';
  src: url('${require('@/modules/assets/fonts/MaisonNeueWEB-Book.woff2')}') format('woff2'),
    url('${require('@/modules/assets/fonts/MaisonNeueWEB-Book.woff')}') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MaisonNeue';
  src: url('${require('@/modules/assets/fonts/MaisonNeueWEB-Medium.woff2')}') format('woff2'),
    url('${require('@/modules/assets/fonts/MaisonNeueWEB-Medium.woff')}') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MaisonNeue';
  src: url('${require('@/modules/assets/fonts/MaisonNeueWEB-Demi.woff2')}') format('woff2'),
    url('${require('@/modules/assets/fonts/MaisonNeueWEB-Demi.woff')}') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

/* /fonts */

*,
*::after,
*::before {
  box-sizing: border-box;
}

*::before {
  clear: both;
  margin-left: -1px;
}

html {
  height: 100%;
  font-size: 10px;
}

body {
  font-family: 'MaisonNeue', sans-serif;
  font-weight: 300;
  margin: 0;
  max-width: 100%;
  height: 100%;
  font-size: 1.3rem;
  color: #111111;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased; 
}

a {
  text-decoration: none;
  color: #111111;
}

.grecaptcha-badge {
  visibility: hidden;
}

a[name]:empty, a[id]:empty {
  padding-top: ${APP_MENU_BAR_HEIGHT}px;
}

#__next {
  height: 100%;
}

button {
  font-family: 'MaisonNeue', sans-serif;
}
`;

export default GlobalStyle;
