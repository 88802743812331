import { FC, Fragment, PropsWithChildren } from 'react';
import withSideEffect from 'react-side-effect';
import { Maybe } from '../types/types';

type Props = PropsWithChildren & {
  statusCode: number;
};

const AppError: FC<Props> = ({ children }) => {
  return <Fragment>{children}</Fragment>;
};

export default withSideEffect<Props, Maybe<Props>>(
  (list) => {
    return list[list.length - 1];
  },
  () => null
)(AppError);
