import { currency } from '../constants';
import { FullParcelPriceFragment } from '../generated/graphql';
import { convertToJSLocale } from './translations';

export const formatPrice = (
  amount: number,
  locale: string,
  options?: {
    compact?: boolean;
    includeCurrencySymbol?: boolean;
  }
) => {
  const cents = amount % 100;

  const opts = {
    compact: true,
    includeCurrencySymbol: true,
    ...options,
  };

  const fractionDigits = opts.compact && cents === 0 ? 0 : 2;
  const formatted = (amount / 100).toLocaleString(convertToJSLocale(locale), {
    style: opts.includeCurrencySymbol ? 'currency' : 'decimal',
    currency,
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
  });

  return formatted;
};

export const parsePrice = (price: string) => {
  const numericPrice = parseFloat(price.replace(',', '.'));
  return numericPrice && Math.round(numericPrice * 100);
};

export const getCurrencySymbol = (locale: string) =>
  new Intl.NumberFormat(convertToJSLocale(locale), {
    style: 'currency',
    currency,
  })
    .formatToParts(0)
    .find((part) => part.type === 'currency')?.value;

// Checks whether the first price part is the currency
export const getIsCurrencySymbolAlignedLeft = (locale: string) =>
  new Intl.NumberFormat(convertToJSLocale(locale), {
    style: 'currency',
    currency,
  }).formatToParts(0)[0].type === 'currency';

export const formatParcelPrice = (
  parcelPrice: FullParcelPriceFragment,
  locale: string,
  options?: {
    compact?: boolean;
    includeCurrencySymbol?: boolean;
  }
) => {
  if (parcelPrice.__typename === 'Price') {
    return formatPrice(parcelPrice.amount, locale, options);
  }
  const minPrice = formatPrice(parcelPrice.minPrice.amount, locale, options);
  const maxPrice = formatPrice(parcelPrice.maxPrice.amount, locale, options);
  return `${minPrice} - ${maxPrice}`;
};
