import { generateCursor } from '@/common/utils/pagination';
import { productsPerPage } from '@/modules/feed/components/ProductResults';
import { WithRouterProps } from 'next/dist/client/with-router';
import { withRouter } from 'next/router';
import { FC, PropsWithChildren, useContext } from 'react';
import { ProductFilters } from '../../generated/graphql';
import { buildFilters, parseOrder } from '../../utils/filters';
import { FiltersContext } from '../FiltersContext';
import { FilterCategory, StaticDataContext } from '../StaticDataContext';

const FiltersProvider: FC<WithRouterProps & PropsWithChildren> = ({
  children,
  router,
}) => {
  const { colors, sizeTypes, findCategoryById } = useContext(StaticDataContext);
  const filters: ProductFilters = buildFilters(colors, router.query);
  const orderBy = parseOrder(router.query.order_by as string);

  const page =
    (router.query.page && parseInt(router.query.page as string, 10)) || 1;
  const after = generateCursor(productsPerPage, page);

  const category: FilterCategory =
    filters.categoryId && findCategoryById(filters.categoryId);
  if (!category) {
    filters.categoryId = undefined;
  }

  const selectedColors = colors.filter((color) =>
    filters.colorIds?.includes(color.id)
  );

  const sizes = sizeTypes.reduce(
    (options, sizeType) =>
      options.concat(
        sizeType.options.filter((option) =>
          filters.sizeIds?.includes(option.id)
        )
      ),
    []
  );

  return (
    <FiltersContext.Provider
      value={{
        filters,
        orderBy,
        category,
        colors: selectedColors,
        sizes,
        page,
        first: productsPerPage,
        after,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export default withRouter(FiltersProvider);
