import { menCategoryId } from '@/common/constants';
import { StaticDataContext } from '@/common/context/StaticDataContext';
import { ProductFilters, ProductsOrder } from '@/common/generated/graphql';
import { FC, PropsWithChildren, useContext, useMemo } from 'react';
import styled from 'styled-components';
import AngleDown from '../../design/components/icons/AngleDown';
import { FilterLink } from './ProductFilters/FilterLink';

const StyledProductPagination = styled.section`
  display: flex;
  flex-direction: row;
`;

const PageContainer = styled.span`
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  overflow: hidden;
  font-size: 1.6rem;
`;

const PageButton = styled(PageContainer)`
  &:hover {
    background-color: ${({ theme }) => theme.colors.CG6};
  }
`;

const StyledPageLink = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
  cursor: pointer;
`;

const SelectedPage = styled(PageContainer)`
  background-color: ${({ theme }) => theme.colors.CalypsoCP1};
  color: #fff;
`;

const StyledNavigationIcon = styled.div<{ direction: 'left' | 'right' }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;

  &:hover {
    svg * {
      fill: ${({ theme }) => theme.colors.CG1};
    }
  }

  svg {
    transform: ${({ direction }) =>
      direction === 'left' ? 'rotate(90deg)' : 'rotate(270deg)'};
  }
`;

const NavigationIcon: FC<{ direction: 'left' | 'right' }> = ({ direction }) => (
  <StyledNavigationIcon direction={direction}>
    <AngleDown />
  </StyledNavigationIcon>
);

const fillArray = (start: number, length: number) =>
  Array(length)
    .fill(start)
    .map((cur, i) => cur + i);

type Props = {
  page: number;
  orderBy?: ProductsOrder;
  filters?: ProductFilters;
  hasLink: boolean;
} & PropsWithChildren;

const PageLink: FC<Props> = ({ page, filters, orderBy, hasLink, children }) => {
  if (hasLink) {
    return (
      <FilterLink page={page} filters={filters} orderBy={orderBy} passHref>
        <StyledPageLink as="a">{children}</StyledPageLink>
      </FilterLink>
    );
  }
  return (
    <FilterLink page={page} filters={filters} orderBy={orderBy}>
      <StyledPageLink>{children}</StyledPageLink>
    </FilterLink>
  );
};

const ProductPagination: FC<{
  totalResultsCount: number;
  productsPerPage: number;
  pagesPreviewCount: number;
  className?: string;
  page: number;
  orderBy?: ProductsOrder;
  filters?: ProductFilters;
}> = ({
  totalResultsCount,
  className,
  productsPerPage,
  pagesPreviewCount,
  page,
  orderBy,
  filters,
}) => {
  const { getRootCategory } = useContext(StaticDataContext);

  const isMensCategory = useMemo(
    () =>
      filters.categoryId &&
      getRootCategory(filters.categoryId)?.id === menCategoryId,
    [filters.categoryId, getRootCategory]
  );

  const totalPages = Math.ceil(totalResultsCount / productsPerPage);

  let previewStart = Math.max(1, page - Math.floor(pagesPreviewCount / 2));
  if (previewStart + pagesPreviewCount > totalPages) {
    previewStart = Math.max(1, totalPages - pagesPreviewCount + 1);
  }
  const length = Math.min(totalPages, pagesPreviewCount);
  const previewPages = fillArray(previewStart, length);

  const hasPrev = page > 1;
  const hasNext = page < totalPages;

  return (
    <StyledProductPagination className={className}>
      {hasPrev && (
        <PageLink
          hasLink={isMensCategory}
          page={page - 1}
          filters={filters}
          orderBy={orderBy}
        >
          <NavigationIcon direction="left" />
        </PageLink>
      )}
      {previewPages.map((previewPage) => {
        if (previewPage === page) {
          return <SelectedPage key={previewPage}>{page}</SelectedPage>;
        }
        return (
          <PageButton key={previewPage}>
            <PageLink
              hasLink={isMensCategory}
              page={previewPage}
              filters={filters}
              orderBy={orderBy}
            >
              {previewPage}
            </PageLink>
          </PageButton>
        );
      })}
      {hasNext && (
        <PageLink
          hasLink={isMensCategory}
          page={page + 1}
          filters={filters}
          orderBy={orderBy}
        >
          <NavigationIcon direction="right" />
        </PageLink>
      )}
    </StyledProductPagination>
  );
};

export default ProductPagination;
