import { FC, PropsWithChildren } from 'react';
import { StaticDataContext } from '../StaticDataContext';

const StaticDataProvider: FC<PropsWithChildren> = ({ children }) => {
  const findCategoryById = () => undefined;
  const searchCategoriesByTitle = () => undefined;
  const getRootCategory = () => undefined;

  return (
    <StaticDataContext.Provider
      value={{
        categories: [],
        colors: [],
        sizeTypes: [],
        findCategoryById,
        searchCategoriesByTitle,
        getRootCategory,
      }}
    >
      {children}
    </StaticDataContext.Provider>
  );
};

export const StaticDataConsumer = StaticDataContext.Consumer;

export default StaticDataProvider;
