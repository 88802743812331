import cookie from 'cookie';
import { IncomingMessage } from 'http';
import getConfig from 'next/config';
import { currency, locales } from '../constants';
import { getIsMobile } from './browser';
import { uwCookies } from './cookies';

const config = getConfig();

const validHeaders = ['accept'];

const forwardHeaders = (request: IncomingMessage) => {
  const headers: { [key: string]: string } = {};
  validHeaders.forEach((header) => {
    const value = request.headers[header];
    if (value) {
      headers[header] = Array.isArray(value) ? value[0] : value;
    }
  });
  return headers;
};

const getLocale = (req?: IncomingMessage) => {
  if (!req) {
    return undefined;
  }

  if (req.language) {
    return locales[req.language];
  }

  return undefined;
};

export const getVersion = () =>
  config?.publicRuntimeConfig?.APP_VERSION ||
  process.env.APP_VERSION ||
  process.env.npm_package_version ||
  'dev';

export const buildHeaders = (request: IncomingMessage) => {
  const headers = forwardHeaders(request);

  const cookies = request.headers.cookie
    ? cookie.parse(request.headers.cookie)
    : undefined;
  const userAgent = request.headers['user-agent'];
  headers['x-platform'] =
    userAgent && getIsMobile(userAgent) ? 'mobileweb' : 'web';
  headers['x-locale'] =
    cookies &&
    cookies[uwCookies.language] &&
    locales[cookies[uwCookies.language]]
      ? locales[cookies[uwCookies.language]]
      : getLocale(request);
  headers['x-version'] = getVersion();
  headers['x-currency'] = currency;

  if (userAgent) {
    headers['x-device'] = userAgent;
  }

  if (cookies && cookies[uwCookies.sessionId]) {
    headers['x-uw-session-id'] = cookies[uwCookies.sessionId];
  }

  const deviceId = (cookies && cookies[uwCookies.deviceId]) || request.deviceId;
  if (deviceId) {
    headers['x-device-id'] = deviceId;
  }
  return headers;
};
