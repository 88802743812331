import ModalOverlay, { ModalProps } from '@/common/components/ModalOverlay';
import { LanguageContext } from '@/common/context/LanguageContext';
import {
  DeleteProductDocument,
  DeleteProductMutation,
  DeleteProductMutationVariables,
} from '@/common/generated/graphql';
import { useApolloClient } from '@apollo/client';
import { FC, useContext, useState } from 'react';
import styled from 'styled-components';
import PrimaryButton from '../../design/components/buttons/PrimaryButton';
import SecondaryButton from '../../design/components/buttons/SecondaryButton';
import { BodyText, Heading2 } from '../../design/components/textstyles';

const StyledModalOverlay = styled(ModalOverlay)`
  padding: 40px;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  display: flex;
  width: 100%;
  max-width: none;
  margin-top: 40px;
`;

const StyledSecondaryButton = styled(SecondaryButton)`
  display: flex;
  margin-top: 8px;
  max-width: none;
  width: 100%;
  border: none;
`;

const Title = styled(Heading2)`
  margin-bottom: 24px;
`;

const ProductDeleteModalOverlay: FC<
  ModalProps<boolean> & { productId: string }
> = ({ productId, onRequestClose, ...props }) => {
  const { translate } = useContext(LanguageContext);
  const [isDeleting, setIsDeleting] = useState(false);
  const apolloClient = useApolloClient();

  const deleteProduct = async () => {
    setIsDeleting(true);
    await apolloClient.mutate<
      DeleteProductMutation,
      DeleteProductMutationVariables
    >({
      mutation: DeleteProductDocument,
      variables: { input: { productId } },
    });
    onRequestClose(true);
  };

  return (
    <StyledModalOverlay onRequestClose={() => onRequestClose(false)} {...props}>
      <Title>{translate('gen_delete')}</Title>
      <BodyText>{translate('closet_delete_product_q')}</BodyText>
      <StyledPrimaryButton disabled={isDeleting} onClick={deleteProduct}>
        {translate('gen_yes')}
      </StyledPrimaryButton>
      <StyledSecondaryButton onClick={() => onRequestClose(false)}>
        {translate('gen_close')}
      </StyledSecondaryButton>
    </StyledModalOverlay>
  );
};

export default ProductDeleteModalOverlay;
