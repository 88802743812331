import MetaTags from '@/common/components/HtmlHead/MetaTags';
import AppProvider from '@/common/context/providers/AppProvider';
import GlobalStyle from '@/common/styles/global';
import { theme } from '@/common/styles/theme';
import PageProps from '@/common/types/page-props';
import withApolloClient, {
  ApolloAppProps,
} from '@/common/utils/withApolloClient';
import { ApolloProvider } from '@apollo/client';
import App, { AppContext, AppInitialProps } from 'next/app';
import Error from 'next/error';
import { Cookies } from 'react-cookie';
import { ThemeProvider } from 'styled-components';

declare module 'next' {
  interface NextPageContext {
    language: string;
  }
}

interface UWAppProps extends AppInitialProps, ApolloAppProps {
  errorCode?: number;
  pageProps: PageProps;
  locale: string;
  universalCookies?: Cookies;
}
class UWApp extends App<UWAppProps> {
  static async getInitialProps({ Component, ctx }: AppContext) {
    let pageProps = {};
    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    return {
      pageProps,
    } as any;
  }

  render() {
    const { Component, pageProps, errorCode, apolloClient } = this.props;

    if (errorCode) {
      return <Error statusCode={errorCode} />;
    }

    return (
      <>
        <ApolloProvider client={apolloClient}>
          <ThemeProvider theme={theme}>
            <AppProvider>
              <MetaTags />
              <Component {...pageProps} />
            </AppProvider>
          </ThemeProvider>
        </ApolloProvider>
        <GlobalStyle />
      </>
    );
  }
}

export default withApolloClient(UWApp);
