import pino from 'pino';

const logger = pino({
  name: `react-web-${process.env.ENVIRONMENT || 'local'}`,
  formatters: {
    level: (label) => ({ level: label }),
  },
  level: process.env.LOG_LEVEL || 'warn',
  serializers: {
    request: () => {
      // Axios errors contain a request property, which is a stream.Writable but
      // these type of objects can become really large, so we just null them.
      return null;
    },
  },
});

export default logger;
