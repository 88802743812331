import { SizeTypeFragment } from '@/common/generated/graphql';
import useOutsideClick from '@/common/hooks/useOutsideClick';
import { useField } from 'formik';
import { transparentize } from 'polished';
import { FC, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { Heading5 } from '../textstyles';
import BaseInput from './BaseInput';

const StyledSizeTypeInput = styled.div`
  position: relative;
`;

const StyledSelect = styled.div<{ hasError: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 48px;
  border-radius: 4px;
  border: ${({ theme, hasError }) =>
    `solid 1px ${
      hasError ? theme.colors.CoralCS3 : transparentize(0.92, theme.colors.CG1)
    }`};
  padding: 0 16px;
  outline: none;
  font-size: 1.6rem;
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  box-sizing: border-box;
  transition: border 0.1s ease-in-out;
  background-color: ${({ theme }) => theme.colors.CG7};
  cursor: pointer;

  &:focus {
    border: solid 1px ${({ theme }) => theme.colors.CalypsoCP1};

    /* needed to override autofill presets */
    -webkit-text-fill-color: ${({ theme }) => theme.colors.CG1};
    box-shadow: 0 0 0 50px ${({ theme }) => theme.colors.CG7} inset;
    background-color: ${({ theme }) => theme.colors.CG7};
  }
`;

const AngleDown = styled.img.attrs({
  src: require('@/modules/assets/icons/chevron-down.svg'),
})`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
`;

const Dropdown = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 15;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(8, 34, 41, 0.2);
  margin-top: 12px;
  height: 192px;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.CG7};
`;

const SizeOption = styled.div<{ isSelected: boolean }>`
  padding-left: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease-in;
  position: relative;

  &:hover {
    background-color: ${({ theme }) => theme.themeColors.hoverBackground};
  }

  &:active {
    background-color: ${({ theme }) => theme.themeColors.activeBackground};
  }

  &:after {
    display: block;
    content: '';
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    right: 16px;
    background-size: cover;
    transform: translateY(-50%);
    border-radius: 4px;
    border: ${({ theme, isSelected }) =>
      isSelected ? 'none' : `1px solid ${theme.colors.CG3}`};
    background-color: ${({ theme, isSelected }) =>
      isSelected ? theme.colors.CalypsoCP1 : 'transparent'};
    background-image: ${({ isSelected }) =>
      isSelected
        ? `url(${require('@/modules/assets/icons/check.svg')})`
        : 'none'};
    background-repeat: no-repeat;
    background-position: center;
    background-size: 14px;
  }
`;

const InnerSizeOption = styled(Heading5).attrs({ as: 'div' })`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
`;

type Option = SizeTypeFragment['options'][number];

const SizeTypeInput: FC<{
  name: string;
  className?: string;
  sizeType: SizeTypeFragment;
}> = ({ name, sizeType, className }) => {
  const ref = useRef<HTMLDivElement>();
  const [field, { touched, error }, { setValue }] = useField<string[]>(name);
  const [isOpened, setIsOpened] = useState(false);
  const label = useMemo(() => {
    if (!field.value || !field.value.length) {
      return undefined;
    }
    const options = sizeType.options.filter((option) =>
      field.value.includes(option.id)
    );
    if (!options.length) {
      return undefined;
    }
    return options.map((option) => option.title).join(', ');
  }, [field.value, sizeType]);

  useOutsideClick(ref, () => {
    setIsOpened(false);
  });

  const handlePlaceHolderOnClick = () => {
    setIsOpened(!isOpened);
  };

  const handleSizeOnClick = (option: Option) => {
    const index = field.value.indexOf(option.id);
    if (index === -1) {
      setValue([...field.value, option.id]);
    } else {
      const newSizes = [...field.value];
      newSizes.splice(index, 1);
      setValue(newSizes);
    }
  };

  return (
    <StyledSizeTypeInput ref={ref}>
      <BaseInput className={className} name={name} placeholder={sizeType.title}>
        {({ minimizePlaceholder, expandPlaceholder }) => {
          if (!label) {
            expandPlaceholder();
          } else {
            minimizePlaceholder();
          }
          return (
            <>
              <StyledSelect
                {...field}
                hasError={!!(touched && error)}
                tabIndex={0}
                onClick={handlePlaceHolderOnClick}
              >
                {label}
              </StyledSelect>
              <AngleDown />
            </>
          );
        }}
      </BaseInput>
      {isOpened ? (
        <Dropdown>
          {sizeType.options.map((option) => (
            <SizeOption
              key={option.id}
              onClick={() => handleSizeOnClick(option)}
              isSelected={field.value.includes(option.id)}
            >
              <InnerSizeOption>{option.title}</InnerSizeOption>
            </SizeOption>
          ))}
        </Dropdown>
      ) : null}
    </StyledSizeTypeInput>
  );
};

export default SizeTypeInput;
