import { rgba, transparentize } from 'polished';
import { DefaultTheme } from 'styled-components';

const CalypsoCP1 = '#007782';
const EmeraldCS1 = '#197B56';
const SafronCS2 = '#F7C600';
const CoralCS3 = '#A43954';

const CalypsoMediumCSM1 = '#73DCDC';
const EmeraldMediumCSM2 = '#80E4AB';
const SafronMediumCSM3 = '#F9DD74';
const CoralMediumCSM4 = '#FF8E8E';

const CalypsoLightCSL1 = '#004654';
const EmeraldLightCSL2 = '#17503B';
const SafronLightCSL3 = '#846528';
const CoralLightCSL4 = '#5A2B39';

const CG1 = '#171717';
const CG2 = '#4D4D4D';
const CG3 = '#757575';
const CG4 = '#999999';
const CG5 = '#C9C9C9';
const CG6 = '#F2F2F2';
const CG7 = '#FFFFFF';

export const APP_MENU_BAR_HEIGHT = 95;

export const theme: DefaultTheme = {
  breakpoints: {
    maxWidth: '1920px',
    contentMaxWidth: '1080px',
    mobileMaxWidth: '768px',
    tabletMaxWidth: '1024px',
    smallDesktopMaxWidth: '1280px',
  },
  colors: {
    CalypsoCP1,
    EmeraldCS1,
    SafronCS2,
    CoralCS3,
    CalypsoMediumCSM1,
    EmeraldMediumCSM2,
    SafronMediumCSM3,
    CoralMediumCSM4,
    CalypsoLightCSL1,
    EmeraldLightCSL2,
    SafronLightCSL3,
    CoralLightCSL4,
    CG1,
    CG2,
    CG3,
    CG4,
    CG5,
    CG6,
    CG7,
  },
  themeColors: {
    border: transparentize(0.92, CG1),
    hoverBackground: transparentize(0.98, CG1),
    activeBackground: transparentize(0.92, CG1),
    primaryBackground: CG7,
    secondaryBackground: '#F0F9FA',
    imageOverlay: rgba(8, 34, 41, 0.4),
  },
  fonts: {
    sansSerif: `'MaisonNeue', sans-serif`,
  },
  fontWeights: {
    book: 300,
    medium: 400,
    demi: 600,
  },
  dimensions: {
    appMenuBarHeight: `${APP_MENU_BAR_HEIGHT}px`,
  },
};
