import getConfig from 'next/config';
import urljoin from 'url-join';

const { publicRuntimeConfig } = getConfig();

// urljoin function that accepts null-values and filteres them
export const urljoinOmitEmpty = (...args: (string | null | undefined)[]) => {
  return urljoin(...args.filter((a): a is string => !!a));
};

export const webUrl = (...parts: (string | null | undefined)[]) =>
  urljoinOmitEmpty(
    publicRuntimeConfig.BASE_URL,
    ...parts.filter((part) => part !== '/')
  );

export const appUrl = (...parts: (string | null | undefined)[]) =>
  webUrl(...parts).replace(/^https?/, publicRuntimeConfig.APP_DEEP_LINK_SCHEME);

// removes query params from url that should be omitted in its canonical form
export const canonicalUrl = (url: string): string => {
  const [parsedUrl] = url.split('?');
  return parsedUrl;
};

export const blacklistQueryParameters = (
  url: string,
  ...blacklist: string[]
) => {
  const [parsedUrl, queryString] = url.split('?');
  const newQueryParameters: string[] = [];
  if (queryString) {
    const queryParameters = queryString.split('&');
    queryParameters.forEach((queryParameter) => {
      const [key] = queryParameter.split('=');
      if (!blacklist.includes(key)) {
        newQueryParameters.push(queryParameter);
      }
    });
  }

  if (newQueryParameters.length) {
    return `${parsedUrl}?${newQueryParameters.join('&')}`;
  }
  return parsedUrl;
};

export const omitUndefined = (params: {
  [key: string]: any;
}): { [key: string]: any } =>
  Object.keys(params).reduce(
    (result, key) =>
      typeof params[key] === 'undefined'
        ? result
        : { ...result, [key]: params[key] },
    {}
  );

export const parseEnum = <T extends readonly string[]>(
  value: string | string[],
  values: T,
  defaultValue?: T[number]
): T[number] =>
  value && typeof value === 'string' && values.includes(value)
    ? value
    : defaultValue;

export const parseHashQuery = (hash: string): Record<string, string> => {
  const [, parsedHash] = hash.split('#');
  if (!parsedHash) {
    return {};
  }
  return parsedHash.split('&').reduce((previous, current) => {
    const [key, value] = current.split('=').map(decodeURIComponent);
    return { ...previous, [key]: value };
  }, {} as Record<string, string>);
};

export const getClosureUrl = (language: string) =>
  `https://help.vinted.ca/hc/${language}-ca/articles/9869619263004`;
