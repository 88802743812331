import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import UTC from 'dayjs/plugin/utc';
import { Language } from './generated/graphql';

dayjs.extend(UTC);
dayjs.extend(duration);

export const supportedLanguages = [Language.En, Language.Fr];
export const defaultLanguage = 'en';
export const minResultCountToPassHref = 4;
export const minProductCount = 1;
export const appName = 'Vinted';
export const appStoreId = '1562168347';
export const androidPackage = 'ca.vinted.app';
export const currency = 'CAD';
export const defaultLocale = 'en_CA';
export const graphqlDateFormat = 'YYYY-MM-DD';
export const newsletterId = '46';
export const locales: Record<string, string> = {
  en: 'en_CA',
  fr: 'fr_CA',
} as const;
export const recaptchaActions = {
  REGISTER: 'REGISTER',
  ADD_TO_CART: 'ADD_TO_CART',
  ADD_PRODUCT: 'ADD_PRODUCT',
  CONFIRM_EMAIL_ADDRESS: 'CONFIRM_EMAIL_ADDRESS',
};
export const menCategoryId = '2';
