import useFormatPrice from '@/common/hooks/useFormatPrice';
import FavoriteButton from '@/modules/design/components/buttons/FavoriteButton';
import { FavoriteEntryPoint } from '@/modules/design/components/buttons/ToggleFavoriteButton';
import {
  BodyText,
  Callout,
  Caption1,
  Heading5,
} from '@/modules/design/components/textstyles';
import ProductPrice from '@/modules/product/components/ProductPrice';
import { transparentize } from 'polished';
import { FC, forwardRef, useContext } from 'react';
import styled, { css } from 'styled-components';
import { LanguageContext, TranslateFunc } from '../../context/LanguageContext';
import {
  ListProductFragment,
  ProductSimplifiedContentFragment,
  ProductState,
} from '../../generated/graphql';
import {
  showFavoriteButton,
  translationKeyForState,
} from '../../utils/product';
import { ProductLink } from '../ProductLink';
import ProductListItemContextMenuButton from './ProductListItemContextMenuButton';
import { TagList } from './TagList';

export type ProductListItemProps = {
  entryPoint: FavoriteEntryPoint;
  isFooterHidden?: boolean;
  isImageOnly?: boolean;
  isTagHidden?: boolean;
  isFavoriteHidden?: boolean;
  isStatusHidden?: boolean;
  isMobileNonResponsive?: boolean;
};

type ParsedDataType = {
  brand: ListProductFragment['brand'];
  size: ListProductFragment['size'];
  price: ListProductFragment['price'];
  tags: ListProductFragment['tags'];
  image: ListProductFragment['image'];
  name: string;
  hoverInfo: string;
  formattedPrice: string;
  colorsLabel: string;
  formattedPreOutletPrice?: string;
  viewerHasFavorited?: ListProductFragment['viewerHasFavorited'];
  outletDiscount?: ListProductFragment['outletDiscount'];
  state?: ListProductFragment['state'];
};

const StyledProductListItem = styled.article`
  display: flex;
  flex-direction: column;
`;

const ImageWrapper = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const ProductImage = styled.img.attrs({
  loading: 'lazy',
  itemProp: 'image',
})`
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  background-color: #eee;
`;

const ImageOnlyContainer = styled.div`
  position: relative;
  padding-top: 100%;
  max-width: 160px;
  width: 160px;
  height: 160px;

  @media (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobileMaxWidth}) {
    height: 112px;
    width: 112px;
  }
`;

const ImageContainer = styled.div<
  Pick<ProductListItemProps, 'isMobileNonResponsive'>
>`
  position: relative;
  padding-top: 100%;
  ${({ isMobileNonResponsive }) =>
    isMobileNonResponsive
      ? css`
          height: 162px;
          width: 162px;
        `
      : css`
          height: inherit;
          width: inherit;
        `}}
`;

const Footer = styled.div`
  flex-direction: column;
  display: flex;
  padding: 8px;
  cursor: pointer;
`;

const StyledFavoriteButton = styled(FavoriteButton)`
  position: absolute;
  z-index: 1;
  right: 16px;
  top: 16px;
`;

const PreOutletPrice = styled.span`
  font-size: 1.2rem;
  margin-right: 4px;
  text-decoration: line-through;
`;

const OutletPrice = styled(ProductPrice)`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.CoralCS3};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

const Discount = styled.div`
  position: absolute;
  bottom: 16px;
  background-color: ${({ theme }) =>
    transparentize(0.15, theme.colors.CoralCS3)};
  padding: 6px 10px;
  color: #fff;
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

const StyledTagList = styled(TagList)`
  margin-top: auto;
`;

const StyledProductStateOverlay = styled(BodyText)<{ isSold: boolean }>`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  color: ${({ theme }) => theme.colors.CG7};
  background-color: ${({ theme, isSold }) =>
    isSold ? theme.colors.EmeraldCS1 : theme.colors.CG3};
  pointer-events: none;
  line-height: normal;
  padding: 0 8px;
  height: 32px;
  display: flex;
  align-items: center;
  font-size: 12px;
`;

const StyledProductListItemContextMenuButton = styled(
  ProductListItemContextMenuButton
)`
  position: absolute;
  z-index: 1;
  right: 16px;
  top: 16px;
`;

const ProductName = styled(Heading5)<
  Pick<ProductListItemProps, 'isMobileNonResponsive'>
>`
  
  font-weight: ${({ theme }) => theme.fontWeights.book};
  color: ${({ theme }) => theme.colors.CG3};

  ${({ isMobileNonResponsive }) =>
    isMobileNonResponsive
      ? css`
          font-size: 12px;
          line-height: 16px;
        `
      : css`
          font-size: 16px;
          line-height: 22px;
          @media (max-width: ${({ theme: { breakpoints } }) =>
              breakpoints.mobileMaxWidth}) {
            font-size: 12px;
            line-height: 16px;
          }
        `}}
`;

const Price = styled(Callout)<
  Pick<ProductListItemProps, 'isMobileNonResponsive'>
>`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.CG1};

  ${({ isMobileNonResponsive }) =>
    isMobileNonResponsive
      ? css`
          font-size: 14px;
          line-height: 16px;
        `
      : css`
          font-size: 16px;
          line-height: 22px;
          @media (max-width: ${({ theme: { breakpoints } }) =>
              breakpoints.mobileMaxWidth}) {
            font-size: 14px;
            line-height: 16px;
          }
        `}}
`;

const ProductText = styled(Caption1)<
  Pick<ProductListItemProps, 'isMobileNonResponsive'>
>`
  ${({ isMobileNonResponsive }) =>
    isMobileNonResponsive
      ? css`
          font-size: 12px;
          line-height: 16px;
        `
      : css`
          font-size: 16px;
          line-height: 22px;
          @media (max-width: ${({ theme: { breakpoints } }) =>
              breakpoints.mobileMaxWidth}) {
            font-size: 12px;
            line-height: 16px;
          }
        `}}
`;

const isListProductFragment = (
  fragment: ListProductFragment | ProductSimplifiedContentFragment
): fragment is ListProductFragment => {
  return typeof (fragment as ListProductFragment).state !== 'undefined';
};

function parseProductInfo(
  product: ListProductFragment | ProductSimplifiedContentFragment,
  locale: string,
  translate: TranslateFunc,
  isSimple?: boolean
): ParsedDataType {
  const formatPrice = useFormatPrice();
  const { title, brand, category, colors, size, price } = product;
  const formattedPrice = formatPrice(price.amount, locale);
  const colorsLabel = colors.map((color) => color.title).join(', ');
  const formattedPreOutletPrice = !isSimple
    ? (product as ListProductFragment).outletDiscount
      ? formatPrice(
          (product as ListProductFragment).outletDiscount?.preOutletPrice
            .amount,
          locale
        )
      : undefined
    : undefined;
  const name =
    `${brand.name} - ${category.title}` +
    (colors.length ? ` (${colorsLabel})` : '');
  const hoverInfo =
    `${title} - ${category.title}` +
    (size ? `, ${translate('gen_size')} ${size.label}` : '') +
    `, ${formattedPrice}` +
    (colors.length ? ` (${colorsLabel})` : '');

  return {
    brand: product.brand,
    size: product.size,
    price: product.price,
    tags: product.tags,
    image: product.image,
    name,
    hoverInfo,
    formattedPrice,
    colorsLabel,
    formattedPreOutletPrice: isSimple ? null : formattedPreOutletPrice,
    viewerHasFavorited: isSimple
      ? null
      : (product as ListProductFragment).viewerHasFavorited,
    outletDiscount: isSimple
      ? null
      : (product as ListProductFragment).outletDiscount,
    state: isSimple ? null : (product as ListProductFragment).state,
  };
}

const ProductStateOverlay: FC<{
  state: ProductState | null;
  translate: TranslateFunc;
}> = ({ state, translate }) => {
  const translationKey = translationKeyForState(state);

  if (!translationKey || !state) {
    return null;
  }

  return (
    <StyledProductStateOverlay isSold={state === ProductState.Sold}>
      {translate(translationKey)}
    </StyledProductStateOverlay>
  );
};

const ProductListItem = forwardRef<
  HTMLElement,
  {
    product: ListProductFragment | ProductSimplifiedContentFragment;
    className?: string;
  } & ProductListItemProps
>(
  (
    {
      product,
      entryPoint,
      className,
      isImageOnly,
      isTagHidden,
      isFavoriteHidden,
      isStatusHidden,
      isMobileNonResponsive,
    },
    ref
  ) => {
    const { locale, translate } = useContext(LanguageContext);

    const {
      brand,
      size,
      price,
      tags,
      image,
      name,
      hoverInfo,
      viewerHasFavorited,
      formattedPreOutletPrice,
      outletDiscount,
      state,
    } = parseProductInfo(product, locale, translate);

    return (
      <StyledProductListItem
        className={className}
        itemScope
        itemType="https://schema.org/Product"
        ref={ref}
      >
        <meta itemProp="name" content={name} />
        <meta itemProp="brand" content={brand.name} />

        {isImageOnly ? (
          <ImageOnlyContainer>
            <ProductLink
              product={product}
              withAnchor={isListProductFragment(product)}
            >
              <ImageWrapper>
                <ProductImage
                  height="100%"
                  src={image?.url}
                  alt={name}
                  title={hoverInfo}
                />
              </ImageWrapper>
            </ProductLink>
          </ImageOnlyContainer>
        ) : (
          <>
            <ImageContainer isMobileNonResponsive={isMobileNonResponsive}>
              <ProductLink
                product={product}
                withAnchor={isListProductFragment(product)}
              >
                <ImageWrapper>
                  <ProductImage
                    height="100%"
                    src={image?.url}
                    alt={name}
                    title={hoverInfo}
                  />
                </ImageWrapper>
              </ProductLink>
              {isListProductFragment(product) && (
                <>
                  {outletDiscount ? (
                    <Discount>-{outletDiscount.discountPercentage}%</Discount>
                  ) : null}

                  {!isFavoriteHidden ? (
                    <>
                      {showFavoriteButton(product) ? (
                        <StyledFavoriteButton
                          viewerHasFavorited={viewerHasFavorited}
                          productId={product.id}
                          entryPoint={entryPoint}
                        />
                      ) : undefined}{' '}
                    </>
                  ) : null}
                  <StyledProductListItemContextMenuButton
                    product={product as ListProductFragment}
                  />
                  {!isStatusHidden && (
                    <ProductStateOverlay state={state} translate={translate} />
                  )}
                </>
              )}
            </ImageContainer>

            <ProductLink
              product={product}
              withAnchor={isListProductFragment(product)}
            >
              <Footer>
                <Price isMobileNonResponsive={isMobileNonResponsive}>
                  {outletDiscount ? (
                    <>
                      <PreOutletPrice>{formattedPreOutletPrice}</PreOutletPrice>
                      <OutletPrice amount={price.amount} />
                    </>
                  ) : (
                    <ProductPrice amount={price.amount} />
                  )}
                </Price>
                <ProductName
                  isMobileNonResponsive={isMobileNonResponsive}
                  title={hoverInfo}
                >
                  {brand.name}
                </ProductName>
                <ProductText isMobileNonResponsive={isMobileNonResponsive}>
                  {size ? size.label : ''}
                </ProductText>
              </Footer>
            </ProductLink>
            {!isTagHidden && <StyledTagList tags={tags} />}
          </>
        )}
      </StyledProductListItem>
    );
  }
);

ProductListItem.displayName = 'ProductListItem';

export default ProductListItem;
