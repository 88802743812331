import { FC, PropsWithChildren } from 'react';
import { EventsContext } from '../EventsContext';

const EventsProvider: FC<PropsWithChildren> = ({ children }) => {
  const logEvent = () => undefined;
  const sendQueuedEvents = () => undefined;

  return (
    <EventsContext.Provider value={{ logEvent, sendQueuedEvents }}>
      {children}
    </EventsContext.Provider>
  );
};

export default EventsProvider;
