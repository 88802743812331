import { ApolloClient, ApolloLink } from '@apollo/client';
import {
  InMemoryCache,
  NormalizedCacheObject,
  defaultDataIdFromObject,
} from '@apollo/client/cache';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import dayjs from 'dayjs';
import fetch from 'isomorphic-unfetch';
import getConfig from 'next/config';
import { currency, locales } from '../constants';
import introspection from '../generated/graphql';
import { getIsMobile } from './browser';
import {
  clearCookie,
  getCookie,
  longExpiryDate,
  setCookie,
  uwCookies,
} from './cookies';
import { isServerError } from './error';
import { getVersion } from './headers';

let apolloClient: ApolloClient<NormalizedCacheObject>;

interface ClientOptions {
  getHeaders: () => { [key: string]: string };
}

const authLink = () => {
  // authLink is only required for client side rendering
  if (!process.browser) {
    return new ApolloLink((operation, forward) => forward(operation));
  }

  let authExpiresOn = getCookie(uwCookies.authExpiresOn)
    ? dayjs.unix(parseInt(getCookie(uwCookies.authExpiresOn), 10))
    : undefined;
  const requestQueue: Promise<void>[] = [];

  // awaitLink enqueues a promise and awaits the previous one
  const awaitLink = setContext(async (operation) => {
    if (authExpiresOn && authExpiresOn.isBefore(dayjs())) {
      let resolvePromise: () => void;
      requestQueue.push(
        new Promise((resolve) => {
          resolvePromise = resolve;
        })
      );
      if (requestQueue.length > 1) {
        await requestQueue[requestQueue.length - 2];
      }
      operation.context = {
        ...operation.context,
        resolvePromise,
      };
    }
    return operation;
  });

  // resolveLink resolves the promise and dequeues it
  const resolveLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((data) => {
      const {
        context,
        response: { headers },
      } = operation.getContext();

      if (context?.resolvePromise) {
        context?.resolvePromise();
        requestQueue.shift();
      }

      const authExpiresOnHeader = headers?.get('x-auth-expires-on');
      if (authExpiresOnHeader) {
        const newAuthExpiresOn = dayjs.unix(parseInt(authExpiresOnHeader, 10));
        if (newAuthExpiresOn !== authExpiresOn) {
          authExpiresOn = newAuthExpiresOn;
          setCookie(
            uwCookies.authExpiresOn,
            authExpiresOnHeader,
            '/',
            longExpiryDate
          );
        }
      } else {
        if (authExpiresOn) {
          clearCookie(uwCookies.authExpiresOn, '/');
        }
        authExpiresOn = undefined;
      }

      return data;
    });
  });

  // errorLink refreshes the page if the token became invalid
  const errorLink = onError(({ networkError, graphQLErrors }) => {
    if (
      process.browser &&
      isServerError(networkError) &&
      networkError.statusCode === 401 &&
      typeof networkError.result !== 'string' &&
      networkError.result?.reason === 'INVALID_TOKEN'
    ) {
      clearCookie(uwCookies.authExpiresOn, '/');
      window.location.reload();
    }
    if (
      process.browser &&
      graphQLErrors?.length &&
      graphQLErrors.some((error) => error.extensions.isDisabledDueToClosure)
    ) {
      window.dispatchEvent(new Event('closureError'));
    }
  });

  return ApolloLink.from([errorLink, awaitLink, resolveLink]);
};

const sessionLink = (options: ClientOptions) =>
  setContext(() => {
    const headers = options.getHeaders();
    if (!process.browser) {
      return { headers };
    }

    const language = getCookie(uwCookies.language);
    if (language && locales[language]) {
      headers['x-locale'] = locales[language];
    }

    const sessionId = getCookie(uwCookies.sessionId);
    if (sessionId) {
      headers['x-uw-session-id'] = sessionId;
    }

    const { userAgent } = window.navigator;

    return {
      headers: {
        ...headers,
        'x-device': userAgent,
        'x-platform': getIsMobile(userAgent) ? 'mobileweb' : 'web',
        'x-currency': currency,
        'x-version': getVersion(),
        dpr: window.devicePixelRatio,
      },
    };
  });

function create(options: ClientOptions, initialState?: NormalizedCacheObject) {
  const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();

  return new ApolloClient<NormalizedCacheObject>({
    connectToDevTools: process.browser,
    ssrMode: !process.browser,
    link: ApolloLink.from([
      sessionLink(options),
      authLink(),
      new BatchHttpLink({
        credentials: 'include',
        uri: serverRuntimeConfig.GRAPHQL_URL || publicRuntimeConfig.GRAPHQL_URL,
        fetch: process.browser ? undefined : fetch,
        headers: options.getHeaders(),
        batchMax: 10,
        batchInterval: 10,
      }),
    ]),
    cache: new InMemoryCache({
      possibleTypes: introspection.possibleTypes,
      dataIdFromObject: (object) => {
        switch (object.__typename) {
          case 'Viewer':
          case 'Account':
          case 'AccountEmail':
            return object.__typename;
          default:
            return defaultDataIdFromObject(object);
        }
      },
      typePolicies: {
        Account: {
          merge: true,
        },
        Query: {
          fields: {
            alternatingContent: {
              merge: false,
            },
          },
        },
      },
    }).restore(initialState || {}),
  });
}

export default function getApolloClient(
  options: ClientOptions,
  initialState?: NormalizedCacheObject
) {
  // create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!process.browser) {
    return create(options, initialState);
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(options, initialState);
  }

  return apolloClient;
}
