import styled, { css } from 'styled-components';

const heading = css`
  color: ${({ theme }) => theme.colors.CG1};
`;

export const Heading1 = styled.h1`
  ${heading};
  font-size: 4rem;
  font-weight: ${({ theme }) => theme.fontWeights.demi};
  line-height: 4.8rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMaxWidth}) {
    font-size: 3.2rem;
    line-height: 4rem;
  }
`;

export const Heading2 = styled.h2`
  ${heading};
  font-size: 3.2rem;
  font-weight: ${({ theme }) => theme.fontWeights.demi};
  line-height: 3.6rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMaxWidth}) {
    font-size: 2.4rem;
    line-height: 3.2rem;
  }
`;

export const Heading3 = styled.h3`
  ${heading}
  font-size: 2.4rem;
  font-weight: ${({ theme }) => theme.fontWeights.demi};
  line-height: 3.2rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMaxWidth}) {
    font-size: 2rem;
    line-height: 2.8rem;
  }
`;

export const Heading4 = styled.h4`
  ${heading};
  font-size: 2rem;
  font-weight: ${({ theme }) => theme.fontWeights.demi};
  line-height: 2.8rem;
`;

export const Heading5 = styled.h5`
  ${heading};
  font-size: 1.6rem;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: 2.2rem;
`;

export const Heading6 = styled.h6`
  ${heading};
  font-size: 1.6rem;
  font-weight: ${({ theme }) => theme.fontWeights.book};
  line-height: 2.2rem;
`;

export const BodyText = styled.p`
  color: ${({ theme }) => theme.colors.CG2};
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-weight: ${({ theme }) => theme.fontWeights.book};
`;

export const UserInputText = styled(BodyText)`
  color: ${({ theme }) => theme.colors.CG1};
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-weight: ${({ theme }) => theme.fontWeights.book};
`;

export const Callout = styled(BodyText)`
  color: ${({ theme }) => theme.colors.CG3};
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: ${({ theme }) => theme.fontWeights.book};
`;

export const Caption1 = styled.span`
  color: ${({ theme }) => theme.colors.CG3};
  font-size: 1.3rem;
  line-height: 1.9rem;
  font-weight: ${({ theme }) => theme.fontWeights.book};
`;

export const Caption2 = styled(Caption1)`
  color: ${({ theme }) => theme.colors.CG3};
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: ${({ theme }) => theme.fontWeights.book};
`;
