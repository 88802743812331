import { AuthContext } from '@/common/context/AuthContext';
import { EventsContext } from '@/common/context/EventsContext';
import { TodoContext } from '@/common/context/TodoContext';
import {
  useFavoriteProductMutation,
  useUnfavoriteProductMutation,
} from '@/common/generated/graphql';
import { useRouter } from 'next/router';
import { ButtonHTMLAttributes, FC, MouseEvent, useContext } from 'react';

export type FavoriteEntryPoint =
  | 'Checklist'
  | 'Feed'
  | 'Item_page'
  | 'Closet'
  | 'Favorites'
  | 'Item_page_closet'
  | 'Item_page_related'
  | 'Add_product'
  | 'Home';

const ToggleFavoriteButton: FC<
  {
    productId: string;
    viewerHasFavorited: boolean;
    entryPoint: FavoriteEntryPoint;
  } & ButtonHTMLAttributes<HTMLButtonElement>
> = ({ productId, viewerHasFavorited, entryPoint, onClick, ...props }) => {
  const { isAuthorized } = useContext(AuthContext);
  const { handleTodoFetching } = useContext(TodoContext);
  const { logEvent } = useContext(EventsContext);
  const [favoriteProduct] = useFavoriteProductMutation({
    variables: {
      productId,
    },
    optimisticResponse: {
      __typename: 'Mutation',
      favoriteProduct: {
        __typename: 'FavoriteProductPayload',
        product: {
          __typename: 'Product',
          id: productId,
          viewerHasFavorited: true,
        },
      },
    },
    errorPolicy: 'all',
  });

  const [unfavoriteProduct] = useUnfavoriteProductMutation({
    variables: {
      productId,
    },
    optimisticResponse: {
      __typename: 'Mutation',
      unfavoriteProduct: {
        __typename: 'UnfavoriteProductPayload',
        product: {
          __typename: 'Product',
          id: productId,
          viewerHasFavorited: false,
        },
      },
    },
    errorPolicy: 'all',
  });

  const router = useRouter();

  const toggleFavorite = async (event: MouseEvent<HTMLButtonElement>) => {
    if (isAuthorized) {
      if (viewerHasFavorited) {
        await unfavoriteProduct();
        await handleTodoFetching();
      } else {
        await favoriteProduct();
        logEvent('favorite', {
          entry_point: entryPoint,
          product_id: productId,
        });
        await handleTodoFetching();
      }
    } else {
      router
        .push(
          `/register?redirect_uri=${encodeURIComponent(`product/${productId}`)}`
        )
        .then(() => {
          window.scrollTo(0, 0);
        });
    }

    if (onClick) {
      onClick(event);
    }
  };

  return <button onClick={toggleFavorite} {...props} />;
};

export default ToggleFavoriteButton;
