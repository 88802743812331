import getConfig from 'next/config';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { FC, useContext } from 'react';
import { appStoreId, defaultLanguage } from '../../constants';
import { LanguageContext } from '../../context/LanguageContext';
import { theme } from '../../styles/theme';
import {
  getFacebookUrl,
  getInstagramUrl,
  getLinkedInUrl,
} from '../../utils/externalUrls';
import { canonicalUrl, webUrl } from '../../utils/url';

const { publicRuntimeConfig } = getConfig();

const MetaTags: FC = () => {
  const router = useRouter();
  const { language, translate } = useContext(LanguageContext);

  const pageTitle =
    translate('home_app_title') + ' | ' + translate('gen_app_name');

  const schema = JSON.stringify(
    {
      '@context': 'http://schema.org',
      '@type': 'Organization',
      name: 'Vinted',
      alternateName: 'Vinted B.V.',
      url: webUrl(),
      logo: '/logo.png',
      sameAs: [getFacebookUrl(), getLinkedInUrl(), getInstagramUrl()],
      potentialAction: {
        '@type': 'SearchAction',
        target: webUrl(defaultLanguage, 'feed?q={query}'),
        query: 'required',
        'query-input': 'required name=query',
      },
    },
    null,
    2
  );

  // The server side render doesn't seem to include the language while the client side render does
  const url = webUrl(language, router.asPath);

  return (
    <>
      <Head>
        <title>{pageTitle}</title>

        <meta property="fb:app_id" content={publicRuntimeConfig.FB_APP_ID} />
        <meta
          name="description"
          content={translate('gen_default_descr')}
          key="description"
        />
        <meta
          name="keywords"
          content={translate('gen_default_keywords')}
          key="keywords"
        />
        <link rel="shortcut icon" type="image/x-icon" href="/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" key="manifest" href="/manifest.json" />

        <meta property="og:site_name" content={translate('gen_app_name')} />
        <meta property="og:type" content="website" key="og:type" />
        <meta
          property="og:title"
          content={translate('home_header_title')}
          key="og:title"
        />
        <meta
          property="og:image"
          content={`/share-banner-${language}.jpg`}
          key="og:image"
        />
        <meta
          property="og:description"
          content={translate('gen_default_descr')}
          key="og:description"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@vinted" />

        <meta name="apple-itunes-app" content={`app-id=${appStoreId}`} />

        <meta name="theme-color" content={theme.colors.CalypsoCP1} />

        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <link rel="canonical" key="canonical" href={canonicalUrl(url)} />
        <meta property="og:url" content={url} key="og:url" />
      </Head>
      <Script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: schema }}
      />
    </>
  );
};

export default MetaTags;
