import { forwardRef, PropsWithChildren, useEffect, useRef } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import useOutsideClick from '../hooks/useOutsideClick';

const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  overflow: auto;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.4);
`;

const Modal = styled.section`
  position: relative;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.CG7};
  width: calc(100% - 50px);
  max-width: 560px;
  margin: 86px auto;
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMaxWidth}) {
    margin: 54px auto;
  }
`;

export type ModalProps<ReturnType = any> = {
  onRequestClose: (value?: ReturnType) => void;
  className?: string;
  zIndex?: number;
} & PropsWithChildren;

const ModalOverlay = forwardRef<HTMLDivElement, ModalProps>(
  ({ children, onRequestClose, className, zIndex, ...props }, ref) => {
    const modalRef = useRef<HTMLDivElement>();
    useOutsideClick(modalRef, onRequestClose);

    useEffect(() => {
      const handleKeydown = (event: KeyboardEvent) => {
        switch (event.key) {
          case 'Escape':
            onRequestClose();
            break;
        }
      };
      window.addEventListener('keydown', handleKeydown);
      return () => window.removeEventListener('keydown', handleKeydown);
    });

    return (
      <Overlay {...props} style={{ zIndex }} ref={ref}>
        <GlobalStyle />
        <Modal className={className} ref={modalRef}>
          {children}
        </Modal>
      </Overlay>
    );
  }
);

ModalOverlay.displayName = 'ModalOverlay';

export default ModalOverlay;
