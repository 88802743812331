import { useRouter } from 'next/router';
import { transparentize } from 'polished';
import { FC, PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { v4 } from 'uuid';
import { MessageContext } from '../MessageContext';

const MessagesContainer = styled.div`
  position: fixed;
  bottom: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
  z-index: 99;

  @media (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobileMaxWidth}) {
    bottom: 40px;
  }
`;

const MessageContainer = styled.div<{ isAnimated?: boolean }>`
  background-color: ${({ theme }) => theme.colors.CG7};
  border-radius: 4px;
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0px 8px 24px
    ${({ theme }) => transparentize(0.76, theme.colors.CG1)};
  margin: 24px 20px 0 20px;
  pointer-events: initial;
  animation: ${({ isAnimated }) =>
    isAnimated
      ? 'fade-in 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) both'
      : 'none'};

  @media (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobileMaxWidth}) {
    padding: 8px 10px 8px 8px;
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const CloseButton = styled.div`
  position: relative;
  margin-left: 16px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-image: ${() =>
    `url(${require('@/modules/assets/icons/close.svg')})`};

  &:before {
    content: '';
    display: block;
    background-color: transparent;
    position: absolute;
    top: -4px;
    left: -4px;
    bottom: -4px;
    right: -4px;
    transition: background-color 0.2s ease-in;
    border-radius: 50%;
    pointer-events: none;
  }

  &:hover:before {
    background-color: ${({ theme }) => transparentize(0.98, theme.colors.CG1)};
  }

  &:active:before {
    background-color: ${({ theme }) => transparentize(0.92, theme.colors.CG1)};
  }

  @media (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobileMaxWidth}) {
    width: 16px;
    height: 16px;
    background-image: ${() =>
      `url(${require('@/modules/assets/icons/close-small.svg')})`};
  }
`;

interface Message {
  id: string;
  node: ReactNode;
  hideOnRouteChange: boolean;
  hideCloseButton?: boolean;
  isAnimated?: boolean;
}

const MessageProvider: FC<PropsWithChildren> = ({ children }) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const router = useRouter();

  useEffect(() => {
    const handleOnRouteChangeComplete = () => {
      messages.forEach((message) => {
        if (message.hideOnRouteChange) {
          hideMessage(message.id);
        }
      });
    };
    router.events.on('routeChangeComplete', handleOnRouteChangeComplete);
    return () =>
      router.events.off('routeChangeComplete', handleOnRouteChangeComplete);
  }, [router.events, messages]);

  const hideMessage = (id: string) => {
    setMessages((messages) => messages.filter((message) => message.id !== id));
  };

  const showMessage = (
    getNode: (props: { hideMessage: () => void }) => ReactNode,
    options?: {
      removeDelay?: number;
      hideOnRouteChange?: boolean;
      hideCloseButton?: boolean;
      isAnimated?: boolean;
    }
  ) => {
    const { removeDelay, hideOnRouteChange, hideCloseButton, isAnimated } = {
      removeDelay: 0,
      hideOnRouteChange: true,
      hideCloseButton: false,
      isAnimated: false,
      ...options,
    };
    const id = v4();
    const node = getNode({
      hideMessage: () => hideMessage(id),
    });

    const message: Message = {
      id,
      node,
      hideOnRouteChange,
      hideCloseButton,
      isAnimated,
    };

    setMessages((messages) => [...messages, message]);
    if (removeDelay) {
      setTimeout(() => hideMessage(message.id), removeDelay);
    }
  };

  return (
    <MessageContext.Provider value={{ showMessage }}>
      {children}
      {messages.length ? (
        <MessagesContainer>
          {messages.map((message) => {
            return (
              <MessageContainer
                isAnimated={message.isAnimated}
                key={message.id}
              >
                {message.node}
                {!message.hideCloseButton && (
                  <CloseButton onClick={() => hideMessage(message.id)} />
                )}
              </MessageContainer>
            );
          })}
        </MessagesContainer>
      ) : undefined}
    </MessageContext.Provider>
  );
};

export const MessageProviderConsumer = MessageContext.Consumer;

export default MessageProvider;
