import ProductDeleteModalOverlay from '@/modules/product/components/ProductDeleteModalOverlay';
import Link from 'next/link';
import { FC, useContext, useState } from 'react';
import styled from 'styled-components';
import { LanguageContext } from '../../context/LanguageContext';
import { ListProductFragment, ProductState } from '../../generated/graphql';
import ContextMenu from '../ContextMenu/ContextMenu';
import ContextMenuButton from '../ContextMenu/ContextMenuButton';
import ContextMenuItem from '../ContextMenu/ContextMenuItem';

const StyledContextMenu = styled(ContextMenu)`
  padding-bottom: 8px;
`;

const ProductListItemContextMenuButton: FC<{
  product: ListProductFragment;
  className?: string;
}> = ({ className, product }) => {
  const { translate } = useContext(LanguageContext);
  const [isModalOpened, setIsModalOpened] = useState(false);

  const hasCompleteVerification =
    product.viewerIsSeller && product.state === ProductState.UserPendingKyc;
  const hasEditProduct =
    product.viewerIsSeller &&
    ![
      ProductState.Blocked,
      ProductState.Deleted,
      ProductState.BeingPaid,
      ProductState.Sold,
    ].includes(product.state);
  const hasDeleteProduct =
    product.viewerIsSeller &&
    ![
      ProductState.Deleted,
      ProductState.Sold,
      ProductState.BeingPaid,
      ProductState.UnderReview,
    ].includes(product.state);

  if (!hasCompleteVerification && !hasEditProduct && !hasDeleteProduct) {
    return null;
  }

  return (
    <>
      {isModalOpened ? (
        <ProductDeleteModalOverlay
          productId={product.id}
          onRequestClose={() => setIsModalOpened(false)}
        />
      ) : null}
      <StyledContextMenu
        alignContextMenu={'bottom-right'}
        className={className}
      >
        <ContextMenuButton />
        <>
          {hasCompleteVerification ? (
            <Link href={'/complete-verification'} passHref>
              <ContextMenuItem as={'a'}>
                {translate('complete_verification_title')}
              </ContextMenuItem>
            </Link>
          ) : null}
          {hasEditProduct ? (
            <Link href={`/product/${product.id}/edit`} passHref>
              <ContextMenuItem as={'a'}>
                {translate('add_product_edit_product')}
              </ContextMenuItem>
            </Link>
          ) : null}
          {hasDeleteProduct ? (
            <ContextMenuItem onClick={() => setIsModalOpened(true)}>
              {translate('gen_delete')}
            </ContextMenuItem>
          ) : null}
        </>
      </StyledContextMenu>
    </>
  );
};

export default ProductListItemContextMenuButton;
