import { transparentize } from 'polished';
import styled from 'styled-components';
import PrimaryButton from './PrimaryButton';

const SecondaryButton = styled(PrimaryButton)`
  background-color: ${({ theme }) => theme.colors.CG7};
  color: ${({ theme }) => theme.colors.CalypsoCP1};
  border: 1px solid ${({ theme }) => theme.colors.CalypsoCP1};

  &:hover {
    background-color: ${({ theme }) =>
      transparentize(0.96, theme.colors.CalypsoCP1)};
    color: ${({ theme }) => theme.colors.CalypsoCP1};
  }
`;

export default SecondaryButton;
