import { Heading6 } from '@/modules/design/components/textstyles';
import { FilterLink } from '@/modules/feed/components/ProductFilters/FilterLink';
import { linearGradient } from 'polished';
import { FC } from 'react';
import styled, { css } from 'styled-components';
import { ProductTag } from '../../generated/graphql';

const StyledTagList = styled.div<{ wrapped?: boolean }>`
  padding-bottom: 8px;
  position: relative;
  box-sizing: content-box;

  ${({ wrapped }) =>
    wrapped
      ? null
      : css`
          height: 32px;
          overflow: hidden;

          &:after,
          &:before {
            position: absolute;
            content: '';
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            width: 16px;
            top: 0;
            pointer-events: none;
          }

          &:after {
            right: 0;
            ${linearGradient({
              colorStops: ['rgba(255,255,255,0)', '#fff'],
              toDirection: 'to right',
            })}
          }

          &:before {
            left: 0;
            ${linearGradient({
              colorStops: ['rgba(255,255,255,0)', '#fff'],
              toDirection: 'to left',
            })}
          }
          @media (max-width: ${({ theme: { breakpoints } }) =>
              breakpoints.mobileMaxWidth}) {
            height: 28px;
          }
        `}
`;

const TagsOuterWrapper = styled.section`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  align-items: center;
  flex: 1;
  height: 100%;
  overflow-x: auto;

  -ms-overflow-style: none; /* IE 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const TagsWrapper = styled.section<{ wrapped?: boolean }>`
  display: flex;
  flex-direction: row;
  ${({ wrapped }) =>
    wrapped
      ? css`
          flex-wrap: wrap;
        `
      : css`
          padding-left: 8px;
          padding-right: 8px;
        `}
`;

const Tag = styled(Heading6)<{ wrapped?: boolean }>`
  display: flex;
  height: 32px;
  border-radius: 16px;
  padding: 0 16px;
  border: solid 1px #bbbbbb;
  align-items: center;
  margin: ${({ wrapped }) => (wrapped ? '0 8px 8px 0' : '0 8px 0 0')};
  cursor: pointer;
  white-space: ${({ wrapped }) => (wrapped ? 'normal' : 'nowrap')};

  &:hover {
    color: #000;
  }

  @media (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobileMaxWidth}) {
    font-size: 12px;
    height: 28px;
  }
`;

interface Props {
  tags: ProductTag[];
  wrap?: boolean;
}

export const TagList: FC<Props> = ({ tags, wrap, ...props }) => {
  if (wrap) {
    return (
      <StyledTagList {...props} wrapped>
        <TagsWrapper wrapped>
          {tags.map((tag) => (
            <FilterLink filters={{ query: tag.name }} key={tag.name}>
              <Tag as="a" wrapped>
                {tag.name}
              </Tag>
            </FilterLink>
          ))}
        </TagsWrapper>
      </StyledTagList>
    );
  }
  return (
    <StyledTagList {...props}>
      <TagsOuterWrapper>
        <TagsWrapper>
          {tags.map((tag, i) => (
            <FilterLink filters={{ query: tag.name }} key={i}>
              <Tag as="a">{tag.name}</Tag>
            </FilterLink>
          ))}
        </TagsWrapper>
      </TagsOuterWrapper>
    </StyledTagList>
  );
};
