import MobileDetect from 'mobile-detect';

export const getIsMobile = (userAgent: string) => {
  const mobileDetect = new MobileDetect(userAgent);
  return !!mobileDetect.mobile();
};

export const getIsBot = (userAgent: string) => {
  const mobileDetect = new MobileDetect(userAgent);
  return mobileDetect.is('bot');
};

export const getIsAndroid = (userAgent: string) => {
  const mobileDetect = new MobileDetect(userAgent);
  return mobileDetect.os() === 'AndroidOS';
};

export const getIsIOS = (userAgent: string) => {
  const mobileDetect = new MobileDetect(userAgent);
  return mobileDetect.os() === 'iOS';
};
