import { AuthContext } from '@/common/context/AuthContext';
import { EventsContext } from '@/common/context/EventsContext';
import { ExperimentContext } from '@/common/context/ExperimentContext';
import { getClosureUrl } from '@/common/utils/url';
import PrimaryButton from '@/modules/design/components/buttons/PrimaryButton';
import SecondaryButton from '@/modules/design/components/buttons/SecondaryButton';
import { BodyText, Heading3 } from '@/modules/design/components/textstyles';
import { useRouter } from 'next/router';
import { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import ModalOverlay from '../../../common/components/ModalOverlay';
import { LanguageContext } from '../../../common/context/LanguageContext';

const StyledModalOverlay = styled(ModalOverlay).attrs({ zIndex: 1001 })`
  max-width: 335px;
  padding: 40px 20px;
`;

const Title = styled(Heading3)`
  margin-bottom: 8px;
`;

const LearnMoreButton = styled(PrimaryButton)`
  margin-top: 40px;
  width: 100%;
  max-width: none;
`;

const CloseButton = styled(SecondaryButton)`
  margin-top: 12px;
  width: 100%;
  max-width: none;
  border: none;
`;

const PATH_ALLOW_LIST = [
  '/settings',
  '/',
  '/login',
  '/forgot-password',
  '/user/[slug]/reset-password/[key]',
  '/user/[slug]/activate/[key]',
  '/404',
];

const ClosureError: FC = () => {
  const [isModalShown, setIsModalShown] = useState(false);
  const { translate, language } = useContext(LanguageContext);
  const { logEvent } = useContext(EventsContext);
  const router = useRouter();
  const { closure } = useContext(ExperimentContext);
  const { isAuthorized } = useContext(AuthContext);

  useEffect(() => {
    if (closure.isInVariant && !PATH_ALLOW_LIST.includes(router.pathname)) {
      router.replace(isAuthorized ? '/settings' : '/');
    }
  }, [router.pathname]);

  useEffect(() => {
    const showModal = () => {
      setIsModalShown(true);
      logEvent('error_not_possible');
    };
    window.addEventListener('closureError', showModal);
    return () => {
      window.removeEventListener('closureError', showModal);
    };
  }, [setIsModalShown]);

  if (!isModalShown) {
    return null;
  }

  const handleOnRequestClose = () => {
    setIsModalShown(false);
    logEvent('close_info_closure_banner');
  };

  const handleOnLearnMoreClick = () => {
    logEvent('info_closure_banner');
  };

  return (
    <StyledModalOverlay onRequestClose={handleOnRequestClose}>
      <Title>{translate('closure_feat_unavailable_title')}</Title>
      <BodyText>{translate('closure_feat_unavailable_expl')}</BodyText>
      <LearnMoreButton
        as="a"
        target="_blank"
        href={getClosureUrl(language)}
        onClick={handleOnLearnMoreClick}
      >
        {translate('closure_learn_more_cta')}
      </LearnMoreButton>
      <CloseButton
        onClick={() => {
          setIsModalShown(false);
        }}
      >
        {translate('gen_close')}
      </CloseButton>
    </StyledModalOverlay>
  );
};

export default ClosureError;
