import { darken } from 'polished';
import styled, { css } from 'styled-components';

const PrimaryButton = styled.button<{ disabled?: boolean }>`
  display: inline-flex;
  min-height: 52px;
  background-color: ${({ theme }) => theme.colors.CalypsoCP1};
  align-items: center;
  justify-content: center;
  padding: 12px 20px;
  min-width: 240px;
  max-width: 400px;
  font-size: 1.6rem;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: 2.2rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.CG7};
  border-radius: 4px;
  transition: background-color 0.2s ease-in-out;
  border: none;
  outline: none;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => darken(0.004, theme.colors.CalypsoCP1)};
    color: ${({ theme }) => theme.colors.CG7};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      transition: none;
      background-color: #ccc !important;
      cursor: default;
    `};
`;

export default PrimaryButton;
