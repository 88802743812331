import { FC, SVGAttributes } from 'react';

const Heart: FC<
  SVGAttributes<SVGElement> & { filled?: boolean; borderColor?: string }
> = ({ filled, borderColor, ...props }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {filled ? (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.2095 4C18.6388 4 16.0002 6.984 16.0002 6.984C16.0002 6.984 13.3602 4 9.79082 4C8.60416 4 7.31616 4.32933 5.98682 5.20667C1.11349 8.42533 1.86282 15.2333 6.55349 19.7747C10.9348 24.016 16.0002 27.9947 16.0002 27.9947C16.0002 27.9947 21.0655 24.016 25.4468 19.7747C30.1375 15.2333 30.8868 8.42533 26.0135 5.20667C24.6842 4.32933 23.3962 4 22.2095 4Z"
          fill="#A43954"
        />
      ) : (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.2092 4C18.6386 4 15.9999 6.984 15.9999 6.984C15.9999 6.984 13.3599 4 9.79058 4C8.60391 4 7.31591 4.32933 5.98658 5.20667C1.11325 8.42533 1.86258 15.2333 6.55325 19.7747C10.9346 24.016 15.9999 27.9947 15.9999 27.9947C15.9999 27.9947 21.0652 24.016 25.4466 19.7747C30.1372 15.2333 30.8866 8.42533 26.0132 5.20667C24.6839 4.32933 23.3959 4 22.2092 4ZM22.2092 6C23.1346 6 24.0186 6.28667 24.9106 6.876C26.3226 7.808 27.1506 9.184 27.3066 10.856C27.5386 13.3467 26.3226 16.1427 24.0559 18.3373C21.0852 21.2133 17.7546 23.9987 15.9999 25.428C14.2452 23.9987 10.9146 21.2133 7.94391 18.3373C5.67725 16.1427 4.46125 13.3467 4.69325 10.856C4.84925 9.184 5.67725 7.808 7.08925 6.876C7.98125 6.28667 8.86524 6 9.79058 6C12.3866 6 14.4879 8.29333 14.5026 8.30933L15.9919 9.97733L17.4919 8.316C17.5132 8.29333 19.6132 6 22.2092 6Z"
          fill={borderColor || '#666666'}
        />
      )}
    </svg>
  );
};

export default Heart;
