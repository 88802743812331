import ClosureError from '@/modules/closure/components/ClosureError';
import getConfig from 'next/config';
import { FC, PropsWithChildren } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { InitialDataComponent } from '../../generated/graphql';
import AppleProvider from './AppleProvider';
import AuthProvider from './AuthProvider';
import EventsProvider from './EventsProvider';
import ExperimentProvider from './ExperimentProvider';
import FacebookProvider from './FacebookProvider';
import FiltersProvider from './FiltersProvider';
import LanguageProvider, { TranslationsResponse } from './LanguageProvider';
import MessageProvider from './MessageProvider';
import MobileOverlayProvider from './MobileOverlayProvider';
import StaticDataProvider from './StaticDataProvider';
import TodoProvider from './TodoProvider';

const { publicRuntimeConfig } = getConfig();

export const AppProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <InitialDataComponent>
      {({ data, loading }) => {
        if (loading) {
          return null;
        }

        const parsedTranslationData = JSON.parse(
          data.legacyTranslations
        ) as TranslationsResponse;

        return (
          <EventsProvider>
            <LanguageProvider translations={parsedTranslationData.translations}>
              <GoogleReCaptchaProvider
                reCaptchaKey={publicRuntimeConfig.RECAPTCHA_SITE_KEY}
              >
                <AuthProvider>
                  <ExperimentProvider>
                    <StaticDataProvider>
                      <FiltersProvider>
                        <MobileOverlayProvider>
                          <FacebookProvider>
                            <AppleProvider>
                              <MessageProvider>
                                <ClosureError />
                                <TodoProvider>{children}</TodoProvider>
                              </MessageProvider>
                            </AppleProvider>
                          </FacebookProvider>
                        </MobileOverlayProvider>
                      </FiltersProvider>
                    </StaticDataProvider>
                  </ExperimentProvider>
                </AuthProvider>
              </GoogleReCaptchaProvider>
            </LanguageProvider>
          </EventsProvider>
        );
      }}
    </InitialDataComponent>
  );
};

export default AppProvider;
