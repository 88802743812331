import { AlternatingContentItemFragment } from '@/common/generated/graphql';
import { FC } from 'react';
import HTMLFeedItem from './HTMLFeedItem';
import UserSizesItem from './UserSizesItem';

const AlternatingContentItem: FC<{
  alternatingContentItem: AlternatingContentItemFragment;
  className?: string;
}> = ({ alternatingContentItem, className }) => {
  const item = alternatingContentItem.item;

  switch (item.__typename) {
    case 'UserSizesItem':
      return <UserSizesItem className={className} />;
    case 'HTMLFeedItem':
      return <HTMLFeedItem className={className} item={item} />;
    default:
      return null;
  }
};

export default AlternatingContentItem;
