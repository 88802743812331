import { getIsAndroid, getIsBot, getIsIOS } from '@/common/utils/browser';
import { getCookie, setCookie, uwCookies } from '@/common/utils/cookies';
import { useRouter } from 'next/router';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { MobileOverlayContext } from '../MobileOverlayContext';

const MobileOverlayProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isMobileOverlayVisible, setIsMobileOverlayVisible] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const { userAgent } = window.navigator;
    const isMobileOverlayDismissed =
      getCookie(uwCookies.mobileOverlayDismissed) === '1';

    // Only show the overlay for mobile non-bots
    if (
      !isMobileOverlayDismissed &&
      !getIsBot(userAgent) &&
      (getIsAndroid(userAgent) || getIsIOS(userAgent))
    ) {
      setIsMobileOverlayVisible(true);
    }

    if (router.pathname.includes('/product/[slug]')) {
      setIsMobileOverlayVisible(false);
    }
  }, [router.pathname]);

  const hideMobileOverlayVisible = () => {
    setCookie(uwCookies.mobileOverlayDismissed, '1');
    setIsMobileOverlayVisible(false);
  };

  return (
    <MobileOverlayContext.Provider
      value={{
        isMobileOverlayVisible,
        hideMobileOverlayVisible,
      }}
    >
      {children}
    </MobileOverlayContext.Provider>
  );
};

export default MobileOverlayProvider;
